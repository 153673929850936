const formatCargoItemSum = (
  t,
  {
    totalWeight,
    totalVolume
  }
) => {
  const builder = [];

  builder.push(`${t("core:cargo.item.sum")}: `);

  if (totalWeight !== undefined) {
    builder.push(` ${totalWeight} kg`);
  }
  
  if (totalVolume !== undefined) {
    builder.push(`, ${totalVolume} m³`);
  }

  return builder.join("");
};

export default formatCargoItemSum;
