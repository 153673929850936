import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  makeStyles
} from "@material-ui/core";

import { useTranslation } from "react-i18next";

import { formatDateTime }
  from "../../../../../multiload/cargotic-common";

import FileDropzone
  from "../../../../../multiload/cargotic-webapp/component/FileDropzone";

import FileUpload
  from "../../../../../multiload/cargotic-webapp/component/FileUpload";

import useMediaQuery from "@material-ui/core/useMediaQuery";

const StampCard = ({
  classes,

  file,
  hasDocumentFileDropzoneError,
  handleDocumentFileDropzoneError,
  handleStampSignatureFileSelect,
  handleFileDelete
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Card className={classes.card}>
        <CardHeader title={t("settings.stamp.title")} />
        <CardContent>
          {!file ? (
            <Grid container spacing={1} alignItems="center" justifyContent="center">
              <FileDropzone
                multipleFiles={false}
                accept={["image/jpeg", "image/png"]}
                title={
                  hasDocumentFileDropzoneError
                    ? t("settings.stamp.error.general")
                    : t("settings.stamp.upload.title")
                }
                description={[
                  t("settings.stamp.upload.supported"),
                  t("settings.stamp.upload.limit")
                ]}
                hasError={hasDocumentFileDropzoneError}
                onError={handleDocumentFileDropzoneError}
                onSelect={(file) => handleStampSignatureFileSelect(file[0])}
              />
            </Grid>
          ) : undefined}
          {file ? (
            <Grid container>
              <React.Fragment key={1}>
                <Grid item xs={1} />
                <Grid item xs={11}>
                  <FileUpload
                    className={classes.upload}
                    title={file.name}
                    description={
                      file.createdAt
                        ? formatDateTime(file.createdAt)
                        : undefined
                    }
                    url={file.url}
                    isUploading={file.isUploading}
                    error={file.error}
                    onDelete={() => handleFileDelete(file.id)}
                  />
                </Grid>
              </React.Fragment>
            </Grid>
          ) : undefined}
        </CardContent>
      </Card>
    </>
  );
};

export default StampCard;
