import React from "react";
import { useTranslation } from "react-i18next";

import { Typography, makeStyles, Grid } from "@material-ui/core";

import { formatCargoItem, formatCargoItemSum } from "../../../cargotic-core";
import { getCargoItemActionIcon } from "../../utility";

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    display: "flex",
    marginLeft: spacing(3)
  },
  icon: {
    color: palette.text.secondary
  },
  text: {
    marginLeft: spacing(1)
  }
}));

const CargoItemListItem = ({ item }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { action } = item;
  const Icon = getCargoItemActionIcon(action);

  return (
    <div className={classes.root}>
      <Icon className={classes.icon} />
      <Grid container>
        <Grid item xs={12}>
          <Typography
            className={classes.text}
            color="textSecondary"
          >
            {formatCargoItem(t, item)}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            className={classes.text}
            color="textSecondary"
          >
            {formatCargoItemSum(t, item)}
      </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default CargoItemListItem;
