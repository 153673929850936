import React from "react";

import { Autosuggest, AutosuggestProps } from "@cargotic/common-deprecated";
import { TextField, TextFieldProps } from "@material-ui/core";

import { Form, FormValues } from "../../form";

type FormAutosuggestTextFieldProps<S, T extends FormValues> = {
  form: Form<T>;
  name: keyof T;
} & Omit<AutosuggestProps<S>, "inputComponent"> & TextFieldProps;

function FormAutosuggestTextField<S, T extends FormValues>(
  {
    name,
    form: {
      errors,
      touches,
      values,
      handleBlur,
      handleChange
    },
    InputProps,
    ...other
  }: FormAutosuggestTextFieldProps<S, T>
): React.ReactElement {
  const isTouched = touches[name];
  const error = isTouched ? errors[name] : undefined;
  const hasError = error !== undefined;
  const errorMessage = error?.message || undefined;
  const value = values[name];

  const formProps = {
    name,
    value,
    error: hasError,
    helperText: errorMessage ?? error?.name ?? " ",
    onBlur: handleBlur,
    onChange: handleChange
  };

  return (
    // @ts-ignore
    <Autosuggest
      {...other}
      {...formProps}
      inputComponent={TextField}
      InputProps={InputProps}
    />
  );
}

export default FormAutosuggestTextField;
export {
  FormAutosuggestTextFieldProps
};
