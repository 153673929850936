import { createContext } from "react";

import { ExchangeRates } from "../currency";

interface ExchangeRatesContextLoadingProps {
  isLoading: true;
  exchangeRates: undefined;
  exchangeRatesEUR: undefined;
  refetch: () => Promise<void>;
}

interface ExchangeRatesContextLoadedProps {
  isLoading: false;
  exchangeRates: ExchangeRates;
  exchangeRatesEUR: ExchangeRates;
  refetch: () => Promise<void>;
}

type ExchangeRatesContextProps = ExchangeRatesContextLoadedProps
  | ExchangeRatesContextLoadingProps;

// @ts-ignore
const ExchangeRatesContext = createContext<ExchangeRatesContextProps>();

export default ExchangeRatesContext;
export {
  ExchangeRatesContextProps
};
