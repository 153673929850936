import clsx from "clsx";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { CircularProgress, makeStyles } from "@material-ui/core";

import ContactEmployeeDeleteConfirmationAlert
  from "../ContactEmployeeDeleteConfirmationAlert";

import ContactEmployeeEditor from "../ContactEmployeeEditor";
import ContactEmployeeGrid from "../ContactEmployeeGrid";
import ContactEmployeeOverviewSwitchboard
  from "./ContactEmployeeOverviewSwitchboard";

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: "flex",
    flexDirection: "column",

    "& > :not(:first-child)": {
      marginTop: spacing(4)
    }
  },
  content: {
    flex: 1
  }
}));

const ContactEmployeeOverview = ({
  className,
  contact: {
    id,
    name
  },
  employees,
  query,
  selectedEmployee,
  isEditorOpen,
  isDeleteConfirmationAlertOpen,
  isLoading,
  onBreadcrumbsChange,
  onDeleteConfirmationAlertClose,
  onDeleteConfirmationAlertSubmit,
  onEditorClose,
  onEditorSubmit,
  onEmployeeAdd,
  onEmployeeDelete,
  onEmployeeEdit,
  onQueryChange
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleEmployeeAddButtonClick = () => {
    if (onEmployeeAdd) {
      onEmployeeAdd();
    }
  };

  const handlePageChange = value => {
    if (onQueryChange && query) {
      onQueryChange({ ...query, page: value });
    }
  };

  const handleRowsPerPageChange = value => {
    if (onQueryChange && query) {
      onQueryChange({ ...query, rowsPerPage: value });
    }
  };

  const handleSearchChange = value => {
    if (onQueryChange && query) {
      onQueryChange({ ...query, match: { ...query.match, search: value } });
    }
  };

  useEffect(() => {
    onBreadcrumbsChange([
      { label: t("webapp:common.title.contacts"), href: "/contacts" },
      { label: name, href: `/contacts/${id}` },
      { label: t("webapp:common.title.employees") }
    ]);
  }, []);

  useEffect(() => {
    onQueryChange({
      match: { search: "" },
      page: 0,
      rowsPerPage: 10
    });
  }, []);

  return (
    <>
      <div className={clsx(className, classes.root)}>
        <ContactEmployeeOverviewSwitchboard
          search={query?.match?.search ?? ""}
          onSearchChange={handleSearchChange}
          onAddButtonClick={handleEmployeeAddButtonClick}
        />
        <ContactEmployeeGrid
          className={classes.content}
          employees={employees}
          page={query?.page}
          rowsPerPage={query?.rowsPerPage}
          isLoading={isLoading}
          onEmployeeDelete={onEmployeeDelete}
          onEmployeeEdit={onEmployeeEdit}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </div>
      <ContactEmployeeDeleteConfirmationAlert
        isOpen={isDeleteConfirmationAlertOpen}
        onClose={onDeleteConfirmationAlertClose}
        onSubmit={onDeleteConfirmationAlertSubmit}
      />
      <ContactEmployeeEditor
        initialValue={selectedEmployee}
        isOpen={isEditorOpen}
        onClose={onEditorClose}
        onSubmit={onEditorSubmit}
      />
    </>
  );
};

export default ContactEmployeeOverview;
