import React from "react"
import { 
  Button,
  Checkbox,
  makeStyles,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography
} from "@material-ui/core"
import SettingsIcon from '@material-ui/icons/Settings';
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  sortableText: {
    fontWeight: 1000
  },
  button: {
    display: "inline",
    backgroundColor: "white",
    margin: 2
  }
}));

const DrawSettingsIcon = ({
  tableMode,
  setDialogOpen
}) => {
  if (!tableMode) {
    return null;
  }

  const handleSettingsClick = () => {
    setDialogOpen(true)
  };

  return (
    <TableCell>
      <TableSortLabel hideSortIcon={true} onClick={handleSettingsClick} >
        <SettingsIcon /> 
      </TableSortLabel>
    </TableCell>
  );
};

const DrawCheckbox = ({
  tableMode,
  checkedAll,
  selectOnlyOne,
  onSelectAll
}) => {
  if (!tableMode) {
    return null;
  }
  return (
    <TableCell padding="checkbox">
      {!selectOnlyOne ? (
        <Checkbox
          color="primary"
          checked={checkedAll}
          onChange={({ target: { checked: selected } }) => onSelectAll(selected)}
        />
      ): null}
    </TableCell>
  );
};

const DrawTableCell = ({
  tableMode,
  name,
  label,
  isOrderable,
  width,
  ordering,
  direction,
  onSort
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  if (!tableMode && !isOrderable) {
    return null;
  }

  const TcProps = tableMode
    ? {
      width: width
     } : {
      component: Button,
      variant: "outlined",
      color: "secondary",
      className: classes.button
    };

  return (
    <TableCell {...TcProps}>
      {isOrderable ? (
        <TableSortLabel
          className={classes.sortableText}
          active={(ordering.includes(name))}
          direction={direction[ordering.indexOf(name)]}
          onClick={event => onSort(name, event.shiftKey)}
        >
          {t(label)}
        </TableSortLabel>
      ) : (
        <Typography variant="subtitle2">{t(label)}</Typography>
      )}
    </TableCell>
  );
};

const DataTableHead = ({
  tableMode,
  headers,
  actions,
  ordering,
  direction,
  checkedAll,
  selectOnlyOne,
  onSort,
  onSelectAll,
  setDialogOpen
}) => {
  const ThHead = tableMode ? TableHead : "div";
  const ThMainRow = tableMode ? TableRow : React.Fragment;
  const ThEmptyCell = (tableMode && (actions != undefined)) ? TableCell : React.Fragment;

  return (
    <ThHead>
      <ThMainRow key="header">
        <DrawCheckbox 
          tableMode={tableMode}
          checkedAll={checkedAll}
          selectOnlyOne={selectOnlyOne}
          onSelectAll={onSelectAll}
        />
        {headers.map(({
          name, label, isOrderable, width
        }) => (
          <DrawTableCell
            key={name}
            tableMode={tableMode}
            name={name}
            label={label}
            isOrderable={isOrderable}
            width={width}
            ordering={ordering}
            direction={direction}
            onSort={onSort}
          />
        ))}
        <ThEmptyCell />
      </ThMainRow>
    </ThHead>
  );
};

export default DataTableHead