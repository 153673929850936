import React from "react";

import { OutlinedInputProps, TextField } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { ParsableDate } from "@material-ui/pickers/constants/prop-types";

import { Form, FormValues } from "../../form";
import { getCleanDate } from "../../../utility/common";

interface FormDatePickerProps<T extends FormValues> {
  form: Form<T>;
  name: string;
  label?: string;
  InputProps?: Partial<OutlinedInputProps>;
  isDisabled?: boolean;
  hasFullWidth?: boolean;
  format?: any;
}

function FormDatePicker<T extends FormValues>(
  {
    form: {
      errors,
      touches,
      values,
      handleBlur,
      handleChange
    },
    name,
    label,
    InputProps,
    isDisabled,
    hasFullWidth,
    format
  }: FormDatePickerProps<T>
): React.ReactElement {
  const isTouched = touches[name];
  const error = isTouched ? errors[name] : undefined;
  const errorMessage = error?.message || undefined;
  const hasError = error !== undefined;
  const value = values[name] as ParsableDate;

  return (
    <DatePicker
      value={value}
      disabled={isDisabled}
      inputFormat={format}
      disableMaskedInput
      allowKeyboardControl
      allowSameDateSelection
      renderInput={(props) => (
        <TextField
          {...props}
          InputProps={{
            ...props.InputProps
          }}
          name={name}
          label={label}
          error={hasError}
          value={props.inputProps.value}
          onBlur={handleBlur}
          helperText={errorMessage ?? error?.name ?? " "}
          inputProps={{
            ...props.inputProps,
            placeholder: "1.1.2020"
          }}
          fullWidth={hasFullWidth}
        />)
      }
      onChange={
        // @ts-ignore
        (newValue) => handleChange({ target: { name, value: getCleanDate(newValue) } })
      }
    />
  );
}

export default FormDatePicker;
