import LengthUnit from "./LengthUnit";

const CargoItemTemplateSpecification = {
  EUR_PALLET: {
    width: 80,
    height: undefined,
    length: 120,
    lengthUnit: LengthUnit.CM,
    fullTruck: false,
    stackability: true
  },
  US_PALLET: {
    width: 100,
    height: undefined,
    length: 120,
    lengthUnit: LengthUnit.CM,
    fullTruck: false,
    stackability: true
  },
  HALF_EUR_PALLET: {
    width: 60,
    height: undefined,
    length: 80,
    lengthUnit: LengthUnit.CM,
    fullTruck: false,
    stackability: true
  },
  LDM: {
    width: 2.4,
    height: 2.7,
    length: undefined,
    lengthUnit: LengthUnit.M,
    fullTruck: false,
    stackability: false
  },
  VAN: {
    width: 2.0,
    height: 2.0,
    length: 4.2,
    lengthUnit: LengthUnit.M,
    fullTruck: true,
    stackability: false
  },
  SOLO: {
    width: 2.4,
    height: 2.7,
    length: 6.0,
    lengthUnit: LengthUnit.M,
    fullTruck: true,
    stackability: false
  },
  LKW: {
    width: 2.4,
    height: 2.7,
    length: 13.6,
    lengthUnit: LengthUnit.M,
    fullTruck: true,
    stackability: false
  },
  TANDEM: {
    width: 2.4,
    height: 3.0,
    length: 15.4,
    lengthUnit: LengthUnit.M,
    fullTruck: true,
    stackability: false
  },
  BOX_SEMI_TRAILER: {
    width: 2.4,
    height: 2.7,
    length: 13.6,
    lengthUnit: LengthUnit.M,
    fullTruck: true,
    stackability: false
  },
  FRIGO_SEMI_TRAILER: {
    width: 2.4,
    height: 2.7,
    length: 13.6,
    lengthUnit: LengthUnit.M,
    fullTruck: true,
    stackability: false
  },
  MEGA: {
    width: 2.4,
    height: 3.0,
    length: 13.6,
    lengthUnit: LengthUnit.M,
    fullTruck: true,
    stackability: false
  },
  BOX_SEMI_TRAILER: {
    width: 2.4,
    height: 2.7,
    length: 13.6,
    lengthUnit: LengthUnit.M,
    fullTruck: true,
    stackability: false
  },
  FRIGO_SEMI_TRAILER: {
    width: 2.4,
    height: 2.7,
    length: 13.6,
    lengthUnit: LengthUnit.M,
    fullTruck: true,
    stackability: false
  },
};

export default CargoItemTemplateSpecification;
