import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";

import {
  Avatar,
  Checkbox,
  CircularProgress,
  DialogContent,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  makeStyles,
  TextField,
  FormControlLabel,
  InputAdornment
} from "@material-ui/core";

import { getNameInitials } from "../../../cargotic-webapp-utility";

const useStyles = makeStyles(() => ({
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    height: "100%"
  }
}));

const ContactShareEditorSplittingGM = ({
  value,
  onChange
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isSplittingGMActive, setIsSplittingGMActive] = useState(false);

  useEffect(() => {
    if (isSplittingGMActive && value === undefined) {
      onChange({ userShare: 70, ownerShare: 30 });
    } else {
      onChange(undefined);
    }

  }, [isSplittingGMActive]);

  return (
    <>
      <Typography variant="h6">Rozdělení provize</Typography>
      <FormControlLabel
        control={(
          <Checkbox
            checked={!isSplittingGMActive}
            onChange={({ target: { checked } }) => setIsSplittingGMActive(!isSplittingGMActive)}
            name="isSplittingGMActive"
            color="primary"
          />
        )}
        label="Bez rozdeleni provize"
      />
      {isSplittingGMActive ? (
        <div>
          <div>
            <TextField
              fullWidth
              label={t("webapp:common.title.owner")}
              type="number"
              value={value && value.ownerShare ? value.ownerShare : undefined}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>
              }}
              onChange={({ target: { value: v } }) => onChange(isSplittingGMActive ? { ...value, ownerShare: v } : undefined)}
            />
          </div>
          <div>
            <TextField
              fullWidth
              label={t("webapp:common.title.sharedWith")}
              type="number"
              value={value && value.userShare ? value.userShare : undefined}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>
              }}
              onChange={({ target: { value: v } }) => onChange(isSplittingGMActive ? { ...value, userShare: v } : undefined)}
            />
          </div>
        </div>
      ) : (<></>)
      }
    </>
  );
};

export default ContactShareEditorSplittingGM;
