import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Grid, Typography } from "@material-ui/core";

import {
  createVehicle,
  updateVehicle,
  deleteVehicle,
  createVehiclesQuery,
  updateVehicleAvatar
} from "../../../resource";
import useAuth from "../../hook/useAuth";
import useRouter from "../../hook/useRouter";
import useTable from "../../../datatable/useTable";
import Vehicles from "./Vehicles";
import VehicleDeleteDialog from "./VehicleDeleteDialog";

import {
  loadMatchFilter,
  storeMatchFilter,
  storeSearchText,
  loadSearchText,
  storeFilters,
  loadFilters
} from "../../../storage";

import { ImageAvatar } from "../../common";
import { getNameInitials } from "../../../utility/common";
import { VehicleEditor } from "../../../../cargotic-webapp-vehicle";
import { useApiClient } from "../../../../cargotic-webapp-component";
import FilterSettings from "../../../../cargotic-webapp-filter/component/FilterSettings";

import { VehicleType } from "@cargotic/model";

import {
  addUrlParam,
  getTableUrlParams
} from "../../../utility/window"

const toDisplayable = ({
  id, vendor, name, fuel, spz, vin, height, width, length, maxLoad, date, avatarUrl
}) => ({
  id,
  title: `${vendor} ${name}`,
  avatarUrl,
  fuel,
  spz,
  vin,
  height,
  width,
  length,
  maxLoad,
  date,
  selected: false
});

const VehiclesContainer = () => {
  const client = useApiClient();
  const { t } = useTranslation();
  const { history, location: { search: routerSearch } } = useRouter();
  const { enqueueSnackbar } = useSnackbar();

  const pathname = location.pathname.slice(1);

  const {
    searchText: initSearchText,
    filter: initFilter
  } = getTableUrlParams(routerSearch);

  const [vehicles, setVehicles] = useState([]);
  const [search, setSearch] = useState(initSearchText);
  const [filter, setFilter] = useState(initFilter);
  const [defaultFilters, setDefaultFilters] = useState([]);

  const [selectedVehicle, setSelectedVehicle] = useState();
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedForUpdate, setSelectedForUpdate] = useState({});
  const [isFilterSettingsOpen, setIsFilterSettingsOpen] = useState(false);

  let storeSearchDelay;

  const loadAvailableUsers = async (search) => {
    try {
      const { matches } = await client.user.postUserMatchQuery({
        query: {
          match: { search, limit: 100 }
        }
      });
      return matches;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  };

  const handleFilterSettingsClose = () => setIsFilterSettingsOpen(false);

  const handleFilterSettingsOpen = () => setIsFilterSettingsOpen(true);

  const onFilterSettingsSubmit = (value) => {
    setIsFilterSettingsOpen(true);
    storeFilters("vehicles", value);
    setDefaultFilters(expandFilters(value, availableFilters));
    setIsFilterSettingsOpen(false);
  };

  const handleChangeVehicleAvatarImage = async (vehicle, file) => {
    try {
      await updateVehicleAvatar(vehicle.id, file);
      reloadData();
    } catch (error) {
      console.log(error);

      const { response } = error;

      if (response && response.status === 413) {
        enqueueSnackbar(t("settings.error.updatePayloadTooLarge"), {
          variant: "error"
        });
      } else {
        enqueueSnackbar(t("settings.error.update"), {
          variant: "error"
        });
      }
    }
  };

  const handleUpdateVehicleAvatar = (vehicle, file) => {
    const [vendor, name] = vehicle.name.split(" ");
    vehicle = {
      ...vehicle,
      vendor,
      name
    };

    handleChangeVehicleAvatarImage(vehicle, file);
  };

  const defaultFilterValues = ["types", "creators", "createdAt"];
  const availableFilters = [
    {
      label: t("vehicles.vehicleType"),
      value: "types"
    }, {
      label: t("vehicles.creator"),
      value: "creators"
    }, {
      label: t("vehicles.creationDate"),
      value: "createdAt"
    }];

  const VehicleAvatar = ({
    vehicle
  }) => {
    return (
      <Grid container alignItems="center" spacing={2}>
        <Grid item>
          <input
            id={`vehicle-avatar-file-input${vehicle.id}`}
            style={{ display: "none" }}
            type="file"
            accept="image/png,image/jpeg"
            onChange={async ({ target: { files } }) => {
              if (files.length !== 1) {
                return;
              }

              const file = files[0];

              if (file.type !== "image/png" && file.type !== "image/jpeg") {
                enqueueSnackbar(t("settings.changeAvatarFileTypeError"), {
                  variant: "error"
                });
                return;
              }

              handleUpdateVehicleAvatar(vehicle, file);
            }}
          />
          <Button
            size="medium"
            color="secondary"
            onClick={(event) => {
              event.preventDefault();
              document.querySelector(`#vehicle-avatar-file-input${vehicle.id}`).click();
            }}
          >
            <ImageAvatar
              source={vehicle.avatarUrl}
              text={getNameInitials(vehicle.name).toUpperCase()}
            />
          </Button>
        </Grid>
        <Grid item>
          <Typography>{vehicle.name}</Typography>
        </Grid>
      </Grid>
    );
  };

  let reloadDelay;

  const transformFilter = (filter) => ({
    ...filter,
    creators: filter.creators ? filter.creators.map(({ id }) => id) : undefined,
  });

  const reloadVehicles = useCallback(async (offset, limit, ordering) => {
    try {
      const transformedFilter = transformFilter(filter);
      let vehiclesData = [];
      let promise = client.vehicle.postVehicleMatchQuery({
        query: {
          match: { search, ...transformedFilter },
          orderBy: ordering,
          offset,
          limit
        }
      });

      let _vehicles = await promise;

      if (_vehicles.total === 0 && offset !== 0) {
        handleChangePage(
          undefined,
          0
        )
      }

      _vehicles.matches.map((vehicle) => {
        vehicle.name = `${vehicle.vendor} ${vehicle.name}`;
        vehicle.selected = false;

        let tableCells = [];
        tableCells.push({
          render: <Typography variant="body2">{vehicle.manufacturer}</Typography>
        });
        tableCells.push({
          render: <Typography variant="body2">{vehicle.model}</Typography>
        });
        tableCells.push({
          render: <Typography variant="body2"> {vehicle.licensePlate} </Typography>
        });
        tableCells.push({
          render: <Typography variant="body2"> {vehicle.vin} </Typography>
        });
        tableCells.push({
          render: <Typography variant="body2"> {t(`webapp:vehicle.type.${vehicle.type.toLowerCase()}`)} </Typography>
        });

        vehiclesData.push({ id: vehicle.id, row: tableCells, selected: false });
        return vehicle;
      });

      setVehicles(_vehicles.matches);
      return { data: vehiclesData, totalCnt: _vehicles.total };
    } catch (err) {
      console.error(err);
      enqueueSnackbar(t("vehicles.error.fetchData"), {
        variant: "error"
      });
    }
  }, [search, filter]);

  const {
    data,
    dataCount,
    selectedColumns,
    loading,
    ordering,
    direction,
    checkedAll,
    page,
    rowsPerPage,
    reloadData,
    reloadDataFromScratch,
    handleSort,
    handleSelect,
    handleSelectAll,
    handleChangePage,
    handleChangeRowsPerPage,
    handleChangeSelectedColumns
  } = useTable(reloadVehicles, "vehicles");

  const selectedVehicles = [];
  for (let index = 0; index < data.length; index++) {
    if (data[index].selected) {
      selectedVehicles.push(vehicles[index]);
    }
  }

  const handleEditorSubmit = async vehicle => {
    setIsEditorOpen(false);
    const { id: vehicleId } = selectedVehicle;

    try {
      if (vehicleId) {
        await client.vehicle.putVehicle({ vehicleId, vehicle });
        await reloadData();
      } else {
        const { id } = await client.vehicle.postVehicle({ vehicle });

        history.push(`/vehicles/${id}`);
      }
    } catch (error) {
      console.log(error);

      const { response: { data: errData } } = error;

      if (errData.type === "DuplicateEntityError") {
        enqueueSnackbar(
          t("vehicles.error.duplicate"),
          { variant: "error" }
        );
      }
      else if (vehicleId) {
        enqueueSnackbar(
          t("vehicles.error.update"),
          { variant: "error" }
        );
      }
      else {
        enqueueSnackbar(
          t("vehicles.error.create"),
          { variant: "error" }
        );
      }
    }
  };

  const handleDeleteSubmit = () => {
    setDeleteDialogOpen(false);

    const ids = selectedVehicles.map(({ id }) => id);
    const requests = ids.map(deleteVehicle);

    return Promise.all(requests)
      .then(() => {
        reloadDataFromScratch();
      })
      .catch((error) => {
        console.log(error);

        enqueueSnackbar(
          t("vehicles.error.delete"),
          { variant: "error" }
        );
      });
  };

  const handleSearch = (_search) => {
    clearTimeout(reloadDelay);
    reloadDelay = setTimeout(() => {
      setSearch(_search);
    }, 250);
  };

  const handleSelectVehicleType = (types) => setFilter({ ...filter, types });

  const handleSelectCreatedAtRange = (createdAt) => setFilter({ ...filter, createdAt });

  const handleSelectCreators = (creators) => setFilter({ ...filter, creators });

  const handleDeselect = () => setFilter({});

  const handleOpenDeleteDialog = () => {
    const isAssigned = selectedVehicles.filter(({ isAssigned }) => isAssigned === 1).length;

    if (isAssigned) {
      enqueueSnackbar(
        t("vehicles.error.assignedToShipment"),
        { variant: "error" }
      );
    } else {
      setDeleteDialogOpen(true);
    }
  };

  useEffect(() => {
    addUrlParam("filter", filter);
  }, [filter])

  useEffect(() => {
    clearTimeout(storeSearchDelay);
    storeSearchDelay = setTimeout(() => {
      addUrlParam("searchText", search);
    }, 250)
  }, [search])

  const expandFilters = (values, fullValues) => values.map(item => fullValues.find(i => i.value === item));

  useEffect(() => {
    const loadedFilters = loadFilters("vehicles");
    if (loadedFilters.length === 0) {
      setDefaultFilters(expandFilters(defaultFilterValues, availableFilters));
    } else {
      setDefaultFilters(expandFilters(loadedFilters, availableFilters));
    }
  }, []);


  const { hasPermission } = useAuth();
  const canCreateVehicle = hasPermission("resource.vehicle.create");
  const canUpdateVehicle = hasPermission("resource.vehicle.update");
  const canDeleteVehicle = hasPermission("resource.user.delete") || hasPermission("resource.user.delete");

  return (
    <>
      <Vehicles
        data={data}
        defaultFilters={defaultFilters}
        dataCount={dataCount}
        selectedColumns={selectedColumns}
        loading={loading}
        search={search}
        ordering={ordering}
        direction={direction}
        rowsPerPage={rowsPerPage}
        page={page}
        checkedAll={checkedAll}
        canCreateVehicle={canCreateVehicle}
        canUpdateVehicle={canUpdateVehicle}
        canDeleteVehicle={canDeleteVehicle}
        filter={filter}
        handleFilterSettingsOpen={handleFilterSettingsOpen}
        handleSort={handleSort}
        handleSearch={handleSearch}
        handleSelectAll={handleSelectAll}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleCreateRequest={() => {
          setSelectedVehicle({});
          setIsEditorOpen(true);
        }}
        handleDeleteRequest={handleOpenDeleteDialog}
        handleSelect={handleSelect}
        handleDeselect={handleDeselect}
        handleSelectVehicleType={handleSelectVehicleType}
        loadAvailableUsers={loadAvailableUsers}
        handleSelectCreators={handleSelectCreators}
        handleSelectCreatedAtRange={handleSelectCreatedAtRange}
        handleChangeSelectedColumns={handleChangeSelectedColumns}
        handleUpdateRequest={(id) => {
          const selected = vehicles.find(({ id: other }) => id === other);
          setSelectedVehicle(selected);
          setIsEditorOpen(true);
        }}
        handleMoreRequest={(id) => {
          history.push(`/vehicles/${id}`);
        }}
      />
      <VehicleDeleteDialog
        open={deleteDialogOpen}
        selected={selectedVehicles.length}
        handleClose={() => setDeleteDialogOpen(false)}
        handleSubmit={handleDeleteSubmit}
      />
      <VehicleEditor
        initialValue={selectedVehicle}
        isOpen={isEditorOpen}
        onClose={() => setIsEditorOpen(false)}
        onSubmit={handleEditorSubmit}
      />
      <FilterSettings
        availableFilters={availableFilters}
        initialFilters={defaultFilters}
        isOpen={isFilterSettingsOpen}
        onClose={handleFilterSettingsClose}
        onSubmit={onFilterSettingsSubmit}
      />
    </>
  );
};

export default VehiclesContainer;
