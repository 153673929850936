import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  DialogContent,
  InputAdornment,
  TextField,
  makeStyles
} from "@material-ui/core";

import { Search as SearchIcon } from "@material-ui/icons";

import { UserRole } from "@cargotic/model";
import { useApiClient } from "../../../cargotic-webapp-component";
import ContactOwnerEditorSelectList from "./ContactOwnerEditorSelectList";
import { createUserMatchQuery } from "../../../cargotic-webapp/resource";

const useStyles = makeStyles(({ spacing }) => ({
  input: {
    flex: "none",
    marginBottom: spacing(1)
  },
  list: {
    padding: 0
  }
}));

const ContactOwnerEditorSelect = ({ value, onChange }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const client = useApiClient();

  const [search, setSearch] = useState("");
  const [availableOwners, setAvailableOwners] = useState();
  const [isOwnersLoading, setIsOwnersLoading] = useState(true);

  const handleDeselect = () => {
    if (onChange) {
      onChange(undefined);
    }
  };

  const handleSearchChange = ({ target: { value: newSearch } }) => (
    setSearch(newSearch)
  );

  const handleSelect = id => {
    if (onChange) {
      const owner = availableOwners.find(({ id: other }) => other === id);
      onChange(owner);
    }
  };

  const loadOwners = async () => {
    setIsOwnersLoading(true);

    try {
      const { matches } = await createUserMatchQuery({ search });
      const selected = matches.map(({ id, ...rest }) => ({
        ...rest,
        id,
        isSelected: value?.id === id
      }));

      setAvailableOwners(selected);
      setIsOwnersLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    loadOwners();
  }, [search]);

  useEffect(() => {
    if (!availableOwners) {
      return;
    }

    const selected = availableOwners.map(({ id, ...rest }) => ({
      ...rest,
      id,
      isSelected: value?.id === id
    }));

    setAvailableOwners(selected);
  }, [value]);

  return (
    <>
      <DialogContent className={classes.input}>
        <TextField
          label={t("webapp:common.title.search-user")}
          value={search}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            )
          }}
          fullWidth
          onChange={handleSearchChange}
        />
      </DialogContent>
      <DialogContent className={classes.list} dividers>
        <ContactOwnerEditorSelectList
          owners={availableOwners}
          isLoading={isOwnersLoading}
          onDeselect={handleDeselect}
          onSelect={handleSelect}
        />
      </DialogContent>
    </>
  );
};

export default ContactOwnerEditorSelect;
