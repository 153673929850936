import { endOfMonth, startOfMonth, parseISO, isBefore, differenceInDays, endOfToday } from "date-fns";
import React, { useCallback, useState, useEffect, useRef } from "react";
import queryString from "query-string";

import {
  Grid,
  makeStyles,
  Tooltip,
  Typography,
  Icon,
  IconButton,
  Link
} from "@material-ui/core";
import Divider from '@mui/material/Divider';
import { Link as RouterLink } from "react-router-dom";

import {
  LocalShipping,
  FastForward,
  ArrowDownward,
  ArrowUpward,
  Info,
  SwapVert,
  Warning,
  Error,
  Cancel,
  CheckCircle,
  ModeComment,
  CallReceived,
  CallMade
} from "@material-ui/icons";

import useRouter from "../../hook/useRouter";

import { ContactType, ShipmentType } from "@cargotic/model-deprecated";
import { formatDate } from "@cargotic/common";

import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import Shipments from "./Shipments";
import useTable from "../../../datatable/useTable";
import { formatJourneyPointDateTime } from "../../../utility/common";
import { getActionFromWaypoint, getFormattedPlaceName, parseShipmentState } from "../../../utility/waypoint";

import useAuth from "../../hook/useAuth";
import UserAvatar from "../../../../cargotic-webapp-component/component/UserAvatar";

import {
  addUrlParam,
  getTableUrlParams
} from "../../../utility/window"

import {
  storeFilters,
  loadFilters
} from "../../../storage";

import {
  createShipmentsExport,
  createShipmentsQuery,
  deleteShipment,
  createShipmentInvoice,
  readCompanyBanking
} from "../../../resource";
import ShipmentsDeleteDialog from "./ShipmentsDeleteDialog";
import DetailedShipmentTableRow from "./DetailedShipmentTableRow";

import { updateShipmentPaidAt } from "../../../resource";

import { useApiClient } from "../../../../cargotic-webapp-component";
import FilterSettings from "../../../../cargotic-webapp-filter/component/FilterSettings";

const useStyles = makeStyles(({ palette }) => ({
  journeyPointActionUpIcon: {
    fill: "#009688",
    height: 20,
    width: 20
  },
  journeyPointActionDownIcon: {
    fill: palette.error.main,
    height: 20,
    width: 20
  },
  carriedForwarderIcon: {
    fill: "rgba(0,0,0,0.56)"
  },
  inlineContent: {
    display: "inline-flex"
  },
  warning: {
    fill: "silver"
  },
  error: {
    fill: palette.error.main,
    color: palette.error.main
  },
  success: {
    fill: "#009688"
  }
}));

const ShipmentsContainer = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const pathname = location.pathname.slice(1);

  const [shipments, setShipments] = useState([]);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const { location: { search: routerSearch } } = useRouter();

  const {
    searchText: initSearchText,
    filter: initFilter
  } = getTableUrlParams(routerSearch);

  if (initFilter.lastWaypointDateFrom) {
    initFilter.lastWaypointDateFrom = new Date(initFilter.lastWaypointDateFrom);
    initFilter.lastWaypointDateTo = new Date(initFilter.lastWaypointDateTo);
  }

  const [additionalFilter, setAdditionalFilter] = useState(initFilter);
  const [isShipmentsExportDialogOpen, setIsShipmentsExportDialogOpen] = useState(false);
  const [isShipmentsExportProcessing, setIsShipmentsExportProcessing] = useState(false);
  const [isShipmentsExportDone, setIsShipmentsExportDone] = useState(false);
  const [shipmentsExportLink, setShipmentsExportLink] = useState(null);
  const [search, setSearch] = useState(initSearchText);
  const [isFilterSettingsOpen, setIsFilterSettingsOpen] = useState(false);
  const [defaultFilters, setDefaultFilters] = useState([]);
  const [bankAccounts, setBankAccounts] = useState([]);

  const paymentAvailable = useRef(true);

  const handleDeselect = () => setAdditionalFilter({});

  const handleFilterSettingsClose = () => setIsFilterSettingsOpen(false);
  const handleFilterSettingsOpen = () => setIsFilterSettingsOpen(true);

  const onFilterSettingsSubmit = async (value) => {
    setIsFilterSettingsOpen(true);
    storeFilters("shipments", value);
    setDefaultFilters(expandFilters(value, availableFilters));
    setIsFilterSettingsOpen(false);
  };

  const defaultFilterValues = ["types", "loadingDate", "unloadingDate", "creators", "createdAt", "customers", "carriers"];
  const availableFilters = [
    {
      label: t("statistics.shipmentType"),
      value: "types"
    }, {
      label: t("shipments.loadingsDateRange"),
      value: "loadingDate"
    }, {
      label: t("shipments.unloadingsDateRange"),
      value: "unloadingDate"
    }, {
      label: t("shipments.creator"),
      value: "creators"
    }, {
      label: t("shipments.creationDate"),
      value: "createdAt"
    }, {
      label: t("contacts.customer"),
      value: "customers"
    }, {
      label: t("contacts.carrier"),
      value: "carriers"
    }, {
      label: t("shipments.driver"),
      value: "drivers"
    }, {
      label: t("shipments.customerPrice"),
      value: "customerPrice"
    }, {
      label: t("shipments.carrierPrice"),
      value: "carrierPrice"
    }, {
      label: t("shipments.commission"),
      value: "commission"
    }, {
      label: t("shipments.cargo"),
      value: "cargo"
    }, {
      label: t("shipments.draft"),
      value: "isDraft"
    }, {
      label: t("shipments.vehicles"),
      value: "vehicles"
    }, {
      label: t("shipments.issuedPaymentState"),
      value: "issuedPaymentState"
    }, {
      label: t("shipments.receivedPaymentState"),
      value: "receivedPaymentState"
    }
  ];

  const classes = useStyles();

  const apiClient = useApiClient();

  let reloadDelay;
  let storeSearchDelay;

  const getShipmentTypeIcon = ({ type, isDraft }) => {
    if (isDraft) {
      return (
        <Tooltip title={t("shipments.draft")}>
          <Warning className={classes.carriedForwarderIcon} />
        </Tooltip>
      )
    }

    if (type === ShipmentType.CARRIED) {
      return (
        <Tooltip title={t("shipments.carried")}>
          <LocalShipping className={classes.carriedForwarderIcon} />
        </Tooltip>
      )
    }
    if (type === ShipmentType.FORWARDED) {
      return (
        <Tooltip title={t("shipments.forwarded")}>
          <FastForward className={classes.carriedForwarderIcon} />
        </Tooltip>
      )
    }

    return null;
  }

  const ShipmentsDateTime = ({
    shipment
  }) => (
    <Grid key={shipment.id} container direction="column">
      {shipment.journey.waypoints.map((waypoint, index) => {
        const waypointAction = waypoint.cargo ? getActionFromWaypoint(waypoint.cargo) : [];

        const actionIcon = (waypointAction) => {
          if (waypointAction.includes("LOAD") && waypointAction.includes("UNLOAD")) {
            return (
              <Tooltip key={`${index}-both`} title={t("shipments.action.both")}>
                <SwapVert className={classes.journeyPointActionUpIcon} />
              </Tooltip>
            );
          }
          if (waypointAction.includes("LOAD")) {
            return (
              <Tooltip key={`${index}-load`} title={t("shipments.action.loading")}>
                <ArrowUpward className={classes.journeyPointActionUpIcon} />
              </Tooltip>
            );
          }
          if (waypointAction.includes("UNLOAD")) {
            return (
              <Tooltip key={`${index}-unload`} title={t("shipments.action.unloading")}>
                <ArrowDownward className={classes.journeyPointActionDownIcon} />
              </Tooltip>
            );
          }
          return (
            <Tooltip key={`${index}-none`} title={t("shipments.action.none")}>
              <Info className={classes.journeyPointActionDownIcon} />
            </Tooltip>
          );
        };

        return (
          <Grid key={`${index}-grid`} container item spacing={1} alignItems="center">
            <Grid item>
              {actionIcon(waypointAction)}
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body2">{formatJourneyPointDateTime(waypoint.arriveAtFrom, waypoint.arriveAtTo)}</Typography>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );

  const ShipmentsRoute = ({
    shipment
  }) => (
    <Grid key={shipment.id} container>
      {shipment.journey.waypoints.map((waypoint, index) => {
        const { components, formatted } = waypoint.place.address;
        const result = Array.isArray(components)
          ? getFormattedPlaceName(components)
          : formatted;
        return (
          <Grid key={index} item xs={12}>
            <Typography variant="body2">
              {result}
            </Typography>
          </Grid>
        );
      })}
    </Grid>
  );

  const ShipmentServices = ({
    shipment
  }) => {
    return (
        <Grid key={shipment.id} container>
          <Grid key={1} item xs={12}>
            <Typography variant="body2">
              {`${t("webapp:shipment.form.label.additionalInsurance")}: ${shipment.additionalInsurancePrice} ${shipment.additionalInsuranceCurrency}`}
            </Typography>
          </Grid>
          <Grid key={2} item xs={12}>
            <Typography variant="body2">
              {`${t("webapp:shipment.form.label.storage")}: ${shipment.storagePrice} ${shipment.storagePriceCurrency}`}
            </Typography>
          </Grid>
          <Grid key={3} item xs={12}>
            <Typography variant="body2">
              {`${t("webapp:shipment.form.label.differentService")}: ${shipment.differentServicePrice} ${shipment.differentServiceCurrency}`}
            </Typography>
          </Grid>

        </Grid>
    );
  };

  const ShipmentCommision = ({
    shipment,
    isInDetailedView = true
  }) => {

    return (
      <Typography variant="body2">
        {
          shipment.commission != null
            ? (<div>
                {
                  (shipment.saleSplittingOwnerShare === undefined && shipment.saleSplittingOwnerName === undefined) || shipment.commission < 0 ?
                      (`${(shipment.commission).toFixed(0)} ${shipment.commissionCurrency}`) :
                    (
                      <div>
                        {`${(shipment.commission * (isInDetailedView ? 1 :shipment.saleSplittingUserShare)).toFixed(1)} ${shipment.commissionCurrency}`}
                        <div style={{
                          width: "inherit",
                          display: "flex",
                          justifyContent: "start"
                        }}>
                          <Typography
                            sx={{ mt: 0.5, ml: 2 }}
                            color="text.secondary"
                            display="block"
                            variant="caption"
                            style={{
                              float: "left"
                            }}
                          >
                            {t("webapp:shipment.form.label.commission")}
                          </Typography>
                        </div>
                        <Divider style={{
                          marginBottom: "5px"
                        }}/>
                        {isInDetailedView ? (
                                <div style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  minWidth: "85px"
                                }}>
                                  <UserAvatar
                                      user={{
                                        name: `${shipment.createdBy.firstName} ${shipment.createdBy.lastName}`,
                                        id: shipment.createdBy.id
                                      }}
                                      size={"small"}
                                  />
                                  <div style={{
                                    fontSize: "10px"
                                  }}>
                                    {`${(shipment.commission * shipment.saleSplittingUserShare).toFixed(1)} ${shipment.commissionCurrency}`}
                                  </div>
                                </div>
                        ) : null}
                        <div style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          minWidth: "85px",
                          marginTop: (isInDetailedView ? '5px' : '0px')
                        }}>
                          <UserAvatar
                            user={{
                              name: `${shipment.saleSplittingOwnerName}`,
                              id: shipment.saleSplittingOwnerId
                            }}
                            size={"small"}
                          />
                          <div style={{
                            fontSize: "10px"
                          }}>
                            {`${(shipment.commission * shipment.saleSplittingOwnerShare).toFixed(1)} ${shipment.commissionCurrency}`}
                          </div>
                        </div>
                      </div>
                    ) //todo here
                }
              </div>)
            : null
        }
      </Typography>
    );
  };

  const transformFilter = (filter) => ({
    ...filter,
    creators: filter.creators ? filter.creators.map(({ id }) => id) : undefined,
    drivers: filter.drivers ? filter.drivers.map(({ id }) => id) : undefined,
    carriers: filter.carriers ? filter.carriers.map(({ id }) => id) : undefined,
    customers: filter.customers ? filter.customers.map(({ id }) => id) : undefined,
    vehicles: filter.vehicles ? filter.vehicles.map(({ id }) => id) : undefined
  });

  const isDue = (dateString) => dateString && isBefore(parseISO(dateString), new Date());
  const getOverdueDays = (dateString) => dateString ? differenceInDays(new Date(), parseISO(dateString)) : undefined;

  const ShipmentsInvoices = ({
    shipment
  }) => (
    <Grid key={shipment.id} container>
      <Grid key="issuedInvoiceNumber" item xs={10} className={classes.inlineContent}>
        <Tooltip title={t("shipments.issuedInvoice")}>
          <CallMade className={classes.success} style={{ marginRight: 5 }} />
        </Tooltip>
        <Typography variant="body2" className={isDue(shipment.issuedInvoiceDueDate) && !shipment.issuedInvoicePaidAt ? classes.error : null}>
          {shipment.issuedInvoiceNumber ? `#${shipment.issuedInvoiceNumber}` : `-`}
        </Typography>
      </Grid>
      <Grid key="issuedInvoicePaidAt" item xs={2}>
        {shipment.issuedInvoicePaidAt ? (
          <Tooltip title={t("shipments.beenPaid", { paidAt: formatDate(parseISO(shipment.issuedInvoicePaidAt), true) })}>
            <IconButton
              size="small"
              onClick={() => paymentAvailable.current && handlePayShipment(shipment.id, { ...shipment, issuedInvoicePaidAt: null })}>
              <CheckCircle className={classes.success} />
            </IconButton>
          </Tooltip>)
          : isDue(shipment.issuedInvoiceDueDate) ? (
            <Tooltip title={t("shipments.overdue", { days: getOverdueDays(shipment.issuedInvoiceDueDate) })}>
              <IconButton
                size="small"
                onClick={() => paymentAvailable.current && handlePayShipment(shipment.id, { ...shipment, issuedInvoicePaidAt: endOfToday() })}>
                <Error className={classes.error} />
              </IconButton>
            </Tooltip>) : (
            <Tooltip title={t("shipments.unpaid")}>
              <IconButton
                size="small"
                onClick={() => paymentAvailable.current && handlePayShipment(shipment.id, { ...shipment, issuedInvoicePaidAt: endOfToday() })}>
                <Cancel className={classes.warning} />
              </IconButton>
            </Tooltip>
          )}
      </Grid>
      {shipment.type === ShipmentType.FORWARDED ? (
        <>
          <Grid key="receivedInvoiceNumber" item xs={10} className={classes.inlineContent}>
            <Tooltip title={t("shipments.receivedInvoice")}>
              <CallReceived className={classes.error} style={{ marginRight: 5 }} />
            </Tooltip>
            <Typography variant="body2" className={isDue(shipment.receivedInvoiceDueDate) && !shipment.receivedInvoicePaidAt ? classes.error : null}>
              {shipment.receivedInvoiceNumber ? `#${shipment.receivedInvoiceNumber}` : `-`}
            </Typography>
          </Grid>
          <Grid key="receivedInvoicePaidAt" item xs={2}>
            {shipment.receivedInvoicePaidAt ?
              (
                <Tooltip title={t("shipments.beenPaid", { paidAt: formatDate(parseISO(shipment.receivedInvoicePaidAt), true) })}>
                  <IconButton
                    size="small"
                    onClick={() => paymentAvailable.current && handlePayShipment(shipment.id, { ...shipment, receivedInvoicePaidAt: null })} >
                    <CheckCircle className={classes.success} />
                  </IconButton>
                </Tooltip>) :
              isDue(shipment.receivedInvoiceDueDate) ? (
                <Tooltip title={t("shipments.overdue", { days: getOverdueDays(shipment.receivedInvoiceDueDate) })}>
                  <IconButton
                    size="small"
                    onClick={() => paymentAvailable.current && handlePayShipment(shipment.id, { ...shipment, receivedInvoicePaidAt: endOfToday() })}>
                    <Error className={classes.error} />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title={t("shipments.unpaid")}>
                  <IconButton
                    size="small"
                    onClick={() => paymentAvailable.current && handlePayShipment(shipment.id, { ...shipment, receivedInvoicePaidAt: endOfToday() })} >
                    <Cancel className={classes.warning} />
                  </IconButton>
                </Tooltip>
              )}
          </Grid> </>)
        : undefined}
    </Grid >
  );

  const reloadShipments = useCallback(async (offset, limit, ordering) => {
    const filter = transformFilter(additionalFilter);
    try {
      const shipmentsResponse = await apiClient.shipment.postShipmentMatchQuery({
        query: {
          match: {
            search,
            ...filter
          },
          orderBy: ordering,
          offset,
          limit
        }
      });

      if (shipmentsResponse.total === 0 && offset !== 0) {
        handleChangePage(
          undefined,
          0
        );
      }

      const updatedShipments = shipmentsResponse.matches.map((shipment) => {
        shipment.isDisabled = shipment.isDisabled === 1;
        shipment.selected = false;

        shipment.tableCells = [
          {
            render: !shipment.isDraft ? (
              <Typography variant="body2">
                {" "}
                #
                {shipment.indexNumber}
              </Typography>
            ) : (
              <Typography variant="body2">
                <i>{t("shipments.draft")}</i>
              </Typography>
            )
          },
          {
            render: <ShipmentsDateTime shipment={shipment} />
          },
          {
            render: <ShipmentsRoute shipment={shipment} />
          },
          {
            render:
              <UserAvatar
                user={{
                  ...shipment.createdBy,
                  name: `${shipment.createdBy.firstName} ${shipment.createdBy.lastName}`
                }}
              />
          },
          {
            render: (
              <ShipmentCommision isInDetailedView={false} shipment={shipment} />
            )
          },
          {
            render: <ShipmentsInvoices shipment={shipment} />
          },
          {
            render: getShipmentTypeIcon(shipment)
          }, {
            render: (
              <Typography variant="body2">
                {shipment.customerPrice && shipment.customerPriceCurrency ? `${shipment.customerPrice} ${shipment.customerPriceCurrency}` : null}
              </Typography>
            )
          }, {
            render: (
              <Typography variant="body2">
                {shipment.carrierPrice && shipment.carrierPriceCurrency ? `${shipment.carrierPrice} ${shipment.carrierPriceCurrency}` : null}
              </Typography>
            )
          }, {
            render: (
              <Typography variant="body2">
                {shipment.vehicle ? <Link component={RouterLink} to={`/vehicles/${shipment.vehicle.id}`}>{shipment.vehicle.manufacturer} {shipment.vehicle.model}, SPZ: {shipment.vehicle.licensePlate}</Link> : null}
              </Typography>
            )
          },
          {
            render: (
              <Typography variant="body2">
                {parseShipmentState(shipment.state, t)}
              </Typography>
            )
          }, {
            render: (
              shipment.driver ? (
                <Link component={RouterLink} to={`/users/${shipment.driver.id}`}>{shipment.driver.name}</Link>
              ) : <></>
            )
          }, {
            render: (
              shipment.customerContact ? (
                <Link component={RouterLink} to={`/contacts/${shipment.customerContact.id}`}>{shipment.customerContact.name}</Link>
              ) : <></>
            )
          },
          {
            render: (
              shipment.carrierContact ? (
                <Link component={RouterLink} to={`/contacts/${shipment.carrierContact.id}`}>{shipment.carrierContact.name}</Link>
              ) : <></>
            )
          },
          {
            render: <ShipmentServices shipment={shipment} />
          }
        ];

        return shipment;
      });

      const shipmentData = updatedShipments.reduce((acc, curr) => [
        ...acc,
        {
          type: curr.type,
          id: curr.id,
          row: curr.tableCells,
          selected: false,
          details: (
            <DetailedShipmentTableRow
              shipment={curr}
              ShipmentCommision={ShipmentCommision}
            />
          )
        }
      ], []);

      setShipments(updatedShipments);
      return { data: shipmentData, totalCnt: shipmentsResponse.total };
    } catch (err) {
      console.error(err);
      enqueueSnackbar(t("shipments.error.get"), {
        variant: "error"
      });
    }
  }, [search, additionalFilter]);

  const handleSelectLoadingsDateRange = (loadingDate) => setAdditionalFilter({ ...additionalFilter, loadingDate });
  const handleSelectUnloadingsDateRange = (unloadingDate) => setAdditionalFilter({ ...additionalFilter, unloadingDate });
  const handleSelectCreatedAtDateRange = (createdAt) => setAdditionalFilter({ ...additionalFilter, createdAt });
  const handleSelectShipmentType = (types) => (setAdditionalFilter({ ...additionalFilter, types }));
  const handleSelectCustomer = (customers) => setAdditionalFilter({ ...additionalFilter, customers });
  const handleSelectCarrier = (carriers) => setAdditionalFilter({ ...additionalFilter, carriers });
  const handleSelectVehicles = (vehicles) => setAdditionalFilter({ ...additionalFilter, vehicles });
  const handleSelectCustomerPrice = (customerPrice) => setAdditionalFilter({ ...additionalFilter, customerPrice });
  const handleSelectCarrierPrice = (carrierPrice) => setAdditionalFilter({ ...additionalFilter, carrierPrice });
  const handleSelectCommission = (commission) => setAdditionalFilter({ ...additionalFilter, commission });
  const handleSelectCreators = (creators) => setAdditionalFilter({ ...additionalFilter, creators });
  const handleSelectDrivers = (drivers) => setAdditionalFilter({ ...additionalFilter, drivers });
  const handleSelectCargoTemplate = (cargo) => setAdditionalFilter({ ...additionalFilter, cargo });
  const handleSelectIsDraft = (isDraft) => setAdditionalFilter({ ...additionalFilter, isDraft });
  const handleSelectIssuedPaymentState = (issuedPaymentState) => setAdditionalFilter({ ...additionalFilter, issuedPaymentState });
  const handleSelectReceivedPaymentState = (receivedPaymentState) => setAdditionalFilter({ ...additionalFilter, receivedPaymentState });

  const handleSelectLastMonth = () => {
    const now = new Date();

    setAdditionalFilter({
      lastWaypointDateFrom: startOfMonth(now),
      lastWaypointDateTo: endOfMonth(now)
    });
  };

  const loadAvailableUsers = async (search, roles = undefined) => {
    try {
      const { matches } = await apiClient.user.postUserMatchQuery({
        query: {
          match: { search, roles },
          limit: 15
        }
      });
      return matches;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  };

  const loadAvailableContacts = async (type, search) => {
    try {
      const contacts = await apiClient.contact.postContactSuggestQuery({
        query: {
          search, types: ["BOTH", type]
        }
      });
      return contacts;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  };

  const loadAvailableVehicles = async (search) => {
    try {
      const vehicles = await apiClient.vehicle.postVehicleSuggestQuery({
        query: {
          search
        }
      });
      return vehicles;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  };

  const loadInitVehicle = async (vehicleId) => {
    try {
      const vehicle = await apiClient.vehicle.getVehicle({
        vehicleId
      });

      setAdditionalFilter({
        ...additionalFilter,
        vehicles: [vehicle]
      });
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  const loadInitContact = async (contactId) => {
    try {
      const contact = await apiClient.contact.getContact({
        contactId
      });

      if (contact.type === ContactType.CUSTOMER) {
        setAdditionalFilter({
          ...additionalFilter,
          customers: [contact]
        });
      }
      else {
        setAdditionalFilter({
          ...additionalFilter,
          carriers: [contact]
        });
      }
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  const handleSendOrder = async (shipmentId, {
    email,
    message,
    language,
    isSendCopyToMeChecked
  }) => {
    try {
      await apiClient.email.postSendOrderByEmail({
        shipmentId,
        data: {
          email,
          message,
          language,
          hasSendCopyToMe: isSendCopyToMeChecked
        }
      });
      enqueueSnackbar(t("webapp:shipment.send-order.success.title"), {
        variant: "success"
      });
    } catch (error) {
      console.log(error);

      enqueueSnackbar(t("webapp:shipment.send-order.error.submit"), {
        variant: "error"
      });
    }
  };

  useEffect(() => {
    const { filterVehicleId, filterContactId } = queryString.parse(routerSearch);

    if (filterVehicleId) {
      loadInitVehicle(filterVehicleId);
    }
    if (filterContactId) {
      loadInitContact(filterContactId);
    }
  }, [])

  useEffect(() => {
    const fetchBankAccounts = async () => {
      try {
        const banking = await readCompanyBanking();
        setBankAccounts(banking);
      } catch (error) {
        console.error(error);
      }
    }

    fetchBankAccounts();
  }, []);

  const {
    data,
    dataCount,
    selectedColumns,
    loading,
    ordering,
    direction,
    checkedAll,
    page,
    rowsPerPage,
    reload,
    reloadData,
    reloadDataFromScratch,
    handleSort,
    handleSelect,
    handleSelectAll,
    handleChangePage,
    handleChangeRowsPerPage,
    handleChangeSelectedColumns,
    setData
  } = useTable(reloadShipments, "shipments");

  const generateShipmentInvoice = async (idShipment) => {
    await createShipmentInvoice(idShipment);
    reload();
  };

  const handlePayShipment = async (
    id,
    {
      issuedInvoiceNumber,
      issuedInvoiceDueDate,
      issuedInvoicePaidAt,
      receivedInvoiceDueDate,
      receivedInvoiceNumber,
      receivedInvoicePaidAt,
      bankAccountId,
      taxableSupplyDate,
      invoiceNote,
      issueDate
    }
  ) => {
    try {
      paymentAvailable.current = false;
      await apiClient.shipment.postShipmentPayment({
        shipmentId: id,
        payment: {
          issuedInvoiceNumber,
          issuedInvoiceDueDate,
          issuedInvoicePaidAt,
          receivedInvoiceDueDate,
          receivedInvoiceNumber,
          receivedInvoicePaidAt,
          bankAccountId,
          taxableSupplyDate,
          invoiceNote,
          issueDate
        }
      });

      setShipments((cur) => cur.map((shipment) =>
        shipment.id === id
          ? {
            ...shipment,
            issuedInvoiceNumber,
            issuedInvoiceDueDate,
            issuedInvoicePaidAt,
            receivedInvoiceDueDate,
            receivedInvoiceNumber,
            receivedInvoicePaidAt,
            bankAccountId,
            taxableSupplyDate,
            invoiceNote,
            issueDate
          }
          : shipment)
      );

      setData(current => current.map((shipment) => {
        if (shipment.id === id) {
          const row = [...shipment.row];

          row[5] = {
            render: <ShipmentsInvoices
              shipment={{
                id,
                type: shipment.type,
                issuedInvoiceNumber,
                issuedInvoiceDueDate: typeof issuedInvoiceDueDate === "object" ? issuedInvoiceDueDate.toISOString() : issuedInvoiceDueDate,
                issuedInvoicePaidAt: typeof issuedInvoicePaidAt === "object" ? issuedInvoicePaidAt?.toISOString() : issuedInvoicePaidAt,
                receivedInvoiceDueDate: typeof receivedInvoiceDueDate === "object" ? receivedInvoiceDueDate.toISOString() : receivedInvoiceDueDate,
                receivedInvoiceNumber,
                receivedInvoicePaidAt: typeof receivedInvoicePaidAt === "object" ? receivedInvoicePaidAt?.toISOString() : receivedInvoicePaidAt,
                bankAccountId,
                taxableSupplyDate: typeof taxableSupplyDate === "object" ? taxableSupplyDate?.toISOString() : taxableSupplyDate,
                invoiceNote,
                issueDate
              }}
            />
          };

          return {
            ...shipment,
            row
          };
        }

        return shipment;
      }));
      // await reload();
    }
    catch (err) {
      console.log(err);

      enqueueSnackbar(t("shipments.error.paidAt"), {
        variant: "error"
      });
    }
    finally {
      paymentAvailable.current = true;
    }
  };

  const handleSearch = (_search) => {
    clearTimeout(reloadDelay);
    reloadDelay = setTimeout(() => {
      setSearch(_search);
    }, 250);
  };

  const expandFilters = (values, fullValues) => values.map(item => fullValues.find(i => i.value === item));

  useEffect(() => {
    const loadedFilters = loadFilters("shipments");
    if (loadedFilters.length === 0) {
      setDefaultFilters(expandFilters(defaultFilterValues, availableFilters));
    } else {
      setDefaultFilters(expandFilters(loadedFilters, availableFilters));
    }
  }, []);


  const selectedShipments = data.filter(({ selected }) => selected);

  const handleDeleteSubmit = () => {
    setDeleteDialogOpen(false);

    const ids = selectedShipments.map(({ id }) => id);
    const requests = ids.map(deleteShipment);

    return Promise.all(requests)
      .then(() => {
        reloadDataFromScratch();
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(t("orders.error.delete"), {
          variant: "error"
        });
      });
  };

  const handleShipmentsExportRequest = () => {
    setIsShipmentsExportDialogOpen(true);
  };

  const closeShipmentsExportDialog = () => {
    setIsShipmentsExportDialogOpen(false);

    if (isShipmentsExportDone) {
      setIsShipmentsExportDone(false);
      setShipmentsExportLink(null);
    }
  };

  const exportShipments = async (language) => {
    setIsShipmentsExportProcessing(true);

    try {
      const link = await createShipmentsExport(selectedShipments.map(({ id }) => id), language);

      setShipmentsExportLink(link);
      setIsShipmentsExportDone(true);
    } catch (error) {
      console.log(error);

      const type = error.response.data.error;

      if (type === "ShipmentExportLimitExceededError") {
        enqueueSnackbar(t("orders.export.errorLimit"), {
          variant: "error"
        });
      } else {
        enqueueSnackbar(t("orders.export.error"), {
          variant: "error"
        });
      }
    } finally {
      setIsShipmentsExportProcessing(false);
    }
  };

  useEffect(() => {
    addUrlParam("filter", additionalFilter);
  }, [additionalFilter]);

  useEffect(() => {
    clearTimeout(storeSearchDelay);
    storeSearchDelay = setTimeout(() => {
      addUrlParam("searchText", search);
    }, 250)
  }, [search])

  const { hasPermission, user } = useAuth();
  const canUpdateShipment = hasPermission("resource.shipment.company.update")
    || hasPermission("resource.shipment.user.update");
  const canCreateInvoice = hasPermission("resource.shipment.invoice.create");
  const canCreateShipment = hasPermission("resource.shipment.user.create");
  const canCreateOrderPdf = hasPermission("resource.shipment.summary.read");
  const canDeleteShipment = hasPermission("resource.shipment.user.delete")
    || hasPermission("resource.shipment.company.delete");
  const canReadInvoice = hasPermission("resource.shipment.invoice.read");
  const canReadCompanyShipment = hasPermission("resource.shipment.company.read");

  return (
    <>
      <Shipments
        data={data}
        dataCount={dataCount}
        selectedColumns={selectedColumns}
        shipments={shipments}
        setShipments={setShipments}
        loading={loading}
        search={search}
        ordering={ordering}
        direction={direction}
        rowsPerPage={rowsPerPage}
        page={page}
        checkedAll={checkedAll}
        exportShipments={exportShipments}
        canCreateOrderPdf={canCreateOrderPdf}
        canUpdateShipment={canUpdateShipment}
        canCreateInvoice={canCreateInvoice}
        canCreateShipment={canCreateShipment}
        canDeleteShipment={canDeleteShipment}
        canReadInvoice={canReadInvoice}
        canReadCompanyShipment={canReadCompanyShipment}
        isShipmentsExportDialogOpen={isShipmentsExportDialogOpen}
        isShipmentsExportProcessing={isShipmentsExportProcessing}
        isShipmentsExportDone={isShipmentsExportDone}
        shipmentsExportLink={shipmentsExportLink}
        additionalFilter={additionalFilter}
        closeShipmentsExportDialog={closeShipmentsExportDialog}
        handleSort={handleSort}
        handleChangePage={handleChangePage}
        handleShipmentsExportRequest={handleShipmentsExportRequest}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleSearch={handleSearch}
        handleSelectAll={handleSelectAll}
        handleSelect={handleSelect}
        handleDeleteRequest={() => setDeleteDialogOpen(true)}
        handleGenerateShipmentInvoice={generateShipmentInvoice}
        handlePayShipment={handlePayShipment}
        handleSelectLastMonth={handleSelectLastMonth}
        filter={additionalFilter}
        availableBankAccounts={bankAccounts}
        handleSelectShipmentType={handleSelectShipmentType}
        handleDeselect={handleDeselect}
        loadAvailableUsers={loadAvailableUsers}
        defaultFilters={defaultFilters}
        handleSelectCustomer={handleSelectCustomer}
        handleSelectCarrier={handleSelectCarrier}
        handleSelectCustomerPrice={handleSelectCustomerPrice}
        handleSelectCarrierPrice={handleSelectCarrierPrice}
        handleSelectCommission={handleSelectCommission}
        loadAvailableContacts={loadAvailableContacts}
        handleSelectLoadingsDateRange={handleSelectLoadingsDateRange}
        handleSelectUnloadingsDateRange={handleSelectUnloadingsDateRange}
        handleSelectCreatedAtDateRange={handleSelectCreatedAtDateRange}
        handleSelectCreators={handleSelectCreators}
        handleFilterSettingsOpen={handleFilterSettingsOpen}
        handleSelectDrivers={handleSelectDrivers}
        handleSelectCargoTemplate={handleSelectCargoTemplate}
        handleSelectIsDraft={handleSelectIsDraft}
        handleSelectVehicles={handleSelectVehicles}
        loadAvailableVehicles={loadAvailableVehicles}
        handleSelectIssuedPaymentState={handleSelectIssuedPaymentState}
        handleSelectReceivedPaymentState={handleSelectReceivedPaymentState}
        handleSendOrder={handleSendOrder}
        handleChangeSelectedColumns={handleChangeSelectedColumns}
      />
      <ShipmentsDeleteDialog
        open={deleteDialogOpen}
        selected={selectedShipments.length}
        handleClose={() => setDeleteDialogOpen(false)}
        handleSubmit={handleDeleteSubmit}
      />
      <FilterSettings
        availableFilters={availableFilters}
        initialFilters={defaultFilters}
        isOpen={isFilterSettingsOpen}
        onClose={handleFilterSettingsClose}
        onSubmit={onFilterSettingsSubmit}
      />
    </>
  );
};

export default ShipmentsContainer;
