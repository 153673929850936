import clsx from "clsx";
import React, { useEffect, useState } from "react";

import { makeStyles, useTheme } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import CargoEditor from "../CargoEditor";
import WaypointBrowser from "../WaypointBrowser";
import JourneySummary from "../JourneySummary";
import JourneySummaryResponsive from "../JourneySummaryResponsive"

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  cargo: {
    position: "absolute",
    top: 50,
    bottom: 10,

    [breakpoints.down("lg")]: {
      left: 340,
    },
    [breakpoints.up("xl")]: {
      left: 410,
    },
  },
  cargoResponsive: {
    width: "calc(100% - 420px)"
  },
  cargoNonResponsive: {
    right: 410
  },
  summary: {
    position: "absolute",
    top: 50,
    right: 10,
    bottom: 10,

    width: 380
  },
  waypoints: {
    position: "absolute",
    top: 0,
    left: 10,

    paddingTop: spacing(1),
    paddingLeft: spacing(1),
    paddingRight: spacing(1),

    maxHeight: "100%",
    overflowY: "auto",

    scrollbarWidth: "none",

    [breakpoints.down("lg")]: {
      width: 320
    },
    [breakpoints.up("xl")]: {
      width: 380
    },

    "& > :last-child": {
      marginBottom: spacing(2)
    },

    "&::-webkit-scrollbar": {
      display: "none"
    }
  }
}));

const JourneyPlannerControl = ({
  waypoints,
  errors,
  cargo,
  selectedCargoItemId,
  selectedWaypointIndex,
  isCargoEditorVisible,
  isCargoItemLoadingDisabled,
  isCargoItemUnloadingDisabled,
  isJourneyComplete,
  searchPlace,
  searchPlaceByCoordinates,
  suggestPlace,
  unloadableCargoItems,
  onCargoItemChange,
  onCargoItemCopy,
  onCargoItemDelete,
  onCargoItemLoad,
  onCargoItemReload,
  onCargoItemSelect,
  onCargoItemUnload,
  onCargoEditorToggle,
  onComplete,
  onPlaceSearchFail,
  onWaypointAdd,
  onWaypointChange,
  onWaypointError,
  onWaypointDelete,
  onWaypointSelect,
  onWaypointsReorder
}) => {
  const classes = useStyles();

  const [isResponsive, setIsResponsive] = useState(false);
  const [isResponsiveFillSpace, setIsResponsiveFillSpace] = useState(false);

  const theme = useTheme();
  if (useMediaQuery(theme.breakpoints.down("lg")) != isResponsive) {
    setIsResponsive(!isResponsive);
  }

  useEffect(() => {
    if (isResponsive) {
      setTimeout(() => setIsResponsiveFillSpace(isResponsive), 400);
    } else {
      setIsResponsiveFillSpace(isResponsive);
    }
  }, [isResponsive]);

  return (
    <>
      <WaypointBrowser
        className={classes.waypoints}
        waypoints={waypoints}
        errors={errors}
        selectedWaypointIndex={selectedWaypointIndex}
        searchPlace={searchPlace}
        searchPlaceByCoordinates={searchPlaceByCoordinates}
        suggestPlace={suggestPlace}
        onPlaceSearchFail={onPlaceSearchFail}
        onWaypointAdd={onWaypointAdd}
        onWaypointChange={onWaypointChange}
        onWaypointError={onWaypointError}
        onWaypointDelete={onWaypointDelete}
        onWaypointSelect={onWaypointSelect}
        onWaypointsReorder={onWaypointsReorder}
      />
      <CargoEditor
        className={isResponsiveFillSpace ? clsx(classes.cargo, classes.cargoResponsive) : clsx(classes.cargo, classes.cargoNonResponsive)}
        cargo={cargo}
        selectedCargoItemId={selectedCargoItemId}
        unloadableCargoItems={unloadableCargoItems}
        isCargoItemLoadingDisabled={isCargoItemLoadingDisabled}
        isCargoItemUnloadingDisabled={isCargoItemUnloadingDisabled}
        isVisible={isCargoEditorVisible}
        onCargoItemChange={onCargoItemChange}
        onCargoItemCopy={onCargoItemCopy}
        onCargoItemDelete={onCargoItemDelete}
        onCargoItemLoad={onCargoItemLoad}
        onCargoItemReload={onCargoItemReload}
        onCargoItemSelect={onCargoItemSelect}
        onCargoItemUnload={onCargoItemUnload}
      />
      <JourneySummary
        className={classes.summary}
        waypoints={waypoints}
        isVisible={!isResponsive}
        isCargoEditorVisible={isCargoEditorVisible}
        isJourneyComplete={isJourneyComplete}
        onCargoEditorToggle={onCargoEditorToggle}
        onComplete={onComplete}
      />
      <JourneySummaryResponsive
        isVisible={isResponsiveFillSpace}
        isCargoEditorVisible={isCargoEditorVisible}
        isJourneyComplete={isJourneyComplete}
        onCargoEditorToggle={onCargoEditorToggle}
        onComplete={onComplete}
      />
    </>
  );
};

export default JourneyPlannerControl;
