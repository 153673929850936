import firebase from "firebase/compat/app";
import React from "react";
import { render } from "react-dom";
import App from "./component/core/App";
import config from "./config";

if (location.host === "app.master.cargotic.com") {
  window.location.href = "https://app.cargotic.com";
}

firebase.initializeApp(config.firebase);

render(<App />, document.querySelector("#app"));
