import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  DialogContent,
  InputAdornment,
  TextField,
  makeStyles
} from "@material-ui/core";

import { Search as SearchIcon } from "@material-ui/icons";

import { useApiClient } from "../../../cargotic-webapp-component";
import VehicleDriverEditorSelectList from "./VehicleDriverEditorSelectList";

import { VehicleType, UserRole } from "@cargotic/model";

const useStyles = makeStyles(({ spacing }) => ({
  input: {
    flex: "none",
    marginBottom: spacing(1)
  },
  list: {
    padding: 0
  }
}));

const VehicleDriverEditorSelect = ({ value, onChange }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const client = useApiClient();

  const [search, setSearch] = useState("");
  const [availableDrivers, setAvailableDrivers] = useState();
  const [isDriversLoading, setIsDriversLoading] = useState(true);

  const handleDeselect = () => {
    if (onChange) {
      onChange(undefined);
    }
  };

  const handleSearchChange = ({ target: { value: newSearch } }) => (
    setSearch(newSearch)
  );

  const handleSelect = id => {
    if (onChange) {
      const driver = availableDrivers.find(({ id: other }) => other === id);
      onChange(driver);
    }
  };

  const loadDrivers = async () => {
    setIsDriversLoading(true);

    try {
      const matches = await client.user.postUserSuggestQuery({ search, role:  UserRole.DRIVER });
      const selected = matches.map(({ id, ...rest }) => ({
        ...rest,
        id,
        isSelected: value?.id === id
      }));

      setAvailableDrivers(selected);
      setIsDriversLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    loadDrivers();
  }, [search]);

  useEffect(() => {
    if (!availableDrivers) {
      return;
    }

    const selected = availableDrivers.map(({ id, ...rest }) => ({
      ...rest,
      id,
      isSelected: value?.id === id
    }));

    setAvailableDrivers(selected);
  }, [value]);

  return (
    <>
      <DialogContent className={classes.input}>
        <TextField
          label={t("webapp:common.title.search-driver")}
          value={search}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            )
          }}
          fullWidth
          onChange={handleSearchChange}
        />
      </DialogContent>
      <DialogContent className={classes.list} dividers>
        <VehicleDriverEditorSelectList
          drivers={availableDrivers}
          isLoading={isDriversLoading}
          onDeselect={handleDeselect}
          onSelect={handleSelect}
        />
      </DialogContent>
    </>
  );
};

export default VehicleDriverEditorSelect;
