
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
  Menu,
  MenuItem,
  Button,
  Checkbox,
  Select,
  ListItem,
  ListItemText,
  ListItemIcon
} from "@material-ui/core";
import { EmojiObjectsOutlined } from "@material-ui/icons";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Link } from "react-router-dom";

import Page from "../../common/Page";
import Placeholder from "../../common/Placeholder";
import StatisticsCard from "./StatisticsCard";
import StatisticsLineChart from "./StatisticsLineChart";
import StatisticsSummary from "./StatisticsSummary";
import FilterStaticDropdown from "../../../../cargotic-webapp-filter/component/FilterStaticDropdown";

import { ShipmentType } from "@cargotic/model-deprecated"

const Statistics = ({
  filter,
  selectTypeOpen,
  selectTypeAnchorEl,
  handleSelectTypeOpen,
  handleSelectTypeClose,
  handleSelectTypeChange,
  handleSelectShipmentType,

  loadingPersonalStatistics,
  loadingCompanyStatistics,
  loadingShipmentsStatistics,
  loadingRevenueStatistics,
  loadingCommissionStatistics,

  personalStatistics,
  companyStatistics,
  shipmentsStatistics,
  revenueStatistics,
  commissionStatistics,

  fetchPersonalStatistics,
  fetchCompanyStatistics,
  fetchShipmentsStatistics,
  fetchRevenueStatistics,
  fetchCommissionStatistics,

  classes,

  hasPermissionUserRead,
  hasPermissionCompanyRead
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isResponsive = useMediaQuery(theme.breakpoints.down("xs"));

  const shipmentTypeSource = [
    { title: t("statistics.forwarded"), value: ShipmentType.FORWARDED },
    { title: t("statistics.carried"), value: ShipmentType.CARRIED }
  ];

  return (
    <Page
      className={classes.tercialActions}
      title={t("menu.statistics")}
      tertiaryActions={(
        !isResponsive ? (
          <>
            <div>
              <FilterStaticDropdown
                id="types"
                placeholderTitle={t("statistics.shipmentType")}
                value={filter.types}
                onChange={(arr) => handleSelectShipmentType(arr.length === 0 ? [] : arr)}
                selectAll={() => handleSelectShipmentType(shipmentTypeSource.map(item => item.value))}
                onClear={() => handleSelectShipmentType([])}
                source={shipmentTypeSource}
              />
            </div>
            <div style={{ float: "right" }}>
              <Tooltip
                title={(
                  <>
                    <Typography color="inherit" style={{ fontWeight: 800 }}>{t("didYouKnowThat")}</Typography>
                    <Typography color="inherit">{t("relativeCommissionTip")}</Typography>
                  </>
                )}
              >
                <IconButton>
                  <EmojiObjectsOutlined />
                </IconButton>
              </Tooltip>
            </div>
          </>
        ) : null
      )}
    >
      <Grid container spacing={2}>
        {hasPermissionUserRead || hasPermissionCompanyRead ? (
          <Grid item xs={12} md={6}>
            <StatisticsCard
              title={t("statistics.ordersTitle")}
              fetchMethod={fetchShipmentsStatistics}
              periodPicker
            >
              <Placeholder
                loading={loadingShipmentsStatistics}
                render={() => (
                  <StatisticsLineChart data={shipmentsStatistics} axisYFormat={(v) => `${v % 1 !== 0 ? "" : v}`} />
                )}
              />
            </StatisticsCard>
          </Grid>
        ) : null}
        <Grid container item xs={12} md={6} direction="column">
          {hasPermissionCompanyRead ? (
            <Grid item className={classes.kpi}>
              <StatisticsCard
                title={t("statistics.kpi")}
                fetchMethod={fetchCompanyStatistics}
                periodPicker
              >
                <Placeholder
                  loading={loadingCompanyStatistics}
                  render={() => (
                    <StatisticsSummary data={companyStatistics} />
                  )}
                />
              </StatisticsCard>
            </Grid>
          ) : null}
          {hasPermissionUserRead ? (
            <Grid item>
              <StatisticsCard
                title={t("statistics.personal")}
                fetchMethod={fetchPersonalStatistics}
                periodPicker
              >
                <Placeholder
                  loading={loadingPersonalStatistics}
                  render={() => (
                    <StatisticsSummary data={personalStatistics} />
                  )}
                />
              </StatisticsCard>
            </Grid>
          ) : null}
        </Grid>
        {(hasPermissionUserRead || hasPermissionCompanyRead) ? (
          <Grid item xs={12} md={6}>
            <StatisticsCard
              title={t("statistics.commission")}
              fetchMethod={fetchCommissionStatistics}
              periodPicker
              hide={filter.types.includes(ShipmentType.CARRIED)}
            >
              <Placeholder
                loading={loadingCommissionStatistics}
                render={() => (
                  <StatisticsLineChart data={commissionStatistics} yLabel="CZK" axisYFormat=".2s" isMoneyFormat />
                )}
              />
            </StatisticsCard>
          </Grid>
        ) : null}
        {hasPermissionUserRead || hasPermissionCompanyRead ? (
          <Grid item xs={12} md={6}>
            <StatisticsCard
              title={t("statistics.revenue")}
              fetchMethod={fetchRevenueStatistics}
              periodPicker
            >
              <Placeholder
                loading={loadingRevenueStatistics}
                render={() => (
                  <StatisticsLineChart data={revenueStatistics} yLabel="CZK" axisYFormat=".2s" isMoneyFormat />
                )}
              />
            </StatisticsCard>
          </Grid>
        ) : null}
      </Grid>
    </Page>
  );
};

export default Statistics;
