import React, { useState, useRef } from "react";

import { DatePicker } from "@material-ui/pickers";
import * as Yup from "yup";

import {
  Button,
  Typography,
  Tooltip,
  IconButton,
  Select,
  MenuItem,
  makeStyles,
  FormControl,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  TextField,
  InputAdornment,
  InputLabel,
  ListItemIcon,
  ListItemText,
  Chip,
  Grid,
  Stepper,
  Step,
  StepButton,
  StepContent
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import EditIcon from "@material-ui/icons/Edit";
import {
  EmojiObjectsOutlined,
  Done,
  Close,
  Place,
  Warning,
  AddCircleOutline,
  RemoveCircleOutline
} from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { queryContacts, useApiClient, findContactByIcAndDic } from "@cargotic/api-client";

import { ContactType } from "@cargotic/model";

import { ContactSchemaWithoutType } from "../../../utility/validationSchema";
import { replaceEmptyStringsByNull } from "../../../utility/functional";
import PlacesAutocompleteTextField from "../../common/Input/PlacesAutocompleteTextField";
import { readCompanyInfo } from "../../../resource";
import useAuth from "../../hook/useAuth";

import SearchAutosuggestTextField from "../../../../../multiload/cargotic-webapp/component/SearchAutosuggestTextField";
import { useGoogleMapsApi } from "../../../../../multiload/cargotic-map";
import { normalizeGooglePlace } from "../../../../../multiload/cargotic-webapp/utility";
import {
  searchPlace,
  searchPlaceByCoordinates,
  suggestPlace,
  fetchGooglePlaceDetail,
  fetchPlaceByGooglePlaceId,
  fetchPlaceIdByAddress
} from "../../../places";

const useStyles = makeStyles(({ spacing, palette }) => ({
  container: {
    display: "flex",
    width: "100%",
    height: "100%",
    flexDirection: "column",
    marginTop: spacing(2),
    alignItems: "flex-start",
    overflowY: "auto"
  },
  error: {
    color: "red"
  },
  validInsurance: {
    color: palette.primary.main
  },
  incorrectInsurace: {
    color: palette.status.danger
  },
  closeButton: {
    position: "absolute",
    right: 0,
    top: 0
  },
  abort: {
    background: palette.status.danger,
    marginRight: spacing(1),
    color: "white",
    "&:hover": {
      background: palette.status.danger,
      color: "white"
    }
  },
  root: {
    padding: spacing(4),
    height: "100%"
  },
  actionButtonGroup: {
    justifyContent: "flex-end",
    flexDirection: "row",
    display: "flex",
    padding: spacing(2),
    marginTop: "auto",
    borderTop: "1px solid silver"
  },
  form: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    marginLeft: spacing(1)
  },
  warning: {
    color: palette.warning.light
  },
  stepper: {
    padding: 0
  }
}));

const ContactsCreateForm = ({
  open,
  isForwarder,
  handleSearchFail,
  handleClose,
  handleSubmit,
  contactType,
  optionTags
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { user, hasPermission } = useAuth();
  const apiClient = useApiClient();
  const { enqueueSnackbar } = useSnackbar();

  const [billingAddressSuggested, setBillingAddressSuggested] = useState(true);
  const [billingAddress, setBillingAddress] = useState();
  const [deliveryAddressSuggested, setDeliveryAddressSuggested] = useState(true);
  const [deliveryAddress, setDeliveryAddress] = useState();
  const [activeStep, setActiveStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState(new Set());

  const steps = [t("contacts.basicInfo"), t("contacts.contactType"), t("contacts.contactDetail")];
  const canReadCompanyContact = hasPermission("resource.contact.company.read");

  const previousIc = useRef(undefined);
  const previousDic = useRef(undefined);
  const previousContact = useRef(undefined);

  async function checkContactExistence(dic, ic) {
    const contact = (previousDic.current === dic && previousIc.current === ic)
      ? previousContact.current
      : await findContactByIcAndDic(apiClient, dic, ic);

    previousContact.current = contact;
    previousDic.current = dic;
    previousIc.current = ic;

    return (contact === undefined)
      ? true
      : this.createError({
        params: {
          contact
        }
      });
  }

  const ContactSchemaCreation = ContactSchemaWithoutType.concat(Yup.object().shape({
    dic: Yup.string().required(t("company.validation.dicRequired"))
      .test("checkContactExistsByDic", ({ contact }) => {
        if (contact.isMine) {
          return t("contacts.dicAlreadyExistsAndIsMine", { companyName: contact.companyName });
        }

        if (!contact.isPrivate) {
          return t(
            "contacts.dicAlreadyExistsAndIsPublic",
            { companyName: contact.companyName }
          );
        }

        if (contact.isSharedWithMe) {
          return t(
            "contacts.dicAlreadyExistsAndIsSharedWithMe",
            { companyName: contact.companyName, creator: contact.creator.name }
          );
        }

        return t(
          "contacts.dicAlreadyExistsCustomMessage",
          { companyName: contact.companyName, creator: contact.creator.name }
        );
      },
        function (value) {
          return (value === "" || value === undefined)
            ? true
            : checkContactExistence.call(this, value, this.options.parent.ic);
        }),
    ic: Yup.string().nullable()
      .test("checkContactExistsByIc", ({ contact }) => {
        if (contact.isMine) {
          return t("contacts.dicAlreadyExistsAndIsMine", { companyName: contact.companyName });
        }

        if (!contact.isPrivate) {
          return t(
            "contacts.dicAlreadyExistsAndIsPublic",
            { companyName: contact.companyName }
          );
        }

        if (contact.isSharedWithMe) {
          return t(
            "contacts.dicAlreadyExistsAndIsSharedWithMe",
            { companyName: contact.companyName, creator: contact.creator.name }
          );
        }

        return t(
          "contacts.dicAlreadyExistsCustomMessage",
          { companyName: contact.companyName, creator: contact.creator.name }
        );
      },
        function (value) {
          return (value === "" || value === undefined)
            ? true
            : checkContactExistence.call(this, this.options.parent.dic, value);
        })
  }));

  const allStepsCompleted = () => {
    return completedSteps.size === 2;
  };

  const isLastStep = () => {
    return activeStep === 2;
  };

  const handleNext = () => {
    const newActiveStep = isLastStep() && !allStepsCompleted()
      ? steps.findIndex((step, i) => !completedSteps.has(i))
      : activeStep + 1;

    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompletedSteps(new Set());
  };

  const handleComplete = () => {
    const newCompleted = new Set(completedSteps);
    newCompleted.add(activeStep);
    setCompletedSteps(newCompleted);
  };

  const onBillingPlaceChange = async (setValues, values, place) => {
    const normalizedPlace = await normalizeGooglePlace(placesService, place.place_id);

    setBillingAddress(normalizedPlace);

    setBillingAddressSuggested(true);
    setValues({ ...values, billingAddress: place.description });
  };

  const onDeliveryPlaceChange = async (setValues, values, place) => {
    const normalizedPlace = await normalizeGooglePlace(placesService, place.place_id);

    setDeliveryAddress(normalizedPlace);

    setDeliveryAddressSuggested(true);
    setValues({ ...values, deliveryAddress: place.description });
  };

  const isStepCompleted = (step) => completedSteps.has(step);

  const hasBillingAddressError = (errors, touched) => errors.billingAddress && touched.billingAddress;
  const hasDeliveryAddressError = (errors, touched) => errors.deliveryAddress && touched.deliveryAddress;

  const {
    api: googleMapsApi
  } = useGoogleMapsApi();

  const googlePlaceService = new google.maps.places.PlacesService(document.createElement("div"));
  const googleGeocoderService = new googleMapsApi.Geocoder();
  const googleAutocompleteService = new googleMapsApi.places.AutocompleteService();

  const [placesService, setPlacesService] = useState(googlePlaceService);
  const [geocoderService, setGeocoderService] = useState(googleGeocoderService);
  const [tagValue, setTagValue] = useState([]);

  return (
    <Formik
      validationSchema={ContactSchemaCreation}
      initialValues={{
        companyName: "",
        ic: "",
        dic: "",
        email: "",
        phoneNumber: "",
        website: "",
        notes: "",
        employees: [],
        billingPlaceFormattedAddress: "",
        mailingPlaceFormattedAddress: "",
        type: contactType ?? ContactType.CARRIER,
        insuranceExpiresAt: null,
        paymentDueDays: "",
        billingContact: "",
        isBilledOnline: false,
        visibility: false
      }}
      onSubmit={(values, { setSubmitting }) => {
        replaceEmptyStringsByNull(values);

        setSubmitting(false);

        handleSubmit({
          companyName: values.companyName,
          ic: values.ic,
          dic: values.dic,
          email: values.email,
          phoneNumber: values.phoneNumber,
          website: values.website,
          notes: values.notes,
          employees: values.employees,
          billingPlace: {
            formattedAddress: billingAddress?.address?.formatted || values.billingAddress,
            googleId: billingAddress?.googleId
          },
          mailingPlace: values.deliveryAddress ? {
            formattedAddress: deliveryAddress?.address?.formatted || values.deliveryAddress,
            googleId: deliveryAddress?.googleId
          } : undefined,
          type: values.type,
          tags: tagValue,
          insuranceExpiresAt: values.insuranceExpiresAt,
          paymentDueDays: values.paymentDueDays,
          billingContact: values.billingContact,
          isBilledOnline: values.isBilledOnline,
          isPrivate: values.visibility
        });
      }}
    >
      {({
        handleBlur,
        handleChange,
        isSubmitting,
        submitForm,
        values,
        errors,
        touched,
        setValues,
        setErrors,
        setFieldError,
        isValid,
        setTouched
      }) => {
        const getErrorStep = (index) => {
          switch (index) {
            case 0:
              return values.companyName === "" || values.dic === "" || errors.companyName || errors.ic || errors.dic;
            case 1:
              return values.visibility === "" || values.type === "" || errors.visibility || errors.type;
            case 2:
              return values.email === "" || values.billingPlaceFormattedAddress === "" || errors.billingContact || errors.billingPlaceFormattedAddress || errors.email || errors.insuranceExpiresAt || errors.isBilledOnline || errors.website || errors.phoneNumber || errors.paymentDueDays || errors.notes;
            default:
              return undefined;
          }
        };
        const getContent = (index) => {
          switch (index) {
            case 0:
              return (
                <>
                  <div>
                    <TextField
                      name="companyName"
                      label={t("contacts.companyName")}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                      helperText={touched.companyName && t(errors.companyName)}
                      value={values.companyName}
                      error={errors.companyName && touched.companyName}
                      required
                    />
                    <div>
                      <TextField
                        name="ic"
                        label="IČ"
                        style={{ width: "90%" }}
                        error={errors.ic && touched.ic}
                        value={values.ic}
                        helperText={touched.ic && t(errors.ic)}
                        onChange={handleChange}
                        onBlur={async (e) => {
                          if (values.ic === "" || values.ic === undefined) {
                            return;
                          }
                          handleBlur(e);
                          setValues({ ...values, ic: values.ic.replace(/\s/g, "") });
                          const { data } = await readCompanyInfo(values.ic);
                          const placeId = await fetchPlaceIdByAddress(data.address);
                          setBillingAddress({ title: data.address, placeId });
                          setDeliveryAddress({ title: data.address, placeId });
                          setValues({
                            ...values,
                            billingAddress: data.address,
                            deliveryAddress: data.address,
                            companyName: data.name
                          });
                        }}
                      />
                      <Tooltip title={(
                        <>
                          <Typography color="inherit" style={{ fontWeight: 800 }}>{t("didYouKnowThat")}</Typography>
                          <Typography color="inherit">{t("fetchCompanyFromJusticeTip")}</Typography>
                        </>
                      )}
                      >
                        <IconButton>
                          <EmojiObjectsOutlined />
                        </IconButton>
                      </Tooltip>
                    </div>
                    <TextField
                      name="dic"
                      label="DIČ"
                      required
                      value={values.dic}
                      error={errors.dic && touched.dic}
                      fullWidth
                      helperText={touched.dic && t(errors.dic)}
                      onChange={handleChange}
                      onBlur={(e) => {
                        handleBlur(e);
                        setValues({ ...values, dic: values.dic.replace(/\s/g, "") });
                      }}
                    />
                  </div>
                </>
              );
            case 1:
              return (
                <>
                  <div>
                    <FormControl style={{ width: "90%" }}>
                      <InputLabel htmlFor="visibility" required error={errors.visibility && touched.visibility}>{t("contacts.visibility")}</InputLabel>
                      <Select
                        value={values.visibility}
                        name="visibility"
                        inputProps={{
                          name: "visibility"
                        }}
                        onChange={handleChange}
                        error={errors.visibility && touched.visibility}
                        required
                      >
                        <MenuItem value={true}>{t("contacts.private")}</MenuItem>
                        <MenuItem value={false}>{t("contacts.public")}</MenuItem>
                      </Select>
                      <FormHelperText error={errors.visibility && touched.visibility}>{touched.visibility && t(errors.visibility)}</FormHelperText>
                    </FormControl>
                    <Tooltip title={(
                      <>
                        <Typography color="inherit" style={{ fontWeight: 800 }}>{t("didYouKnowThat")}</Typography>
                        <Typography color="inherit">{t("contacts.visibilityTooltip")}</Typography>
                      </>
                    )}
                    >
                      <IconButton>
                        <EmojiObjectsOutlined />
                      </IconButton>
                    </Tooltip>
                  </div>
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel htmlFor="type" required error={errors.type && touched.type}>{t("contacts.type")}</InputLabel>
                    <Select
                      value={values.type}
                      disabled={contactType !== undefined}
                      name="type"
                      inputProps={{
                        name: "type"
                      }}
                      onChange={handleChange}
                      error={errors.type && touched.type}
                      required
                    >
                      <MenuItem value={ContactType.CARRIER}>{t("contacts.carrier")}</MenuItem>
                      <MenuItem value={ContactType.CUSTOMER}>{t("contacts.customer")}</MenuItem>
                    </Select>
                    <FormHelperText error={errors.type && touched.type}>{touched.type && t(errors.type)}</FormHelperText>
                  </FormControl>
                </>
              );
            case 2:
              return (
                <>
                  <TextField
                    type="email"
                    name="email"
                    value={values.email}
                    label={t("email")}
                    fullWidth
                    helperText={touched.email && t(errors.email)}
                    error={errors.email && touched.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <TextField
                    name="phoneNumber"
                    label={t("phone")}
                    type="tel"
                    value={values.phoneNumber}
                    error={errors.phoneNumber && touched.phoneNumber}
                    onChange={handleChange}
                    helperText={touched.phoneNumber && t(errors.phoneNumber)}
                    fullWidth
                    onBlur={handleBlur}
                  />
                  <TextField
                    name="website"
                    label="Web"
                    fullWidth
                    value={values.website}
                    type="url"
                    helperText={touched.website && t(errors.website)}
                    error={errors.website && touched.website}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <TextField
                    name="notes"
                    label={t("contacts.notes")}
                    fullWidth
                    value={values.notes}
                    type="url"
                    helperText={touched.notes && t(errors.notes)}
                    error={errors.notes && touched.notes}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <TextField
                    name="paymentDueDays"
                    label={t("contacts.dueDays")}
                    value={values.paymentDueDays}
                    fullWidth
                    error={errors.paymentDueDays && touched.paymentDueDays}
                    helperText={touched.paymentDueDays && t(errors.paymentDueDays)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="number"
                  />
                  <SearchAutosuggestTextField
                    required
                    fullWidth
                    hideCargoPlaces
                    name="billingAddress"
                    text={values.billingAddress ?? ""}
                    value={billingAddress}
                    helperText={hasBillingAddressError(errors, touched) && t(errors.billingAddress)}
                    error={hasBillingAddressError(errors, touched)}
                    label={t("contacts.billingAddress")}
                    placeholder={t("contacts.address")}
                    renderSuggestion={({ description }) => (
                      <>
                        <ListItemIcon>
                          <Place />
                        </ListItemIcon>
                        <ListItemText primary={description} />
                      </>
                    )}
                    search={(text) => searchPlace(placesService, text)}
                    searchByCoordinates={(latitude, longitude) =>
                      searchPlaceByCoordinates(geocoderService, placesService, latitude, longitude)}
                    onSearchFail={handleSearchFail}
                    suggest={(text) => suggestPlace(googleAutocompleteService, text)}
                    onTextChange={(text) => {
                      setValues({ ...values, billingAddress: text });
                      setBillingAddressSuggested(false);
                    }}
                    onValueChange={(place) => onBillingPlaceChange(setValues, values, place)}
                    onBlur={handleBlur}
                  />
                  <SearchAutosuggestTextField
                    fullWidth
                    hideCargoPlaces
                    name="deliveryAddress"
                    text={values.deliveryAddress ?? ""}
                    value={deliveryAddress}
                    helperText={hasDeliveryAddressError(errors, touched) && t(errors.deliveryAddress)}
                    error={hasDeliveryAddressError(errors, touched)}
                    label={t("contacts.deliveryAddress")}
                    placeholder={t("contacts.address")}
                    renderSuggestion={({ description }) => (
                      <>
                        <ListItemIcon>
                          <Place />
                        </ListItemIcon>
                        <ListItemText primary={description} />
                      </>
                    )}
                    search={(text) => searchPlace(placesService, text)}
                    searchByCoordinates={(latitude, longitude) =>
                      searchPlaceByCoordinates(geocoderService, placesService, latitude, longitude)}
                    onSearchFail={handleSearchFail}
                    suggest={(text) => suggestPlace(googleAutocompleteService, text)}
                    onTextChange={(text) => {
                      setValues({ ...values, deliveryAddress: text });
                      setDeliveryAddressSuggested(text === "");
                    }}
                    onValueChange={(place) => onDeliveryPlaceChange(setValues, values, place)}
                    onBlur={handleBlur}
                  />
                  <Autocomplete
                    multiple
                    id="contact-tags"
                    options={optionTags}
                    value={tagValue}
                    filterSelectedOptions
                    noOptionsText={t("contact.noTag")}
                    // groupBy={(item) => item.type}
                    getOptionSelected={(
                      option,
                      value,
                    ) => value.value === option.value}
                    onChange={(event, newValue) => {
                      event.preventDefault();
                      return setTagValue(newValue);
                    }}
                    getOptionLabel={(option) => (option.geopoliticalType !== null ? `${option.value} / ${option.localizedGeopoliticalType}` : `${option.value} / ${option.localizedType}`)}
                    renderTags={(value, getTagProps) => (
                      value.map(({
                        value
                      }, index) => (
                          <Chip key={index} label={value} {...getTagProps({ index })} />
                        )))}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label={t("contact.tags")}
                        placeholder="Tag"
                      />
                    )}
                  />
                  <DatePicker
                    name="insuranceExpiresAt"
                    format="dd.MM.yyyy"
                    value={values.insuranceExpiresAt}
                    renderInput={(props) => <TextField variant="outlined" {...props} helperText={undefined} />}
                    label={t("contacts.insurance")}
                    onChange={(value) => setValues({ ...values, insuranceExpiresAt: value })}
                    ampm={false}
                    fullWidth
                    InputProps={{
                      endAdornment:
                        (values.insuranceExpiresAt == null || values.insuranceExpiresAt < new Date()) && values.type !== ContactType.CUSTOMER
                          ? (
                            <InputAdornment position="end">
                              <Tooltip title={values.insuranceExpiresAt ? t("contacts.incorrectInsurance") : t("contacts.undefinedInsurance")}>
                                <IconButton className={classes.warning} size="small">
                                  <Warning />
                                </IconButton>
                              </Tooltip>
                            </InputAdornment>
                          ) : null
                    }}
                  />
                  <TextField
                    name="billingContact"
                    label={t("contacts.billingContact")}
                    value={values.billingContact}
                    fullWidth
                    error={errors.billingContact && touched.billingContact}
                    helperText={touched.billingContact && t(errors.billingContact)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <FormControlLabel
                    style={{ marginBottom: 0 }}
                    control={(
                      <Checkbox
                        name="isBilledOnline"
                        checked={values.isBilledOnline}
                        onChange={handleChange}
                      />
                    )}
                    label={t("contacts.electronicalBilling")}
                  />
                </>
              );
            default:
              return undefined;
          };
        };

        return (
          <div className={classes.form}>
            <Typography variant="h4">{t("contacts.createTitle")}</Typography>
            <form className={classes.container}>
              <Stepper orientation="vertical" activeStep={activeStep} style={{ width: "100%" }}>
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepButton onClick={() => setActiveStep(index)} completed={isStepCompleted(index)}>{label}</StepButton>
                    <StepContent>
                      {getContent(index)}
                      <div style={{ textAlign: "right" }}>
                        {activeStep !== 2
                          ? (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                handleNext();
                                handleComplete();
                              }}
                              disabled={getErrorStep(index)}
                              className={classes.button}
                            >
                              {t("continue")}
                            </Button>
                          ) : undefined}
                      </div>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </form>
            <div className={classes.actionButtonGroup}>
              <Button onClick={handleClose} variant="contained" className={classes.abort}>
                {t("abort")}
              </Button>
              <Button variant="contained" color="primary" onClick={submitForm} disabled={isSubmitting || !isValid}>
                {t("create")}
              </Button>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default ContactsCreateForm;
