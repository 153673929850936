import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import VehicleIncomeOverview from "../VehicleIncomeOverview";
import VehicleIncomeHeader from "../VehicleIncomeHeader";
import DirectVehicleIncomeEditor from "../DirectVehicleIncomeEditor";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import client from "../../../cargotic-webapp/client"
import DirectVehicleIncomeDeleteDialog from "../DirectVehicleIncomeDeleteDialog";
import Placeholder from "../../../cargotic-webapp/component/common/Placeholder";
import { format } from "date-fns";
import { cs, enUS } from "date-fns/locale";
import { TextField } from "@material-ui/core";

const VehicleIncomeOverviewContainer = ({
  vehicle: {
    id: vehicleId,
    manufacturer,
    model
  },
  isIncomeCreationDisabled,
  isIncomeDeletionDisabled,
  isIncomeEditationDisabled,
  onBreadcrumbsChange
}) => {
  const { search: searchQuery } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const locale = t("locale") === "cs" ? cs : enUS;

  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);

  const [search, setSearch] = useState("");
  const [updatingIncome, setUpdatingIncome] = useState(undefined);
  const [incomes, setIncomes] = useState([]);
  const [totalIncomes, setTotalIncomes] = useState(0);
  const [isIncomeEditorOpen, setIsIncomeEditorOpen] = useState(
    searchQuery.includes("isEditorOpen")
  );

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  let reloadDelay;

  const formatGroupDate = (date) => format(
    date,
    'MMMM yyyy',
    { locale }
  );

  const groupByF = (arr, f) =>
    arr?.reduce((acc, item) => {
      const itemParam = f(item);
      acc[itemParam] = acc[itemParam] ? [...acc[itemParam], item] : [item];
      return acc;
    }, {});

  const handleChangeRowsPerPage = (event) => {
    const rowsPerPage = +event.target.value;
    setLoading(true);
    setRowsPerPage(rowsPerPage);
    setPage(0);
    loadIncomes(0, rowsPerPage, search);
  };

  const handleChangePage = (_, newPage) => {
    setLoading(true);
    setPage(newPage);
    loadIncomes(newPage, rowsPerPage, search);
  };

  const handleSearchChange = (value) => {
    setSearch(value);
    loadIncomes(page, rowsPerPage, value);
  };

  const handleDirectIncomeDelete = async () => {
    const { id } = updatingIncome;
    await client.vehicle.deleteDirectVehicleIncome({
      vehicleId,
      incomeId: id
    });

    loadIncomes(page, rowsPerPage, search);
    setUpdatingIncome(undefined);
    setIsDeleteDialogOpen(false);
  };

  const handleEditorSubmit = async (income) => {
    if (updatingIncome) {
      await client.vehicle.putDirectVehicleIncome({
        vehicleId,
        incomeId: updatingIncome.id,
        income
      });
    } else {
      await client.vehicle.postDirectVehicleIncome({
        vehicleId,
        income
      });
    }

    loadIncomes(page, rowsPerPage, search);
    setUpdatingIncome(undefined);
    setIsIncomeEditorOpen(false);
  };

  const handleDeleteClick = (income) => {
    setUpdatingIncome(income);
    setIsDeleteDialogOpen(true);
  };

  const handleEditClick = (income) => {
    setUpdatingIncome(income);
    setIsIncomeEditorOpen(true);
  };

  const loadIncomes = async (offset, limit, search) => {
    setLoading(true);
    const { total, matches } = await client.vehicle.postVehicleIncomeMatchQuery({
      vehicleId,
      query: {
        offset,
        limit,
        match: {
          search
        }
      }
    });

    const groupedMatches = groupByF(
      matches,
      ({ income: { accountedAt } }) => formatGroupDate(accountedAt)
    );

    setIncomes(groupedMatches);
    setTotalIncomes(total);
    setLoading(false);
  };

  useEffect(() => {
    onBreadcrumbsChange([
      { label: t("webapp:common.title.vehicles"), href: "/vehicles" },
      { label: `${manufacturer} ${model}`, href: `/vehicles/${vehicleId}` },
      { label: t("webapp:vehicle.title.incomes") }
    ]);

    loadIncomes(page, rowsPerPage, search);
  }, []);

  return (
    <>
      <div style={{ marginTop: 20, marginBottom: 20 }}>
        <VehicleIncomeHeader
          search={search}
          onSearchChange={handleSearchChange}
          isIncomeCreationDisabled={isIncomeCreationDisabled}
          onAddButtonClick={() => setIsIncomeEditorOpen(true)}
        />
        <Placeholder
          loading={loading}
          render={() => (
            <VehicleIncomeOverview
              search={search}
              incomes={incomes}
              totalIncomes={totalIncomes}
              page={page}
              rowsPerPage={rowsPerPage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              onChangePage={handleChangePage}
              onEditClick={handleEditClick}
              onDeleteClick={handleDeleteClick}
              isIncomeEditationDisabled={isIncomeEditationDisabled}
              isIncomeDeletionDisabled={isIncomeDeletionDisabled}
            />
          )}
        />
      </div>
      <DirectVehicleIncomeEditor
        income={updatingIncome}
        isOpen={isIncomeEditorOpen}
        onClose={() => {
          setUpdatingIncome(undefined);
          setIsIncomeEditorOpen(false);
        }}
        onSubmit={handleEditorSubmit}
      />
      <DirectVehicleIncomeDeleteDialog
        isOpen={isDeleteDialogOpen}
        onClose={() => {
          setUpdatingIncome(undefined);
          setIsDeleteDialogOpen(false);
        }}
        onSubmit={handleDirectIncomeDelete}
      />
    </>
  );
};

export default VehicleIncomeOverviewContainer;
