import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import en from "./translations/en.json";
import cs from "./translations/cs.json";

import coreCs from "../../multiload/cargotic-i18n/cs/core.json";
import webappCs from "../../multiload/cargotic-i18n/cs/webapp.json";
import coreEn from "../../multiload/cargotic-i18n/en/core.json";
import webappEn from "../../multiload/cargotic-i18n/en/webapp.json";

const resources = {
  en: {
    legacy: en,
    core: coreEn,
    webapp: webappEn
  },
  cs: {
    legacy: cs,
    core: coreCs,
    webapp: webappCs
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    ns: [
      "core",
      "webapp",
      "legacy"
    ],
    defaultNS: "legacy",
    fallbackLng: "en",
    debug: false,
    resources
  });

export default i18n;
