import clsx from "clsx";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { makeStyles } from "@material-ui/core";

import VehicleExpirationDeleteConfirmationAlert
  from "../VehicleExpirationDeleteConfirmationAlert";

import VehicleExpirationEditor from "../VehicleExpirationEditor";
import VehicleExpirationTable from "../VehicleExpirationTable";
import VehicleExpirationOverviewSwitchboard
  from "./VehicleExpirationOverviewSwitchboard";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column"
  },
  table: {
    flex: 1
  }
}));

const VehicleExpirationOverview = ({
  className,
  expirations,
  page,
  query,
  rowsPerPage,
  selectedExpiration,
  vehicle: {
    manufacturer,
    model,
    id
  },
  isCreationDisabled,
  isDeletionDisabled,
  isEditationDisabled,
  isDeleteConfirmationAlertOpen,
  isEditorOpen,
  isExpirationsLoading,
  onBreadcrumbsChange,
  onDeleteConfirmationAlertClose,
  onDeleteConfirmationAlertSubmit,
  onEditorClose,
  onEditorSubmit,
  onExpirationAdd,
  onExpirationDelete,
  onExpirationEdit,
  onPageChange,
  onQueryChange,
  onRowsPerPageChange
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleAddButtonClick = () => onExpirationAdd();

  const handleExpirationDeleteButtonClick = (event, index) => (
    onExpirationDelete(index)
  );

  const handleExpirationEditButtonClick = (event, index) => (
    onExpirationEdit(index)
  );

  const handleQueryMatchTypeChange = type => (
    onQueryChange({ ...query, match: { ...query.match, type }, page: 0 })
  );

  const handleQueryMatchIsExpiredChange = isExpired => (
    onQueryChange({ ...query, match: { ...query.match, isExpired }, page: 0 })
  );

  const handleQueryMatchSearchChange = search => (
    onQueryChange({ ...query, match: { ...query.match, search }, page: 0 })
  );

  useEffect(() => {
    onBreadcrumbsChange([
      { label: t("webapp:common.title.vehicles"), href: "/vehicles" },
      { label: `${manufacturer} ${model}`, href: `/vehicles/${id}` },
      { label: t("webapp:vehicle.title.expirations") }
    ]);
  }, []);

  useEffect(() => {
    onQueryChange({
      match: {
        isExpired: false
      },
      page: 0,
      rowsPerPage: 10
    });
  }, []);

  return (
    <>
      <div className={clsx(classes.root, className)}>
        <VehicleExpirationOverviewSwitchboard
          isAddButtonDisabled={isCreationDisabled}
          match={query?.match}
          onIsExpiredChange={handleQueryMatchIsExpiredChange}
          onTypeChange={handleQueryMatchTypeChange}
          onSearchChange={handleQueryMatchSearchChange}
          onAddButtonClick={handleAddButtonClick}
        />
        <VehicleExpirationTable
          className={classes.table}
          isDeleteButtonDisabled={isDeletionDisabled}
          isEditButtonDisabled={isEditationDisabled}
          isLoading={isExpirationsLoading}
          expirations={expirations}
          page={page}
          rowsPerPage={rowsPerPage}
          onExpirationDeleteButtonClick={handleExpirationDeleteButtonClick}
          onExpirationEditButtonClick={handleExpirationEditButtonClick}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
        />
      </div>
      <VehicleExpirationDeleteConfirmationAlert
        isOpen={isDeleteConfirmationAlertOpen}
        onClose={onDeleteConfirmationAlertClose}
        onSubmit={onDeleteConfirmationAlertSubmit}
      />
      <VehicleExpirationEditor
        initialValue={selectedExpiration}
        isOpen={isEditorOpen}
        onClose={onEditorClose}
        onSubmit={onEditorSubmit}
      />
    </>
  );
};

export default VehicleExpirationOverview;
