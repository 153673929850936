import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

import { deserializeQuery } from "../../utility/common";

import VehicleProfileView
  from "../../../cargotic-webapp-vehicle/VehicleProfileView";

import VehicleProfileContainer
  from "../../../cargotic-webapp-vehicle/component/VehicleProfileContainer";

const VehicleProfileContainerAdapter = ({ view }) => {
  const history = useHistory();
  const params = useParams();
  const { search } = useLocation();
  const actions = deserializeQuery(search.substring(1));

  const [selectedExpiration, setSelectedExpiration] = useState();
  const [isExpirationEditorOpen, setIsExpirationEditorOpen] = useState(false);

  const vehicleId = parseInt(params.id, 10);

  const handleExpirationAdd = type => {
    if (view !== VehicleProfileView.VEHICLE_EXPIRATION_OVERVIEW) {
      history.push(`/vehicles/${vehicleId}/expirations?add=${type}`);
      return;
    }

    setSelectedExpiration({ type });
    setIsExpirationEditorOpen(true);
  }

  const handleExpirationSelect = value => setSelectedExpiration(value);
  const handleExpirationEditorOpen = () => setIsExpirationEditorOpen(true);
  const handleExpirationEditorClose = () => setIsExpirationEditorOpen(false);

  const handleViewChange = value => {
    switch (value) {
      case VehicleProfileView.VEHICLE_INCOME_OVERVIEW: {
        history.push(`/vehicles/${vehicleId}/incomes`);
        return;
      }

      case VehicleProfileView.VEHICLE_EXPENSE_OVERVIEW: {
        history.push(`/vehicles/${vehicleId}/expenses`);
        return;
      }

      case VehicleProfileView.VEHICLE_EXPIRATION_OVERVIEW: {
        history.push(`/vehicles/${vehicleId}/expirations`);
        return;
      }

      case VehicleProfileView.VEHICLE_OVERVIEW: {
        history.push(`/vehicles/${vehicleId}`);
        return;
      }

      default: {
        throw new Error(`Invalid vehicle profile view '${view}'!`);
      }
    }
  };

  const handleShipmentRedirect = () => (
    history.push(`/shipment?vehicleId=${vehicleId}`)
  );

  const handleVehiclesRedirect = () => history.push("/vehicles");

  useEffect(() => {
    const { add: type } = actions;

    if (!type) {
      return;
    }

    setSelectedExpiration({ type });
    setIsExpirationEditorOpen(true);
  }, [actions.add]);

  return (
    <VehicleProfileContainer
      selectedExpiration={selectedExpiration}
      vehicleId={vehicleId}
      view={view}
      isExpirationEditorOpen={isExpirationEditorOpen}
      onExpirationAdd={handleExpirationAdd}
      onExpirationEditorClose={handleExpirationEditorClose}
      onExpirationEditorOpen={handleExpirationEditorOpen}
      onExpirationSelect={handleExpirationSelect}
      onVehiclesRedirect={handleVehiclesRedirect}
      onViewChange={handleViewChange}
      onShipmentRedirect={handleShipmentRedirect}
    />
  );
}

export default VehicleProfileContainerAdapter;
