import React, { useCallback, useState, useEffect } from "react";
import { useSnackbar } from "notistack";

import { useTranslation } from "react-i18next";
import queryString from "query-string";
import { Typography, Chip, Button, Avatar, Tooltip, makeStyles } from "@material-ui/core";
import LockIcon from "@material-ui/icons/Lock";
import { getContactSharingUsers, setContactSharingUsers } from "@cargotic/api-client";
import LockOpenIcon from "@material-ui/icons/LockOpen";

import {
  loadMatchFilter,
  storeMatchFilter,
  storeSearchText,
  loadSearchText,
  storeFilters,
  loadFilters
} from "../../../storage";

import Ico from "./Ico";
import { useApiClient } from "../../../../cargotic-webapp-component";
import ContactEditor from "../../../../cargotic-webapp-contact/component/ContactEditor";

import UserAvatar from "../../../../cargotic-webapp-component/component/UserAvatar";

import {
  readIcoInfo
} from "../../../resource";
import useRouter from "../../hook/useRouter";
import useAuth from "../../hook/useAuth";
import useTable from "../../../datatable/useTable";
import FilterSettings from "../../../../cargotic-webapp-filter/component/FilterSettings";

import {
  addUrlParam,
  getTableUrlParams
} from "../../../utility/window"

const useStyles = makeStyles(({ palette, spacing }) => ({
  contactName: {
    display: "flex",
    alignItems: "center",

    "& > :not(:first-child)": {
      marginLeft: spacing(1),
      fontSize: 19
    }
  }
}));

const IcoContainer = () => {
  const { t, i18n } = useTranslation();
  const { user } = useAuth();
  const client = useApiClient();

  const [search, setSearch] = useState("");
  const [icoData, setIcoData] = useState(undefined);
  const [searching, setSearching] = useState(false);
  const [loading, setLoading] = useState(true);

  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();


  const handleSearch = async (search) => {
    if (!search.length) {
      return;
    }
    setLoading(true);
    search = search.trim();
    setSearch(search);
    setSearching(true);
    const response = await readIcoInfo(search);
    setIcoData(response);
    setLoading(false);
  };

  const { hasPermission } = useAuth();

  return (
      <>
        <Ico
            searching={searching}
            search={search}
            handleSearch={handleSearch}
            icoData={icoData}
            loading={loading}
        />
      </>
  );
};

export default IcoContainer;
