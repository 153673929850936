import { format, isSameDay } from "date-fns";

const TIME_FORMAT = "HH:mm";
const DATE_TIME_FORMAT = `dd.MM ${TIME_FORMAT}`;

const formatDateRange = (from, to) => {
  const formattedFrom = format(from, DATE_TIME_FORMAT);

  if (!to) {
    return formattedFrom;
  }

  const toFormat = isSameDay(from, to)
    ? TIME_FORMAT
    : DATE_TIME_FORMAT;

  return `${formattedFrom} - ${format(to, toFormat)}`;
};

export default formatDateRange;
