import React, { useState, useRef, useEffect } from "react";
import MapTheme from "../../../../../multiload/cargotic-webapp/theme/CargoticMapTheme.json";
import { useTheme } from "@material-ui/core";
import { calculateRoute } from "../../../../../multiload/cargotic-map";
import { teal, blue, red } from "@material-ui/core/colors";
import { Circle } from "react-google-maps";
import { Stop, AddCircle } from "@material-ui/icons";

const CZECH_REPUBLIC_GEOLOCATION = {
  lat: 49.8175,
  lng: 15.4730
};

const TrackingMap = ({ googleMapsApi, tracking, driverPath, plannedPath, selectedDriverId }) => {
  const theme = useTheme();
  const mapRef = useRef();
  const [map, setMap] = useState();
  const [isMapLoaded, setIsMapLoaded] = useState(false);

  const directionsService = useRef();
  const directionsRenderer = useRef();

  const [polyline, setPolyline] = useState(new google.maps.Polyline());
  const [markers, setMarkers] = useState([]);
  const currentPath = useRef([]);
  const infoWindow = useRef(undefined);

  useEffect(() => {
    const newMap = new googleMapsApi.Map(
      mapRef.current,
      {
        center: CZECH_REPUBLIC_GEOLOCATION,
        zoom: 8,
        streetViewControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
        styles: MapTheme
      }
    );

    setMap(newMap);
    directionsService.current = new googleMapsApi.DirectionsService();
    directionsRenderer.current = new googleMapsApi.DirectionsRenderer({
      polylineOptions: {
        strokeColor: theme.palette.primary.main,
        strokeOpacity: 0.7,
        strokeWeight: 10
      }
    });

    googleMapsApi.event.addListenerOnce(
      newMap,
      "idle",
      () => setIsMapLoaded(true)
    );
  }, []);

  useEffect(() => {
    markers.forEach(({ id: markerId, marker }) => {
      marker.setIcon({
        path: google.maps.SymbolPath.CIRCLE,
        scale: 8,
        fillOpacity: 1,
        strokeWeight: 0,
        fillColor: markerId === selectedDriverId ? blue[700] : red[700],
      });
    });
  }, [markers, driverPath]);

  useEffect(() => {
    let newMarkers = [];
    tracking?.forEach(({ id, vehicle, driver, tracking: { latitude, longitude } }, index) => {
      const existingMarker = markers.find(({ id: markerId }) => markerId === id);
      if (existingMarker) {
        existingMarker.marker.setPosition({
          lat: latitude,
          lng: longitude
        });
        newMarkers = [...newMarkers, { id, marker: existingMarker.marker }];
        return;
      }
      const marker = new google.maps.Marker({
        icon: {
          path: google.maps.SymbolPath.CIRCLE,
          scale: 8,
          strokeWeight: 0,
          fillColor: red[700]
        },
        position: {
          lat: latitude,
          lng: longitude
        },
        map,
      });

      const markerInfoWindow = new google.maps.InfoWindow({
        content: `
            <b>${driver?.name}</b></br>
            ${vehicle?.manufacturer} ${vehicle?.model}, ${vehicle?.licensePlate}
          `
      });
      markerInfoWindow.addListener("closeclick", () => infoWindow.current = null);

      marker.addListener("click", () => {
        const currentInfoWindow = infoWindow.current;
        if (currentInfoWindow) {
          currentInfoWindow.close();
          if (currentInfoWindow === markerInfoWindow) {
            infoWindow.current = null;
            return;
          }
        }
        infoWindow.current = markerInfoWindow;
        markerInfoWindow.open(map, marker)
      });

      newMarkers = [...newMarkers, { id, marker }];
    });
    setMarkers(newMarkers);
  }, [map, tracking]);

  useEffect(() => {
    if (driverPath && driverPath.length > 0) {
      const driverPolyline = new google.maps.Polyline({
        path: driverPath,
        geodesic: true,
        strokeColor: blue[700],
        strokeOpacity: 1.0,
        strokeWeight: 3
      });
      driverPolyline.setMap(map);
      polyline.setMap(null);
      setPolyline(driverPolyline);
    } else {
      polyline.setMap(null);
      currentPath.current = [];
      directionsRenderer.current.setMap(null);
    }
  }, [driverPath]);

  useEffect(() => {
    if (JSON.stringify(currentPath.current) !== JSON.stringify(plannedPath)) {
      currentPath.current = plannedPath;
      drawDirectionRoute(plannedPath);
    }
  }, [plannedPath]);

  const drawDirectionRoute = async (waypoints) => {
    directionsRenderer.current.setMap(null);
    const { distance, duration, directions } = await calculateRoute(
      directionsService.current,
      waypoints,
      googleMapsApi.TravelMode.DRIVING
    );

    if (!directions) {
      directionsRenderer.current.setMap(null);
    } else {
      directionsRenderer.current.setMap(map);
      directionsRenderer.current.setDirections(directions);
    }
  }

  return (
    <>
      <div
        style={{ height: "100%", width: "100%" }}
        ref={mapRef} />
    </>
  );
}

export default TrackingMap;