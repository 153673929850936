import React from "react";
import { Route } from "react-router-dom";

import { makeStyles } from "@material-ui/core";

import VehicleProfileView from "../../VehicleProfileView";
import VehicleExpenseOverviewContainer
  from "../VehicleExpenseOverviewContainer";

import VehicleExpirationOverview from "../VehicleExpirationOverview";
import VehicleOverviewContainer from "../VehicleOverviewContainer";
import VehicleIncomeOverviewContainer from "../VehicleIncomeOverviewContainer";

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    marginTop: spacing(5)
  }
}));

const VehicleProfileBodyContent = ({
  expirations,
  expirationPage,
  expirationQuery,
  expirationRowsPerPage,
  selectedExpiration,
  view,
  user,
  activity,
  vehicle,
  isActivityLoading,
  isExpirationCreationDisabled,
  isExpirationDeleteConfirmationAlertOpen,
  isExpirationDeletionDisabled,
  isExpirationEditationDisabled,
  isExpirationEditorOpen,
  isExpirationsLoading,
  isIncomeCreationDisabled,
  isIncomeDeletionDisabled,
  isIncomeEditationDisabled,
  onActivityLoad,
  onCommentaryChange,
  onCommentaryDelete,
  onCommentarySubmit,
  onExpirationAdd,
  onExpirationDelete,
  onExpirationDeleteConfirmationAlertClose,
  onExpirationDeleteConfirmationAlertSubmit,
  onExpirationEdit,
  onExpirationEditorClose,
  onExpirationEditorOpen,
  onExpirationEditorSubmit,
  onExpirationPageChange,
  onExpirationQueryChange,
  onExpirationRowsPerPageChange,
  onBreadcrumbsChange
}) => {
  const classes = useStyles();
  const className = classes.root;

  switch (view) {
    case VehicleProfileView.VEHICLE_INCOME_OVERVIEW: {
      return (
        <VehicleIncomeOverviewContainer
          className={className}
          vehicle={vehicle}
          isIncomeCreationDisabled={isIncomeCreationDisabled}
          isIncomeDeletionDisabled={isIncomeDeletionDisabled}
          isIncomeEditationDisabled={isIncomeEditationDisabled}
          onBreadcrumbsChange={onBreadcrumbsChange}
        />
      );
    }

    case VehicleProfileView.VEHICLE_EXPENSE_OVERVIEW: {
      return (
        <VehicleExpenseOverviewContainer
          className={className}
          vehicle={vehicle}
          onBreadcrumbsChange={onBreadcrumbsChange}
        />
      );
    }

    case VehicleProfileView.VEHICLE_EXPIRATION_OVERVIEW: {
      return (
        <VehicleExpirationOverview
          className={className}
          expirations={expirations}
          page={expirationPage}
          query={expirationQuery}
          rowsPerPage={expirationRowsPerPage}
          selectedExpiration={selectedExpiration}
          vehicle={vehicle}
          isCreationDisabled={isExpirationCreationDisabled}
          isDeleteConfirmationAlertOpen={
            isExpirationDeleteConfirmationAlertOpen
          }
          isDeletionDisabled={isExpirationDeletionDisabled}
          isEditationDisabled={isExpirationEditationDisabled}
          isEditorOpen={isExpirationEditorOpen}
          isExpirationsLoading={isExpirationsLoading}
          onBreadcrumbsChange={onBreadcrumbsChange}
          onDeleteConfirmationAlertClose={
            onExpirationDeleteConfirmationAlertClose
          }
          onDeleteConfirmationAlertSubmit={
            onExpirationDeleteConfirmationAlertSubmit
          }
          onEditorClose={onExpirationEditorClose}
          onEditorSubmit={onExpirationEditorSubmit}
          onExpirationAdd={onExpirationAdd}
          onExpirationDelete={onExpirationDelete}
          onExpirationEdit={onExpirationEdit}
          onPageChange={onExpirationPageChange}
          onQueryChange={onExpirationQueryChange}
          onRowsPerPageChange={onExpirationRowsPerPageChange}
        />
      );
    }

    case VehicleProfileView.VEHICLE_OVERVIEW: {
      return (
        <VehicleOverviewContainer
          className={className}
          vehicle={vehicle}
          user={user}
          activity={activity}
          isActivityLoading={isActivityLoading}
          onActivityLoad={onActivityLoad}
          onBreadcrumbsChange={onBreadcrumbsChange}
          onCommentaryChange={onCommentaryChange}
          onCommentaryDelete={onCommentaryDelete}
          onCommentarySubmit={onCommentarySubmit}
        />
      );
    }

    default: {
      throw new Error(`Unknown vehicle profile view '${view}'`);
    }
  }
};

export default VehicleProfileBodyContent;
