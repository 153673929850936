import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";

import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  IconButton,
  Tooltip,
  Typography,
  makeStyles
} from "@material-ui/core";

import {
  Delete as DeleteIcon,
  Edit as EditIcon
} from "@material-ui/icons";

import { getNameInitials } from "../../../cargotic-webapp-utility";

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: "flex",
    flexDirection: "column"
  },
  content: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: spacing(2)
  },
  avatar: {
    width: spacing(10),
    height: spacing(10),

    marginBottom: spacing(2)
  },
  actions: {
    dispaly: "flex",
    justifyContent: "flex-end"
  }
}));

const ContactEmployeeCard = ({
  className,
  employee: {
    id,
    name,
    email,
    phoneNumber
  },
  isDeleteButtonDisabled,
  isEditButtonDisabled,
  onDeleteButtonClick,
  onEditButtonClick
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Card className={clsx(className, classes.root)}>
      <CardContent className={classes.content}>
        <Avatar className={classes.avatar}>
          {name ? getNameInitials(name) : `#${id}`}
        </Avatar>
        <Typography variant="h6" gutterBottom>
          {name ?? `${t("webapp:common.title.employee")} #${id}`}
        </Typography>
        <Typography>
          {email}
        </Typography>
        <Typography>
          {phoneNumber}
        </Typography>
      </CardContent>
      <CardActions className={classes.actions}>
        <Tooltip
          title={t("webapp:contact.title.edit-employee")}
          disableFocusListener={isEditButtonDisabled}
          disableHoverListener={isEditButtonDisabled}
          disableTouchListener={isEditButtonDisabled}
        >
          <span>
            <IconButton
              size="small"
              disabled={isEditButtonDisabled}
              onClick={onEditButtonClick}
            >
              <EditIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip
          title={t("webapp:contact.title.delete-employee")}
          disableFocusListener={isDeleteButtonDisabled}
          disableHoverListener={isDeleteButtonDisabled}
          disableTouchListener={isDeleteButtonDisabled}
        >
          <span>
            <IconButton
              size="small"
              disabled={isDeleteButtonDisabled}
              onClick={onDeleteButtonClick}
            >
              <DeleteIcon />
            </IconButton>
          </span>
        </Tooltip>
      </CardActions>
    </Card>
  );
};

export default ContactEmployeeCard;
