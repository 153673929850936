import { Optional, ValueError } from "@cargotic/common-deprecated";
import {
  ObjectValidationError,
  Validate,
  createObjectValidator
} from "@cargotic/validate-deprecated";

interface FormInputElement {
  type: string;
  name: string;
  checked: boolean;
  value: string;
}

interface FormValues {
  [key: string]: unknown;
}

type FormErrors<T extends FormValues> = {
  [key in keyof T]?: Optional<ValueError>
}

type FormTouches<T extends FormValues> = {
  [key in keyof T]?: Optional<boolean>;
}

type ValidateForm<
  T extends FormValues,
  K extends keyof T = keyof T,
  R extends { [key in K]: unknown } = { [key in K]: unknown }
  > = Validate<T, R>;

interface FormOptions<
  T extends FormValues,
  K extends keyof T = keyof T,
  R extends { [key in K]: unknown } = { [key in K]: unknown }
  > {
  initialValues: T;
  validate?: ValidateForm<T, K, R>;

  onChange: (newValues: T, oldValues: T) => void;
  onSubmit: (values: R) => void;
  onBlur: (value: string) => void;
}

interface Form<T extends FormValues> {
  errors: FormErrors<T>;
  touches: FormTouches<T>;
  values: T;
  isSubmitting: boolean;

  reset: () => void;

  setError: <K extends keyof T>(field: K, error: Optional<ValueError>) => void;
  setErrors: (errors: FormErrors<T>) => void;
  setTouch: <K extends keyof T>(field: K, touch: Optional<boolean>) => void;
  setTouches: (touches: FormTouches<T>) => void;
  setValue: <K extends keyof T>(field: K, value: T[K]) => void;
  setValues: (values: T) => void;
  setIsSubmitting: (value: boolean) => void;

  handleBlur: React.FocusEventHandler<{ name?: string }>;
  handleChange: React.ChangeEventHandler<{ name?: string; value?: unknown }>;
  handleSubmit: React.FormEventHandler;
}

type FormValidationSchema<T extends FormValues, K extends keyof T = keyof T> = {
  [key in K]: (value: T[K]) => void;
};

class FormValidationError<
  T extends FormValues
  > extends ObjectValidationError<T> {

}

function createFormValidator<
  T extends FormValues,
  K extends keyof T,
  R extends { [key in keyof T]: unknown } = { [key in keyof T]: unknown },
  V extends {
    [key in keyof T]: Validate<T[K], R[K]>
  } = { [key in keyof T]: Validate<T[K], R[K]> }
>(validators: V): ValidateForm<T> {
  const validator = createObjectValidator<T, K, R, V>(validators);

  return (object) => {
    try {
      return validator(object);
    } catch (error) {
      if (!(error instanceof ObjectValidationError)) {
        throw error;
      }

      throw new FormValidationError(error.errors);
    }
  };
}

export {
  Form,
  FormErrors,
  FormInputElement,
  FormOptions,
  FormTouches,
  FormValidationError,
  FormValidationSchema,
  FormValues,
  ValidateForm,

  createFormValidator
};
