import { default as UnspecifiedTemplateLengthUnit } from '../../multiload/cargotic-core/model/LengthUnit'

const storePagination = (table, value) => window.localStorage.setItem(`pagination-${table}`, value);
const loadPagination = (table) => {
  const pagination = parseInt(window.localStorage.getItem(`pagination-${table}`))
  if (pagination <= 100 && pagination > 0) {
    return pagination;
  }
  storePagination(table, 5);
  return 5;
};

const storeStatisticsFilter = (filter) => window.localStorage.setItem("statisticsFilter", JSON.stringify(filter));
const loadStatisticsFilter = (initial) => JSON.parse(window.localStorage.getItem("statisticsFilter")) || { types: initial ?? [] };

const storeUserStatisticsFilter = (filter) => window.localStorage.setItem("userStatisticsFilter", JSON.stringify(filter));
const loadUserStatisticsFilter = () => JSON.parse(window.localStorage.getItem("userStatisticsFilter")) || { types: [] };

const storePage = (table, page) => window.localStorage.setItem(`page-${table}`, page);
const loadPage = (table) => parseInt(window.localStorage.getItem(`page-${table}`)) || 0;

const storeDrawerState = (state) => window.localStorage.setItem(`drawerState`, state);
const loadDrawerState = () => !(window.localStorage.getItem(`drawerState`) === "false");

const storeDefaultUniLength = (unit) => window.localStorage.setItem('unspecifiedTemplateUnit', unit);
const loadDefaultUnitLength = () => window.localStorage.getItem('unspecifiedTemplateUnit') || UnspecifiedTemplateLengthUnit.M;

const storeBoardFilterIsPersonalView = (value) => window.localStorage.setItem("boardFilterIsPersonalView", value);
const loadBoardFilterIsPersonalView = () => window.localStorage.getItem("boardFilterIsPersonalView");

const storeSignedUser = (value) => window.localStorage.setItem("signedUser", value);
const loadSignedUser = () => window.localStorage.getItem("signedUser");

const storeHiddenColumns = (columns) => window.localStorage.setItem("hiddenBoardColumns", columns);
const loadHiddenColumns = () => window.localStorage.getItem("hiddenBoardColumns") || "[]";

const storeBoardCardSize = (columns) => window.localStorage.setItem("boardCardSize", columns);
const loadBoardCardSize = () => window.localStorage.getItem("boardCardSize") ? parseInt(window.localStorage.getItem("boardCardSize")) : 3;

const storeFilters = (page, filters) => window.localStorage.setItem(`filters-${page}`, filters);
const loadFilters = (page) => window.localStorage.getItem(`filters-${page}`) && window.localStorage.getItem(`filters-${page}`).length > 0 ? window.localStorage.getItem(`filters-${page}`).split(",") : [];

export {
  storePagination,
  loadPagination,
  storeDrawerState,
  loadDrawerState,
  storeDefaultUniLength,
  loadDefaultUnitLength,
  storeBoardFilterIsPersonalView,
  loadBoardFilterIsPersonalView,
  storeSignedUser,
  loadSignedUser,
  storeHiddenColumns,
  loadHiddenColumns,
  storeBoardCardSize,
  loadBoardCardSize,
  storePage,
  loadPage,
  storeStatisticsFilter,
  loadStatisticsFilter,
  storeFilters,
  loadFilters,
  loadUserStatisticsFilter,
  storeUserStatisticsFilter
};
