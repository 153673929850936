import React from "react";

import { List } from "@material-ui/core";

import { AutosuggestSuggestionComponentProps } from "../Autosuggest";
import SuggestionAreaListItem from "./SuggestionAreaListItem";

interface SuggestionAreaListProps<T> {
  suggestions: T[];
  getSuggestionText: (suggestion: T) => string;
  suggestionComponent:
    React.ComponentType<AutosuggestSuggestionComponentProps<T>>;

  onSuggestionClick?: (suggestion: T) => void;
}

function SuggestionAreaList<T>(
  {
    suggestions,
    getSuggestionText,
    suggestionComponent,
    onSuggestionClick
  }: SuggestionAreaListProps<T>
): React.ReactElement {
  const handleSuggestionClick = (suggestion: T): void => {
    if (onSuggestionClick) {
      onSuggestionClick(suggestion);
    }
  };

  const items = suggestions
    .map((suggestion) => {
      const text = getSuggestionText(suggestion);

      return (
        <SuggestionAreaListItem
          key={text}
          suggestion={suggestion}
          suggestionComponent={suggestionComponent}
          onClick={handleSuggestionClick}
        />
      );
    });

  return (
    <List>
      {items}
    </List>
  );
}

export default SuggestionAreaList;
