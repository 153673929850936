import React from "react";
import { useTranslation } from "react-i18next";

import { Tab, Tabs } from "@material-ui/core";

import ContactProfileView from "../../ContactProfileView";

const ContactProfileBodyNavigation = ({ view, onViewChange }) => {
  const { t } = useTranslation();

  const handleTabChange = (_, value) => onViewChange(value);

  return (
    <Tabs
      indicatorColor="primary"
      value={view}
      onChange={handleTabChange}
    >
      <Tab
        label={t("webapp:common.title.overview")}
        value={ContactProfileView.CONTACT_OVERVIEW}
      />
      <Tab
        label={t("webapp:common.title.employees")}
        value={ContactProfileView.CONTACT_EMPLOYEE_OVERVIEW}
      />
    </Tabs>
  );
};

export default ContactProfileBodyNavigation;
