import { cs, enGB, enUS } from "date-fns/locale";

const DATE_FNS_I18N = {
  cs,
  en: enUS,

  "en-US": enUS,
  "en-GB": enGB,
  "cs-CZ": cs
};

const getDateFnsLocale = (language: string) => {
  if (!(language in DATE_FNS_I18N)) {
    console.error(`Unsupported language '${language}'.`);
    console.log("Fallback language to en-GB");
    return DATE_FNS_I18N["en-GB"] as Locale;
  }

  return DATE_FNS_I18N[language] as Locale;
};

export default getDateFnsLocale;
