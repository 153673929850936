import React from "react";
import { useTranslation } from "react-i18next";

import { dehydrateWaypoints } from "../../utility";
import Pane from "../Pane";
import PaneHeader from "../PaneHeader";
import JourneySummaryActions from "./JourneySummaryActions";
import JourneySummaryContent from "./JourneySummaryContent";

const JourneySummary = ({
  className,
  waypoints,
  isVisible,
  isCargoEditorVisible,
  isJourneyComplete,
  onCargoEditorToggle,
  onComplete
}) => {
  const { t } = useTranslation();
  const dehydratedWaypoints = dehydrateWaypoints(waypoints);

  return (
    <Pane className={className} isVisible={isVisible}>
      <PaneHeader title={t("webapp:journey.summary.title")} />
      <JourneySummaryContent waypoints={dehydratedWaypoints} />
      <JourneySummaryActions
        isCargoEditorVisible={isCargoEditorVisible}
        isCompleteButtonDisabled={!isJourneyComplete}
        onCargoEditorToggle={onCargoEditorToggle}
        onCompleteButtonClick={onComplete}
      />
    </Pane>
  );
};

export default JourneySummary;
