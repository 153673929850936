import React, { useEffect, useState } from "react";
import IBAN from "iban";

import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Currency } from "@cargotic/currency-deprecated";

import useAuth from "../../hook/useAuth";
import { updatePassword } from "../../../auth";
import { useApiClient } from "../../../../cargotic-webapp-component";

import Settings from "./Settings";
import {
  readCompanyProfile,
  updateCompanyProfile,
  createCompanyProfile,
  updateCompanyProfileAvatar,
  deleteCompany,

  updateCustomerProfile,
  updateCustomerProfileAvatar,

  readInvoices,
  readAvailableTags,

  readUserTerms,
  updateTerms,
  createTerms,
  readCompanyTerms,
  deleteTerms,

  createStampSignature,
  deleteStampSignature
} from "../../../resource";
import UpdateUserProfileDialog from "../UserProfile/UpdateUserProfileDialog";
import CreateCompanyProfileDialog from "../CompanyProfile/CreateCompanyProfileDialog";
import UpdateCompanyProfileDialog from "../CompanyProfile/UpdateCompanyProfileDialog";
import ChangePasswordDialog from "../UserProfile/ChangePasswordDialog";
import DeleteCompanyDialog from "../CompanyProfile/DeleteCompanyDialog";
import SetConditionsDialog from "../CompanyProfile/SetConditionsDialog";
import SetPricePerKmDialog from "../CompanyProfile/SetPricePerKmDialog";

import useUserProfile from "../../hook/useUserProfile";
import useTariff from "../../hook/useTariff";

import { convertCzFormatToBBAN, formatIBAN, DEFAULT_COUNTRY_CODE, convertBBANFormatToCz } from "../../../utility/banking";
import { ValueError } from "@cargotic/common";

const SettingsContainer = () => {
  const { t, i18n } = useTranslation();
  const { user, signOut } = useAuth();
  const {
    loading: userProfileLoading,
    setLoading: setUserProfileLoading,
    setUserProfile,
    userProfile
  } = useUserProfile();
  const apiClient = useApiClient();

  const [companyProfile, setCompanyProfile] = useState([]);
  const [userConditions, setUserConditions] = useState({});
  const [companyConditions, setCompanyConditions] = useState({});
  const [invoices, setInvoices] = useState([]);
  const [changePasswordDialogOpen, setChangePasswordDialogOpen] = useState(false);
  const [deleteCompanyDialogOpen, setDeleteCompanyDialogOpen] = useState(false);
  const [companyConditionsOpen, setCompanyConditionsOpen] = useState(false);
  const [userConditionsOpen, setUserConditionsOpen] = useState(false);
  const [priceOpen, setPriceOpen] = useState(false);
  const [optionTags, setOptionTags] = useState([]);
  const [companyBanking, setCompanyBanking] = useState([]);
  const [stampSignatureFile, setStampSignatureFile] = useState();

  const [
    updateCustomerProfileDialogOpen,
    setUpdateCustomerProfileDialogOpen
  ] = useState(false);
  const [
    updateCompanyProfileDialogOpen,
    setUpdateCompanyProfileDialogOpen
  ] = useState(false);
  const [
    createCompanyProfileDialogOpen,
    setCreateCompanyProfileDialogOpen
  ] = useState(false);

  const [loading, setLoading] = useState(true);
  const [loadingBankAccountCard, setLoadingBankAccountCard] = useState(true);
  const [invoicesLoading, setInvoicesLoading] = useState(true);
  const [userConditionsLoading, setUserConditionsLoading] = useState(true);
  const [companyConditionsLoading, setCompanyConditionsLoading] = useState(true);
  const [hasDocumentStampSignatureFileDropzoneError, setHasDocumentStampSignatureFileDropzoneError] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const language = i18n.language === "cs" ? "CZ" : "EN";

  const updateCompanyProfilePartial = (update) =>
    updateCompanyProfile({ ...companyProfile, ...update });

  const fetchTags = async () => {
    const tgs = await readAvailableTags(t, language);
    setOptionTags(tgs);
  };

  const handleDeleteUserConditionsSubmit = async (termsId) => {
    setUserConditionsOpen(false);

    try {
      await deleteTerms(termsId);
      setUserConditions({});
    } catch (error) {
      console.error(error);

      enqueueSnackbar(t("customers.error.update"), {
        variant: "error"
      });
    }
  }

  const handleDeleteCompanyConditionsSubmit = async (termsId) => {
    setCompanyConditionsOpen(false);

    try {
      await deleteTerms(termsId);
      setCompanyConditions({});
    } catch (error) {
      console.error(error);

      enqueueSnackbar(t("customers.error.update"), {
        variant: "error"
      });
    }
  }

  const handleUserConditionsSubmit = async (terms) => {
    setLoading(true);
    setUserConditionsOpen(false);

    try {
      const { termsId, ...termsData } = terms;
      if (termsId) {
        await updateTerms(termsId, termsData);
        setUserConditions({ termsId, ...termsData });
      } else {
        const createdTerms = await createTerms(terms, false);
        setUserConditions({ termsId: createdTerms.termsId, isCompany: false, ...terms });
      }
    } catch (error) {
      console.error(error);

      enqueueSnackbar(t("customers.error.update"), {
        variant: "error"
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCompanyConditionsSubmit = async (terms) => {
    setLoading(true);
    setCompanyConditionsOpen(false);

    try {
      const { termsId, ...termsData } = terms;
      if (termsId) {
        await updateTerms(termsId, termsData);
        setCompanyConditions({ termsId, ...termsData });
      } else {
        await createTerms(terms, true);
        setCompanyConditions({ termsId, ...termsData });
      }

    } catch (error) {
      console.error(error);

      enqueueSnackbar(t("customers.error.update"), {
        variant: "error"
      });
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateCustomerProfileSubmit = (firstName, lastName, phoneNumber) => {
    setUserProfileLoading(true);
    setUpdateCustomerProfileDialogOpen(false);

    return updateCustomerProfile(firstName, lastName, phoneNumber, null)
      .then(() => {
        setUserProfile({
          ...userProfile, firstName, lastName, phoneNumber
        });
        setUserProfileLoading(false);
      })
      .catch((error) => {
        console.error(error);

        enqueueSnackbar(t("users.error.update"), {
          variant: "error"
        });

        setUserProfileLoading(false);
      });
  };
  const handleCreateCompanyProfileSubmit = (
    ic,
    dic,
    companyName,
    url,
    email,
    address,
    placeId,
    tags
  ) => {
    setLoading(true);
    setCreateCompanyProfileDialogOpen(false);

    return createCompanyProfile(ic, dic, url, companyName, email, address, placeId, tags)
      .then(() => {
        setCompanyProfile({
          ic, dic, url, companyName, email, address, placeId, tags
        });
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);

        enqueueSnackbar(t("company.error.create"), {
          variant: "error"
        });
      });
  };

  const handleUpdateCompanyProfileSubmit = async ({
    ic,
    dic,
    url,
    companyName,
    email,
    address,
    placeId,
    mobilePrefix,
    responsiblePerson,
    tags,
    phoneNumber
  }) => {
    setLoading(true);
    setUpdateCompanyProfileDialogOpen(false);
    return updateCompanyProfile({
      ic, dic, url, companyName, email, address, phoneNumber, placeId, responsiblePerson, mobilePrefix, tags
    })
      .then(() => {
        setCompanyProfile({
          ...companyProfile, ic, dic, url, companyName, email, mobilePrefix, phoneNumber, address, placeId, responsiblePerson, tags
        });
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);

        enqueueSnackbar(t("company.error.update"), {
          variant: "error"
        });
      });
  };

  const handleChangePasswordSubmit = (password) => {
    setLoading(true);
    setChangePasswordDialogOpen(false);
    return updatePassword(password)
      .then(() => {
        setLoading(false);
        enqueueSnackbar(t("auth.success.changePassword"), { variant: "success" });
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        enqueueSnackbar(t("auth.error.changePassword"), {
          variant: "error"
        });
      });
  };

  const handleDeleteCompanySubmit = () => {
    setLoading(true);
    setDeleteCompanyDialogOpen(false);
    return deleteCompany().then(() => signOut())
      .catch((err) => {
        console.error(err);
        setLoading(false);
        enqueueSnackbar(t("auth.error.deleteAccount"), {
          variant: "error"
        });
      });
  };

  const handleChangeUserAvatarImage = async (file) => {
    setUserProfileLoading(true);

    try {
      const avatarUrl = await updateCustomerProfileAvatar(file);

      setUserProfile({
        ...userProfile,
        avatarUrl
      });
    } catch (error) {
      console.error(error);

      const { response } = error;

      if (response && response.status === 413) {
        enqueueSnackbar(t("settings.error.updatePayloadTooLarge"), {
          variant: "error"
        });
      } else {
        enqueueSnackbar(t("settings.error.update"), {
          variant: "error"
        });
      }
    } finally {
      setUserProfileLoading(false);
    }
  };

  const handleChangeCompanyAvatarImage = async (file) => {
    setLoading(true);

    try {
      const avatarUrl = await updateCompanyProfileAvatar(file);

      setCompanyProfile({
        ...companyProfile,
        avatarUrl
      });
    } catch (error) {
      console.error(error);

      const { response } = error;

      if (response && response.status === 413) {
        enqueueSnackbar(t("settings.error.updatePayloadTooLarge"), {
          variant: "error"
        });
      } else {
        enqueueSnackbar(t("settings.error.update"), {
          variant: "error"
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSetPriceSubmit = async (price) => {
    setLoading(true);
    setPriceOpen(false);

    try {
      await updateCompanyProfile({ price });

      setCompanyProfile({
        ...companyProfile,
        price
      });
    } catch (error) {
      console.error(error);

      const { response } = error;

      enqueueSnackbar(response, {
        variant: "error"
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCreateBankAccountSubmit = async ({ currency, iban, bban }, isIbanFormatSelected) => {
    try {
      let createdBankAccount;

      if (isIbanFormatSelected) {
        const formattedIban = formatIBAN(iban);

        if (!IBAN.isValid(formattedIban)) {
          throw new ValueError("settings.bankAccount.updateErrorIBAN");
        }

        createdBankAccount = await apiClient.bankAccount.postBankAccount({ bankAccount: { currency, iban: formattedIban } });
      } else {
        const formattedBban = convertCzFormatToBBAN(bban);

        if (!IBAN.isValidBBAN(DEFAULT_COUNTRY_CODE, formattedBban)) {
          throw new ValueError("settings.bankAccount.updateErrorBBAN");
        }

        createdBankAccount = await apiClient.bankAccount.postBankAccount({ bankAccount: { currency, bban: formattedBban } });
      }
      await fetchBanking();

      enqueueSnackbar(t("settings.bankAccount.success.create"), {
        variant: "success"
      });
    }
    catch (error) {
      console.error(error);
      let errorMessage = t("settings.bankAccount.error.create");

      if (error instanceof ValueError) {
        errorMessage = t(error.message);
      }

      enqueueSnackbar(errorMessage, {
        variant: "error"
      });
    }
  };

  const handleRemoveBankAccountSubmit = async (id, index) => {
    setLoading(true);

    try {
      if (id) {
        await apiClient.bankAccount.deleteBankAccount({ id });
      }
      const filteredItems = companyBanking.slice(0, index).concat(companyBanking.slice(index + 1, companyBanking.length))

      setCompanyBanking(filteredItems);
      enqueueSnackbar(t("settings.bankAccount.success.delete"), {
        variant: "success"
      });
    } catch (error) {
      console.error(error);

      enqueueSnackbar(t("settings.bankAccount.error.delete"), {
        variant: "error"
      });
    }

    setLoading(false);
  };


  const handleUpdateBankAccountSubmit = async (id, { currency, bban, iban }, isIbanFormatSelected) => {
    setLoading(true);
    let updatedBankAccount;

    try {
      if (isIbanFormatSelected) {
        const formattedIban = formatIBAN(iban);

        if (!IBAN.isValid(formattedIban)) {
          throw new ValueError("settings.bankAccount.updateErrorIBAN");
        }
        updatedBankAccount = await apiClient.bankAccount.putBankAccount({
          id,
          bankAccount: { currency, iban: formattedIban }
        });
      } else {
        const formattedBban = convertCzFormatToBBAN(bban);

        if (!IBAN.isValidBBAN(DEFAULT_COUNTRY_CODE, formattedBban)) {
          throw new ValueError("settings.bankAccount.updateErrorBBAN");
        }

        updatedBankAccount = await apiClient.bankAccount.putBankAccount({
          id,
          bankAccount: { currency, bban: formattedBban }
        });
      }
      const bankAccountItem = companyBanking.find(item => item.id === id);
      const filteredItems = companyBanking.filter(item => item.id !== id);

      setCompanyBanking([...filteredItems, { ...bankAccountItem, ...updatedBankAccount, bban: convertBBANFormatToCz(updatedBankAccount.bban), isIbanFormatSelected: false }]);

      enqueueSnackbar(t("settings.bankAccount.success.update"), {
        variant: "success"
      });
    } catch (error) {
      console.error(error);
      let errorMessage = t("settings.bankAccount.error.update");

      if (error instanceof ValueError) {
        errorMessage = t(error.message);
      }

      enqueueSnackbar(errorMessage, {
        variant: "error"
      });
    }

    setLoading(false);
  };

  const handleStampSignatureFileSelect = async (file) => {
    setHasDocumentStampSignatureFileDropzoneError(false);

    try {
      const { url, id, createdAt, name } = await createStampSignature(file);

      setStampSignatureFile({ id, url, createdAt, isUploading: false, name });
    } catch (error) {
      console.error(error);

      let message = t("settings.stamp.error.upload.general");

      if (error?.response?.data?.error === "FileTooLargeError") {
        message = t("settings.stamp.error.upload.size");
      }

      setStampSignatureFile({
        isUploading: false,
        error: message
      });
    }
  }

  const handleStampSignatureFileDelete = async () => {
    try {
      await deleteStampSignature();

      setStampSignatureFile(undefined);
    } catch (error) {
      console.error(error);

      enqueueSnackbar(t("settings.stamp.error.delete"), {
        variant: "error"
      });
    }
  };

  const replaceBankAccount = (index, item) => {
    let replaced = [...companyBanking];
    replaced[index] = item;
    setCompanyBanking(replaced);
  };

  const appendUnfilledBankAccount = () => (
    setCompanyBanking([...companyBanking, { currency: Currency.CZK, isIbanFormatSelected: false, bban: "", iban: "" }])
  );

  const { hasPermission } = useAuth();
  const canUpdatePersonalProfile = hasPermission("resource.user.update");
  const canUpdateCompanyProfile = hasPermission("resource.company.update");
  const canReadCompanyProfileConditions = hasPermission("resource.terms.company.read");
  const canUpdateCompanyProfileConditions = hasPermission("resource.terms.company.update");
  const canDeleteCompanyProfileConditions = hasPermission("resource.terms.company.delete");
  const canReadPersonalProfile = hasPermission("resource.user.read");
  const canReadCompanyProfile = hasPermission("resource.company.read");
  const canAccessInvoices = hasPermission("resource.subscription.invoice.read");
  const canUpdateBankAccount = hasPermission("resource.company.update");
  const canDeletePersonalProfile = hasPermission("resource.user.delete");
  const canDeleteCompanyProfile = hasPermission("resource.company.delete");

  useEffect(() => {
    fetchTags();
  }, []);

  const fetchBanking = async () => {
    try {
      const banking = await apiClient.bankAccount.getBankAccounts();
      setCompanyBanking(banking.map((item) => ({ ...item, bban: convertBBANFormatToCz(item.bban), isIbanFormatSelected: false })));
    } catch (error) {
      console.error(error);

      enqueueSnackbar(t("settings.bankAccount.error.read"), {
        variant: "error"
      });
    }
  };

  useEffect(() => {
    fetchBanking();
  }, []);

  useEffect(() => {
    const fetchCompanyProfile = async () => {
      try {
        if (canReadCompanyProfile) {
          const companyProfile = await readCompanyProfile(language);
          setCompanyProfile(companyProfile);
          if (companyProfile.stampSignature) {
            const { stampSignature } = companyProfile;
            setStampSignatureFile(stampSignature);
          }
        }
      }
      catch (err) {
        console.error(err);

        enqueueSnackbar(t("company.error.loading"), {
          variant: "error"
        });
      }
      finally {
        setLoading(false);
      }
    };
    fetchCompanyProfile();
  }, []);

  useEffect(() => {
    const fetchInvoices = async () => {
      try {
        if (canAccessInvoices) {
          const invoices = await readInvoices();
          setInvoices(invoices);
        }
      }
      catch (err) {
        console.error(err);

        enqueueSnackbar(t("settings.error.loadingInvoices"), {
          variant: "error"
        });
      }
      finally {
        setInvoicesLoading(false);
      }
    }
    fetchInvoices();
  }, []);

  useEffect(() => {
    const fetchConditions = async () => {
      if (canReadPersonalProfile) {
        try {
          const userTerms = await readUserTerms();
          setUserConditions(userTerms);
          setUserConditionsLoading(false);
        } catch (error) {
          console.error(error);
          setUserConditionsLoading(false);

          enqueueSnackbar(t("company.error.loading"), {
            variant: "error"
          });
        }
      }

      setUserConditionsLoading(false);
    }

    fetchConditions();
  }, []);


  useEffect(() => {
    const fetchConditions = async () => {
      try {
        const companyTerms = await readCompanyTerms();
        setCompanyConditions(companyTerms);
        setCompanyConditionsLoading(false);
      } catch (error) {
        console.error(error);
        setCompanyConditionsLoading(false);

        enqueueSnackbar(t("company.error.loading"), {
          variant: "error"
        });
      }

      setCompanyConditionsLoading(false);
    }

    fetchConditions();
  }, []);


  return (
    <>
      <Settings
        loading={loading || invoicesLoading || userProfileLoading || userConditionsLoading || companyConditionsLoading}
        companyProfile={companyProfile}
        customerProfile={userProfile}
        user={user}
        banking={companyBanking}
        updateCompanyProfile={updateCompanyProfile}
        updateCompanyProfilePartial={updateCompanyProfilePartial}

        invoices={invoices}
        canUpdatePersonalProfile={canUpdatePersonalProfile}
        canDeletePersonalProfile={canDeletePersonalProfile}
        canUpdateCompanyProfile={canUpdateCompanyProfile}
        canReadPersonalProfile={canReadPersonalProfile}
        canReadCompanyProfile={canReadCompanyProfile}
        canReadCompanyProfileConditions={canReadCompanyProfileConditions}
        canDeleteCompanyProfile={canDeleteCompanyProfile}
        canAccessInvoices={canAccessInvoices}
        canUpdateBankAccount={canUpdateBankAccount}
        handleUpdateCompanyProfileClick={() => setUpdateCompanyProfileDialogOpen(true)}
        handleUpdateCustomerProfileClick={() => setUpdateCustomerProfileDialogOpen(true)}
        handleCreateCompanyProfileClick={() => setCreateCompanyProfileDialogOpen(true)}
        handleChangePassword={() => setChangePasswordDialogOpen(true)}
        handleDeleteCompany={() => setDeleteCompanyDialogOpen(true)}
        handleUserConditionsClick={() => setUserConditionsOpen(true)}
        handleCompanyConditionsClick={() => setCompanyConditionsOpen(true)}
        handleSetPriceClick={() => setPriceOpen(true)}
        handleChangeUserAvatarImage={handleChangeUserAvatarImage}
        handleChangeCompanyAvatarImage={handleChangeCompanyAvatarImage}

        handleUpdateBankAccountSubmit={handleUpdateBankAccountSubmit}
        handleCreateBankAccountSubmit={handleCreateBankAccountSubmit}
        handleRemoveBankAccountSubmit={handleRemoveBankAccountSubmit}
        handleReplaceBankAccount={replaceBankAccount}
        handleAppendUnfilledBankAccount={appendUnfilledBankAccount}

        stampSignatureFile={stampSignatureFile}
        handleStampSignatureFileSelect={handleStampSignatureFileSelect}
        handleFileDelete={handleStampSignatureFileDelete}
      />
      <UpdateUserProfileDialog
        open={updateCustomerProfileDialogOpen}
        customerProfile={userProfile}
        handleClose={() => setUpdateCustomerProfileDialogOpen(false)}
        handleSubmit={handleUpdateCustomerProfileSubmit}
      />
      <CreateCompanyProfileDialog
        open={createCompanyProfileDialogOpen}
        handleClose={() => setCreateCompanyProfileDialogOpen(false)}
        handleSubmit={handleCreateCompanyProfileSubmit}
        optionTags={optionTags}
      />
      <UpdateCompanyProfileDialog
        open={updateCompanyProfileDialogOpen}
        companyProfile={companyProfile}
        handleClose={() => setUpdateCompanyProfileDialogOpen(false)}
        handleSubmit={handleUpdateCompanyProfileSubmit}
        optionTags={optionTags}
      />
      <ChangePasswordDialog
        open={changePasswordDialogOpen}
        handleClose={() => setChangePasswordDialogOpen(false)}
        handleSubmit={handleChangePasswordSubmit}
      />
      <DeleteCompanyDialog
        open={deleteCompanyDialogOpen}
        handleClose={() => setDeleteCompanyDialogOpen(false)}
        handleSubmit={handleDeleteCompanySubmit}
      />
      <SetConditionsDialog
        isCompanyConditions
        open={companyConditionsOpen}
        conditions={companyConditions}
        isReadOnly={!canUpdateCompanyProfileConditions && !canDeleteCompanyProfileConditions}
        canUpdateCompanyProfile={canUpdateCompanyProfile}
        handleClose={() => setCompanyConditionsOpen(false)}
        handleSubmit={handleCompanyConditionsSubmit}
        handleDelete={handleDeleteCompanyConditionsSubmit}
      />
      <SetConditionsDialog
        isCompanyConditions={false}
        open={userConditionsOpen}
        handleClose={() => setUserConditionsOpen(false)}
        conditions={userConditions}
        handleSubmit={handleUserConditionsSubmit}
        handleDelete={handleDeleteUserConditionsSubmit}
      />
      <SetPricePerKmDialog
        open={priceOpen}
        price={companyProfile.price}
        handleClose={() => setPriceOpen(false)}
        handleSubmit={handleSetPriceSubmit}
      />
    </>
  );
};

export default SettingsContainer;
