import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  CircularProgress,
  DialogContent,
  InputAdornment,
  TextField,
  makeStyles
} from "@material-ui/core";

import { Search as SearchIcon } from "@material-ui/icons";

import useAuth from "../../../cargotic-webapp/component/hook/useAuth";
import { useApiClient } from "../../../cargotic-webapp-component";
import ContactShareEditorSelectList from "./ContactShareEditorSelectList";

import { UserRole } from "@cargotic/model";
import { createUserMatchQuery } from "../../../cargotic-webapp/resource";

const useStyles = makeStyles(({ spacing }) => ({
  input: {
    flex: "none",
    marginBottom: spacing(1)
  },
  list: {
    padding: 0
  }
}));

const ContactShareEditorSelect = ({ className, contactCreatorId, value, onChange }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const client = useApiClient();
  const { user } = useAuth();

  const [search, setSearch] = useState();
  const [users, setUsers] = useState();
  const [isUsersLoading, setIsUsersLoading] = useState(true);

  const handleDeselect = id => {
    if (onChange) {
      onChange(value.filter(({ id: other }) => other !== id));
    }
  };

  const handleSearchChange = ({ target: { value: newSearch } }) => (
    setSearch(newSearch)
  );

  const handleSelect = id => {
    if (onChange) {
      const user = users.find(({ id: other }) => other === id);

      onChange([...value, user]);
    }
  };

  const loadUsers = async () => {
    setIsUsersLoading(true);

    try {
      const { matches } = await createUserMatchQuery({ search });
      console.log(matches, value);
      const selected = matches
        .map(({ id, ...rest }) => ({
          ...rest,
          id,
          isSelected: value.some(({ id: other }) => other === id),
          ownerShare: value.find(({ id: other }) => id === other) ? value.find(({ id: other }) => id === other).ownerShare * 100 : undefined,
          userShare: value.find(({ id: other }) => id === other) ? value.find(({ id: other }) => id === other).userShare * 100 : undefined
        }))
        .filter(({ id, role }) => role !== UserRole.DRIVER && contactCreatorId !== id)

      setUsers(selected);
      setIsUsersLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    loadUsers();
  }, [search]);

  useEffect(() => {
    if (!users) {
      return;
    }

    const selected = users.map(({ id, ...rest }) => ({
      ...rest,
      id,
      isSelected: value.some(({ id: other }) => other === id),
      ownerShare: value.find(({ id: other }) => id === other) ? value.find(({ id: other }) => id === other).ownerShare * 100 : undefined,
      userShare: value.find(({ id: other }) => id === other) ? value.find(({ id: other }) => id === other).userShare * 100 : undefined
    }));

    setUsers(selected);
  }, [value]);

  return (
    <>
      <div className={classes.input}>
        <TextField
          label={t("webapp:common.title.search-user")}
          value={search}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            )
          }}
          fullWidth
          onChange={handleSearchChange}
        />
      </div>
      <DialogContent className={classes.list} dividers>
        <ContactShareEditorSelectList
          users={users}
          isLoading={isUsersLoading}
          onDeselect={handleDeselect}
          onSelect={handleSelect}
        />
      </DialogContent>
    </>
  );
};

export default ContactShareEditorSelect;
