import React from "react";

import { Redirect, Route } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import { AuthPolicy } from "../../../auth";
import Placeholder from "../../common/Placeholder";
import useAuth from "../../hook/useAuth";
import useTariff from "../../hook/useTariff";
import { Roles } from "../../enums/enums";

const AuthRoute = ({
  policy,
  exact,
  path,
  component: Component,
  allowedRoles,
  permission,
  allPermissionsRequired,
  oneOfPermissionsRequired,
  handleExpiredTariff
}) => (
    <Route
      exact={exact}
      path={path}
      component={() => {
        const { authenticated, authenticating, user, hasPermission } = useAuth();
        const { enqueueSnackbar } = useSnackbar();
        const { t } = useTranslation();

        let allowedAccess;

        if (user && permission) {
          if (allPermissionsRequired) {
            allowedAccess = permission.reduce((result, item) => result && hasPermission(item));
          }
          if (oneOfPermissionsRequired) {
            allowedAccess = permission.reduce((result, item) => result || hasPermission(item));
          }
        } else {
          allowedAccess = true;
        }

        return (
          <Placeholder
            loading={authenticating}
            render={() => {
              if (policy === AuthPolicy.ENFORCE && !authenticated) {
                return (
                  <Redirect to="/" />
                );
              }

              if (policy === AuthPolicy.FORBID && authenticated) {
                return (
                  <Redirect to="/" />
                );
              }

              if (!allowedAccess && policy === AuthPolicy.ENFORCE) {
                enqueueSnackbar(t("auth.error.permission"), { variant: "error" });
                return (
                  <Redirect to="/" />
                );
              }

              if (handleExpiredTariff) {
                const { subscription } = user;

                if (subscription.isExpired) {
                  return (
                    <Redirect to="/" />
                  );
                }
              }

              return (
                <Component authenticated={authenticated} />
              );
            }}
          />
        );
      }}
    />
  );

export default AuthRoute;
