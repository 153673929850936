import React, { useState } from "react";

import { Formik } from "formik";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  TextField,
  Grid,
  InputAdornment,
  FormLabel,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Divider
} from "@material-ui/core";

import {
  addMinutes
} from "date-fns"

import { DesktopDatePicker, DatePicker } from "@material-ui/pickers";
import { useTranslation } from "react-i18next";

import { convertBBANFormatToCz } from "../../../utility/banking";
import { PaidAtSchema } from "../../../utility/validationSchema";

import { getCleanDate } from "../../../utility/common";

const useStyles = makeStyles(({ palette, spacing }) => ({
  formLabel: {
    marginBottom: spacing(2)
  }
}));

const PayDialog = ({
  issuedInvoiceNumber,
  issuedInvoicePaidAt,
  issuedInvoiceDueDate,

  receivedInvoiceNumber,
  receivedInvoicePaidAt,
  receivedInvoiceDueDate,

  bankAccounts,
  bankAccountValue,

  invoiceNote,
  taxableSupplyDate,
  issueDate,

  isOpen,
  handleSubmit,
  handleClose,

  disableReceivedInvoice
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Dialog open={isOpen} onClose={handleClose} disableEnforceFocus>
      <DialogTitle>
        {t("shipments.invoicing")}
      </DialogTitle>
      <Formik
        validationSchema={PaidAtSchema}
        initialValues={{
          issuedInvoiceNumber,
          issuedInvoiceDueDate: issuedInvoiceDueDate ?? "",
          issuedInvoicePaidAt: issuedInvoicePaidAt ?? "",
          receivedInvoiceDueDate: receivedInvoiceDueDate ?? "",
          receivedInvoiceNumber,
          receivedInvoicePaidAt: receivedInvoicePaidAt ?? "",
          taxableSupplyDate: taxableSupplyDate ?? "",
          bankAccount: bankAccountValue ?? (bankAccounts && bankAccounts.length > 0 ? bankAccounts[0].id : undefined),
          invoiceNote: invoiceNote ?? "",
          issueDate: issueDate ?? new Date()
        }}
        onSubmit={({
          issuedInvoiceNumber,
          issuedInvoiceDueDate,
          issuedInvoicePaidAt,
          receivedInvoiceDueDate,
          receivedInvoiceNumber,
          receivedInvoicePaidAt,
          bankAccount,
          taxableSupplyDate,
          invoiceNote,
          issueDate
        }, { resetForm }) => {
          handleSubmit({
            issuedInvoiceNumber,
            issuedInvoiceDueDate,
            issuedInvoicePaidAt,
            receivedInvoiceDueDate,
            receivedInvoiceNumber,
            receivedInvoicePaidAt,
            bankAccountId: bankAccount,
            taxableSupplyDate,
            invoiceNote,
            issueDate
          });

          resetForm();
        }}
      >
        {({
          values,
          setValues,
          handleBlur,
          handleChange,
          submitForm,
          resetForm,
          touched,
          errors,
          isSubmitting
        }) => (
            <>
              <DialogContent>
                <form>
                  <FormLabel component="legend" className={classes.formLabel}>{t("webapp:shipment.form.label.invoiceForCustomer")}</FormLabel>
                  <Grid container spacing={1} className={classes.formLabel}>
                    <Grid item xs={6}>
                      <TextField
                        label={t("webapp:shipment.form.label.issuedInvoiceNumber")}
                        name="issuedInvoiceNumber"
                        fullWidth
                        error={errors.issuedInvoiceNumber && touched.issuedInvoiceNumber}
                        onBlur={handleBlur}
                        helperText={touched.issuedInvoiceNumber && errors.issuedInvoiceNumber ? t(errors.issuedInvoiceNumber) : ""}
                        onChange={handleChange}
                        value={values.issuedInvoiceNumber}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">#</InputAdornment>
                        }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <DesktopDatePicker
                        inputFormat="dd.MM.yyyy"
                        value={values.issuedInvoiceDueDate}
                        clearable
                        autoOk
                        disableMaskedInput
                        allowSameDateSelection
                        onError={console.error}
                        allowKeyboardControl
                        renderInput={(props) => (
                          <TextField
                            {...props}
                            InputProps={{
                              ...props.InputProps
                            }}
                            name="issuedInvoiceDueDate"
                            label={t("webapp:shipment.form.label.invoiceDueDate")}
                            value={props.inputProps.value}
                            error={errors.issuedInvoiceDueDate && touched.issuedInvoiceDueDate}
                            onBlur={handleBlur}
                            helperText={touched.issuedInvoiceDueDate && errors.issuedInvoiceDueDate && t("shipments.validation.paidAt")}
                            inputProps={{
                              ...props.inputProps,
                              placeholder: "1.1.2020"
                            }}
                            fullWidth
                          />)
                        }
                        onChange={(value, textValue) => setValues({ ...values, issuedInvoiceDueDate: getCleanDate(value) })}
                        onAccept={(value) => setValues({ ...values, issuedInvoiceDueDate: value })}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <DesktopDatePicker
                        value={values.issuedInvoicePaidAt}
                        clearable
                        ampm={false}
                        allowKeyboardControl
                        inputFormat="dd.MM.yyyy"
                        disableMaskedInput
                        allowSameDateSelection
                        onChange={(value, textValue) => setValues({ ...values, issuedInvoicePaidAt: getCleanDate(value) })}
                        onAccept={(value) => setValues({ ...values, issuedInvoicePaidAt: value })}
                        renderInput={(props) => (
                          <TextField
                            {...props}
                            InputProps={{
                              ...props.InputProps
                            }}
                            name="issuedInvoicePaidAt"
                            label={t("shipments.paidAt")}
                            value={props.inputProps.value}
                            error={errors.issuedInvoicePaidAt && touched.issuedInvoicePaidAt}
                            onBlur={handleBlur}
                            helperText={touched.issuedInvoicePaidAt && errors.issuedInvoicePaidAt && t("shipments.validation.paidAt")}
                            inputProps={{
                              ...props.inputProps,
                              placeholder: "1.1.2020"
                            }}
                            fullWidth
                          />)
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <InputLabel shrink id="bankAccount-label">
                          {t("settings.bankAccount.bankAccount")}
                        </InputLabel>
                        <Select
                          id="bankAccount"
                          name="bankAccount"
                          value={values.bankAccount}
                          onChange={handleChange}
                          fullWidth
                          error={errors.bankAccount && touched.bankAccount}
                          helperText={touched.bankAccount && errors.bankAccount && t("shipments.validation.bankAccount")}
                        >
                          {bankAccounts.map(({ currency, iban, bban, id }) => (
                            <MenuItem key={id} value={id}>
                              {currency}, {convertBBANFormatToCz(bban)}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                     <DesktopDatePicker
                        value={values.taxableSupplyDate}
                        clearable
                        ampm={false}
                        allowKeyboardControl
                        inputFormat="dd.MM.yyyy"
                        disableMaskedInput
                        allowSameDateSelection
                        onChange={(value, textValue) => setValues({ ...values, taxableSupplyDate: getCleanDate(value) })}
                        onAccept={(value) => setValues({ ...values, taxableSupplyDate: value })}
                        renderInput={(props) => (
                          <TextField
                            {...props}
                            InputProps={{
                              ...props.InputProps
                            }}
                            name="taxableSupplyDate"
                            label={t("shipments.taxableSupplyDate")}
                            value={props.inputProps.value}
                            error={errors.taxableSupplyDate && touched.taxableSupplyDate}
                            onBlur={handleBlur}
                            helperText={touched.taxableSupplyDate && errors.taxableSupplyDate && t("shipments.validation.taxableSupplyDate")}
                            inputProps={{
                              ...props.inputProps,
                              placeholder: "1.1.2020"
                            }}
                            fullWidth
                          />)
                        }
                      />
                    </Grid>
                    <Grid item xs={3}>
                     <DesktopDatePicker
                        value={values.issueDate}
                        clearable
                        ampm={false}
                        allowKeyboardControl
                        inputFormat="dd.MM.yyyy"
                        disableMaskedInput
                        allowSameDateSelection
                        onChange={(value, textValue) => setValues({ ...values, issueDate: getCleanDate(value) })}
                        onAccept={(value) => setValues({ ...values, issueDate: value })}
                        renderInput={(props) => (
                          <TextField
                            {...props}
                            InputProps={{
                              ...props.InputProps
                            }}
                            name="issueDate"
                            label={t("shipments.issueDate")}
                            value={props.inputProps.value}
                            error={errors.issueDate && touched.issueDate}
                            onBlur={handleBlur}
                            helperText={touched.issueDate && errors.issueDate && t("shipments.validation.issueDate")}
                            inputProps={{
                              ...props.inputProps,
                              placeholder: "1.1.2020"
                            }}
                            fullWidth
                          />)
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label={t("webapp:shipment.form.label.note")}
                        name="invoiceNote"
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.invoiceNote}
                      />
                    </Grid>
                  </Grid>
                  {!disableReceivedInvoice ? (
                    <>
                      <Divider style={{ marginBottom: 15 }} />
                      <FormLabel component="legend" className={classes.formLabel}>{t("webapp:shipment.form.label.invoiceFromCarrier")}</FormLabel>
                      <Grid container spacing={1}>
                        <Grid item xs={6}>
                          <TextField
                            label={t("webapp:shipment.form.label.receivedInvoiceNumber")}
                            name="receivedInvoiceNumber"
                            fullWidth
                            onBlur={handleBlur}
                            error={errors.receivedInvoiceNumber && touched.receivedInvoiceNumber}
                            helperText={touched.receivedInvoiceNumber && errors.receivedInvoiceNumber ? t(errors.receivedInvoiceNumber) : ""}
                            onChange={handleChange}
                            value={values.receivedInvoiceNumber}
                            InputProps={{
                              startAdornment: <InputAdornment position="start">#</InputAdornment>
                            }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <DesktopDatePicker
                            value={values.receivedInvoiceDueDate}
                            ampm={false}
                            fullWidth
                            clearable
                            allowSameDateSelection
                            disableMaskedInput
                            allowKeyboardControl
                            renderInput={(props) => (
                              <TextField
                                {...props}
                                InputProps={{
                                  ...props.InputProps
                                }}
                                name="receivedInvoiceDueDate"
                                label={t("webapp:shipment.form.label.invoiceDueDate")}
                                value={props.inputProps.value}
                                error={errors.receivedInvoiceDueDate && touched.receivedInvoiceDueDate}
                                onBlur={handleBlur}
                                helperText={touched.receivedInvoiceDueDate && errors.receivedInvoiceDueDate && t("shipments.validation.paidAt")}
                                inputProps={{
                                  ...props.inputProps,
                                  placeholder: "1.1.2020"
                                }}
                                fullWidth
                              />)
                            }
                            inputFormat="dd.MM.yyyy"
                            onChange={(value, textValue) => setValues({ ...values, receivedInvoiceDueDate: getCleanDate(value) })}
                            onAccept={(value) => setValues({ ...values, receivedInvoiceDueDate: value })}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <DesktopDatePicker
                            value={values.receivedInvoicePaidAt}
                            name="receivedInvoicePaidAt"
                            ampm={false}
                            allowKeyboardControl
                            disableMaskedInput
                            allowSameDateSelection
                            inputFormat="dd.MM.yyyy"
                            clearable
                            renderInput={(props) => (
                              <TextField
                                {...props}
                                InputProps={{
                                  ...props.InputProps
                                }}
                                name="receivedInvoiceDueDate"
                                label={t("shipments.paidAt")}
                                value={props.inputProps.value}
                                error={errors.receivedInvoicePaidAt && touched.receivedInvoicePaidAt}
                                onBlur={handleBlur}
                                helperText={touched.receivedInvoicePaidAt && errors.receivedInvoicePaidAt && t("shipments.validation.paidAt")}
                                inputProps={{
                                  ...props.inputProps,
                                  placeholder: "1.1.2020"
                                }}
                                fullWidth
                              />)
                            }
                            onChange={(value, textValue) => setValues({ ...values, receivedInvoicePaidAt: getCleanDate(value) })}
                            onAccept={(value) => setValues({ ...values, receivedInvoicePaidAt: value })}
                          />
                        </Grid>
                      </Grid>
                    </>)
                    : undefined}
                </form>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  {t("shipments.cancel")}
                </Button>
                <Button onClick={submitForm} color="primary" disabled={isSubmitting}>
                  OK
                </Button>
              </DialogActions>
            </>
          )}
      </Formik>
    </Dialog>
  )
}

export default PayDialog;
