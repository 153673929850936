import { useSnackbar } from "notistack";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";

import { findOrThrow, replaceIndex } from "@cargotic/common";
import { VehicleActivityType } from "@cargotic/model";
import { CircularProgress, makeStyles } from "@material-ui/core";

import { useApiClient } from "../../../cargotic-webapp-component";
import useAuth from "../../../cargotic-webapp/component/hook/useAuth";
import useUserProfile
  from "../../../cargotic-webapp/component/hook/useUserProfile";

import VehicleProfileView from "../../VehicleProfileView";
import VehicleProfile from "../VehicleProfile";

const useStyles = makeStyles(() => ({
  progress: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    justifyContent: "center"
  }
}));

const VehicleProfileContainer = ({
  vehicleId,
  view,
  selectedExpiration,
  isExpirationEditorOpen,
  onExpirationAdd,
  onExpirationEditorClose,
  onExpirationEditorOpen,
  onExpirationSelect,
  onShipmentRedirect,
  onVehiclesRedirect,
  onViewChange
}) => {
  const profile = useUserProfile();
  const session = useAuth();
  const client = useApiClient();
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [activity, setActivity] = useState();
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [expirationQuery, setExpirationQuery] = useState();

  const [expirations, setExpirations] = useState();
  const [latestExpirations, setLatestExpirations] = useState();
  const [vehicle, setVehicle] = useState();
  const [isActivityLoading, setIsActivityLoading] = useState(true);
  const [isExpirationsLoading, setIsExpirationsLoading] = useState(true);
  const [
    isExpirationDeleteConfirmationAlertOpen,
    setIsExpirationDeleteConfirmationAlertOpen
  ] = useState(false);

  const [
    isLatestExpirationsLoading,
    setIsLatestExpirationsLoading
  ] = useState(true);

  const [isVehicleLoading, setIsVehicleLoading] = useState(true);
  const [
    isVehicleDeleteConfirmationAlertOpen,
    setIsVehicleDeleteConfirmationAlertOpen
  ] = useState(false);

  const [isVehicleEditorOpen, setIsVehicleEditorOpen] = useState(false);
  const [isVehicleTrailerEditorOpen, setIsVehicleTrailerEditorOpen] = useState(false);
  const [isVehicleDriverEditorOpen, setIsVehicleDriverEditorOpen] = useState(false);
  
  const { hasPermission } = session;
  const user = {
    id: session.user.id,
    name: `${session.user.firstName} ${session.user.lastName}`,
    avatarUrl: profile.userProfile.avatarUrl
  };

  const expirationPage = expirationQuery?.page;
  const expirationRowsPerPage = expirationQuery?.rowsPerPage;

  const handleAvatarChange = async avatarFile => {
    try {
      const { avatarUrl } = await client.vehicle.postVehicleAvatar({
        vehicleId,
        avatarFile
      });

      setVehicle(current => ({ ...current, avatarUrl }));
    } catch (error) {
      if (error.response.data.error === "FileTooLargeError") {
        enqueueSnackbar(t("webapp:vehicle.error.avatarTooLarge"), {
          variant: "error"
        });
      } else {
        enqueueSnackbar(t("webapp:vehicle.error.avatarUpload"), {
          variant: "error"
        });
      }
    }
  };

  const handleBreadcrumbsChange = breadcrumbs => setBreadcrumbs(breadcrumbs);

  const handleCommentarySubmit = async content => {
    try {
      const { id, createdAt } = await client.vehicle.postVehicleCommentary({
        vehicleId,
        commentary: { content }
      });

      setActivity(current => ([
        ...current,
        {
          type: VehicleActivityType.VEHICLE_COMMENTARY,
          commentary: {
            id,
            content,
            createdAt,
            author: user
          }
        }
      ]));
    } catch (error) {
      console.log(error);

      enqueueSnackbar(t("webapp:vehicle.error.postCommentary"), {
        variant: "error"
      });
    }
  };

  const handleCommentaryChange = async (commentaryId, content) => {
    try {
      const { createdAt } = await client.vehicle.putVehicleCommentary({
        vehicleId,
        commentaryId,
        commentary: {
          content
        }
      });

      setActivity(current => {
        const index = current
          .findIndex((other) => other.commentary?.id === commentaryId);

        const updated = replaceIndex(current, index, {
          type: VehicleActivityType.VEHICLE_COMMENTARY,
          commentary: {
            content,
            createdAt,
            id: commentaryId,
            author: user
          }
        });

        return updated;
      });
    } catch (error) {
      console.log(error);

      enqueueSnackbar(t("webapp:vehicle.error.updateCommentary"), {
        variant: "error"
      });
    }
  };

  const handleCommentaryDelete = async commentaryId => {
    try {
      await client.vehicle.deleteVehicleCommentary({
        vehicleId,
        commentaryId
      });

      setActivity(current => (
        current.filter((other) => other.commentary?.id !== commentaryId)
      ));
    } catch (error) {
      console.log(error);

      enqueueSnackbar(t("webapp:vehicle.error.deleteCommentary"), {
        variant: "error"
      });
    }
  };

  const handleExpirationDelete = index => {
    onExpirationSelect(expirations.matches[index]);
    setIsExpirationDeleteConfirmationAlertOpen(true);
  };

  const handleExpirationDeleteConfirmationAlertClose = () => (
    setIsExpirationDeleteConfirmationAlertOpen(false)
  );

  const handleExpirationDeleteConfirmationAlertSubmit = async () => {
    setIsExpirationDeleteConfirmationAlertOpen(false);

    const { id, type } = selectedExpiration;

    try {
      const { latest } = await client.vehicle.deleteVehicleExpiration({
        vehicleId,
        expirationId: id
      });

      setLatestExpirations({ ...latestExpirations, [type]: latest });
      setExpirationQuery({ ...expirationQuery });
    } catch (error) {
      console.log(error);

      enqueueSnackbar(t("webapp:vehicle.error.expiration-delete"), {
        variant: "error"
      });
    }
  };

  const handleExpirationEdit = index => {
    onExpirationSelect(expirations.matches[index]);
    onExpirationEditorOpen();
  };

  const handleExpirationQueryChange = query => setExpirationQuery(query);

  const handleExpirationPageChange = page => (
    setExpirationQuery({ ...expirationQuery, page })
  );

  const handleExpirationRowsPerPageChange = rowsPerPage => (
    setExpirationQuery({ ...expirationQuery, rowsPerPage, page: 0 })
  );

  const handleVehicleDeleteConfirmationAlertClose = () => (
    setIsVehicleDeleteConfirmationAlertOpen(false)
  );

  const handleVehicleDeleteConfirmationAlertOpen = () => (
    setIsVehicleDeleteConfirmationAlertOpen(true)
  );

  const handleVehicleDeleteConfirmationAlertSubmit = async () => {
    setIsVehicleDeleteConfirmationAlertOpen(false);

    try {
      await client.vehicle.deleteVehicle({ vehicleId });

      onVehiclesRedirect();
    } catch (error) {
      console.log(error);

      enqueueSnackbar(t("webapp:vehicle.error.deleteVehicle"), {
        variant: "error"
      });
    }
  };

  const handleVehicleEditorClose = () => setIsVehicleEditorOpen(false);
  const handleVehicleEditorOpen = () => setIsVehicleEditorOpen(true);

  const handleVehicleEditorSubmit = async value => {
    setIsVehicleEditorOpen(false);

    try {
      await client.vehicle.putVehicle({
        vehicleId,
        vehicle: value
      });
    } catch (error) {
      console.log(error);

      const { response: { data: errData } } = error;

      if (errData.type === "DuplicateEntityError") {
        enqueueSnackbar(
          t("vehicles.error.duplicate"),
          { variant: "error" }
        );

        return;
      }

      enqueueSnackbar(t("webapp:vehicle.error.update"), {
        variant: "error"
      });

      return;
    }

    setVehicle(current => ({ ...current, ...value }));

    if (activity) {
      setActivity(current => [
        ...current,
        {
          type: VehicleActivityType.VEHICLE_UPDATE,
          user,
          properties: {},
          createdAt: new Date()
        }
      ]);
    }
  };

  const handleVehicleTrailerEditorClose = () => setIsVehicleTrailerEditorOpen(false);
  const handleVehicleTrailerEditorOpen = () => setIsVehicleTrailerEditorOpen(true);
  const handleVehicleDriverEditorOpen = () => setIsVehicleDriverEditorOpen(true);
  const handleVehicleDriverEditorClose = () => setIsVehicleDriverEditorOpen(false);
  
  const handleVehicleTrailerEditorSubmit = async value => {
    setIsVehicleTrailerEditorOpen(false);
    const updatedVehicle = {
      ...vehicle,
      defaultTrailer: value
    };
    handleVehicleEditorSubmit(updatedVehicle);
  };

  const handleVehicleDriverEditorSubmit = async value => {
    setIsVehicleDriverEditorOpen(false);
    const updatedVehicle = {
      ...vehicle,
      defaultDriver: value
    };
    handleVehicleEditorSubmit(updatedVehicle);
  };

  const handleShipmentCreate = () => onShipmentRedirect({ vehicleId });

  const handleActivityLoad = async () => {
    setIsActivityLoading(true);

    try {
      const loaded = await client.vehicle.getVehicleActivity({ vehicleId });

      setActivity(loaded);
      setIsActivityLoading(false);
    } catch (error) {
      console.log(error);

      enqueueSnackbar(t("webapp:vehicle.error.loadActivity"), {
        variant: "error"
      });
    }
  };

  const loadExpirations = async () => {
    setIsExpirationsLoading(true);

    try {
      const loaded = await client.vehicle.postVehicleExpirationMatchQuery({
        vehicleId,
        query: {
          ...expirationQuery,
          limit: expirationRowsPerPage,
          offset: expirationPage * expirationRowsPerPage,
          page: undefined,
          rowsPerPage: undefined
        }
      });

      setExpirations(loaded);
      setIsExpirationsLoading(false);
    } catch (error) {
      console.log(error);

      enqueueSnackbar(t("webapp:vehicle.error.expiration-query"), {
        variant: "error"
      });
    }
  };

  const loadLatestExpirations = async () => {
    try {
      const loaded = await client.vehicle.getLatestVehicleExpirations({
        vehicleId
      });

      setLatestExpirations(loaded);
      setIsLatestExpirationsLoading(false);
    } catch (error) {
      console.log(error);

      enqueueSnackbar(t("webapp:vehicle.error.expiration-load"), {
        variant: "error"
      });
    }
  };

  const loadVehicle = async () => {
    try {
      const loaded = await client.vehicle.getVehicle({ vehicleId });

      setVehicle(loaded);
      setIsVehicleLoading(false);
    } catch (error) {
      console.log(error);

      enqueueSnackbar(t("webapp:vehicle.error.load"), {
        variant: "error"
      });
    }
  };

  const handleExpirationEditorSubmit = async ({ id, ...expiration }) => {
    onExpirationEditorClose();

    const { type } = expiration;

    try {
      const { latest } = id === undefined
        ? (
          await client.vehicle.postVehicleExpiration({
            vehicleId,
            expiration
          })
        ) : (
          await client.vehicle.putVehicleExpiration({
            vehicleId,
            expirationId: id,
            expiration
          })
        );

      setLatestExpirations({ ...latestExpirations, [type]: latest });
      setExpirationQuery({ ...expirationQuery });
    } catch (error) {
      console.log(error);

      enqueueSnackbar(t("webapp:vehicle.error.expiration-edit"), {
        variant: "error"
      });
    }
  };

  useEffect(() => {
    loadVehicle();
    loadLatestExpirations();
  }, []);

  useEffect(() => {
    if (expirationQuery === undefined) {
      return;
    }

    loadExpirations();
  }, [expirationQuery]);

  if (isLatestExpirationsLoading || isVehicleLoading) {
    return (
      <div className={classes.progress}>
        <CircularProgress size={60} />
      </div>
    );
  }

  return (
    <VehicleProfile
      activity={activity}
      breadcrumbs={breadcrumbs}
      expirationPage={expirationPage}
      expirationRowsPerPage={expirationRowsPerPage}
      expirationQuery={expirationQuery}
      expirations={expirations}
      latestExpirations={latestExpirations}
      selectedExpiration={selectedExpiration}
      user={user}
      view={view}
      vehicle={vehicle}
      isActivityLoading={isActivityLoading}
      isExpirationDeleteConfirmationAlertOpen={
        isExpirationDeleteConfirmationAlertOpen
      }
      isExpirationEditorOpen={isExpirationEditorOpen}
      isExpirationsLoading={isExpirationsLoading}
      isExpenseTabDisabled={!hasPermission("resource.vehicle.expenses.read")}
      isIncomeTabDisabled={!hasPermission("resource.vehicle.income.read")}
      isExpirationCreationDisabled={
        !hasPermission("resource.vehicle.expiration.create")
      }
      isExpirationDeletionDisabled={
        !hasPermission("resource.vehicle.expiration.delete")
      }
      isExpirationEditationDisabled={
        !hasPermission("resource.vehicle.expiration.update")
      }
      isExpirationTabDisabled={
        !hasPermission("resource.vehicle.expiration.read")
      }
      isShipmentCreationDisabled={
        !hasPermission("resource.shipment.user.extended.create")
      }
      isVehicleDeleteConfirmationAlertOpen={
        isVehicleDeleteConfirmationAlertOpen
      }
      isIncomeCreationDisabled={!hasPermission("resource.vehicle.directIncome.create")}
      isIncomeDeletionDisabled={!hasPermission("resource.vehicle.directIncome.delete")}
      isIncomeEditationDisabled={!hasPermission("resource.vehicle.directIncome.update")}
      isVehicleDeletionDisabled={!hasPermission("resource.vehicle.delete")}
      isVehicleEditationDisabled={!hasPermission("resource.vehicle.update")}
      isVehicleEditorOpen={isVehicleEditorOpen}
      isVehicleTrailerEditorOpen={isVehicleTrailerEditorOpen}
      isVehicleDriverEditorOpen={isVehicleDriverEditorOpen}
      onAvatarChange={handleAvatarChange}
      onActivityLoad={handleActivityLoad}
      onBreadcrumbsChange={handleBreadcrumbsChange}
      onCommentaryChange={handleCommentaryChange}
      onCommentaryDelete={handleCommentaryDelete}
      onCommentarySubmit={handleCommentarySubmit}
      onExpirationAdd={onExpirationAdd}
      onExpirationDelete={handleExpirationDelete}
      onExpirationDeleteConfirmationAlertClose={
        handleExpirationDeleteConfirmationAlertClose
      }
      onExpirationDeleteConfirmationAlertSubmit={
        handleExpirationDeleteConfirmationAlertSubmit
      }
      onExpirationEdit={handleExpirationEdit}
      onExpirationEditorClose={onExpirationEditorClose}
      onExpirationEditorSubmit={handleExpirationEditorSubmit}
      onExpirationPageChange={handleExpirationPageChange}
      onExpirationQueryChange={handleExpirationQueryChange}
      onExpirationRowsPerPageChange={handleExpirationRowsPerPageChange}
      onVehicleDeleteConfirmationAlertOpen={
        handleVehicleDeleteConfirmationAlertOpen
      }
      onVehicleDeleteConfirmationAlertSubmit={
        handleVehicleDeleteConfirmationAlertSubmit
      }
      onVehicleEditorOpen={handleVehicleEditorOpen}
      onVehicleEditorClose={handleVehicleEditorClose}
      onVehicleEditorSubmit={handleVehicleEditorSubmit}
      onVehicleTrailerEditorOpen={handleVehicleTrailerEditorOpen}
      onVehicleTrailerEditorClose={handleVehicleTrailerEditorClose}
      onVehicleTrailerEditorSubmit={handleVehicleTrailerEditorSubmit}
      onVehicleDriverEditorOpen={handleVehicleDriverEditorOpen}
      onVehicleDriverEditorClose={handleVehicleDriverEditorClose}
      onVehicleDriverEditorSubmit={handleVehicleDriverEditorSubmit}
      onShipmentCreate={handleShipmentCreate}
      onVehicleDeleteConfirmationAlertClose={
        handleVehicleDeleteConfirmationAlertClose
      }
      onViewChange={onViewChange}
    />
  );
};

export default VehicleProfileContainer;
