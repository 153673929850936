import React, { useState, useEffect } from "react";

import {
  Grid,
  Paper,
  Typography,
  Select,
  MenuItem,
  OutlinedInput,
  makeStyles,
  TextField
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  addHours,
  addWeeks,
  addMonths,
  endOfMonth,
  startOfDay,
  endOfDay,
  startOfMonth,
  startOfWeek,
  endOfWeek,
  startOfToday,
  endOfToday,
  startOfYesterday,
  endOfYesterday,
  addDays
} from "date-fns";

import { cs, enUS } from "date-fns/locale";
import DateFnsAdapter from "@material-ui/pickers/adapter/date-fns";

import { MobileDateTimePicker, LocalizationProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import { Periods } from "../../enums/enums";

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    padding: spacing(2)
  }
}));

const StatisticsCard = ({
  children,
  title,
  fetchMethod,
  periodPicker,
  hide
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const locale = t("locale") === "cs" ? cs : enUS;

  const [periodValue, setPeriodValue] = useState("currentMonth");

  const [periodFrom, setPeriodFrom] = useState(startOfMonth(new Date(), { locale }));
  const [periodTo, setPeriodTo] = useState(endOfMonth(new Date(), { locale }));

  const convertPeriodToDate = (period) => {
    switch (period) {
      case "currentMonth":
        return {
          start: startOfMonth(new Date(), { locale }),
          end: endOfMonth(new Date(), { locale }),
          startPrevious: startOfMonth(addMonths(new Date(), -1), { locale }),
          endPrevious: endOfMonth(addMonths(new Date(), -1), { locale }),
          term: Periods.DAY
        };
      case "currentWeek":
        return {
          start: startOfWeek(new Date(), { locale }),
          end: endOfWeek(new Date(), { locale }),
          startPrevious: startOfWeek(addWeeks(new Date(), -1), { locale }),
          endPrevious: endOfWeek(addWeeks(new Date(), -1), { locale }),
          term: Periods.DAY
        };
      case "today":
        return {
          start: startOfToday(),
          end: endOfToday(),
          startPrevious: startOfDay(addDays(new Date(), -1), { locale }),
          endPrevious: endOfDay(addDays(new Date(), -1), { locale }),
          term: Periods.HOUR
        };
      case "yesterday":
        return {
          start: startOfYesterday(),
          end: endOfYesterday(),
          startPrevious: startOfDay(addDays(new Date(), -2), { locale }),
          endPrevious: endOfDay(addDays(new Date(), -2), { locale }),
          term: Periods.HOUR
        };
      case "lastWeek":
        return {
          start: startOfWeek(addWeeks(new Date(), -1), { locale }),
          end: endOfWeek(addWeeks(new Date(), -1), { locale }),
          startPrevious: startOfWeek(addWeeks(new Date(), -2), { locale }),
          endPrevious: endOfWeek(addWeeks(new Date(), -2), { locale }),
          term: Periods.DAY
        };
      case "lastMonth":
        return {
          start: startOfMonth(addMonths(new Date(), -1), { locale }),
          end: endOfMonth(addMonths(new Date(), -1), { locale }),
          startPrevious: startOfMonth(addMonths(new Date(), -2), { locale }),
          endPrevious: endOfMonth(addMonths(new Date(), -2), { locale }),
          term: Periods.DAY
        };
      case "allTime":
        return {
          start: undefined,
          end: undefined
        };
      case "customPeriod":
        return {
          start: periodFrom,
          end: periodTo,
          term: periodFrom.getFullYear() === periodTo.getFullYear()
            && periodFrom.getMonth() === periodTo.getMonth()
            && periodFrom.getDate() === periodTo.getDate() ? Periods.HOUR : Periods.DAY,
          custom: true
        };
      default:
        return null;
    }
  };

  useEffect(() => {
    if (periodPicker && fetchMethod !== null) {
      const period = convertPeriodToDate("currentMonth");
      period.term = Periods.DAY;
      fetchMethod(period);
    }
  }, []);

  return (
    <Paper className={classes.root} hidden={hide}>
      <Grid container spacing={1}>
        <Grid container item justifyContent="space-between" alignItems="baseline">
          <Grid item xs={5}>
            <Typography variant="h5">{title}</Typography>
          </Grid>
          <Grid item>
            {periodPicker ? (
              <Select
                name="period"
                value={periodValue}
                onChange={(val) => {
                  setPeriodValue(val.target.value);
                  const period = convertPeriodToDate(val.target.value);
                  fetchMethod(period);
                }}
                input={(
                  <OutlinedInput
                    name="period-input"
                    id={`period-input-${title}`}
                  />
                )}
              >
                <MenuItem value="currentMonth">
                  {t("period.currentMonth")}
                </MenuItem>
                <MenuItem value="lastMonth">{t("period.lastMonth")}</MenuItem>
                <MenuItem value="currentWeek">{t("period.currentWeek")}</MenuItem>
                <MenuItem value="lastWeek">{t("period.lastWeek")}</MenuItem>
                <MenuItem value="today">{t("period.today")}</MenuItem>
                <MenuItem value="yesterday">{t("period.yesterday")}</MenuItem>
                <MenuItem value="allTime">{t("period.allTime")}</MenuItem>
                <MenuItem value="customPeriod">{t("period.customPeriod")}</MenuItem>
              </Select>
            ) : null}
          </Grid>
        </Grid>
        {periodValue === "customPeriod" ? (
          <Grid container item justifyContent="space-around">
            <LocalizationProvider
              dateAdapter={DateFnsAdapter}
              locale={t("locale") === "cs" ? cs : enUS}
            >
              <Grid item>
                <MobileDateTimePicker
                  ampm={false}
                  id="statisticsDateTimeFrom"
                  name="statisticsDateTimeFrom"
                  label={t("statistics.datePeriodFrom")}
                  inputFormat="dd.MM.yyyy HH:mm"
                  value={periodFrom}
                  renderInput={(props) => (<TextField {...props} />)}
                  onChange={(date) => {
                    setPeriodFrom(date);
                    const period = convertPeriodToDate(periodValue);
                    period.start = date;
                    if (periodTo < date) {
                      setPeriodTo(date);
                      period.end = date;
                    }
                    period.term = period.start.getFullYear() === period.end.getFullYear()
                      && period.start.getMonth() === period.end.getMonth()
                      && period.start.getDate() === period.end.getDate() ? Periods.HOUR : Periods.DAY;
                    fetchMethod(period);
                  }}
                />
              </Grid>
              <Grid item>
                <MobileDateTimePicker
                  ampm={false}
                  id="statisticsDateTimeTo"
                  name="statisticsDateTimeTo"
                  label={t("statistics.datePeriodTo")}
                  renderInput={(props) => (<TextField {...props} />)}
                  inputFormat="dd.MM.yyyy HH:mm"
                  value={periodTo}
                  onChange={(date) => {
                    setPeriodTo(date);
                    const period = convertPeriodToDate(periodValue);
                    period.end = date;
                    if (periodFrom > date) {
                      setPeriodFrom(date);
                      period.start = date;
                    }
                    period.term = period.start.getFullYear() === period.end.getFullYear()
                      && period.start.getMonth() === period.end.getMonth()
                      && period.start.getDate() === period.end.getDate() ? Periods.HOUR : Periods.DAY;
                    fetchMethod(period);
                  }}
                />
              </Grid>
            </LocalizationProvider>
          </Grid>
        )
          : null}
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default StatisticsCard;
