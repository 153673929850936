import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

const ShipmentsDeleteDialog = ({
  open,
  selected,
  handleClose,
  handleSubmit
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>{t("shipments.removeShipments")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t("shipments.removeShipmentsConfirm", { selected })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t("abort")}
        </Button>
        <Button onClick={handleSubmit} color="primary" data-cy="remove-shipment-confirm">
          {t("remove")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShipmentsDeleteDialog;
