import React, { useState } from "react";

import { Formik } from "formik";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ButtonGroup,
  makeStyles,
  TextField,
  Grid,
  FormLabel,
  Select,
  MenuItem,
  FormControl,
  Checkbox,
  InputLabel,
  FormControlLabel
} from "@material-ui/core";

import { useTranslation } from "react-i18next";

import { SendOrderSchema } from "../../../utility/validationSchema";
import config from "../../../config";

const useStyles = makeStyles(({ palette, spacing }) => ({
  formLabel: {
    marginBottom: spacing(2)
  }
}));

const SendOrderDialog = ({
  email,
  message,
  language,
  isSendCopyToMeChecked,

  shipmentId,
  isOpen,
  handleSubmit,
  handleClose
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      disableEnforceFocus
    >
      <DialogTitle>
        {t("webapp:shipment.send-order.title")}
      </DialogTitle>
      <Formik
        validationSchema={SendOrderSchema}
        initialValues={{
          message,
          email,
          language,
          isSendCopyToMeChecked
        }}
        onSubmit={({
          email,
          message,
          language,
          isSendCopyToMeChecked
        }, { resetForm }) => {
          handleSubmit({
            email,
            message,
            language,
            isSendCopyToMeChecked
          });

          resetForm();
        }}
      >
        {({
          values,
          setValues,
          handleBlur,
          handleChange,
          submitForm,
          resetForm,
          touched,
          errors,
          isSubmitting
        }) => (
          <>
            <DialogContent>
              <form>
                <Grid container spacing={1} className={classes.formLabel}>
                  <Grid item xs={12}>
                    <TextField
                      label={t("webapp:shipment.send-order.label.email")}
                      name="email"
                      fullWidth
                      error={errors.email && touched.email}
                      onBlur={handleBlur}
                      helperText={touched.email && errors.email ? t(errors.email) : ""}
                      onChange={handleChange}
                      value={values.email}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={(
                        <Checkbox
                          name="isSendCopyToMeChecked"
                          checked={isSendCopyToMeChecked}
                          color="primary"
                          onChange={handleChange}
                        />
                      )}
                      label={t("webapp:shipment.send-order.label.send-copy-to-me")}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <ButtonGroup disableElevation variant="outlined" color="primary">
                      <Button href={`${config.api.url}/pdf/shipment/${shipmentId}?language=cs`} target="_blank" download>{t("webapp:shipment.send-order.label.pdf-czech")}</Button>
                      <Button href={`${config.api.url}/pdf/shipment/${shipmentId}?language=en`} target="_blank" download>{t("webapp:shipment.send-order.label.pdf-english")}</Button>
                    </ButtonGroup>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label={t("webapp:shipment.send-order.label.message")}
                      name="message"
                      fullWidth
                      multiline
                      rows={4}
                      error={errors.message && touched.message}
                      onBlur={handleBlur}
                      helperText={touched.message && errors.message ? t(errors.message) : ""}
                      onChange={handleChange}
                      value={values.message}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel shrink id="language-label">
                        {t("webapp:shipment.send-order.label.language")}
                      </InputLabel>
                      <Select
                        id="language"
                        name="language"
                        value={values.language}
                        onChange={handleChange}
                        fullWidth
                        error={errors.language && touched.language}
                        helperText={touched.language && errors.language && t("shipments.validation.bankAccount")}
                      >
                        <MenuItem value="cs">{t("default")} - {t("language.cs")}</MenuItem>
                        <MenuItem value="en">{t("language.en")}</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </form>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                {t("shipments.cancel")}
              </Button>
              <Button onClick={submitForm} color="primary" disabled={isSubmitting}>
                OK
                </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  )
}

export default SendOrderDialog;
