import React, { useCallback, useState } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import FilterContainer from "../../../../cargotic-webapp-filter/component/FilterContainer";

import {
  makeStyles,
  Select,
  Typography,
  Fab,
  InputBase,
  Tooltip,
  IconButton
} from "@material-ui/core";
import { Link } from "react-router-dom";

import {
  Delete,
  Edit,
  AddLocation,
  DeleteOutline,
  Search as SearchIcon,
  EmojiObjectsOutlined,
  Settings
} from "@material-ui/icons";

import SearchTextfield from "../../common/SearchTextfield";
import Page from "../../common/Page";
import DataTable from "../../../datatable";

const useStyles = makeStyles(({ palette, spacing }) => ({
  danger: {
    background: palette.status.danger,
    color: "white",
    "&:hover": {
      background: palette.status.danger
    }
  }
}));

const Places = ({
  data,
  dataCount,
  selectedColumns,
  ordering,
  search,
  direction,
  loading,
  page,
  rowsPerPage,
  checkedAll,
  canCreatePlace,
  canUpdatePlace,
  canDeletePlace,
  handleSearch,
  handleSort,
  handleChangePage,
  handleChangeRowsPerPage,
  handleSelectAll,
  handleCreateRequest,
  handleDeleteRequest,
  handleUpdateRequest,
  handleSelect,
  handleChangeSelectedColumns
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [isOpenSelectColumnsDialog, setIsOpenSelectColumnsDialog] = useState(false);

  const total = loading
    ? null
    : data.length;

  const selected = loading
    ? null
    : data.filter(({ selected }) => selected).length;

  const placesHeader = [
    {
      name: "alias",
      label: "places.alias",
      isOrderable: true
    },
    {
      name: "formatted_address",
      label: "places.address",
      isOrderable: true
    }
  ];

  const placesActions = [
    {
      column: [
        {
          handleClick: handleUpdateRequest,
          icon: <Edit />,
          disableFor: !canUpdatePlace ? [-1] : undefined
        }
      ]
    }
  ];

  const SelectColumnsButton = ({ onClick }) => (
    <Tooltip title={t("webapp:common.tooltip.select-columns-settings")} variant="dense">
      <IconButton aria-label="filter list" size="medium" onClick={onClick}>
        <Settings />
      </IconButton>
    </Tooltip>
  );

  return (
    <Page
      title={t("menu.places")}
      actions={
        <>
          <Fab
            color="primary"
            disabled={loading || !canCreatePlace}
            onClick={handleCreateRequest}
          >
            <AddLocation />
          </Fab>
          <Fab
            className={classes.danger}
            disabled={loading || selected === 0 || !canDeletePlace}
            onClick={handleDeleteRequest}
          >
            <DeleteOutline />
          </Fab>
        </>
      }
      tertiaryActions={(
        <>
          <SearchTextfield handleSearch={handleSearch} value={search} />
          <Tooltip title={(
            <>
              <Typography color="inherit" style={{ fontWeight: 800 }}>{t("didYouKnowThat")}</Typography>
              <Typography color="inherit">{t("proTableTip")}</Typography>
            </>
          )} >
            <IconButton>
              <EmojiObjectsOutlined />
            </IconButton>
          </Tooltip>
          <SelectColumnsButton onClick={() => setIsOpenSelectColumnsDialog(true)} />
        </>
      )}
    >
      <DataTable
        headers={placesHeader}
        actions={placesActions}
        data={data}
        dataCount={dataCount}
        selectedColumns={selectedColumns}
        loading={loading}
        ordering={ordering}
        direction={direction}
        checkedAll={checkedAll}
        page={page}
        rowsPerPage={rowsPerPage}
        onSort={handleSort}
        onSelect={handleSelect}
        onSelectAll={handleSelectAll}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        onChangeSelectedColumns={handleChangeSelectedColumns}
        isOpenSelectColumnsDialog={isOpenSelectColumnsDialog}
        setIsOpenSelectColumnsDialog={setIsOpenSelectColumnsDialog}
      />
    </Page>
  );
}

export default Places;
