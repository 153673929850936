import React, { useState, useEffect } from "react";

import {
  Grid,
  Button,
  CardActions,
  CardHeader,
  TextField,
  IconButton,
  CardContent,
  Card,
  Typography,
  Select,
  MenuItem,
  OutlinedInput,
  FormControlLabel,
  Checkbox,
  makeStyles
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import ReactCountryFlag from "react-country-flag";

const useStyles = makeStyles(({ spacing }) => ({
  grid: {
    "& > div:not(:last-child)": {
      marginBottom: spacing(1)
    }
  },
  content: {
    margin: spacing(1)
  }
}));

const SettingsGeneral = ({
  classes,
  handleUserConditionsClick,
  canUpdatePersonalProfile,
  companyProfile,
  updateCompanyProfile,
  updateCompanyProfilePartial,
  canUpdateCompanyProfile
}) => {
  const { t } = useTranslation();
  const [statisticsDueToValue, setStatisticsDueToValue] = useState("SHIPMENT_CREATION");
  const [isPersonalEmailHidden, setIsPersonalEmailHidden] = useState(false);
  const innerClasses = useStyles();

  useEffect(() => {
    setStatisticsDueToValue(companyProfile.statisticsDueTo);
    setIsPersonalEmailHidden(companyProfile.isPersonalEmailHidden);
  }, []);

  const setLanguage = lang => i18n.changeLanguage(lang);
  return (
    <Card className={classes.card}>
      <CardHeader title={t("settings.appOptions")} />
      <CardContent className={innerClasses.content}>
        <Grid className={innerClasses.grid} container spacing={3}>
          <Grid item xs={12}>
            <Typography><b>{t("settings.languageTitle")}</b></Typography>
            <IconButton onClick={() => setLanguage("cs")}>
              <ReactCountryFlag
                code="cz"
                svg
                styleProps={{
                  width: "32px",
                  height: "32px"
                }}
              />
            </IconButton>
            <IconButton onClick={() => setLanguage("en")}>
              <ReactCountryFlag
                svg
                code="gb"
                styleProps={{
                  width: "32px",
                  height: "32px"
                }}
              />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <Typography><b>{t("settings.nativeCurrency")}</b></Typography>
            <TextField
              name="currencySelect"
              value="CZK"
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <Typography><b>{t("settings.statisticsChargingDate")}</b></Typography>
            <Select
              disabled={!canUpdateCompanyProfile}
              name="charging-date"
              value={statisticsDueToValue}
              onChange={async (val) => {
                setStatisticsDueToValue(val.target.value);
                await updateCompanyProfilePartial({ statisticsDueTo: val.target.value });
              }}
              input={(
                <OutlinedInput
                  name="age"
                  id="outlined-age-simple"
                />
              )}
            >
              <MenuItem value="SHIPMENT_CREATION">{t("statistics.charging.shipmentCreation")}</MenuItem>
              <MenuItem value="SHIPMENT_LAST_UPDATE">{t("statistics.charging.shipmentLastUpdate")}</MenuItem>
              <MenuItem value="SHIPMENT_DELIVERY" disabled>{t("statistics.charging.shipmentDelivery")}</MenuItem>
              <MenuItem value="SHIPMENT_RECEIVED_PAYMENT" disabled>{t("statistics.charging.receivedPayment")}</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12}>
            <Typography><b>{t("settings.order")}</b></Typography>
            <FormControlLabel
              label={t("settings.isPersonalEmailHidden")}
              checked={isPersonalEmailHidden}
              disabled={!canUpdateCompanyProfile}
              onChange={async ({ target: { checked } }) => {
                await updateCompanyProfilePartial({ isPersonalEmailHidden: checked });
                setIsPersonalEmailHidden(checked);
              }}
              control={(
                <Checkbox color="primary" />
              )}
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions className={classes.cardActions}>
        <Button
          disabled={!canUpdatePersonalProfile}
          variant="outlined"
          size="medium"
          color="secondary"
          onClick={handleUserConditionsClick}
        >
          {t("settings.setUserConditions")}
        </Button>
      </CardActions>
    </Card>
  );
};

export default SettingsGeneral;
