import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import {
  Stepper, StepButton, Box, Step, StepLabel, makeStyles
} from "@material-ui/core";

import useAuth from "../../hook/useAuth";
import SignUpCompanyForm from "./SignUpCompanyForm";
import SignUpUserForm from "./SignUpUserForm";
import ChooseTypeAccount from "./ChooseTypeAccount";

const useStyles = makeStyles(({ spacing }) => ({
  label: {
    "& > .MuiStepLabel-label": {
      marginTop: spacing(1)
    }
  }
}));

const SignUpContainer = ({ userCount, tariff }) => {
  const { t } = useTranslation();
  const { signUp } = useAuth();
  const classes = useStyles();

  const [activeStep, setActiveStep] = useState(0);
  const steps = [t("auth.signUpUser"), t("auth.signUpCompany")];
  const [formUser, setFormUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    reenterPassword: ""
  });

  const [formCompany, setFormCompany] = useState({
    companyName: "",
    ic: "",
    dic: "",
    gdpr: false,
    isSubscriber: false
  });

  const handleSubmit = () => signUp({
    firstName: formUser.firstName,
    lastName: formUser.lastName,
    email: formUser.email,
    password: formUser.password,
    companyName: formCompany.companyName,
    ic: formCompany.ic,
    dic: formCompany.dic,
    tariffType: tariff ? tariff.type : undefined,
    countUsers: userCount,
    isSubscriber: formCompany.isSubscriber
  });

  const handleBack = () => setActiveStep(prevActiveStep => prevActiveStep - 1);

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleStep = (step) => {
    setActiveStep(step);
  };

  return (
    <Box>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        style={{ backgroundColor: "transparent" }}
      >
        {steps.map((label, index) => (
          <Step key={label}>
            <StepButton onClick={() => handleStep(index)}>
              <StepLabel classes={{ labelContainer: classes.label }}>
                {label}
              </StepLabel>
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <>
        {activeStep === 0 ? (
          <SignUpUserForm
            initialValues={formUser}
            setFormUser={setFormUser}
            handleNext={handleNext}
          />
        ) : (
            <SignUpCompanyForm
              initialValues={formCompany}
              setFormCompany={setFormCompany}
              handleSubmit={handleSubmit}
              handleBack={handleBack}
            />
          )}
      </>
    </Box>
  );
};

export default SignUpContainer;
