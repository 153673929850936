import React, { useState, useEffect, useRef } from "react";

import {
  AppBar,
  Toolbar,
  Link,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListSubheader,
  ListItemText,
  Typography,
  makeStyles,
  useTheme,
  IconButton,
  Collapse,
  Menu,
  MenuList,
  Paper,
  Popper,
  ClickAwayListener,
  Grow,
  Icon,
  Fade,
  Slide,
  Tooltip
} from "@material-ui/core";

import {
  Menu as MenuIcon,
  Help as HelpIcon,
  KeyboardArrowDown,
  Undo as UndoIcon,
  ChevronLeft,
  ChevronRight,
  NewReleases as NewReleasesIcon
} from "@material-ui/icons";

import { teal } from "@material-ui/core/colors";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useRouter from "../../hook/useRouter";

import clsx from "clsx";

import { ImageAvatar } from "../../common";
import useUserProfile from "../../hook/useUserProfile";
import DashboardDrawerItem from "./DashboardDrawerItem";
import logo from "../../../asset/image/logo.svg";
import logoCube from "../../../asset/image/logoCube.svg";
import { storeDrawerState, loadDrawerState } from "../../../storage";

const DRAWER_WIDTH_LAPTOP = 260;
const DRAWER_WIDTH_MOBILE = 50;

const useStyles = makeStyles(({ breakpoints, spacing, transitions }) => ({
  drawer: {
    [breakpoints.down("md")]: {
      width: DRAWER_WIDTH_MOBILE
    },
    [breakpoints.up("lg")]: {
      width: DRAWER_WIDTH_LAPTOP
    }
  },
  drawerFullWidth: {
    width: DRAWER_WIDTH_LAPTOP,
  },
  drawerOpen: {
    transition: transitions.create("width", {
      easing: transitions.easing.sharp,
      duration: transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: transitions.create("width", {
      easing: transitions.easing.sharp,
      duration: transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: 60
  },
  list: {
    overflow: "hidden",
    "&:hover": {
      overflowY: "auto"
    },
    justifyContent: "space-between",
    height: "100%"
  },
  appBar: {
    transition: transitions.create(['margin', 'width'], {
      easing: transitions.easing.sharp,
      duration: transitions.duration.leavingScreen
    }),
  },
  appBarShift: {
    transition: transitions.create(['margin', 'width'], {
      easing: transitions.easing.easeOut,
      duration: transitions.duration.enteringScreen
    }),
  },
  toolbar: {
    minHeight: 35,
  },
  avatar: {
    [breakpoints.down("md")]: {
      minWidth: 0,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  zeroPadding: {
    [breakpoints.down("md")]: {
      paddingLeft: spacing(2),
      paddingRight: spacing(2)
    }
  },
  content: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "space-between"
  },
  toolbarAvatar: {
    width: 30,
    height: 30,
    fontSize: 13
  },
  leftAppBar: {
    display: "flex",
    alignItems: "center",
    "& > :not(:first-child)": {
      marginLeft: spacing(3)
    }
  },
  rightAppBar: {
    display: "flex",
    alignItems: "center",
    "& > :not(:first-child)": {
      marginLeft: spacing(2)
    }
  },
  boldText: {
    fontWeight: 700
  },
  bigLogo: {
    paddingLeft: spacing(2),
  },
}));

const DashboardAppBar = ({
  items,
  user: { firstName, lastName, email },
  signOut,
  onNewsIconClick
}) => {
  const classes = useStyles();
  const { loading, userProfile: { avatarUrl } } = useUserProfile();
  const { t } = useTranslation();
  const { history, location: { pathname } } = useRouter();
  const theme = useTheme();

  const showTexts = useMediaQuery(theme.breakpoints.up("lg"));
  const initDrawerState = loadDrawerState();

  const [openCompleted, setOpenCompleted] = useState(initDrawerState);
  const [closeCompleted, setCloseCompleted] = useState(!initDrawerState);
  const [drawerOpen, setDrawerOpen] = useState(initDrawerState);
  const [menuOpen, setMenuOpen] = useState(false);

  const anchorRef = useRef(null);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleToggle = () => {
    setMenuOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setMenuOpen(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setMenuOpen(false);
    }
  };

  const prevOpen = useRef(menuOpen);
  useEffect(() => {
    if (prevOpen.current === true && menuOpen === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = menuOpen;
  }, [menuOpen]);

  const handleClickAvatar = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const [appBackground, setAppBackground] = useState("#EEE");

  const handleScroll = () => {
    const color = 238 + window.pageYOffset / 6;
    setAppBackground(`rgb(${color},${color},${color})`);
  }

  useEffect(() => {
    if (!drawerOpen) {
      setOpenCompleted(false);
    }
    else {
      setCloseCompleted(false);
    }
  }, [drawerOpen]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerFullWidth]: showTexts,
          [classes.drawerOpen]: drawerOpen,
          [classes.drawerClose]: !drawerOpen
        })}
        classes={{
          paper: clsx({
            [classes.drawerFullWidth]: showTexts,
            [classes.drawerOpen]: drawerOpen,
            [classes.drawerClose]: !drawerOpen
          })
        }}
      >
        <List className={classes.list}>
          <section style={{ marginBottom: 22 }}>
            {showTexts ?
              <>
                <Collapse
                  in={closeCompleted}
                  onExited={() => setOpenCompleted(true)}
                  unmountOnExit >
                  <DashboardDrawerItem
                    icon={<img src={logoCube} height={24} width={24} />}
                    title={""}
                    path={"/"}
                    selected={false}
                    showText={false}
                    classes={classes}
                  />
                </Collapse>
                <Collapse
                  in={showTexts && openCompleted}
                  onExited={() => setCloseCompleted(true)}
                  unmountOnExit >
                  <DashboardDrawerItem
                    icon={<img src={logo} height={24} className={classes.bigLogo} />}
                    title={""}
                    path={"/"}
                    selected={false}
                    showText={false}
                    classes={classes}
                  />
                </Collapse>
              </>
              : (
                <DashboardDrawerItem
                  icon={<img src={logoCube} alt="Logo Cargotic" height={24} width={24} />}
                  title=""
                  path="/"
                  selected={false}
                  showText={false}
                  classes={classes}
                />
              )
            }
          </section>
          {items.filter(elem => elem.icon).map(({
            icon, title, path, selected, isSubheader, action, showAction
          }, index) => (isSubheader
            ? (
              showTexts ? (
                <div key={index}>
                  <Collapse in={drawerOpen}>
                    <ListSubheader component="div" id="nested-list-subheader">
                      {t(title)}
                    </ListSubheader>
                  </Collapse>
                  <Collapse in={!drawerOpen}>
                    <Divider style={{ marginBottom: 10, marginTop: 10 }} />
                  </Collapse>
                </div>
              ) : (
                <div key={index}>
                  <Divider style={{ marginBottom: 10, marginTop: 10 }} />
                </div>
              )) : (
              <div key={index}>
                <DashboardDrawerItem
                  icon={icon}
                  title={title}
                  path={path}
                  selected={selected}
                  showText={showTexts}
                  classes={classes}
                  action={action}
                  showAction={showAction}
                />
              </div>
            )
          ))}
        </List>
        {showTexts ? (
          <div key="end">
            <Divider style={{ marginBottom: 5, marginTop: 5 }} />
            <ListItem button onClick={() => {
              storeDrawerState(!drawerOpen);
              setDrawerOpen(!drawerOpen);
            }} >
              <ListItemIcon style={{ paddingTop: 4, paddingBottom: 4 }}>
                {drawerOpen ? <ChevronLeft /> : <ChevronRight />}
              </ListItemIcon>
            </ListItem>
          </div>
        ) : null}
      </Drawer>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: drawerOpen
        })}
        elevation={0}
        style={{ borderBottom: "1px solid #DADADA", background: appBackground }}
      >
        <Toolbar className={classes.toolbar}>
          <div className={classes.content}>
            <section className={classes.leftAppBar} />
            <section className={classes.rightAppBar}>
              <IconButton onClick={() => history.push("/feedback")}>
                <HelpIcon />
              </IconButton>
              <Tooltip title={t("menu.news")}>
                <IconButton onClick={onNewsIconClick}>
                  <NewReleasesIcon />
                </IconButton>
              </Tooltip>
              <ImageAvatar
                loading={loading}
                source={avatarUrl}
                classes={{ root: classes.toolbarAvatar }}
                text={`${firstName[0].toUpperCase()}${lastName[0].toUpperCase()}`}
              />
              <IconButton
                style={{ padding: 8 }}
                ref={anchorRef}
                aria-controls={menuOpen ? "menu-list-grow" : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
              >
                <KeyboardArrowDown />
              </IconButton>
              <Popper open={menuOpen} anchorEl={anchorRef.current} role={undefined} transition disablePortal style={{ marginTop: 10, marginRight: 10 }}>
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList autoFocusItem={menuOpen} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                          <ListItemText
                            primary={`${firstName} ${lastName}`}
                            secondary={email}
                            style={{ paddingLeft: 40, paddingRight: 40, paddingBottom: 10, paddingTop: 10 }}
                            classes={{ primary: classes.boldText }}
                          />
                          <Divider />
                          <ListItem button onClick={signOut} style={{ padding: 10 }}>
                            <ListItemIcon>
                              <UndoIcon />
                            </ListItemIcon>
                            {t("menu.signOut")}
                          </ListItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </section>
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default DashboardAppBar;
