import React, { useCallback } from "react";

import {
  makeStyles,
  Grid,
  Typography
} from "@material-ui/core";
import { deepPurple, red } from "@material-ui/core/colors";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import Page from "../../common/Page";
import Placeholder from "../../common/Placeholder";
import BankAccountsCard from "./BankAccountCard";
import TariffCard from "../Tariff/TariffCard";
import CompanyCard from "../CompanyProfile/CompanyCard";
import BillingCard from "../Billing/BillingCard";
import UserProfileCard from "../UserProfile/UserProfileCard";
import General from "./General/SettingsGeneral";
import StampCard from "./StampCard";

const useStyles = makeStyles(theme => ({
  card: {
    padding: 4
  },
  cardBottomMargin: {
    marginBottom: theme.spacing(4)
  },
  buttonBottom: {
    margin: theme.spacing(1),
    justifyContent: "right"
  },
  purpleAvatar: {
    margin: 10,
    width: 60,
    height: 60,
    color: "#fff",
    backgroundColor: deepPurple[500]
  },
  imgAvatar: {
    margin: 10,
    width: 60,
    height: 60
  },
  cardActions: {
    [theme.breakpoints.down("xs")]: {
      display: "grid",
      justifyContent: "center",
      "& > :not(:last-child)": {
        marginBottom: theme.spacing(1)
      }
    },
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      justifyContent: "flex-end"
    }
  },
  center: {
    display: "flex",
    justifyContent: "center"
  },
  flexbox: {
    [theme.breakpoints.down("xs")]: {
      display: "grid",
    },
    [theme.breakpoints.up("sm")]: {
      display: "flex"
    }
  },
  leftFlex: {
    [theme.breakpoints.down("xs")]: {
      display: "grid",
      justifyContent: "center"
    },
    [theme.breakpoints.up("sm")]: {
      flex: 1
    }
  },
  rightFlex: {
    flex: 3
  },
  warning: {
    color: red[500]
  },
  bottomMargin: {
    marginBottom: 10
  },
  topMargin: {
    marginTop: 10
  },
  bankInput: {
    width: theme.spacing(50),
    textAlign: "center",
    fontSize: theme.spacing(3),
    marginTop: 2
  },
  bban: {
    width: "30%",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center"
    },
    [theme.breakpoints.up("sm")]: {
      textAlign: "right"
    }
  },
  iban: {
    minWidth: "30%"
  }
}));

const Settings = ({
  loading,
  companyProfile,
  invoices,
  customerProfile,
  user,
  banking,

  canUpdateBankAccount,
  canAccessInvoices,
  canUpdateCompanyProfile,
  canUpdatePersonalProfile,
  canDeletePersonalProfile,
  canReadPersonalProfile,
  canReadCompanyProfile,
  canReadCompanyProfileConditions,
  canDeleteCompanyProfile,
  handleUpdateCompanyProfileClick,
  handleUpdateCustomerProfileClick,
  handleCreateCompanyProfileClick,
  handleChangePassword,
  handleDeleteCompany,
  handleChangeUserAvatarImage,
  handleChangeCompanyAvatarImage,
  handleUserConditionsClick,
  handleCompanyConditionsClick,
  handleSetPriceClick,

  handleUpdateBankAccountSubmit,
  handleCreateBankAccountSubmit,
  handleRemoveBankAccountSubmit,
  handleReplaceBankAccount,
  handleAppendUnfilledBankAccount,

  updateCompanyProfile,
  updateCompanyProfilePartial,

  stampSignatureFile,
  handleStampSignatureFileSelect,
  handleFileDelete
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const renderStampCard = useCallback(() =>
  (
    <StampCard
      classes={classes}
      handleStampSignatureFileSelect={handleStampSignatureFileSelect}
      file={stampSignatureFile}
      handleFileDelete={handleFileDelete}
    />
  ), [classes, handleStampSignatureFileSelect, stampSignatureFile, handleFileDelete]);

  const renderBankAccountCard = useCallback(() =>
  (
    <BankAccountsCard
      banking={banking}

      handleUpdateBankAccountSubmit={handleUpdateBankAccountSubmit}
      handleRemoveBankAccountSubmit={handleRemoveBankAccountSubmit}
      handleCreateBankAccountSubmit={handleCreateBankAccountSubmit}
      handleReplaceBankAccount={handleReplaceBankAccount}
      handleAppendUnfilledBankAccount={handleAppendUnfilledBankAccount}

      classes={classes}
    />
  ), [classes, banking, handleUpdateBankAccountSubmit, handleRemoveBankAccountSubmit, handleCreateBankAccountSubmit, handleReplaceBankAccount, handleAppendUnfilledBankAccount]);

  const renderUserProfileCard = useCallback(() => (
    <UserProfileCard
      classes={classes}
      canUpdatePersonalProfile={canUpdatePersonalProfile}
      canDeletePersonalProfile={canDeletePersonalProfile}
      customerProfile={customerProfile}
      handleUpdateClick={handleUpdateCustomerProfileClick}
      handleChangePassword={handleChangePassword}
      handleChangeAvatarImage={handleChangeUserAvatarImage}
    />
  ), [classes, customerProfile, canUpdatePersonalProfile, canDeletePersonalProfile, handleUpdateCustomerProfileClick, handleChangePassword, handleChangeUserAvatarImage]);

  const renderCompanyCard = useCallback(() => (
    <CompanyCard
      classes={classes}
      canUpdateCompanyProfile={canUpdateCompanyProfile}
      canReadCompanyProfileConditions={canReadCompanyProfileConditions}
      canDeleteCompanyProfile={canDeleteCompanyProfile}
      companyProfile={companyProfile}
      handleUpdateClick={handleUpdateCompanyProfileClick}
      handleCreateClick={handleCreateCompanyProfileClick}
      handleChangeAvatarImage={handleChangeCompanyAvatarImage}
      handleConditionsClick={handleCompanyConditionsClick}
      handleDeleteCompany={handleDeleteCompany}
      ableToSetPricePerKm={user.profileType === "CARRIER"}
      handleSetPriceClick={handleSetPriceClick}
    />
  ), [classes, canUpdateCompanyProfile, canReadCompanyProfileConditions, canDeleteCompanyProfile, companyProfile, handleUpdateCompanyProfileClick, handleCreateCompanyProfileClick, handleChangeCompanyAvatarImage, handleCompanyConditionsClick, handleDeleteCompany, handleSetPriceClick, user]);

  const renderGeneralCard = useCallback(() => (
    <General
      canUpdatePersonalProfile={canUpdatePersonalProfile}
      canUpdateCompanyProfile={canUpdateCompanyProfile}
      handleUserConditionsClick={handleUserConditionsClick}
      companyProfile={companyProfile}
      updateCompanyProfile={updateCompanyProfile}
      updateCompanyProfilePartial={updateCompanyProfilePartial}
      classes={classes}
    />
  ), [classes, canUpdatePersonalProfile, canUpdateCompanyProfile, companyProfile, updateCompanyProfile, updateCompanyProfilePartial]);

  const renderBillingCard = useCallback(() => (
    <BillingCard classes={classes} invoices={invoices} />
  ), [classes, invoices]);

  const renderTariffCard = useCallback(() => (
    <TariffCard
      classes={classes}
      canUpdateCompanyProfile={canUpdateCompanyProfile}
      user={user}
    />), [classes, user, canUpdateCompanyProfile]);

  return (
    <Page title={t("menu.settings")}>
      <Placeholder
        loading={loading}
        render={() => (
          <>
            <div>
              <Grid container spacing={4}>
                <Grid container item md={12} lg={6} direction="column">
                  {canReadCompanyProfile
                    ? (
                      <Grid item className={classes.cardBottomMargin}>
                        <Placeholder
                          loading={loading}
                          render={renderTariffCard}
                        />
                      </Grid>
                    )
                    : undefined}
                  {canAccessInvoices
                    ? (
                      <Grid item className={classes.cardBottomMargin}>
                        <Placeholder
                          loading={loading}
                          render={renderBillingCard}
                        />
                      </Grid>
                    )
                    : undefined}
                  <Grid item>
                    <Placeholder
                      loading={loading}
                      render={renderGeneralCard}
                    />
                  </Grid>
                </Grid>
                <Grid container item md={12} lg={6} direction="column">
                  {canReadCompanyProfile
                    ? (
                      <Grid item className={classes.cardBottomMargin}>
                        <Placeholder
                          loading={loading}
                          render={renderCompanyCard}
                        />
                      </Grid>
                    )
                    : undefined}
                  {canReadPersonalProfile
                    ? (
                      <Grid item className={classes.cardBottomMargin}>
                        <Placeholder
                          loading={loading}
                          render={renderUserProfileCard}
                        />
                      </Grid>
                    )
                    : undefined}
                  {canUpdateBankAccount
                    ? (
                      <Grid item className={classes.cardBottomMargin}>
                        <Placeholder
                          loading={loading}
                          render={renderBankAccountCard}
                        />
                      </Grid>
                    )
                    : undefined}
                  <Grid item className={classes.cardBottomMargin}>
                    <Placeholder
                      loading={loading}
                      render={renderStampCard}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </>
        )}
      />
    </Page>
  );
};

export default Settings;
