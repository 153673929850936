import React, { useState, useEffect } from "react";

import {
  Typography, Grid, Button, makeStyles
} from "@material-ui/core";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import { AccountType, TariffType, BillingPeriod } from "../../enums/enums";
import ButtonWithTooltip from "../../common/ClickableCard/ButtonWithTooltip";
import Page from "../../common/Page";
import useRouter from "../../hook/useRouter";
import useAuth from "../../hook/useAuth";
import useTariff from "../../hook/useTariff";
import { readTariffs, updateSubscription } from "../../../resource";

const useStyles = makeStyles(({ spacing }) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    paddingRight: spacing(2),
    paddingBottom: spacing(2),
    paddingLeft: spacing(2),
    "& > :not(:last-child)": {
      marginBottom: spacing(1)
    }
  },
  cell: {
    height: 100
  },
  fullHeight: {
    height: "100%"
  },
  error: {
    color: "red"
  },
  button: {
    padding: 20,
    background: "white",
    borderRadius: 3,
    color: "black",
    "&:hover": {
      backgroundColor: "white"
    }
  },
  bordered: {
    border: "2px dashed",
    borderColor: "grey"
  }
}));

const TariffExpiration = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { history } = useRouter();
  const { reload } = useAuth();

  const [selectedTariff, setSelectedTariff] = useState(undefined);
  const [tariffs, setTariffs] = useState([]);

  const handleSubmit = async () => {
    try {
      await updateSubscription(selectedTariff.id);
      reload();

      enqueueSnackbar(t("tariff.update.success", { variant: "success" }));
    } catch (error) {
      console.log(error);
      enqueueSnackbar(t("tariff.update.fail"), { variant: "error" });
    }
  };

  useEffect(() => {
    readTariffs("CZ")
      .then(setTariffs)
      .catch((err) => {
        console.error(err);
        enqueueSnackbar(t("settings.tariff.loadingError"), {
          variant: "error"
        });
      });
  }, []);

  return (
    <Page title={t("settings.tariff.isExpired")}>
      <Typography variant="subtitle1" gutterBottom>{t("settings.tariff.selectNewTariff")}</Typography>
      <Formik
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          handleSubmit();
        }}
      >
        {({
          isSubmitting,
          submitForm
        }) => (
            <form className={classes.container}>
              <Grid container spacing={3}>
                {tariffs.map((tariff) => {
                  const { type, isDisabled } = tariff;

                  return (
                    <Grid item xs={4} className={classes.cell} key={type}>
                      <ButtonWithTooltip
                        className={classes.fullHeight}
                        tooltipText={t(`tariff.${type.toLowerCase()}`)}
                        selected={selectedTariff?.type === type}
                        disabled={isDisabled}
                        onClick={() => setSelectedTariff(tariff)}
                      >
                        <Typography variant="body2">{t(`tariff.${type.toLowerCase()}`)}</Typography>
                      </ButtonWithTooltip>
                    </Grid>
                  );
                })}
                <Grid item xs={9}>
                {
                  !selectedTariff
                    ? null
                    : (
                      <>
                        <Typography variant="h5">
                          {selectedTariff.totalPrice}
                          {" "}
                          {selectedTariff.priceCurrency}
                        </Typography>
                        <Typography variant="body2">{t("forUsersPerYear", { users: selectedTariff.totalUsers })}</Typography>
                      </>
                    )
                }
                </Grid>
                <Grid item xs>
                  <Button
                    onClick={submitForm}
                    variant="outlined"
                    size="medium"
                    color="secondary"
                    disabled={isSubmitting || selectedTariff?.type === undefined}
                    fullWidth
                  >
                    {t("continue")}
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
      </Formik>
    </Page>
  );
};

export default TariffExpiration;
