import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";

import theme from "../App/theme";

import {
  format
} from "date-fns";

import {
  formatDateWithoutYear,
} from "../../../utility/common";

import {
  calculatePercentageDiff
} from "../../../utility/functional";

import {
  readMyStatistics,
  readMyCommissionStatistics,
  readMyRevenueStatistics,
  readMyShipmentStatistics,
  readCompanyStatistics,
  readCompanyCommissionStatistics,
  readCompanyRevenueStatistics,
  readCompanyShipmentStatistics
} from "../../../resource";
import Statistics from "./Statistics";
import useAuth from "../../hook/useAuth";
import { Roles, Periods } from "../../enums/enums";

import { ShipmentType } from "@cargotic/model-deprecated";

import {
  storeStatisticsFilter,
  loadStatisticsFilter
} from "../../../storage";

const useStyles = makeStyles(({ spacing }) => ({
  kpi: {
    marginBottom: spacing(4)
  },
  tercialActions: {
    "& > div:first-child > div > :last-child": {
      justifyContent: "flex-end",

      "& > div:last-child": {
        marginLeft: spacing(2)
      }
    }
  }
}));

const StatisticsContainer = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { user: { profileType, parentType } } = useAuth();
  const classes = useStyles();
  const { hasPermission } = useAuth();

  const [loadingCompany, setLoadingCompany] = useState(true);
  const [loadingPersonal, setLoadingPersonal] = useState(true);
  const [loadingOrders, setLoadingOrders] = useState(true);
  const [loadingShipments, setLoadingShipments] = useState(true);
  const [loadingCommissions, setLoadingCommissions] = useState(true);
  const [loadingRevenue, setLoadingRevenue] = useState(true);

  const [personalStatistics, setPersonalStatistics] = useState([]);
  const [companyStatistics, setCompanyStatistics] = useState([]);
  const [ordersStatistics, setOrdersStatistics] = useState([]);
  const [shipmentsStatistics, setShipmentsStatistics] = useState([]);
  const [commissionStatistics, setCommissionStatistics] = useState([]);
  const [revenueStatistics, setRevenueStatistics] = useState([]);

  const [personalStatisticsPeriod, setPersonalStatisticsPeriod] = useState();
  const [companyStatisticsPeriod, setCompanyStatisticsPeriod] = useState();
  const [ordersStatisticsPeriod, setOrdersStatisticsPeriod] = useState();
  const [shipmentsStatisticsPeriod, setShipmentsStatisticsPeriod] = useState();
  const [commissionStatisticsPeriod, setCommissionStatisticsPeriod] = useState();
  const [revenueStatisticsPeriod, setRevenueStatisticsPeriod] = useState();

  const [prevFilter, setPrevFilter] = useState(() => loadStatisticsFilter([ShipmentType.FORWARDED, ShipmentType.CARRIED]));
  const [filter, setFilter] = useState(() => loadStatisticsFilter([ShipmentType.FORWARDED, ShipmentType.CARRIED]));

  const handleSelectShipmentType = (types) => setFilter({ types });


  useEffect(() => {
    storeStatisticsFilter(filter)
  }, [filter]);

  useEffect(() => {
    try {
      if (JSON.stringify(filter.types.sort()) !== JSON.stringify(prevFilter.types.sort())) {
        fetchPersonalStatistics(personalStatisticsPeriod);
        fetchCompanyStatistics(companyStatisticsPeriod);
        fetchRevenueStatistics(revenueStatisticsPeriod);
        fetchShipmentStatistics(shipmentsStatisticsPeriod);

        if (!filter.CARRIED) {
          fetchCommissionStatistics(commissionStatisticsPeriod);
        }
      }
    } catch (err) {
      console.log(err);

      enqueueSnackbar(t("statistics.error.loading"), {
        variant: "error"
      });
    }

    setPrevFilter(filter);
  }, [filter]);

  const formatTooltipX = (data, period) => {
    if (period === Periods.HOUR) {
      return data.map(({ date }) => {
        return `${date.getDay()}.${date.getMonth()}. ${format(date, "HH")}h`;
      })
    }

    return data.map(({ date }) => {
      return formatDateWithoutYear(date);
    })
  }

  const formatData = (previousData, currentData) => {
    const formattedPrevious = currentData.map(({ x, y }, index) => {
      if (!previousData[index]) {
        return {
          x,
          y: null
        }
      }

      return {
        x,
        y: previousData[index].y
      }
    })

    return formattedPrevious;
  }

  const tranformInto2Curve = (current, previous, { term: period }) => {
    if (period === Periods.HOUR) {
      return [
        {
          id: "currentPeriod",
          data: current[0].data,
          tooltipX: formatTooltipX(current[0].data, period)
        },
        {
          id: "previousPeriod",
          data: previous[0].data,
          tooltipX: formatTooltipX(previous[0].data, period)
        }
      ]
    }

    const cuttedPreviousData = previous[0].data.slice(0, current[0].data.length);

    return [
      {
        id: "currentPeriod",
        data: current[0].data,
        tooltipX: formatTooltipX(current[0].data, period)
      },
      {
        id: "previousPeriod",
        data: formatData(cuttedPreviousData, current[0].data),
        tooltipX: formatTooltipX(cuttedPreviousData, period)
      }
    ]
  }

  const formatStatisticsCompany = (current, previous) => {
    const [totalShipments, totalClients, totalRevenue, totalCommission] = current;
    const [previousTotalShipments, previousTotalClients, previousTotalRevenue, previousTotalCommission] = previous;

    return [
      {
        ...totalShipments,
        dataDiff: calculatePercentageDiff(totalShipments.data, previousTotalShipments.data)?.toFixed(2)
      },
      {
        ...totalClients,
        dataDiff: calculatePercentageDiff(totalClients.data, previousTotalClients.data)?.toFixed(2)
      },
      {
        ...totalRevenue,
        dataDiff: calculatePercentageDiff(totalRevenue.data, previousTotalRevenue.data)?.toFixed(2)
      },
      {
        ...totalCommission,
        dataDiff: calculatePercentageDiff(totalCommission.data, previousTotalCommission.data)?.toFixed(2)
      },
    ]
  }

  const formatMyStatistics = (current, previous) => {
    const result = [];

    if (current.totalShipments) {
      result.push({
        ...current.totalShipments,
        dataDiff: previous?.totalShipments
          ? calculatePercentageDiff(current.totalShipments.data, previous.totalShipments.data)?.toFixed(2)
          : undefined
      })
    }
    if (current.totalClients) {
      result.push({
        ...current.totalClients,
        dataDiff: previous?.totalClients
          ? calculatePercentageDiff(current.totalClients.data, previous.totalClients.data)?.toFixed(2)
          : undefined
      })
    }
    if (current.totalDuration) {
      result.push({
        ...current.totalDuration,
        dataDiff: previous?.totalDuration
          ? calculatePercentageDiff(current.totalDuration.data, previous.totalDuration.data)?.toFixed(2)
          : undefined
      })
    }
    if (current.totalDistance) {
      result.push({
        ...current.totalDistance,
        dataDiff: previous?.totalDistance
          ? calculatePercentageDiff(current.totalDistance.data, previous.totalDistance.data)?.toFixed(2)
          : undefined
      })
    }
    if (current.totalRevenue) {
      result.push({
        ...current.totalRevenue,
        dataDiff: previous?.totalRevenue
          ? calculatePercentageDiff(current.totalRevenue.data, previous.totalRevenue.data)?.toFixed(2)
          : undefined
      })
    }
    if (current.totalCommission) {
      result.push({
        ...current.totalCommission,
        dataDiff: previous?.totalCommission
          ? calculatePercentageDiff(current.totalCommission.data, previous.totalCommission.data)?.toFixed(2)
          : undefined
      })
    }

    return result;
  }

  const fetchPersonalStatistics = async (period) => {
    setPersonalStatisticsPeriod(period);

    if (hasPermission("resource.statistics.user.read")) {
      setLoadingPersonal(true);
      try {
        const myStatistics = period.start
          ? await readMyStatistics(filter, period.start.toISOString(), period.end.toISOString())
          : await readMyStatistics(filter)

        let previousMyStatistics;
        if (period.startPrevious) {
          previousMyStatistics = await readMyStatistics(filter, period.startPrevious.toISOString(), period.endPrevious.toISOString(), period.term);

        }

        setPersonalStatistics(formatMyStatistics(myStatistics, previousMyStatistics).map(item => ({ ...item, title: t(item.title) })));
        setLoadingPersonal(false);
      } catch (error) {
        console.error(error);
        setLoadingPersonal(false);
        enqueueSnackbar(t("statistics.error.loading"), {
          variant: "error"
        });
      }
    }
  };

  const fetchCompanyStatistics = async (period) => {
    setCompanyStatisticsPeriod(period);

    if (hasPermission("resource.statistics.company.read")) {
      setLoadingCompany(true);
      try {
        const companyStatistics = period.start
          ? await readCompanyStatistics(filter, period.start.toISOString(), period.end.toISOString())
          : await readCompanyStatistics(filter);

        let fullStatistics = companyStatistics;
        if (period.startPrevious) {
          const previousCompanyStatistics = await readCompanyStatistics(filter, period.startPrevious.toISOString(), period.endPrevious.toISOString(), period.term);

          fullStatistics = formatStatisticsCompany(companyStatistics, previousCompanyStatistics);
        }

        setCompanyStatistics(fullStatistics.map(item => ({ ...item, title: t(item.title) })));
        setLoadingCompany(false);
      } catch (error) {
        console.error(error);
        setLoadingCompany(false);

        enqueueSnackbar(t("statistics.error.loading"), {
          variant: "error"
        });
      }
    }
  };

  const fetchShipmentStatistics = async (period) => {
    setShipmentsStatisticsPeriod(period);

    if (hasPermission("resource.statistics.company.read")) {
      setLoadingShipments(true);
      try {
        const shipmentStatistics = period.start
          ? await readCompanyShipmentStatistics(filter, period.start.toISOString(), period.end.toISOString(), period.term)
          : await readCompanyShipmentStatistics(filter);

        if (period.startPrevious) {
          const previousShipmentStatistics = await readCompanyShipmentStatistics(filter, period.startPrevious.toISOString(), period.endPrevious.toISOString(), period.term)

          setShipmentsStatistics(tranformInto2Curve(shipmentStatistics, previousShipmentStatistics, period));
        }
        else {
          setShipmentsStatistics(shipmentStatistics);
        }

        setLoadingShipments(false);
      } catch (error) {
        console.error(error);
        setLoadingShipments(false);

        enqueueSnackbar(t("statistics.error.loading"), {
          variant: "error"
        });
      }
    } else {
      try {
        setLoadingShipments(true);
        const shipmentStatistics = period.start
          ? await readMyShipmentStatistics(filter, period.start, period.end, period.term)
          : await readMyShipmentStatistics(filter,);

        if (period.startPrevious) {
          const previousShipmentStatistics = await readMyShipmentStatistics(filter, period.startPrevious.toISOString(), period.endPrevious.toISOString(), period.term)

          setShipmentsStatistics(tranformInto2Curve(shipmentStatistics, previousShipmentStatistics, period));
        }
        else {
          setShipmentsStatistics(shipmentStatistics);
        }

        setLoadingShipments(false);
      } catch (error) {
        console.error(error);
        setLoadingShipments(false);

        enqueueSnackbar(t("statistics.error.loading"), {
          variant: "error"
        });
      }
    }
  };

  const fetchCommissionStatistics = async (period) => {
    setCommissionStatisticsPeriod(period);

    if (hasPermission("resource.statistics.company.read")) {
      setLoadingCommissions(true);
      try {
        const commissionStatistics = period.start
          ? await readCompanyCommissionStatistics(filter, period.start.toISOString(), period.end.toISOString(), period.term)
          : await readCompanyCommissionStatistics(filter,);


        if (period.startPrevious) {
          const previousCommissionStatistics = await readCompanyCommissionStatistics(filter, period.startPrevious.toISOString(), period.endPrevious.toISOString(), period.term)

          setCommissionStatistics(tranformInto2Curve(commissionStatistics, previousCommissionStatistics, period));
        }
        else {
          setCommissionStatistics(commissionStatistics);
        }

        setLoadingCommissions(false);
      } catch (error) {
        console.error(error);
        setLoadingCommissions(false);

        enqueueSnackbar(t("statistics.error.loading"), {
          variant: "error"
        });
      }
    } else {
      try {
        const commissionStatistics = period.start
          ? await readMyCommissionStatistics(filter, period.start.toISOString(), period.end.toISOString(), period.term)
          : await readMyCommissionStatistics(filter);

        if (period.startPrevious) {
          const previousCommissionStatistics = await readMyCommissionStatistics(filter, period.startPrevious.toISOString(), period.endPrevious.toISOString(), period.term)

          setCommissionStatistics(tranformInto2Curve(commissionStatistics, previousCommissionStatistics, period));
        }
        else {
          setCommissionStatistics(commissionStatistics);
        }

        setLoadingCommissions(false);
      } catch (error) {
        console.error(error);
        setLoadingCommissions(false);

        enqueueSnackbar(t("statistics.error.loading"), {
          variant: "error"
        });
      }
    }
  };

  const fetchRevenueStatistics = async (period) => {
    setRevenueStatisticsPeriod(period);

    if (hasPermission("resource.statistics.company.read")) {
      try {
        setLoadingRevenue(true);
        const revenueStatistics = period.start
          ? await readCompanyRevenueStatistics(filter, period.start.toISOString(), period.end.toISOString(), period.term)
          : await readCompanyRevenueStatistics(filter);

        if (period.startPrevious) {
          const previousRevenueStatistics = await readCompanyRevenueStatistics(filter, period.startPrevious.toISOString(), period.endPrevious.toISOString(), period.term)

          setRevenueStatistics(tranformInto2Curve(revenueStatistics, previousRevenueStatistics, period));
        }
        else {
          setRevenueStatistics(revenueStatistics);
        }

        setLoadingRevenue(false);
      } catch (error) {
        console.error(error);
        setLoadingRevenue(false);

        enqueueSnackbar(t("statistics.error.loading"), {
          variant: "error"
        });
      }
    } else {
      try {
        setLoadingRevenue(true);
        const revenueStatistics = period.start
          ? await readMyRevenueStatistics(filter, period.start.toISOString(), period.end.toISOString(), period.term)
          : await readMyRevenueStatistics(filter);

        if (period.startPrevious) {
          const previousRevenueStatistics = await readMyRevenueStatistics(filter, period.startPrevious.toISOString(), period.endPrevious.toISOString(), period.term)

          setRevenueStatistics(tranformInto2Curve(revenueStatistics, previousRevenueStatistics, period));
        }
        else {
          setRevenueStatistics(revenueStatistics);
        }

        setLoadingRevenue(false);
      } catch (error) {
        console.error(error);
        setLoadingRevenue(false);

        enqueueSnackbar(t("statistics.error.loading"), {
          variant: "error"
        });
      }
    }
  };

  return (
    <Statistics
      classes={classes}
      hasPermissionUserRead={hasPermission("resource.statistics.user.read")}
      hasPermissionCompanyRead={hasPermission("resource.statistics.company.read")}
      loadingPersonalStatistics={loadingPersonal}
      loadingCompanyStatistics={loadingCompany}
      loadingShipmentsStatistics={loadingShipments}
      loadingRevenueStatistics={loadingRevenue}
      loadingCommissionStatistics={loadingCommissions}

      filter={filter}
      handleSelectShipmentType={handleSelectShipmentType}

      personalStatistics={personalStatistics}
      companyStatistics={companyStatistics}
      shipmentsStatistics={shipmentsStatistics}
      revenueStatistics={revenueStatistics}
      commissionStatistics={commissionStatistics}

      fetchPersonalStatistics={fetchPersonalStatistics}
      fetchCompanyStatistics={fetchCompanyStatistics}
      fetchShipmentsStatistics={fetchShipmentStatistics}
      fetchRevenueStatistics={fetchRevenueStatistics}
      fetchCommissionStatistics={fetchCommissionStatistics}
    />
  );
};

export default StatisticsContainer;
