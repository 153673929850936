import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {
  TextField,
  IconButton,
  InputAdornment,
  makeStyles,
  Tooltip,
  ListItem,
  ListItemAvatar,
  Avatar, ListItemText, List
} from "@material-ui/core";
import {Close, Search} from "@material-ui/icons";
import {getNameInitials} from "../../../utility/common";
import {ImageAvatar} from "../index";
import {format} from "date-fns";
import {Alert} from "@material-ui/lab";

const useStyles = makeStyles(() => ({
  field: {
    marginBottom: 0,
    maxWidth: '400px',
    marginTop: '15px',
    backgroundColor: 'rgba(79, 195, 247, 0.25)',
    border: 'solid 1px rgb(79, 195, 247)',
    borderRadius: '3px'
  },
  success: {
    maxWidth: '400px',
    marginTop: '15px',
    backgroundColor: 'rgba(102, 187, 106, 0.25)',
    border: 'solid 1px rgb(102, 187, 106)',
    borderRadius: '3px'
  },
  error: {
    maxWidth: '400px',
    marginTop: '15px',
    backgroundColor: 'rgb(253, 236, 234)',
    border: 'solid 1px #dc3545',
    borderRadius: '3px'
  }
}));

const IcoData = ({
  icoData
}) => {

  const classes = useStyles();

  const formatDate = (date) => {
    if (!date) {
      return '-';
    }
    date = new Date(date);
    return `${date.getDate().toString().padStart(0, 2)}.${(date.getMonth() + 1).toString().padStart(0, 2)}.${date.getFullYear()}`;
  };

  const isOlderThan4Months = (date) => {
    if (!date) {
      return true;
    }
    let currentDate = new Date(date);
    let olderDate = new Date();
    olderDate.setMonth(olderDate.getMonth() - 4);
    return +olderDate >= +currentDate;
  };

  const {
    name,
    ico,
    dic,
    contactEmail,
    contactPhoneNumber,
    contactBillingAddress,
    lastDateOfUse,
    ownerName,
    ownerEmail,
    creatorName,
    creatorEmail,
    creatorAvatarUrl,
    ownerAvatarUrl,
    shipmentCreatorName,
    shipmentCreatorEmail,
    shipmentCreatorAvatarUrl
  } = icoData;

  const isOlder = isOlderThan4Months(lastDateOfUse);

  const {t} = useTranslation();

  return (
    <div>
      <List classes={{
        root: classes.field
      }}>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <ImageAvatar
                source={creatorAvatarUrl}
                text={getNameInitials(creatorName).toUpperCase()}
              />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={`${t('common.title.contactCreatedBy')}:`}
                        secondary={`${creatorName} (${creatorEmail})`}/>
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <ImageAvatar
                source={ownerAvatarUrl}
                text={getNameInitials(ownerName).toUpperCase()}
              />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={`${t('common.title.contactOwnedBy')}:`} secondary={`${ownerName} (${ownerEmail})`}/>
        </ListItem>
        <ListItem>
          <ListItemText primary={`${t('common.title.name')}:`} secondary={name}/>
        </ListItem>
        <ListItem>
          <ListItemText primary={`${t('common.title.address')}:`} secondary={contactBillingAddress}/>
        </ListItem>
        <ListItem>
          <ListItemText primary={`${t('common.title.icoDic')}:`} secondary={`${ico || "-"} / ${dic || "-"}`}/>
        </ListItem>
        <ListItem>
          <ListItemText primary={`${t('common.title.emailPhone')}:`}
                        secondary={`${contactPhoneNumber || "-"} / ${contactEmail || "-"}`}/>
        </ListItem>
        <ListItem style={{
          fontSize: '20px',
          fontWeight: 'bold'
        }}>
          <ListItemText primary={`${t('common.title.lastDateOfUse')}:`} secondary={formatDate(lastDateOfUse)}/>
        </ListItem>
        {
          lastDateOfUse && shipmentCreatorName ?
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <ImageAvatar
                    source={shipmentCreatorAvatarUrl}
                    text={getNameInitials(shipmentCreatorName).toUpperCase()}
                  />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={`${t('common.title.shipmentCreatedBy')}:`}
                            secondary={`${shipmentCreatorName} (${shipmentCreatorEmail})`}/>
            </ListItem> : null
        }
      </List>
      {
        isOlder ?
          <Alert classes={{
            root: classes.success
          }} severity="success">{`${t('common.title.older')}`}</Alert> :
          <Alert classes={{
            root: classes.error
          }} severity="error">{`${t('common.title.notOlder')}`}</Alert>
      }
    </div>);
}

export default IcoData;
