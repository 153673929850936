import React, {useEffect, useState} from "react";

import {
  Fab,
  Grid,
  Chip,
  makeStyles,
  Button,
  InputBase,
  IconButton,
  Tooltip,
  Typography
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { useSnackbar } from "notistack";
import { UserRole } from "@cargotic/model";

import {
  KeyboardArrowRight as KeyboardArrowRightIcon,
  PersonAdd,
  DeleteOutline,
  Search,
  EmojiObjectsOutlined,
  Close,
  ControlPoint,
  Edit as EditIcon
} from "@material-ui/icons";

import { useTranslation } from "react-i18next";
import { useApiClient } from "../../../../cargotic-webapp-component";

import Page from "../../common/Page";
import DataTable from "../../../datatable";
import SearchTextfield from "../../common/SearchTextfield";
import FilterStaticDropdown from "../../../../cargotic-webapp-filter/component/FilterStaticDropdown";
import FilterDateRangeDropdown from "../../../../cargotic-webapp-filter/component/FilterDateRangeDropdown";
import FilterDynamicDropdown from "../../../../cargotic-webapp-filter/component/FilterDynamicDropdown";
import FilterContainer from "../../../../cargotic-webapp-filter/component/FilterContainer";
import UserFilterDropdown from "../../../../cargotic-webapp-filter/component/UserFilterDropdown";
import FilterTextDropdown from "../../../../cargotic-webapp-filter/component/FilterTextDropdown";
import SearchTextfieldIco from "../../common/SearchTextfieldIco";
import IcoData from "../../common/SearchTextfieldIco/IcoData";
import IcoFailure from "../../common/SearchTextfieldIco/IcoFailure";

const useStyles = makeStyles(({ palette, spacing }) => ({
  danger: {
    background: palette.status.danger,
    color: "white",
    "&:hover": {
      background: palette.status.danger
    }
  },
  marginBottom: {
    marginBottom: spacing(2)
  },
  pressedChip: {
    fontWeight: "bold",
    filter: "brightness(20%)"
  }
}));

const Ico = ({
  search,
  handleSearch,
  icoData,
  searching,
  loading
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const client = useApiClient();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Page
      title={t("menu.ico_lookup")}
    >
      <SearchTextfieldIco handleSearch={handleSearch} value={search} fullWidth placeholder={t("ico.searchTooltip")} />
      {
        !loading && searching && icoData ? <IcoData icoData={icoData} /> : (!loading && searching && !icoData ? <IcoFailure search={search} /> : null)
      }

    </Page>
  );
};

export default Ico;