const DashboardView = {
  CHAT: "CHAT",
  CONTACTS: "CONTACTS",
  SHIPMENT: "SHIPMENT",
  SHIPMENTS: "SHIPMENTS",
  USERS: "USERS",
  OVERVIEW: "OVERVIEW",
  SETTINGS: "SETTINGS",
  STATISTICS: "STATISTICS",
  VEHICLES: "VEHICLES",
  VEHICLE_OVERVIEW: "VEHICLE_OVERVIEW",
  TRACKING: "TRACKING",
  ICO_LOOKUP: "ICO_LOOKUP",
  BULK_SHIPMENTS: "BULK_SHIPMENTS",
  DRIVERS: "DRIVERS",
  PLACES: "PLACES",
  NOTFOUND: "NOTFOUND",
  FEEDBACK: "FEEDBACK",
  TARIFF_EXPIRATION: "TARIFF_EXPIRATION",
  SHIPMENT_BOARD: "SHIPMENT_BOARD"
};

export default DashboardView;
