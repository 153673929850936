import React, { useState } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  makeStyles,
  Switch,
  useTheme,
  Select,
  MenuItem,
  IconButton
} from "@material-ui/core";
import { Edit, Add, Remove, Check } from "@material-ui/icons";

import { Currency } from "@cargotic/currency-deprecated";

import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import InputMask from "react-input-mask";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const BankAccountCard = ({
  banking,

  handleCreateBankAccountSubmit,
  handleRemoveBankAccountSubmit,
  handleUpdateBankAccountSubmit,
  handleReplaceBankAccount,
  handleAppendUnfilledBankAccount,

  classes
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isResponsive = useMediaQuery(theme.breakpoints.down("xs"));

  const prefix = "aa99 9999 9999 9999 9999 9999";

  return (
    <>
      <Card className={classes.card}>
        <CardHeader title={t("settings.bankAccount.bankAccount")} />
        <CardContent>
          <Grid container spacing={1} alignItems="center" style={{ padding: 5 }}>
            {banking.map(({ id, currency, iban, bban, isIbanFormatSelected }, index) => (
              <>
                <Grid item className={classes.bban} xs={5}>
                  {isResponsive ? t("settings.bankAccount.bbanResponsive") : t("settings.bankAccount.bban")}
                </Grid>
                <Grid item xs={2}>
                  <Switch
                    color="primary"
                    value={isIbanFormatSelected}
                    onChange={({ target: { checked: value } }) => handleReplaceBankAccount(index, { ...banking[index], isIbanFormatSelected: value })}
                  />
                </Grid>
                <Grid item className={classes.iban} xs={5}>
                  {isResponsive ? t("settings.bankAccount.ibanResponsive") : t("settings.bankAccount.iban")}
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Select value={currency} fullWidth variant="outlined" onChange={({ target: { value } }) => handleReplaceBankAccount(index, { ...banking[index], currency: value })}>
                    <MenuItem value={Currency.CZK}>CZK</MenuItem>
                    <MenuItem value={Currency.EUR}>EUR</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <InputMask
                    value={isIbanFormatSelected ? iban : bban}
                    name={isIbanFormatSelected ? "bankAccounts.iban" : "bankAccounts.bban"}
                    className={classes.bankInput}
                    mask={isIbanFormatSelected ? prefix : ""}
                    maskChar=""
                    onChange={({ target: { value } }) => isIbanFormatSelected ? handleReplaceBankAccount(index, { ...banking[index], iban: value }) : handleReplaceBankAccount(index, { ...banking[index], bban: value })}
                  />
                </Grid>
                <Grid item xs={3} sm={2}>
                  {id ? (
                    <IconButton onClick={() => handleUpdateBankAccountSubmit(id, { currency, bban, iban }, isIbanFormatSelected)}><Edit /></IconButton>
                  ) : (
                    <IconButton onClick={() => handleCreateBankAccountSubmit({ currency, iban, bban }, isIbanFormatSelected)}><Check /></IconButton>
                  )}
                  <IconButton onClick={() => handleRemoveBankAccountSubmit(id, index)}><Remove /></IconButton>
                </Grid>
              </>
            ))}
          </Grid>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Button
            variant="outlined"
            size="medium"
            color="secondary"
            onClick={handleAppendUnfilledBankAccount}
          >
            <Add />
            {t("settings.bankAccount.bankAccount")}
          </Button>
        </CardActions>
      </Card>
    </>
  );
};

export default BankAccountCard;
