import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  makeStyles
} from "@material-ui/core";

import ContactOwnerEditorSelect from "./ContactOwnerEditorSelect";

const useStyles = makeStyles(({ spacing }) => ({
  paper: {
    width: spacing(50),
    height: spacing(60)
  }
}));

const ContactOwnerEditor = ({
  owner: initialOwner,
  isOpen,
  onClose,
  onSubmit
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [owner, setOwner] = useState(initialOwner);

  const handleOwnerChange = value => setOwner(value);

  const handleSubmitButtonClick = () => {
    if (onSubmit) {
      onSubmit(owner);
    }
  };

  useEffect(() => {
    setOwner(initialOwner);
  }, [isOpen]);

  return (
    <Dialog
      classes={{
        paper: classes.paper
      }}
      open={isOpen}
      onClose={onClose}
    >
      <DialogTitle>
        {t("webapp:vehicle.title.assign-owner")}
      </DialogTitle>
      <ContactOwnerEditorSelect value={owner} onChange={handleOwnerChange} />
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          {t("webapp:common.title.cancel")}
        </Button>
        <Button color="primary" onClick={handleSubmitButtonClick}>
          {t("webapp:common.title.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ContactOwnerEditor;
