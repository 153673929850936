import Axios from "axios";
import config from "./../cargotic-webapp/config";
enum Currency {
  CZK = "CZK",
  EUR = "EUR"
}

interface ExchangeRates {
  base: Currency;
  rates: {
    [K in Currency]: number;
  };
}

//const EXCHANGE_RATES_API_URL = "https://api.exchangerate.host";
const EXCHANGE_RATES_API_URL = config.api.url;

async function fetchExchangeRates(
  baseCurrency: Currency,
): Promise<ExchangeRates> {
  const { data } = await Axios.get(
    //`${EXCHANGE_RATES_API_URL}/latest?base=${baseCurrency}&source=ecb`,
    `${EXCHANGE_RATES_API_URL}/exchange/base?baseCurrency=${baseCurrency}`,
  );

  return data;
}

function convertCurrency(
  value: number,
  sourceCurrency: Currency,
  targetCurrency: Currency,
  exchangeRates: ExchangeRates,
  exchangeRatesEUR: ExchangeRates
): number {
  if (sourceCurrency === targetCurrency) {
    return value;
  }

  if (sourceCurrency === Currency.EUR && targetCurrency === Currency.CZK) {
    const {  rates } = exchangeRatesEUR;
    return value * rates[targetCurrency];
  }

  const { base, rates } = exchangeRates;

  if (sourceCurrency === base) {
    return value * rates[targetCurrency];
  }

  if (targetCurrency === base) {
    return value / rates[sourceCurrency];
  }

  return (value / rates[sourceCurrency]) * rates[targetCurrency];
}

export {
  Currency,
  ExchangeRates,

  convertCurrency,
  fetchExchangeRates
};
