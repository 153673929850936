import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";

import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography, makeStyles
} from "@material-ui/core";

import { Formik } from "formik";
import { useTranslation } from "react-i18next";

import ButtonWithTooltip from "../../common/ClickableCard/ButtonWithTooltip";
import { updateSubscription, readTariffs } from "../../../resource";
import { AccountType, TariffType, BillingPeriod } from "../../enums/enums";
import useAuth from "../../hook/useAuth";

const useStyles = makeStyles(({ spacing }) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    paddingRight: spacing(2),
    paddingBottom: spacing(2),
    paddingLeft: spacing(2),
    "& > :not(:last-child)": {
      marginBottom: spacing(1)
    }
  },
  cell: {
    height: 100
  },
  fullHeight: {
    height: "100%"
  },
  error: {
    color: "red"
  },
  button: {
    padding: 20,
    background: "white",
    borderRadius: 3,
    color: "black",
    "&:hover": {
      backgroundColor: "white"
    }
  },
  bordered: {
    border: "2px dashed",
    borderColor: "grey"
  }
}));

const SetTariffDialog = ({
  open,
  handleClose
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { user: { subscription }, reload } = useAuth();

  const [selectedTariff, setSelectedTariff] = useState();
  const [tariffs, setTariffs] = useState([]);

  const handleSubmit = async () => {
    try {
      await updateSubscription(selectedTariff.id);
      reload();

      enqueueSnackbar(t("subscription.update.success"), { variant: "success" });
    } catch (error) {
      console.log(error);
      enqueueSnackbar(t("subscription.update.fail"), { variant: "error" });
    }

    handleClose();
  };

  useEffect(() => {
    readTariffs("CZ")
      .then(setTariffs)
      .catch((err) => {
        console.error(err);
        enqueueSnackbar(t("settings.tariff.loadingError"), {
          variant: "error"
        });
      });
  }, []);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t("settings.configureTariff")}</DialogTitle>
      <Formik
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          handleSubmit();
        }}
      >
        {({
          isSubmitting,
          submitForm
        }) => (
          <>
            <DialogContent>
              <form className={classes.container}>
                <Grid container spacing={3}>
                  {tariffs.map((tariff) => {
                    const { type, isDisabled } = tariff;

                    return (
                      <Grid item xs={4} className={classes.cell} key={type}>
                        <ButtonWithTooltip
                          className={classes.fullHeight}
                          tooltipText={t(`tariff.${type.toLowerCase()}`)}
                          selected={selectedTariff?.type === type}
                          disabled={Boolean(isDisabled)}
                          onClick={() => setSelectedTariff(tariff)}
                        >
                          <Typography variant="body2">{t(`tariff.${type.toLowerCase()}`)}</Typography>
                        </ButtonWithTooltip>
                      </Grid>
                    );
                  })}
                  <Grid item xs={9}>
                  {
                    !selectedTariff
                      ? null
                      : (
                        <>
                          <Typography variant="h5">
                            {selectedTariff.totalPrice}
                            {" "}
                            {selectedTariff.priceCurrency}
                          </Typography>
                          <Typography variant="body2">{t("forUsersPerYear", { users: selectedTariff.totalUsers })}</Typography>
                        </>
                      )
                  }
                  </Grid>
                </Grid>
              </form>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                {t("abort")}
              </Button>
              <Button
                onClick={submitForm}
                size="medium"
                color="primary"
                disabled={isSubmitting || selectedTariff?.type === undefined}
              >
                {t("continue")}
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default SetTariffDialog;
