import clsx from "clsx";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  uploadShipmentDocument,
  deleteShipmentDocument,
  setContactSharingUsers
} from "@cargotic/api-client-deprecated";
import { Currency } from "@cargotic/currency-deprecated";
import { ContactType } from "@cargotic/model-deprecated";
import { usePrevious } from "@cargotic/common-deprecated";
import {
  Fab,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Tooltip,
  Typography,
  makeStyles,
  Button,
  Chip,
  Avatar,
  Select,
  Menu
} from "@material-ui/core";
import {
  AccountCircle, Edit, Warning, Work, Error, Done
} from "@material-ui/icons";
import { red, yellow } from "@material-ui/core/colors";
import useAuth from "../../../component/hook/useAuth";
import ContactEditor from "../../../../cargotic-webapp-contact/component/ContactEditor";
import EmployeeEditor from "../../../../cargotic-webapp-contact/component/ContactEmployeeEditor";
import SetConditionsDialog from "../../../component/core/CompanyProfile/SetConditionsDialog";

import PlaceSearchFailDialog from "../../../component/common/PlaceSearchFailDialog";

import { ErrorHandler } from "../../../../cargotic-webapp-error";

import {
  ContactFormAutosuggestTextField,
  EmployeeFormAutosuggestTextField
} from "../../../contact";
import {
  FormCheckbox, FormSelect, FormTextField, FormDatePicker, FormDatePickerWithDueAdornment
} from "../../../form";
import {
  updateContact,
  readAvailableTags,
  updateEmployee,
  createEmployee,
  deleteEmployee
} from "../../../resource";

import SwipeableDialog from "../../../swipeableDialog";

import { generateUuid, formatDateTime }
  from "../../../../../multiload/cargotic-common";

import FileDropzone
  from "../../../../../multiload/cargotic-webapp/component/FileDropzone";

import FileUpload
  from "../../../../../multiload/cargotic-webapp/component/FileUpload";

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    "& > section": {
      "&:not(:first-child)": {
        marginTop: spacing(4)
      },
      "& > h6": {
        marginBottom: spacing(3)
      }
    }
  },
  fab: {
    display: "flex",
    justifyContent: "center"
  },
  upload: {
    marginTop: spacing(2)
  },
  warning: {
    color: palette.warning.light
  }
}));

const ShipmentForwarderForm = ({
  resetWarnings,
  className,
  form,
  onDocumentsChange,
  apiClient,
  newApiClient,
  initialCustomerPaymentDueDays,
  initialCarrierPaymentDueDays,
  isOrderSerialNumberDuplicate,
  availableTerms
}) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const { hasPermission } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const language = i18n.language === "cs" ? "CZ" : "EN";
  const handleError = ErrorHandler(
    t,
    message => enqueueSnackbar(message, { variant: "error" })
  );

  const [
    hasDocumentFileDropzoneError,
    setHasDocumentFileDropzoneError
  ] = useState(false);

  const previousValues = usePrevious(form.values);

  const termsId = form.values.terms ? form.values.terms.termsId : undefined;
  const areTermsForCompany = form.values.terms ? form.values.terms.isCompany : undefined;
  const { terms } = form.values;
  const haveTermsMissingLanguage = !(terms?.cs?.content && terms?.en?.content);

  const [selectedContact, setSelectedContact] = useState();
  const [selectedContactType, setSelectedContactType] = useState();
  const [isContactEditorOpen, setIsContactEditorOpen] = useState(false);
  const [sharingDialogOpen, setSharingDialogOpen] = useState(false);
  const [searchFailDialogOpen, setSearchFailDialogOpen] = useState(false);
  const [employeeEditorOpen, setEmployeeEditorOpen] = useState(false);
  const [employeeToEdit, setEmployeeToEdit] = useState({});
  const [conditionsDialogOpen, setConditionsDialogOpen] = useState(false);

  const [optionTags, setOptionTags] = useState([]);
  const [sharingUsers, setSharingUsers] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  const canUpdateCompanyContact = hasPermission("resource.contact.company.update");

  const handleDocumentDelete = async (uuid) => {
    const { url } = form.values.documents
      .find(({ uuid: other }) => other === uuid);

    if (url) {
      try {
        await deleteShipmentDocument(apiClient, url);
      } catch (error) {
        console.log(error);

        enqueueSnackbar(
          t("webapp:shipment.form.document.error.file.delete"),
          { variant: "error" }
        );

        return;
      }
    }

    form.setValues(values => ({
      ...values,
      documents: values.documents.filter(({ uuid: other }) => other !== uuid)
    }));
  };

  useEffect(() => {
    onDocumentsChange();
  }, [form.values.documents]);

  const handleDocumentFileDropzoneError = () => setHasDocumentFileDropzoneError(true);

  const handleDocumentFilesSelect = files => {
    setHasDocumentFileDropzoneError(false);

    files.forEach(async (file) => {
      const uuid = generateUuid();
      const document = {
        uuid,
        name: file.name,
        isUploading: true
      };

      const updateDocument = update => form.setValues(values => ({
        ...values,
        documents: values.documents
          .map((other) => {
            if (other.uuid !== uuid) {
              return other;
            }

            return { ...other, ...update };
          })
      }));

      form.setValues((values) => ({
        ...values,
        documents: [...values.documents, document]
      }));

      try {
        const { url, createdAt, id } = await uploadShipmentDocument(apiClient, file);

        updateDocument({
          id, url, createdAt, isUploading: false
        });
      } catch (error) {
        console.log(error);

        let message = t("webapp:shipment.form.document.error.upload.general");

        if (error?.response?.data?.error === "FileTooLargeError") {
          message = t("webapp:shipment.form.document.error.upload.size");
        }

        updateDocument({
          isUploading: false,
          error: message
        });
      }
    });
  };

  const handleEmployeeSubmit = async employee => {
    try {
      if (employee.id) {
        await newApiClient.contact.putContactEmployee({
          contactId: employee.contactId,
          employeeId: employee.id,
          employee: {
            email: employee.email,
            name: employee.name,
            phoneNumber: employee.phoneNumber
          }
        });
      } else {
        const { id } = await newApiClient.contact.postContactEmployee({
          contactId: employee.contactId,
          employee: {
            name: employee.name,
            email: employee.email,
            phoneNumber: employee.phoneNumber
          }
        });
        employee = { ...employee, id };
      }
      if (employee.contactId === form.values.customerContact.id) {
        form.setValue("customerEmployee", employee);
      } else {
        form.setValue("carrierEmployee", employee);
      }
      setEmployeeEditorOpen(false);
    } catch (err) {
      console.log(err);
      if (employee.id) {
        enqueueSnackbar(t("contacts.employees.error.update"), {
          variant: "error"
        });
      } else {
        enqueueSnackbar(t("contacts.employees.error.create"), {
          variant: "error"
        });
      }
    }
  };

  const disableCustomerContactButton = () => {
    const { values: { customerContact } } = form;

    if (typeof customerContact === "string" && customerContact !== "") {
      return true;
    }

    if (customerContact?.isDeleted) {
      return true;
    }

    return false;
  };

  const disableCustomerEmployeeButton = () => {
    const { values: { customerContact } } = form;

    if (typeof customerContact === "string") {
      return true;
    }

    if (customerContact?.isDeleted) {
      return true;
    }

    return false;
  };

  const disableCarrierEmployeeButton = () => {
    const { values: { carrierContact } } = form;

    if (typeof carrierContact === "string") {
      return true;
    }

    if (carrierContact?.isDeleted) {
      return true;
    }

    return false;
  };

  const disableCarrierContactButton = () => {
    const { values: { carrierContact } } = form;

    if (typeof carrierContact === "string" && carrierContact !== "") {
      return true;
    }

    if (carrierContact?.isDeleted) {
      return true;
    }

    return false;
  };

  const fetchContact = async (contactId) => newApiClient.contact.getContact({ contactId });

  const handleCustomerContactChange = async () => {
    resetWarnings();
    const { values: { customerContact } } = form;
    const { customerContact: oldCustomer } = previousValues ?? {};

    if (!customerContact || (oldCustomer && oldCustomer.id !== customerContact.id)) {
      form.setValue("customerEmployee", "");
    }

    if (typeof form.values.customerContact === "string") {
      return;
    }

    if (customerContact.paymentDueDays) {
      form.setValue("customerPaymentDueDays", customerContact.paymentDueDays);
    }

    if (customerContact?.billingAddress) {
      return;
    }

    const contact = await fetchContact(customerContact.id);

    if (contact.paymentDueDays) {
      form.setValue("customerPaymentDueDays", contact.paymentDueDays);
    }

    form.setValue("customerContact", contact);
  };

  useEffect(() => {
    handleCustomerContactChange();
  }, [form.values.customerContact]);

  const handleCarrierContactChange = async () => {
    resetWarnings();
    const { values: { carrierContact } } = form;
    const { carrierContact: oldCarrier } = previousValues ?? {};

    if (!carrierContact || (oldCarrier && oldCarrier.id !== carrierContact.id)) {
      form.setValue("carrierEmployee", "");
    }

    if (typeof form.values.carrierContact === "string") {
      return;
    }

    if (carrierContact.paymentDueDays) {
      form.setValue("carrierPaymentDueDays", carrierContact.paymentDueDays);
    }

    if (carrierContact?.billingAddress) {
      return;
    }

    const contact = await fetchContact(carrierContact.id);

    if (contact.paymentDueDays) {
      form.setValue("carrierPaymentDueDays", contact.paymentDueDays);
    }
    form.setValue("carrierContact", contact);
  };

  useEffect(() => {
    handleCarrierContactChange();
  }, [form.values.carrierContact]);

  const handleContactEditorSubmit = async ({ id: contactId, ...contact }) => {
    setIsContactEditorOpen(false);

    try {
      let result;

      if (contactId) {
        result = await newApiClient.contact.putContact({ contactId, contact });
      } else {
        result = await newApiClient.contact.postContact({ contact });
      }

      if (selectedContactType === ContactType.CARRIER) {
        form.setValue("carrierContact", result);
      } else {
        form.setValue("customerContact", result);
      }
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <>
      <div className={clsx(classes.root, className)}>
        <section data-cy="forwarder-form-customer">
          <Typography variant="subtitle2">
            {t("webapp:shipment.form.subtitle.customer")}
          </Typography>
          <Grid container spacing={2}>
            <Grid className={classes.fab} item xs={1}>
              <Fab
                size="medium"
                color="primary"
                disabled={disableCustomerContactButton()}
                onClick={() => {
                  setSelectedContact(
                    form.values.customerContact || { type: ContactType.CUSTOMER }
                  );
                  setSelectedContactType(ContactType.CUSTOMER);
                  setIsContactEditorOpen(true);
                }}
              >
                {
                  form.values.customerContact
                    ? (
                      <Edit />
                    ) : (
                      <AccountCircle />
                    )
                }
              </Fab>
            </Grid>
            <Grid item xs={11}>
              <ContactFormAutosuggestTextField
                form={form}
                contactType={ContactType.CUSTOMER}
                apiClient={newApiClient}
                name="customerContact"
                label={t("webapp:shipment.form.label.contact")}
                fullWidth
                data-cy="customer-contact"
              />
            </Grid>
            <Grid className={classes.fab} item xs={1}>
              <Fab
                size="medium"
                color="primary"
                disabled={disableCustomerEmployeeButton()}
                onClick={() => {
                  setEmployeeToEdit({ ...(form.values.customerEmployee), contactId: form.values.customerContact.id });
                  setEmployeeEditorOpen(true);
                }}
              >
                {
                  form.values.customerEmployee
                    ? (
                      <Edit />
                    ) : (
                      <Work />
                    )
                }
              </Fab>
            </Grid>
            <Grid item xs={11}>
              <EmployeeFormAutosuggestTextField
                form={form}
                apiClient={newApiClient}
                disabled={!form.values.customerContact?.id}
                contactId={form.values.customerContact?.id}
                name="customerEmployee"
                label={t("webapp:shipment.form.label.employee")}
                fullWidth
              />
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={8}>
              <FormTextField
                form={form}
                name="customerPosition"
                label={t("webapp:shipment.form.label.position")}
                hasFullWidth
                InputProps={{
                  startAdornment: <InputAdornment position="start">#</InputAdornment>
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <FormTextField
                form={form}
                name="customerPaymentDueDays"
                label={t("webapp:shipment.form.label.dueDays")}
                hasFullWidth
              />
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={5}>
              <FormTextField
                form={form}
                name="issuedInvoiceNumber"
                label={t("webapp:shipment.form.label.issuedInvoiceNumber")}
                hasFullWidth
                InputProps={{
                  startAdornment: <InputAdornment position="start">#</InputAdornment>
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <FormDatePickerWithDueAdornment
                form={form}
                name="issuedInvoiceDueDate"
                label={t("webapp:shipment.form.label.invoiceDueDate")}
                hasFullWidth
                format="dd.MM.yyyy"
              />
            </Grid>
            <Grid item xs={3}>
              <FormDatePicker
                form={form}
                name="issuedInvoicePaidAt"
                label={t("webapp:shipment.form.label.paidAt")}
                hasFullWidth
                format="dd.MM.yyyy"
              />
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={8}>
              <FormTextField
                form={form}
                name="customerPrice"
                label={t("webapp:shipment.form.label.price")}
                hasFullWidth
                dataCy="customer-price"
              />
            </Grid>
            <Grid item xs={3}>
              <FormSelect
                form={form}
                name="customerPriceCurrency"
                label={t("webapp:shipment.form.label.currency")}
                hasFullWidth
              >
                {
                  Object.values(Currency).map((currency) => (
                    <MenuItem key={currency} value={currency}>
                      {currency}
                    </MenuItem>
                  ))
                }
              </FormSelect>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={11}>
              <FormCheckbox
                form={form}
                name="isCustomerPriceWithDph"
                label={t("webapp:shipment.form.label.isWithDph")}
                color="primary"
              />
            </Grid>










            <Grid item xs={1} />
            <Grid item xs={8}>
              <FormTextField
                  form={form}
                  name="additionalInsurancePrice"
                  label={t("webapp:shipment.form.label.additionalInsurance")}
                  hasFullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <FormSelect
                  form={form}
                  name="additionalInsuranceCurrency"
                  label={t("webapp:shipment.form.label.currency")}
                  hasFullWidth
              >
                {
                  Object.values(Currency).map((currency) => (
                      <MenuItem key={currency} value={currency}>
                        {currency}
                      </MenuItem>
                  ))
                }
              </FormSelect>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={8}>
              <FormTextField
                  form={form}
                  name="storagePrice"
                  label={t("webapp:shipment.form.label.storagePrice")}
                  hasFullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <FormSelect
                  form={form}
                  name="storagePriceCurrency"
                  label={t("webapp:shipment.form.label.currency")}
                  hasFullWidth
              >
                {
                  Object.values(Currency).map((currency) => (
                      <MenuItem key={currency} value={currency}>
                        {currency}
                      </MenuItem>
                  ))
                }
              </FormSelect>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={8}>
              <FormTextField
                  form={form}
                  name="differentServicePrice"
                  label={t("webapp:shipment.form.label.differentServicePrice")}
                  hasFullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <FormSelect
                  form={form}
                  name="differentServiceCurrency"
                  label={t("webapp:shipment.form.label.currency")}
                  hasFullWidth
              >
                {
                  Object.values(Currency).map((currency) => (
                      <MenuItem key={currency} value={currency}>
                        {currency}
                      </MenuItem>
                  ))
                }
              </FormSelect>
            </Grid>















          </Grid>
        </section>
        <section data-cy="forwarder-form-carrier">
          <Typography variant="subtitle2">
            {t("webapp:shipment.form.subtitle.carrier")}
          </Typography>
          <Grid container spacing={2}>
            <Grid className={classes.fab} item xs={1}>
              <Fab
                size="medium"
                color="primary"
                disabled={disableCarrierContactButton()}
                onClick={() => {
                  setSelectedContact(
                    form.values.carrierContact || { type: ContactType.CARRIER }
                  );
                  setSelectedContactType(ContactType.CARRIER);
                  setIsContactEditorOpen(true);
                }}

              >
                {
                  form.values.carrierContact
                    ? (
                      <Edit />
                    ) : (
                      <AccountCircle />
                    )
                }
              </Fab>
            </Grid>
            <Grid item xs={11}>
              <ContactFormAutosuggestTextField
                form={form}
                apiClient={newApiClient}
                contactType={ContactType.CARRIER}
                name="carrierContact"
                label={t("webapp:shipment.form.label.contact")}
                fullWidth
                data-cy="carrier-contact"
              />
            </Grid>
            <Grid className={classes.fab} item xs={1}>
              <Fab
                size="medium"
                color="primary"
                disabled={disableCarrierEmployeeButton()}
                onClick={() => {
                  setEmployeeToEdit({ ...(form.values.carrierEmployee), contactId: form.values.carrierContact.id });
                  setEmployeeEditorOpen(true);
                }}
              >
                {
                  form.values.carrierEmployee
                    ? (
                      <Edit />
                    ) : (
                      <Work />
                    )
                }
              </Fab>
            </Grid>
            <Grid item xs={11}>
              <EmployeeFormAutosuggestTextField
                form={form}
                apiClient={newApiClient}
                disabled={!form.values.carrierContact?.id}
                contactId={form.values.carrierContact?.id}
                name="carrierEmployee"
                label={t("webapp:shipment.form.label.employee")}
                fullWidth
              />
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={8}>
              <FormTextField
                form={form}
                name="orderSerialNumber"
                label={t("webapp:shipment.form.label.orderSerialNumber")}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">#</InputAdornment>
                  ),
                  endAdornment: isOrderSerialNumberDuplicate
                    ? (
                      <InputAdornment position="end">
                        <Tooltip title={t("webapp:shipment.form.warning.duplicate")}>
                          <IconButton size="small">
                            <Warning className={classes.warning} />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ) : null
                }}
                hasFullWidth
                required
              />
            </Grid>
            <Grid item xs={3}>
              <FormTextField
                form={form}
                name="carrierPaymentDueDays"
                label={t("webapp:shipment.form.label.dueDays")}
                hasFullWidth
              />
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={5}>
              <FormTextField
                form={form}
                name="receivedInvoiceNumber"
                label={t("webapp:shipment.form.label.receivedInvoiceNumber")}
                hasFullWidth
                InputProps={{
                  startAdornment: <InputAdornment position="start">#</InputAdornment>
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <FormDatePickerWithDueAdornment
                form={form}
                name="receivedInvoiceDueDate"
                label={t("webapp:shipment.form.label.invoiceDueDate")}
                hasFullWidth
                format="dd.MM.yyyy"
              />
            </Grid>
            <Grid item xs={3}>
              <FormDatePicker
                form={form}
                name="receivedInvoicePaidAt"
                label={t("webapp:shipment.form.label.paidAt")}
                hasFullWidth
                format="dd.MM.yyyy"
              />
            </Grid>
            {/* <Grid item xs={1} /> */}
            <Grid item xs={1} />
            <Grid item xs={8}>
              <FormTextField
                form={form}
                name="carrierPrice"
                label={t("webapp:shipment.form.label.price")}
                hasFullWidth
                dataCy="carrier-price"
              />
            </Grid>
            <Grid item xs={3}>
              <FormSelect
                form={form}
                name="carrierPriceCurrency"
                label={t("webapp:shipment.form.label.currency")}
                hasFullWidth
              >
                {
                  Object.values(Currency).map((currency) => (
                    <MenuItem key={currency} value={currency}>
                      {currency}
                    </MenuItem>
                  ))
                }
              </FormSelect>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={11}>
              <FormTextField
                form={form}
                name="driverContact"
                label={t("webapp:shipment.form.label.driverContact")}
                hasFullWidth
              />
            </Grid>
            <Grid item xs={1} />
            <Grid item xs>
              <FormCheckbox
                form={form}
                name="isCarrierPriceWithDph"
                label={t("webapp:shipment.form.label.isWithDph")}
                color="primary"
              />
            </Grid>
          </Grid>
        </section>
        <section>
          <Typography variant="subtitle2">
            {t("webapp:shipment.form.subtitle.terms")}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={1} />
            <Grid item xs={4}>
              {termsId ? (
                haveTermsMissingLanguage
                  ? (
                    <Chip
                      disabled={true}
                      avatar={<Avatar><Warning style={{ backgroundColor: yellow[700] }} /></Avatar>}
                      label={t("settings.incompleteConditions")}
                      style={{ backgroundColor: yellow[700], color: "#000" }}
                      onDelete={(e) => form.setValues(values => ({
                        ...values,
                        terms: undefined,
                        termsId: undefined
                      }))}
                    />
                  )
                  : (
                    <Chip
                      disabled={true}
                      avatar={<Avatar><Done /></Avatar>}
                      label={areTermsForCompany ? t("settings.defaultCompanyConditions") : t("settings.defaultUserConditions")}
                      color="primary"
                      onDelete={(e) => form.setValues(values => ({
                        ...values,
                        terms: undefined,
                        termsId: undefined
                      }))}
                    />
                  )
              ) : (
                <Chip
                  disabled={true}
                  avatar={<Avatar><Error style={{ color: red[600], backgroundColor: "#fff" }} /></Avatar>}
                  label={t("settings.undefinedConditions")}
                  style={{ backgroundColor: red[600], color: "#fff" }}
                />
              )
              }
            </Grid>
            <Grid item xs>
              {termsId
                ? (
                  <Button
                    disabled={true}
                    onClick={() => setConditionsDialogOpen(true)}
                  >
                    {t("show")}
                  </Button>
                )
                : (
                  <>
                    <Button aria-controls="terms-select" aria-haspopup="true" onClick={(event) => setAnchorEl(event.currentTarget)}>
                      {t("select")}
                    </Button>
                    <Menu
                      id="terms-select"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={() => setAnchorEl(null)}
                    >
                      {availableTerms.map((item) => (
                        <MenuItem onClick={() => {
                          form.setValues(values => ({
                            ...values,
                            terms: item,
                            termsId: item.termsId
                          }));
                          setAnchorEl(null);
                        }}
                        >
                          {item.isCompany ? t("webapp:shipment.form.subtitle.companyTerms") : t("webapp:shipment.form.subtitle.userTerms")}
                        </MenuItem>
                      ))}
                    </Menu>
                    <Button
                      href="/settings"
                    >
                      {t("goToSettings")}
                    </Button>
                  </>
                )}
            </Grid>
          </Grid>
          <Typography variant="subtitle2">
            {t("webapp:shipment.form.subtitle.documents")}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={1} />
            <Grid item xs={11}>
              <FormTextField
                form={form}
                name="notes"
                label={t("webapp:shipment.form.label.notes")}
                variant="outlined"
                rows={10}
                isMultiline
                hasFullWidth
              />
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={11}>
              <FileDropzone
                accept={["application/pdf", "image/jpeg", "image/png"]}
                title={
                  hasDocumentFileDropzoneError
                    ? t("webapp:shipment.form.document.error.file.type")
                    : t("webapp:shipment.form.document.title.upload")
                }
                description={[
                  t("webapp:shipment.form.document.description.about"),
                  t("webapp:shipment.form.document.description.supported"),
                  t("webapp:shipment.form.document.description.limit")
                ]}
                hasError={hasDocumentFileDropzoneError}
                onError={handleDocumentFileDropzoneError}
                onSelect={handleDocumentFilesSelect}
              />
            </Grid>
            {
              form.values.documents.map(({
                uuid, name, url, error, isUploading, createdAt
              }, index) => (
                <React.Fragment key={uuid}>
                  <Grid item xs={1} />
                  <Grid item xs={11}>
                    <FileUpload
                      className={index === 0 ? classes.upload : undefined}
                      title={name}
                      description={
                        createdAt
                          ? formatDateTime(createdAt)
                          : undefined
                      }
                      url={url}
                      isUploading={isUploading}
                      error={error}
                      onDelete={() => handleDocumentDelete(uuid)}
                    />
                  </Grid>
                </React.Fragment>
              ))
            }
          </Grid>
        </section>
      </div>
      <ContactEditor
        initialValue={selectedContact}
        isOpen={isContactEditorOpen}
        type={selectedContactType}
        onClose={() => setIsContactEditorOpen(false)}
        onSubmit={handleContactEditorSubmit}
      />
      <PlaceSearchFailDialog
        isOpen={searchFailDialogOpen}
        onClose={() => setSearchFailDialogOpen(false)}
      />
      <EmployeeEditor
        initialValue={employeeToEdit}
        isOpen={employeeEditorOpen}
        onClose={() => setEmployeeEditorOpen(false)}
        onSubmit={handleEmployeeSubmit}
      />
      <SetConditionsDialog
        isCompanyConditions={areTermsForCompany}
        isReadOnly
        open={conditionsDialogOpen}
        conditions={terms}
        handleClose={() => setConditionsDialogOpen(false)}
      />
    </>
  );
};

export default ShipmentForwarderForm;
