import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {
  TextField,
  IconButton,
  InputAdornment,
  makeStyles,
  Tooltip,
  ListItem,
  ListItemAvatar,
  Avatar, ListItemText, List
} from "@material-ui/core";
import {Close, Search} from "@material-ui/icons";
import {getNameInitials} from "../../../utility/common";
import {ImageAvatar} from "../index";
import {Alert} from "@material-ui/lab";

const useStyles = makeStyles(() => ({
  field: {
    marginBottom: 0,
    maxWidth: '400px',
    marginTop: '15px',
    border: 'solid 1px #dc3545',
    borderRadius: '3px'
  }
}));

const IcoFailure = ({
  search
}) => {

  const classes = useStyles();

  const {t} = useTranslation();

  return (
    <Alert classes={{
      root: classes.field
    }} severity="error">{`${t('common.title.forIco')}: ${search} ${t('common.title.notFound')}`}</Alert>
  );
}

export default IcoFailure;
