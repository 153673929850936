const Subject = {
  CARRIER: "carrier",
  CUSTOMER: "customer"
};

const Currency = {
  CZK: "CZK",
  EUR: "EUR",
  USD: "USD",
  GBP: "GBP"
};

const DistanceUnit = {
  KM: "km",
  M: "m"
};

const DurationUnit = {
  MIN: "minute",
  H: "hour",
  DAY: "day",
  MONTH: "month"
};

const Roles = {
  CARRIER: "CARRIER",
  FORWARDER: "FORWARDER",
  DISPATCHER: "DISPATCHER",
  DRIVER: "DRIVER",
  OWNER: "OWNER",
  ACCOUNTANT: "ACCOUNTANT",
  MANAGER: "MANAGER"
};

const FirebaseErrorCodes = {
  DISABLED_USER: "auth/user-disabled",
  WRONG_PASSWORD: "auth/wrong-password",
  USER_NOT_FOUND: "auth/user-not-found"
};

const ServerErrorCodes = {
  DELETED_COMPANY: "DELETED_COMPANY"
};

const Periods = {
  WEEK: "week",
  DAY: "day",
  HOUR: "hour"
};

const AmountUnit = {
  KS: {
    name: "ks"
  },
  LDM: {
    name: "ldm"
  },
  EUR: {
    name: "eur",
    length: 120,
    width: 80
  },
  US: {
    name: "us",
    length: 120,
    width: 100
  },
  LKW: {
    name: "lkw",
    unit: "ldm",
    amount: 13.6
  }
};

const AccountType = {
  FORWARDER: "Forwarder",
  CARRIER: "Carrier",
  COMBINATION: "Combination"
};

const TariffType = {
  CARRIER: "CARRIER",
  FORWARDER: "FORWARDER",
  LOGISTICIAN: "LOGISTICIAN",
  TRIAL: "TRIAL"
};

const BillingPeriod = {
  MONTHLY: "MONTHLY",
  YEARLY: "YEARLY"
};

const OrderingDirection = {
  ASC: "asc",
  DESC: "desc"
}

const PermissionRoles = {
  OWNER: "OWNER",
  MANAGER: "MANAGER",
  DISPATCHER: "DISPATCHER",
  DRIVER: "DRIVER",
  ACCOUNTANT: "ACCOUNTANT",
  FORWARDER: "Forwarder",
  CARRIER: "CARRIER"
}

export {
  Subject,
  Currency,
  DistanceUnit,
  DurationUnit,
  Roles,
  PermissionRoles,
  FirebaseErrorCodes,
  Periods,
  AmountUnit,
  AccountType,
  TariffType,
  BillingPeriod,
  OrderingDirection,
  ServerErrorCodes
};
