import React from "react";

import { ApiClient } from "@cargotic/api-client";
import { ContactSnapshot, ContactType } from "@cargotic/model";
import {
  ListItemText,
  IconButton,
  InputAdornment,
  Tooltip,
  makeStyles,
  Link,
} from "@material-ui/core";
import { Warning, Error } from "@material-ui/icons";

import { useTranslation } from "react-i18next";

import {
  FormValues,
  FormAutosuggestTextField,
  FormAutosuggestTextFieldProps
} from "../../../form";

import { suggestContact } from "../../utility";
import {Link as RouterLink} from "react-router-dom";

type ContactFormAutosuggestTextField<T extends FormValues> =
  Omit<
    FormAutosuggestTextFieldProps<ContactSnapshot, T>,
    "suggest" | "suggestionComponent" | "getSuggestionText"
  > & {
    apiClient: ApiClient;
    contactType?: ContactType;
  }

const useStyles = makeStyles(({ palette }) => ({
  warning: {
    color: palette.warning.light
  },
  danger: {
    color: palette.status.danger
  }
}));

function ContactFormAutosuggestTextField<T extends FormValues>(
  {
    apiClient,
    contactType,
    ...other
  }: ContactFormAutosuggestTextField<T>
): React.ReactElement {
  const { t } = useTranslation();
  const classes = useStyles();

  function formatSuggestion({
    name,
    ic,
    dic,
    phoneNumber,
    email
  }: {
    name: string,
    ic?: string,
    dic?: string,
    phoneNumber?: string,
    email?: string
  }): string {
    const buffer = [name];

    if (ic) {
      buffer.push(`IČ: ${ic}`);
    }

    if (dic) {
      buffer.push(`DIČ ${dic}`);
    }

    if (email) {
      buffer.push(`Email: ${email}`)
    }

    if (phoneNumber) {
      buffer.push(`Mobil: ${phoneNumber}`)
    }

    return buffer.join(", ");
  }

  const insuranceAdornment = (tooltip, isExpired) => ({
    endAdornment: (
      <InputAdornment position="start">
        <Tooltip title={tooltip}>
          <IconButton className={isExpired ? classes.danger : classes.warning} size="small">
            <Warning />
          </IconButton>
        </Tooltip>
      </InputAdornment>
    )
  });

  const deletedAdornment = () => ({
    endAdornment: (
        <InputAdornment position="start">
          <Tooltip title={t("webapp:shipment.form.error.deletedContact")}>
            <IconButton className={classes.danger} size="small">
              <Error />
            </IconButton>
          </Tooltip>
        </InputAdornment>
    )
  })

  const badRatingAdornment = (tooltip, contactId) => ({
    startAdornment: (
        <Link target={'_blank'} component={RouterLink} to={`/contacts/${contactId}`}>
          <InputAdornment position="start">
            <Tooltip title={tooltip}>
              <IconButton className={classes.danger} size="small">
                <Error />
              </IconButton>
            </Tooltip>
          </InputAdornment>
        </Link>
    )
  });


  const resolveInputProps = () => {
    const { form: { values } } = other;
    const { name } = other;

    const contact = values[name];

    // if there is no contact selected yet, there is no point of showing the icon
    if (!contact) {
      return {}
    }

    const { isDeleted } = contact;
    if (isDeleted) {
      return deletedAdornment();
    }

    const { insuranceExpiresAt, lastBadRating, id } = contact;

    if (lastBadRating) {
      const { created_at } = lastBadRating;
      const date = new Date(created_at);
      const formattedDate = `${date.getDate().toString().padStart(0, 2)}.${(date.getMonth() + 1).toString().padStart(0, 2)}.${date.getFullYear()}`;
      return badRatingAdornment(`${t('contacts.badRatingToDate')}: ${formattedDate}`, id);
    }

    if (contactType === ContactType.CUSTOMER) {
      return {};
    }

    if (!insuranceExpiresAt) {
      return insuranceAdornment(t("contacts.undefinedInsurance"), false);
    }

    if (insuranceExpiresAt.setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)) {
      return insuranceAdornment(t("contacts.incorrectInsurance"), true);
    }

    // if there is insurance and has legit date there is no point of showing the icon
    return {};
  }
// @ts-ignore
  return (
    <div>
      <FormAutosuggestTextField
          {...other}
          // @ts-ignore
          getSuggestionText={(suggestion) => {
            if (suggestion == null) {
              return '';
            }

            return formatSuggestion(suggestion);
          }}
          // @ts-ignore
          suggest={(text) => suggestContact(apiClient, contactType, text)}
          // @ts-ignore
          suggestionComponent={({ suggestion }) => {
            return (
                <ListItemText primary={formatSuggestion(suggestion)} />
            )
          }}
          // @ts-ignore
          InputProps={resolveInputProps()}
      />
    </div>
  );
}

export default ContactFormAutosuggestTextField;
