import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  makeStyles
} from "@material-ui/core";

import VehicleDriverEditorSelect from "./VehicleDriverEditorSelect";

const useStyles = makeStyles(({ spacing }) => ({
  paper: {
    width: spacing(50),
    height: spacing(60)
  }
}));

const VehicleDriverEditor = ({
  driver: initialDriver,
  isOpen,
  onClose,
  onSubmit
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [driver, setDriver] = useState(initialDriver);

  const handleDriverChange = value => setDriver(value);

  const handleSubmitButtonClick = () => {
    if (onSubmit) {
      onSubmit(driver);
    }
  };

  useEffect(() => {
    setDriver(initialDriver);
  }, [isOpen]);

  return (
    <Dialog
      classes={{
        paper: classes.paper
      }}
      open={isOpen}
      onClose={onClose}
    >
      <DialogTitle>
        {t("webapp:vehicle.title.assign-driver")}
      </DialogTitle>
      <VehicleDriverEditorSelect value={driver} onChange={handleDriverChange} />
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          {t("webapp:common.title.cancel")}
        </Button>
        <Button color="primary" onClick={handleSubmitButtonClick}>
          {t("webapp:common.title.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default VehicleDriverEditor;
