import React from "react";

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText
} from "@material-ui/core";

import { Form, FormValues } from "../../form";

interface FormCheckboxProps<T extends FormValues> {
  form: Form<T>;
  name: keyof T;
  label?: string;
  isDisabled?: boolean;
  hasFullWidth?: boolean;
  color?: "default" | "primary" | "secondary";
}

function FormCheckbox<T extends FormValues>(
  {
    form: {
      errors,
      touches,
      values,
      handleBlur,
      handleChange
    },
    name,
    label,
    isDisabled,
    hasFullWidth,
    color
  }: FormCheckboxProps<T>
): React.ReactElement {
  const isTouched = touches[name];
  const error = isTouched ? errors[name] : undefined;
  const hasError = error !== undefined;
  const errorMessage = error?.message || undefined;
  const isChecked = Boolean(values[name]);

  return (
    <FormControl error={hasError} fullWidth={hasFullWidth}>
      <FormControlLabel
        label={label}
        control={(
          <Checkbox
            color={color}
            name={name as unknown as string}
            checked={isChecked}
            disabled={isDisabled}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        )}
      />
      <FormHelperText>{errorMessage ?? error?.name ?? " "}</FormHelperText>
    </FormControl>
  );
}

export default FormCheckbox;
