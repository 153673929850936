import { useState, useEffect } from "react";

import {
  Optional,
  ValueError,
  fromEntries,
  hasProperty,
  usePrevious
} from "@cargotic/common-deprecated";

import {
  Form,
  FormInputElement,
  FormOptions,
  FormValidationError,
  FormValues,
  FormErrors
} from "../form";

function useForm<T extends FormValues>(
  {
    initialValues,
    validate,
    onChange,
    onBlur,
    onSubmit
  }: FormOptions<T>
): Form<T> {
  const [changed, setChanged] = useState(false);
  const [errors, setErrors] = useState({});
  const [touches, setTouches] = useState({});
  const [values, setValues] = useState(initialValues);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const oldValues = usePrevious(values);

  const reset = (): void => {
    setChanged(false);
    setErrors({});
    setTouches({});
    setValues(initialValues);
    setIsSubmitting(false);
  };

  const touchAll = (): void => {
    setTouches(
      fromEntries(
        Object.entries(values)
          .map(([k, _]) => [k, true])
      )
    );
  };

  const setError = (field: keyof T, error: Optional<ValueError>): void => {
    setErrors((current) => ({ ...current, [field]: error }));
  };

  const setTouch = (field: keyof T, touch: Optional<boolean>): void => {
    setTouches((current) => ({ ...current, [field]: touch }));
  };

  const setValue = <K extends keyof T>(field: K, value: T[K]): void => {
    setValues((current) => ({ ...current, [field]: value }));
  };

  const handleBlur = (
    {
      target: { name: field }
    }: React.FocusEvent<FormInputElement>
  ): void => {
    if (!field || !hasProperty(values, field)) {
      return;
    }

    if (onBlur) {
      onBlur(field);
    }

    setTouch(field, true);
  };

  const handleChange = (
    {
      target: {
        type,
        name: field,
        value,
        checked: isChecked
      }
    }: React.ChangeEvent<FormInputElement>
  ): void => {
    if (!field || !hasProperty(values, field)) {
      return;
    }

    setChanged(true);
    setTouch(field, true);

    if (type === "checkbox") {
      setValue(field, isChecked as T[string]);
    } else {
      setValue(field, value as T[string]);
    }
  };

  const handleSubmit = (event: React.FormEvent): FormErrors<T> => {
    event.preventDefault();
    touchAll();

    if (!validate) {
      setIsSubmitting(true);

      if (onSubmit) {
        onSubmit(values);
      }

      return;
    }

    try {
      const validated = validate(values);
      setIsSubmitting(true);

      setErrors({});

      if (onSubmit) {
        onSubmit(validated);
      }
    } catch (error) {
      if (!(error instanceof FormValidationError)) {
        throw error;
      }

      const { errors: newErrors } = error;

      setErrors(newErrors);
      return newErrors;
    }
  };

  useEffect(() => {
    if (!validate) {
      return;
    }

    try {
      validate(values);

      setErrors({});
    } catch (error) {
      if (!(error instanceof FormValidationError)) {
        throw error;
      }

      const { errors: newErrors } = error;

      setErrors(newErrors);
    }
  }, [touches, values]);

  useEffect(() => {
    if (oldValues === undefined || !changed) {
      return;
    }

    if (onChange) {
      onChange(values, oldValues);
    }

    setChanged(false);
  }, [changed]);

  return {
    errors,
    touches,
    values,
    reset,
    setError,
    setErrors,
    setTouch,
    setTouches,
    setValue,
    setValues,
    setIsSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
    touchAll,
    isSubmitting
  };
}

export default useForm;
