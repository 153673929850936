const JourneyPlannerAction = {
  ADD_WAYPOINT: "ADD_WAYPOINT",
  CHANGE_JOURNEY: "CHANGE_JOURNEY",
  ADD_ERROR: "ADD_ERROR",

  COPY_CARGO_ITEM: "COPY_CARGO_ITEM",

  DELETE_CARGO_ITEM: "DELETE_CARGO_ITEM",
  DELETE_WAYPOINT: "DELETE_WAYPOINT",

  LOAD_CARGO_ITEM: "ADD_CARGO_ITEM",
  RELOAD_CARGO_ITEM: "RELOAD_CARGO_ITEM",
  REORDER_WAYPOINTS: "REORDER_WAYPOINTS",

  VALIDATE_FORM_DATES: "VALIDATE_FORM_DATES",
  VALIDATE_WAYPOINT_PLACE: "VALIDATE_WAYPOINT_PLACE",

  SELECT_CARGO_ITEM: "SELECT_CARGO_ITEM",
  SELECT_WAYPOINT: "SELECT_WAYPOINT",

  SET_DISTANCE_AND_DURATION: "SET_DISTANCE_AND_DURATION",
  SET_IS_PLACE_SEARCH_FAIL_ALERT_OPEN: "SET_IS_PLACE_SEARCH_FAIL_ALERT_OPEN",

  UNLOAD_CARGO_ITEM: "UNLOAD_CARGO_ITEM",
  UPDATE_CARGO_ITEM: "UPDATE_CARGO_ITEM",
  UPDATE_WAYPOINT: "UPDATE_WAYPOINT"
};

const addWaypoint = () => ({
  type: JourneyPlannerAction.ADD_WAYPOINT
});

const copyCargoItem = id => ({
  type: JourneyPlannerAction.COPY_CARGO_ITEM,
  id
});

const deleteCargoItem = (id, itemId, action) => ({
  type: JourneyPlannerAction.DELETE_CARGO_ITEM,
  id,
  itemId,
  action
});

const deleteWaypoint = index => ({
  type: JourneyPlannerAction.DELETE_WAYPOINT,
  index
});

const loadCargoItem = () => ({
  type: JourneyPlannerAction.LOAD_CARGO_ITEM
});

const validateWaypointDates = index => ({
  type: JourneyPlannerAction.VALIDATE_FORM_DATES,
  index
});

const validateWaypointPlace = index => ({
  type: JourneyPlannerAction.VALIDATE_WAYPOINT_PLACE,
  index
});

const reloadCargoItem = id => ({
  type: JourneyPlannerAction.RELOAD_CARGO_ITEM,
  id
});

const reorderWaypoints = (sourceIndex, destinationIndex) => ({
  type: JourneyPlannerAction.REORDER_WAYPOINTS,
  sourceIndex,
  destinationIndex
});

const selectCargoItem = id => ({
  type: JourneyPlannerAction.SELECT_CARGO_ITEM,
  id
});

const selectWaypoint = index => ({
  type: JourneyPlannerAction.SELECT_WAYPOINT,
  index
});

const setDistanceAndDuration = (distance, duration) => ({
  type: JourneyPlannerAction.SET_DISTANCE_AND_DURATION,
  distance,
  duration
});

const setIsPlaceSearchFailAlertOpen = isPlaceSearchFailAlertOpen => ({
  type: JourneyPlannerAction.SET_IS_PLACE_SEARCH_FAIL_ALERT_OPEN,
  isPlaceSearchFailAlertOpen
});

const unloadCargoItem = item => ({
  type: JourneyPlannerAction.UNLOAD_CARGO_ITEM,
  item
});

const updateCargoItem = (id, itemId, action, item) => ({
  type: JourneyPlannerAction.UPDATE_CARGO_ITEM,
  id,
  itemId,
  action,
  item
});

const updateWaypoint = (index, waypoint, name) => ({
  type: JourneyPlannerAction.UPDATE_WAYPOINT,
  index,
  waypoint,
  name
});

const addError = (index, name, value) => ({
  type: JourneyPlannerAction.ADD_ERROR,
  index,
  name,
  value
});

export default JourneyPlannerAction;
export {
  addWaypoint,
  addError,
  copyCargoItem,
  deleteCargoItem,
  deleteWaypoint,
  loadCargoItem,
  validateWaypointDates,
  validateWaypointPlace,
  reloadCargoItem,
  reorderWaypoints,
  selectCargoItem,
  selectWaypoint,
  setDistanceAndDuration,
  setIsPlaceSearchFailAlertOpen,
  unloadCargoItem,
  updateCargoItem,
  updateWaypoint
};
