import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import {
  createContact,
  uploadShipmentDocument,
  deleteShipmentDocument,
  setContactSharingUsers
} from "@cargotic/api-client-deprecated";

import { Currency } from "@cargotic/currency-deprecated";
import { ContactType, VehicleType } from "@cargotic/model";
import { Fab, Grid, MenuItem, Typography, makeStyles, InputAdornment } from "@material-ui/core";
import {
  AccountCircle,
  Edit as EditIcon,
  DirectionsCar,
  Work,
  CodeSharp
} from "@material-ui/icons";

import { usePrevious } from "@cargotic/common-deprecated";
import useAuth from "../../../component/hook/useAuth";

import {
  ContactFormAutosuggestTextField,
  EmployeeFormAutosuggestTextField
} from "../../../contact";

import ContactsCreateForm from "../../../component/core/Contacts/ContactsCreateForm";
import ContactsUpdateForm from "../../../component/core/Contacts/ContactsUpdateForm";
import PlaceSearchFailDialog from "../../../component/common/PlaceSearchFailDialog";
import ContactEditor from "../../../../cargotic-webapp-contact/component/ContactEditor";
import EmployeeEditor from "../../../../cargotic-webapp-contact/component/ContactEmployeeEditor"
import {
  FormikVehicleAutosuggestTextField,
  useApiClient,
  FormUserAutosuggestTextField
} from "../../../../cargotic-webapp-component";

import VehicleEditor
  from "../../../../cargotic-webapp-vehicle/component/VehicleEditor";

import { FormCheckbox, FormSelect, FormTextField, FormDatePickerWithDueAdornment, FormDatePicker } from "../../../form";

import {
  updateContact,
  readAvailableTags,
  updateEmployee,
  createEmployee,
  deleteEmployee
} from "../../../resource";
import SwipeableDialog from "../../../swipeableDialog";

import { generateUuid, formatDateTime }
  from "../../../../../multiload/cargotic-common";

import FileDropzone
  from "../../../../../multiload/cargotic-webapp/component/FileDropzone";

import FileUpload
  from "../../../../../multiload/cargotic-webapp/component/FileUpload";

import { ErrorHandler } from "../../../../cargotic-webapp-error";


const useStyles = makeStyles(({ spacing }) => ({
  root: {
    "& > section": {
      "&:not(:first-child)": {
        marginTop: spacing(4)
      },
      "& > h6": {
        marginBottom: spacing(3)
      }
    }
  },
  fab: {
    display: "flex",
    justifyContent: "center"
  }
}));

const ShipmentCarrierForm = ({
  resetWarnings,
  className,
  form,
  apiClient,
  newApiClient,
  onDocumentsChange,
  initialCustomerPaymentDueDays
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t, i18n } = useTranslation();
  const { hasPermission } = useAuth();
  const language = i18n.language === "cs" ? "CZ" : "EN";
  const client = useApiClient();
  const handleError = ErrorHandler(
    t,
    message => enqueueSnackbar(message, { variant: "error" })
  );

  const previousValues = usePrevious(form.values);

  const [
    hasDocumentFileDropzoneError,
    setHasDocumentFileDropzoneError
  ] = useState(false);

  const isVehicleComplete = useRef(false);
  const isTrailerComplete = useRef(false);
  const [selectedContact, setSelectedContact] = useState();
  const [selectedVehicle, setSelectedVehicle] = useState();
  const [isContactEditorOpen, setIsContactEditorOpen] = useState(false);
  const [isVehicleEditorOpen, setIsVehicleEditorOpen] = useState(false);
  const [sharingDialogOpen, setSharingDialogOpen] = useState(false);
  const [searchFailDialogOpen, setSearchFailDialogOpen] = useState(false);
  const [employeeEditorOpen, setEmployeeEditorOpen] = useState(false);
  const [employeeToEdit, setEmployeeToEdit] = useState({});
  const [selectedVehicleType, setSelectedVehicleType] = useState();

  const [optionTags, setOptionTags] = useState([]);
  const [sharingUsers, setSharingUsers] = useState([]);

  const canUpdateCompanyContact = hasPermission("resource.contact.company.update");

  const handleCustomerContactEditDialogClose = async () => setCustomerUpdateDialogOpen(false);

  const handleCustomerUpdateSubmit = (contact) => {
    setCustomerUpdateDialogOpen(false);
    updateContact(contact)
      .then(async () => {
        const newContact = await newApiClient.contact.findContact({ contactId: contact.id });
        form.setValue("customerContact", newContact);
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(t("contacts.error.update"), {
          variant: "error"
        });
      });
  };

  const handleDocumentDelete = async (uuid) => {
    const { url } = form.values.documents
      .find(({ uuid: other }) => other === uuid);

    if (url) {
      try {
        await deleteShipmentDocument(apiClient, url);
      } catch (error) {
        console.log(error);

        enqueueSnackbar(
          t("webapp:shipment.form.document.error.file.delete"),
          { variant: "error" }
        );

        return;
      }
    }

    form.setValues(values => ({
      ...values,
      documents: values.documents.filter(({ uuid: other }) => other !== uuid)
    }));
  };

  const handleDocumentFileDropzoneError = () =>
    setHasDocumentFileDropzoneError(true);

  const handleDocumentFilesSelect = files => {
    setHasDocumentFileDropzoneError(false);

    files.forEach(async (file) => {
      const uuid = generateUuid();
      const document = {
        uuid,
        name: file.name,
        isUploading: true
      };

      const updateDocument = update => form.setValues(values => ({
        ...values,
        documents: values.documents
          .map((other) => {
            if (other.uuid !== uuid) {
              return other;
            }

            return { ...other, ...update };
          })
      }));

      form.setValues((values) => ({
        ...values,
        documents: [...values.documents, document]
      }));

      try {
        const { url, createdAt, id } = await uploadShipmentDocument(apiClient, file);

        updateDocument({ id, url, createdAt, isUploading: false });
      } catch (error) {
        console.log(error);

        let message = t("webapp:shipment.form.document.error.upload.general");

        if (error?.response?.data?.error === "FileTooLargeError") {
          message = t("webapp:shipment.form.document.error.upload.size");
        }

        updateDocument({
          isUploading: false,
          error: message
        });
      }
    });
  };

  const fetchContact = async (contactId) => newApiClient.contact.getContact({
    contactId
  });

  const handleCustomerContactChange = async () => {
    resetWarnings();
    const { values: { customerContact } } = form;
    const { customerContact: oldCustomer } = previousValues ?? {};

    if (!customerContact || (oldCustomer && oldCustomer.id !== customerContact.id)) {
      form.setValue("customerEmployee", "");
    }

    if (typeof form.values.customerContact === "string") {
      return;
    }

    if (customerContact.paymentDueDays) {
      form.setValue("customerPaymentDueDays", customerContact.paymentDueDays);
    }

    if (customerContact?.billingAddress) {
      return;
    }

    const contact = await fetchContact(customerContact.id);

    if (contact.paymentDueDays) {
      form.setValue("customerPaymentDueDays", contact.paymentDueDays);
    }

    form.setValue("customerContact", contact);
  };

  useEffect(() => {
    handleCustomerContactChange();
  }, [form.values.customerContact]);

  useEffect(() => {
    onDocumentsChange();
  }, [form.values.documents]);

  const handleVehicleChange = async () => {
    const { driver, trailer } = form.values;
    let { vehicle } = form.values;

    if (typeof vehicle === "string") {
      isVehicleComplete.current = false;
      return;
    }

    if (typeof vehicle === "object" && !isVehicleComplete.current) {
      vehicle = await client.vehicle.getVehicle({ vehicleId: vehicle.id });

      form.setValue("vehicle", vehicle);

      isVehicleComplete.current = true;
    }

    if (typeof driver !== "object" && vehicle?.defaultDriver) {
      form.setValue("driver", vehicle.defaultDriver);
    }

    if (typeof trailer !== "object" && vehicle?.defaultTrailer) {
      form.setValue("trailer", vehicle.defaultTrailer);
    }
  };

  const handleTrailerChange = async () => {
    let { trailer } = form.values;

    if (typeof trailer === "string") {
      isTrailerComplete.current = false;
      return;
    }

    if (typeof trailer === "object" && !isTrailerComplete.current) {
      trailer = await client.vehicle.getVehicle({ vehicleId: trailer.id });

      form.setValue("trailer", trailer);

      isTrailerComplete.current = true;
    }
  }

  useEffect(() => {
    handleVehicleChange();
  }, [form.values.vehicle]);

  useEffect(() => {
    handleTrailerChange();
  }, [form.values.trailer]);

  const handleCreateCustomerSubmit = async (contact) => {
    const created = await createContact(apiClient, {
      ...contact,
      isPrivate: contact.visibility
    });

    form.setValue("customerContact", created);

    setCustomerCreateDialogOpen(false);
  }

  const handleEmployeeSubmit = async employee => {
    try {
      if (employee.id) {
        await newApiClient.contact.putContactEmployee({
          contactId: employee.contactId,
          employeeId: employee.id,
          employee: {
            email: employee.email,
            name: employee.name,
            phoneNumber: employee.phoneNumber
          }
        })
      }
      else {
        const { id } = await newApiClient.contact.postContactEmployee({
          contactId: employee.contactId,
          employee: {
            name: employee.name,
            email: employee.email,
            phoneNumber: employee.phoneNumber
          }
        });
        employee = { ...employee, id: id };
      }
      if (employee.contactId === form.values.customerContact.id) {
        form.setValue("customerEmployee", employee);
      }
      else {
        form.setValue("carrierEmployee", employee);
      }
      setEmployeeEditorOpen(false);
    } catch (err) {
      console.log(err);
      if (employee.id) {
        enqueueSnackbar(t("contacts.employees.error.update"), {
          variant: "error"
        });
      }
      else {
        enqueueSnackbar(t("contacts.employees.error.create"), {
          variant: "error"
        });
      }
    }
  }

  const handleSharingSubmit = async (users, contactId) => {
    const resultSharing = await setContactSharingUsers(apiClient, contactId, users);
    setSharingDialogOpen(false);
    setSharingUsers(users);
  };

  const disableCustomerContactButton = () => {
    const { values: { customerContact } } = form;

    if (typeof customerContact === "string" && customerContact !== "") {
      return true;
    }

    if (customerContact?.isDeleted) {
      return true;
    }

    return false;
  }

  const disableCustomerEmployeeButton = () => {
    const { values: { customerContact, customerEmployee } } = form;

    if (typeof customerContact === "string") {
      return true;
    }

    if (customerContact?.isDeleted) {
      return true;
    }

    if (typeof customerEmployee === "string" && customerEmployee !== "") {
      return true;
    }

    return false;
  };

  const handleContactEditorSubmit = async ({ id: contactId, ...contact }) => {
    setIsContactEditorOpen(false);

    try {
      let result;

      if (contactId) {
        result = await client.contact.putContact({ contactId, contact });
      } else {
        result = await client.contact.postContact({ contact });
      }

      form.setValue("customerContact", result);
    } catch (error) {
      handleError(error);
    }
  };

  const handleVehicleEditorSubmit = async vehicle => {
    setIsVehicleEditorOpen(false);

    const vehicleId = selectedVehicleType === VehicleType.CAR
      ? form.values.vehicle?.id
      : form.values.trailer?.id;

    try {
      let result = vehicleId
        ? await client.vehicle.putVehicle({ vehicleId, vehicle })
        : await client.vehicle.postVehicle({ vehicle });

      result = { ...vehicle, ...result };

      if (selectedVehicleType === VehicleType.CAR) {
        form.setValue("vehicle", result);
      }
      else {
        form.setValue("trailer", result);
      }
    } catch (error) {
      console.log(error);

      const { response: { data: errData } } = error;

      if (errData.type === "DuplicateEntityError") {
        enqueueSnackbar(
          t("vehicles.error.duplicate"),
          { variant: "error" }
        );
      }
      else if (vehicleId) {
        enqueueSnackbar(
          t("vehicles.error.update"),
          { variant: "error" }
        );
      }
      else {
        enqueueSnackbar(
          t("vehicles.error.create"),
          { variant: "error" }
        );
      }
    }
  };

  return (
    <>
      <div className={clsx(classes.root, className)}>
        <section>
          <Typography variant="subtitle2">
            {t("webapp:shipment.form.subtitle.customer")}
          </Typography>
          <Grid container spacing={2}>
            <Grid className={classes.fab} item xs={1}>
              <Fab
                size="medium"
                color="primary"
                disabled={disableCustomerContactButton()}
                onClick={() => {
                  setSelectedContact(form.values.customerContact || { type: ContactType.CUSTOMER });
                  setIsContactEditorOpen(true);
                }}
              >
                {
                  form.values.customerContact
                    ? (
                      <EditIcon />
                    ) : (
                      <AccountCircle />
                    )
                }
              </Fab>
            </Grid>
            <Grid item xs={11}>
              <ContactFormAutosuggestTextField
                form={form}
                contactType={ContactType.CUSTOMER}
                apiClient={newApiClient}
                name="customerContact"
                label={t("webapp:shipment.form.label.contact")}
                fullWidth
              />
            </Grid>
            <Grid className={classes.fab} item xs={1}>
              <Fab
                size="medium"
                color="primary"
                disabled={disableCustomerEmployeeButton()}
                onClick={() => {
                  setEmployeeToEdit({ contactId: form.values.customerContact.id, ...(form.values.customerEmployee) });
                  setEmployeeEditorOpen(true);
                }}
              >
                {
                  form.values.customerEmployee
                    ? (
                      <EditIcon />
                    ) : (
                      <Work />
                    )
                }
              </Fab>
            </Grid>
            <Grid item xs={11}>
              <EmployeeFormAutosuggestTextField
                form={form}
                apiClient={newApiClient}
                disabled={!form.values.customerContact?.id}
                contactId={form.values.customerContact?.id}
                name="customerEmployee"
                label={t("webapp:shipment.form.label.employee")}
                fullWidth
              />
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={8}>
              <FormTextField
                form={form}
                name="customerPosition"
                label={t("webapp:shipment.form.label.position")}
                hasFullWidth
                InputProps={{
                  startAdornment: <InputAdornment position="start">#</InputAdornment>
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <FormTextField
                form={form}
                name="customerPaymentDueDays"
                label={t("webapp:shipment.form.label.dueDays")}
                hasFullWidth
              />
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={5}>
              <FormTextField
                form={form}
                name="issuedInvoiceNumber"
                label={t("webapp:shipment.form.label.issuedInvoiceNumber")}
                hasFullWidth
                InputProps={{
                  startAdornment: <InputAdornment position="start">#</InputAdornment>
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <FormDatePickerWithDueAdornment
                form={form}
                name="issuedInvoiceDueDate"
                label={t("webapp:shipment.form.label.invoiceDueDate")}
                hasFullWidth
                format="dd.MM.yyyy"
              />
            </Grid>
            <Grid item xs={3}>
              <FormDatePicker
                form={form}
                name="issuedInvoicePaidAt"
                label={t("webapp:shipment.form.label.paidAt")}
                hasFullWidth
                format="dd.MM.yyyy"
              />
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={8}>
              <FormTextField
                form={form}
                name="customerPrice"
                label={t("webapp:shipment.form.label.price")}
                hasFullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <FormSelect
                form={form}
                name="customerPriceCurrency"
                label={t("webapp:shipment.form.label.currency")}
                hasFullWidth
              >
                {
                  Object.values(Currency).map((currency) => (
                    <MenuItem key={currency} value={currency}>
                      {currency}
                    </MenuItem>
                  ))
                }
              </FormSelect>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={11}>
              <FormCheckbox
                form={form}
                name="isCustomerPriceWithDph"
                label={t("webapp:shipment.form.label.isWithDph")}
                color="primary"
              />
            </Grid>



            <Grid item xs={1} />
            <Grid item xs={8}>
              <FormTextField
                  form={form}
                  name="additionalInsurancePrice"
                  label={t("webapp:shipment.form.label.additionalInsurance")}
                  hasFullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <FormSelect
                  form={form}
                  name="additionalInsuranceCurrency"
                  label={t("webapp:shipment.form.label.currency")}
                  hasFullWidth
              >
                {
                  Object.values(Currency).map((currency) => (
                      <MenuItem key={currency} value={currency}>
                        {currency}
                      </MenuItem>
                  ))
                }
              </FormSelect>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={8}>
              <FormTextField
                  form={form}
                  name="storagePrice"
                  label={t("webapp:shipment.form.label.storagePrice")}
                  hasFullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <FormSelect
                  form={form}
                  name="storagePriceCurrency"
                  label={t("webapp:shipment.form.label.currency")}
                  hasFullWidth
              >
                {
                  Object.values(Currency).map((currency) => (
                      <MenuItem key={currency} value={currency}>
                        {currency}
                      </MenuItem>
                  ))
                }
              </FormSelect>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={8}>
              <FormTextField
                  form={form}
                  name="differentServicePrice"
                  label={t("webapp:shipment.form.label.differentServicePrice")}
                  hasFullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <FormSelect
                  form={form}
                  name="differentServiceCurrency"
                  label={t("webapp:shipment.form.label.currency")}
                  hasFullWidth
              >
                {
                  Object.values(Currency).map((currency) => (
                      <MenuItem key={currency} value={currency}>
                        {currency}
                      </MenuItem>
                  ))
                }
              </FormSelect>
            </Grid>





          </Grid>
        </section>
        <section>
          <Typography variant="subtitle2">
            {t("webapp:shipment.form.subtitle.shipping")}
          </Typography>
          <Grid container spacing={2}>
            <Grid className={classes.fab} item xs={1}>
              <Fab
                size="medium"
                color="primary"
                onClick={() => {
                  setIsVehicleEditorOpen(true);
                  setSelectedVehicle(
                    typeof form.values.vehicle !== "string"
                      ? form.values.vehicle
                      : {}
                  );
                  setSelectedVehicleType(VehicleType.CAR);
                }}
              >
                {
                  typeof form.values.vehicle === "string"
                    ? (
                      <DirectionsCar />
                    ) : (
                      <EditIcon />
                    )
                }
              </Fab>
            </Grid>
            <Grid item xs={11}>
              <FormikVehicleAutosuggestTextField
                form={form}
                name="vehicle"
                type={VehicleType.CAR}
                label={t("webapp:shipment.form.label.vehicle")}
                fullWidth
              />
            </Grid>
            <Grid className={classes.fab} item xs={1}>
              <Fab
                size="medium"
                color="primary"
                onClick={() => {
                  setIsVehicleEditorOpen(true);
                  setSelectedVehicle(
                    typeof form.values.trailer !== "string"
                      ? form.values.trailer
                      : {}
                  );
                  setSelectedVehicleType(VehicleType.TRAILER);
                }}
              >
                {
                  typeof form.values.trailer === "string"
                    ? (
                      <DirectionsCar />
                    ) : (
                      <EditIcon />
                    )
                }
              </Fab>
            </Grid>
            <Grid item xs={11}>
              <FormikVehicleAutosuggestTextField
                form={form}
                name="trailer"
                type={VehicleType.TRAILER}
                label={t("webapp:shipment.form.label.trailer")}
                fullWidth
              />
            </Grid>
            <Grid className={classes.fab} item xs={1}>
              <Fab size="medium" color="primary" disabled>
                <AccountCircle />
              </Fab>
            </Grid>
            <Grid item xs={11}>
              <FormUserAutosuggestTextField
                form={form}
                role="DRIVER"
                name="driver"
                label={t("webapp:shipment.form.label.driver")}
                fullWidth
              />
            </Grid>
          </Grid>
        </section>
        <section>
          <Typography variant="subtitle2">
            {t("webapp:shipment.form.subtitle.documents")}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={1} />
            <Grid item xs={11}>
              <FormTextField
                form={form}
                name="notes"
                label={t("webapp:shipment.form.label.notes")}
                variant="outlined"
                rows={10}
                isMultiline
                hasFullWidth
              />
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={11}>
              <FileDropzone
                accept={["application/pdf", "image/jpeg", "image/png"]}
                title={
                  hasDocumentFileDropzoneError
                    ? t("webapp:shipment.form.document.error.file.type")
                    : t("webapp:shipment.form.document.title.upload")
                }
                description={[
                  t("webapp:shipment.form.document.description.about"),
                  t("webapp:shipment.form.document.description.supported"),
                  t("webapp:shipment.form.document.description.limit")
                ]}
                hasError={hasDocumentFileDropzoneError}
                onError={handleDocumentFileDropzoneError}
                onSelect={handleDocumentFilesSelect}
              />
            </Grid>
            {
              form.values.documents.map(({ uuid, name, url, error, isUploading, createdAt }, index) => (
                <React.Fragment key={uuid}>
                  <Grid item xs={1} />
                  <Grid item xs={11}>
                    <FileUpload
                      className={index === 0 ? classes.upload : undefined}
                      title={name}
                      error={error}
                      description={
                        createdAt
                          ? formatDateTime(createdAt)
                          : undefined
                      }
                      url={url}
                      isUploading={isUploading}
                      onDelete={() => handleDocumentDelete(uuid)}
                    />
                  </Grid>
                </React.Fragment>
              ))
            }
          </Grid>
        </section>
      </div>
      <ContactEditor
        initialValue={selectedContact}
        isOpen={isContactEditorOpen}
        type={ContactType.CUSTOMER}
        onClose={() => setIsContactEditorOpen(false)}
        onSubmit={handleContactEditorSubmit}
      />
      <VehicleEditor
        initialValue={selectedVehicle}
        isOpen={isVehicleEditorOpen}
        type={selectedVehicleType}
        onClose={() => setIsVehicleEditorOpen(false)}
        onSubmit={handleVehicleEditorSubmit}
      />
      <PlaceSearchFailDialog
        isOpen={searchFailDialogOpen}
        onClose={() => setSearchFailDialogOpen(false)}
      />
      <EmployeeEditor
        initialValue={employeeToEdit}
        isOpen={employeeEditorOpen}
        onClose={() => setEmployeeEditorOpen(false)}
        onSubmit={handleEmployeeSubmit}
      />
    </>
  );
};

export default ShipmentCarrierForm;
