import React, { useMemo, useState } from "react";

import {
  Dialog,
  IconButton,
  makeStyles,
  Box
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import ColumnsSelection from "./ColumnsSelection";
import ChangeOrder from "./ChangeOrder";

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    margin: 0,
    padding: spacing(2)
  },
  actions: {
    justifyContent: "flex-start",
    alignItems: "center",
    display: "flex"
  },
  closeButton: {
    position: "absolute",
    right: spacing(1),
    width: 48,
    color: palette.grey[500]
  },
  textContent: {
    paddingLeft: spacing(3),
    paddingRight: spacing(3),
    "& > ul": {
      paddingLeft: spacing(2),
      paddingRight: spacing(2)
    },
    "& > p": {
      paddingLeft: spacing(2),
      paddingRight: spacing(2)
    }
  },
  content: {
    padding: 0
  },
  box: {
    display: "flex",
    padding: spacing(3, 7.5, 4, 2.5)
  },
  socialIcon: {
    width: 25,
    height: 25,
    color: "rgba(0, 0, 0, 0.54)"
  }
}));

const SelectColumnsDialog = ({
  selectedColumns,
  additionalColumns,
  onChangeSelectedColumns,
  open,
  setDialogOpen,
  showCloseButton,
  headers
}) => {
  const classes = useStyles();

  const columns = useMemo(
    () => (
      headers
        .map(header => (
          {
            name: header.name,
            label: header.label,
            isActive: !selectedColumns || !!selectedColumns.find(column => header.name === column)
          }
        ))
        .filter(column => !additionalColumns.find(additional => column.name === additional))
    ),
    [headers, selectedColumns, additionalColumns]
  );

  const orderedColumns = useMemo(
    () => (
      selectedColumns
        ? selectedColumns.filter((e) => e !== undefined)
          .map(column => columns.find(entry => entry.name === column)).filter((e) => e !== undefined)
          .filter(column => column.isActive)
        : columns
    ),
    [headers, columns]
  );

  const setOrderedColumns = (columns) => onChangeSelectedColumns(columns.map(({ name }) => name));

  const setActive = (name, isActive) => {
    if (isActive) {
      onChangeSelectedColumns(
        selectedColumns ? [...selectedColumns, name] : [name]
      );
    } else {
      onChangeSelectedColumns(
        selectedColumns
          ? selectedColumns.filter(column => column !== name)
          : columns
            .map(column => column.name)
            .filter(column => column !== name)
      );
    }
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  return (
    <div>
      <Dialog open={open} maxWidth="lg" fullWidth>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <Close />
        </IconButton>

        <Box className={classes.box}>
          <ColumnsSelection
            columns={columns}
            setColumns={onChangeSelectedColumns}
            setActive={setActive}
          />
          <ChangeOrder
            columns={orderedColumns}
            setColumns={setOrderedColumns}
          />
        </Box>
      </Dialog>
    </div>
  );
};

export default SelectColumnsDialog;
