import { ApiClientProvider } from "@cargotic/api-client-deprecated";
import { Currency, ExchangeRatesProvider } from "../../../../cargotic-currency";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import { LocalizationProvider } from "@material-ui/pickers";
import { cs, enUS } from "date-fns/locale";
import DateFnsAdapter from "@material-ui/pickers/adapter/date-fns";

import { I18nextProvider, useTranslation } from "react-i18next";

import React, { useEffect } from "react";
import { DndProvider } from "react-dnd";
import Html5Backend from "react-dnd-html5-backend";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import client, { axios } from "../../../client";
import i18n from "../../../i18n";
import config from "../../../config";

import "@fontsource/barlow/400.css";
import "@fontsource/barlow/500.css";
import "@fontsource/barlow/300.css";
import "@fontsource/barlow/600.css";
import "@fontsource/barlow/700.css";

import AuthProvider from "../AuthProvider";
import UserProfileProvider from "../UserProfileProvider";
import ShipmentConceptProvider from "../ShipmentConceptProvider";
import BoltClientProvider from "../BoltClientProvider";

import App from "./App";
import store from "./store";
import theme from "./theme";
import { GoogleMapsApiProvider } from "../../../../../multiload/cargotic-map";
import { ApiClientProvider as ApiClientProvider2 } from "../../../../cargotic-webapp-component";


const AppContainer = () => {
  const Content = () => {
    const { i18n } = useTranslation();
    const pickerLocale = i18n.language === "cs" || i18n.language === "cs-CZ"
      ? cs
      : enUS;

    useEffect(() => {
      if (pickerLocale === cs) {
        axios.defaults.headers["Accept-Language"] = "cz";
      } else {
        axios.defaults.headers["Accept-Language"] = "en";
      }
    }, [pickerLocale]);

    return (
      <LocalizationProvider dateAdapter={DateFnsAdapter} locale={pickerLocale}>
        <App />
      </LocalizationProvider>
    );
  };

  return (
    <ApiClientProvider2 client={client}>
      <ApiClientProvider client={{ baseUrl: config.api.url }}>
        <ThemeProvider theme={theme}>
          <I18nextProvider i18n={i18n}>
            <BrowserRouter>
              <SnackbarProvider>
                <AuthProvider>
                  <BoltClientProvider>
                    <UserProfileProvider>
                      <ShipmentConceptProvider>
                        <Provider store={store}>
                          <GoogleMapsApiProvider
                            apiKey={config.googleMaps.apiKey}
                            libraries={["places"]}
                          >
                            <ExchangeRatesProvider baseCurrencyEUR={Currency.EUR} baseCurrency={Currency.CZK}>
                              <DndProvider backend={Html5Backend}>
                                <CssBaseline />
                                <Content />
                              </DndProvider>
                            </ExchangeRatesProvider>
                          </GoogleMapsApiProvider>
                        </Provider>
                      </ShipmentConceptProvider>
                    </UserProfileProvider>
                  </BoltClientProvider>
                </AuthProvider>
              </SnackbarProvider>
            </BrowserRouter>
          </I18nextProvider>
        </ThemeProvider>
      </ApiClientProvider>
    </ApiClientProvider2>
  );
};

export default AppContainer;
