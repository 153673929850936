const CargoItemTemplate = {
  EUR_PALLET: "EUR_PALLET",
  US_PALLET: "US_PALLET",
  HALF_EUR_PALLET: "HALF_EUR_PALLET",
  LDM: "LDM",
  VAN: "VAN",
  SOLO: "SOLO",
  LKW: "LKW",
  MEGA: "MEGA",
  TANDEM: "TANDEM",
  BOX_SEMI_TRAILER: "BOX_SEMI_TRAILER",
  FRIGO_SEMI_TRAILER: "FRIGO_SEMI_TRAILER"
};

export default CargoItemTemplate;
