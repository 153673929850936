import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  SessionExpiredError,
  FirebaseSessionExpiredError,
  signIn,
  signOut,
  signUp,
  resetPassword,
  resendVerificationMail,
  restoreSession,
  reloadSession
} from "../../../auth";
import AuthContext from "../../context/AuthContext";
import useRouter from "../../hook/useRouter";
import { FirebaseErrorCodes, ServerErrorCodes } from "../../enums/enums";

const AuthProvider = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(false);
  const [authenticating, setAuthenticating] = useState(true);
  const [user, setUser] = useState(null);

  const { history } = useRouter();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const hasPermission = permission => user.permissions.includes(permission);

  const signInAndUpdate = (email, password, remember) => {
    setAuthenticating(true);

    return signIn(email, password, remember)
      .then((signedUsed) => {
        setUser(signedUsed);
        setAuthenticated(true);
        return signedUsed.id;
      })
      .catch((error) => {
        if (error.response) {
          error = error.response.data;
        }
        console.error(error);

        let errorMessage = t("auth.error.signIn");
        switch (error.code) {
          case "auth.error.platformPermission":
            errorMessage = t("auth.error.platformPermission");
            break;
          case ServerErrorCodes.DELETED_COMPANY:
            errorMessage = t("auth.error.deletedCompany");
            break;
          case FirebaseErrorCodes.DISABLED_USER:
            errorMessage = t("auth.error.deletedUser");
            break;
          case FirebaseErrorCodes.USER_NOT_FOUND:
            errorMessage = t("auth.error.badEmail");
            break;
          case FirebaseErrorCodes.WRONG_PASSWORD:
            errorMessage = t("auth.error.badPassword");
            break;
          default:
            errorMessage = t("auth.error.general");
            break;
        }

        enqueueSnackbar(
          errorMessage,
          { variant: "error" }
        );
      })
      .finally(() => setAuthenticating(false));
  };

  const signOutAndUpdate = () => {
    setAuthenticating(true);

    return signOut()
      .then(() => {
        setUser(null);
        setAuthenticated(false);
        setAuthenticating(false);
      })
      .catch((error) => {
        console.log(error);

        enqueueSnackbar(
          t("auth.error.signOut"),
          { variant: "error" }
        );
      });
  };

  const signUpAndUpdate = ({ firstName, lastName, email, password, companyName, ic, dic, tariffType, countUsers, isSubscriber }) => {
    setAuthenticating(true);

    return signUp({ firstName, lastName, email, password, companyName, ic, dic, tariffType, countUsers, isSubscriber })
      .then(() => {
        enqueueSnackbar(t("auth.success.signUp"), { variant: "success" });
        history.push("/");
      })
      .catch((error) => {
        console.log(error);

        let message = t("auth.error.general");
        if (error.code === "auth/email-already-in-use") {
          message = t("auth.error.emailAlreadyExists");
        } else if (error?.response?.data?.error === "DataIntegrityError") {
          const { field } = error.response.data;

          if (field === "ic") {
            message = t("auth.error.icAlreadyExists");
          } else if (field === "dic") {
            message = t("auth.error.dicAlreadyExists");
          }
        }

        enqueueSnackbar(
          message,
          { variant: "error" }
        );
      })
      .finally(() => setAuthenticating(false));
  };

  const resendVerificationMail = () => resendVerificationMail().then(() => {
    enqueueSnackbar(t("auth.success.verificationMail"), { variant: "success" });
  }).catch((error) => {
    console.error(error);

    enqueueSnackbar(t("auth.error.resetPassword"), { variant: "error" });
  });

  const resetPasswordAndUpdate = email => resetPassword(email).then(() => {
    enqueueSnackbar(t("auth.success.resetPassword"), { variant: "success" });
    history.push("/");
  })
    .catch((error) => {
      console.error(error);

      enqueueSnackbar(t("auth.error.resetPassword"), { variant: "error" });
    });

  const reload = () => {
    setAuthenticating(true);
    setAuthenticated(false);

    reloadSession()
      .then((user) => {
        setUser(user);

        setAuthenticated(true);
        setAuthenticating(false);
      });
  };

  useEffect(() => {
    restoreSession()
      .then((me) => {
        setUser(me);
        setAuthenticated(true);
      })
      .catch((error) => {
        if (error instanceof SessionExpiredError || error instanceof FirebaseSessionExpiredError) {
          return;
        }

        console.log(error);
      })
      .finally(() => setAuthenticating(false));
  }, []);

  return (
    <AuthContext.Provider value={{
      authenticated,
      authenticating,
      user,
      hasPermission,
      signIn: signInAndUpdate,
      signOut: signOutAndUpdate,
      signUp: signUpAndUpdate,
      reload,
      resetPassword: resetPasswordAndUpdate,
      resendVerificationMail
    }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
