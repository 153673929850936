const config = {
  api: {
    url: process.env.CW_API_URL
  },
  bolt: {
    url: process.env.CW_BOLT_URL
  },
  firebase: {
    apiKey: process.env.CW_FIREBASE_API_KEY,
    authDomain: process.env.CW_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.CW_FIREBASE_PROJECT_ID,
    storageBucket: process.env.CW_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.CW_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.CW_FIREBASE_APP_ID
  },
  googleMaps: {
    apiKey: process.env.CW_GM_API_KEY,
    url: `https://maps.googleapis.com/maps/api/js?key=${process.env.CW_GM_API_KEY}&libraries=places&language=cs`
  },
  landingPage: {
    url: process.env.CW_LANDING_PAGE_URL
  }
};

export default config;
