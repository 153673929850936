import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import queryString from "query-string";
import { startOfWeek, endOfWeek, isValid } from "date-fns";
import { cs, enUS } from "date-fns/locale";

import { ShipmentEventType, ShipmentState } from "@cargotic/model";
import {
  fetchShipmentById,
  useApiClient as useOldApiClient,
  updateShipmentById
} from "@cargotic/api-client-deprecated";

import { FormatListBulleted, AssignmentReturnRounded } from "@material-ui/icons";
import ShipmentBoard from "./ShipmentBoard";
import useAuth from "../../hook/useAuth";
import useRouter from "../../hook/useRouter";
import {
  updateShipmentState,
  deleteShipment
} from "../../../resource";
import ShipmentsDeleteDialog from "../Shipments/ShipmentsDeleteDialog";
import BoardEditForm from "./BoardEditForm";
import SwipeableDialog from "../../../swipeableDialog";
import useBoltClient from "../../hook/useBoltClient";
import { getWeekRangesSinceDateTillToday } from "../../../utility/common";
import FilterSettings from "../../../../cargotic-webapp-filter/component/FilterSettings";

import {
  addUrlParam,
  getTableUrlParams
} from "../../../utility/window";

import { useApiClient } from "../../../../cargotic-webapp-component";
import {
  storeFilters,
  loadFilters
} from "../../../storage";

const ShipmentBoardContainer = () => {
  const { user, hasPermission } = useAuth();
  const { history, location: { search: searchParams, pathname } } = useRouter();
  const {
    searchText: initSearchText,
    filter: initFilter
  } = getTableUrlParams(searchParams);

  const { i18n, t } = useTranslation();
  const pickerLocale = i18n.language === "cs" || i18n.language === "cs-CZ"
    ? cs
    : enUS;

  const { enqueueSnackbar } = useSnackbar();
  const apiClient = useApiClient();
  const oldApiClient = useOldApiClient();

  const path = pathname.split("/");
  const pathShipmentId = parseInt(path[2]);

  const hasCompanyReadPermission = hasPermission("resource.shipment.company.read");
  const hasCreateShipmentPermission = hasPermission("resource.shipment.user.create");
  const hasUpdateShipmentPermission = hasPermission("resource.shipment.user.update");
  const hasDeleteShipmentPermission = hasPermission("resource.shipment.user.delete");
  const canReadCompanyShipment = hasPermission("resource.shipment.company.read");

  const { isReady: isBoltClientReady, client: boltClient } = useBoltClient();
  const reloading = useRef(false);
  const [search, setSearch] = useState(initSearchText);
  const [shipments, setShipments] = useState({});
  const [additionalFilter, setAdditionalFilter] = useState({ createdAt: { from: startOfWeek(new Date(), { locale: pickerLocale }), to: endOfWeek(new Date(), { locale: pickerLocale }) }, ...initFilter });
  const [loading, setLoading] = useState(true);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedShipment, setSelectedShipment] = useState(undefined);
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  const [isFilterSettingsOpen, setIsFilterSettingsOpen] = useState(false);
  const [defaultFilters, setDefaultFilters] = useState([]);

  const handleDeselect = () => setAdditionalFilter({});

  const handleFilterSettingsClose = () => setIsFilterSettingsOpen(false);

  const handleFilterSettingsOpen = () => setIsFilterSettingsOpen(true);

  const states = [ShipmentState.QUEUE, ShipmentState.READY, ShipmentState.IN_PROGRESS, ShipmentState.DONE];

  const expandFilters = (values, fullValues) => values.map(item => fullValues.find(i => i.value === item));

  const onFilterSettingsSubmit = async value => {
    setIsFilterSettingsOpen(true);
    storeFilters("board", value);
    setDefaultFilters(expandFilters(value, availableFilters));
    setIsFilterSettingsOpen(false);
  };

  const handleSelectLoadingDate = (loadingDate) => setAdditionalFilter({ ...additionalFilter, loadingDate });
  const handleSelectUnloadingDate = (unloadingDate) => setAdditionalFilter({ ...additionalFilter, unloadingDate });
  const handleSelectCreatedAt = (createdAt) => setAdditionalFilter({ ...additionalFilter, createdAt });
  const handleSelectShipmentType = (types) => (setAdditionalFilter({ ...additionalFilter, types }));
  const handleSelectCustomers = (customers) => setAdditionalFilter({ ...additionalFilter, customers });
  const handleSelectCarriers = (carriers) => setAdditionalFilter({ ...additionalFilter, carriers });
  const handleSelectCustomerPrice = (customerPrice) => setAdditionalFilter({ ...additionalFilter, customerPrice });
  const handleSelectCarrierPrice = (carrierPrice) => setAdditionalFilter({ ...additionalFilter, carrierPrice });
  const handleSelectCommission = (commission) => setAdditionalFilter({ ...additionalFilter, commission });
  const handleSelectCreators = (creators) => setAdditionalFilter({ ...additionalFilter, creators });
  const handleSelectDrivers = (drivers) => setAdditionalFilter({ ...additionalFilter, drivers });
  const handleSelectCargoTemplate = (cargo) => setAdditionalFilter({ ...additionalFilter, cargo });
  const handleSelectIsDraft = (isDraft) => setAdditionalFilter({ ...additionalFilter, isDraft });
  const handleSelectIssuedPaymentState = (issuedPaymentState) => setAdditionalFilter({ ...additionalFilter, issuedPaymentState });
  const handleSelectReceivedPaymentState = (receivedPaymentState) => setAdditionalFilter({ ...additionalFilter, receivedPaymentState });
  const handleSelectVehicles = (vehicles) => setAdditionalFilter({ ...additionalFilter, vehicles });

  const totalShipmentWarnings = ({ journey: { waypoints } }) => waypoints.filter(({ hasWarning }) => hasWarning).length;

  const totalWaypointsErrors = ({ journey: { waypoints } }) => waypoints.filter(({ hasError }) => hasError).length;

  const hasShipmentError = (shipment, state) => {
    return false;
    switch (state) {
      case ShipmentState.QUEUE:
        return !shipment.isDraft;
      case ShipmentState.READY:
        return shipment.journey.waypoints[0].arriveAtFrom < new Date();
    }
    return false;
  };

  let reloadDelay;
  let storeSearchDelay;
  const timeout = useRef();

  const reloadShipments = async () => {
    if (!reloading.current) {
      reloading.current = true;
      setLoading(true);
      const filter = transformFilter(additionalFilter);
      const result = await apiClient.shipment.postShipmentBoardMatchQuery({
        query: {
          match: {
            search,
            ...filter
          },
          states,
          limit: 50
        }
      });
      setShipments(result);
      setLoading(false);
      reloading.current = false;
    }
  };

  const transformFilter = (filter) => ({
    ...filter,
    creators: filter.creators ? filter.creators.map(({ id }) => id) : undefined,
    drivers: filter.drivers ? filter.drivers.map(({ id }) => id) : undefined,
    carriers: filter.carriers ? filter.carriers.map(({ id }) => id) : undefined,
    customers: filter.customers ? filter.customers.map(({ id }) => id) : undefined,
    vehicles: filter.vehicles ? filter.vehicles.map(({ id }) => id) : undefined
  });

  const loadAdditionalShipments = async (startIndex, stopIndex, state) => {
    const filter = transformFilter(additionalFilter);

    const result = await apiClient.shipment.postShipmentBoardMatchQuery({
      query: {
        match: {
          search,
          ...filter
        },
        states: [state],
        offset: startIndex,
        limit: stopIndex - startIndex + 1
      }
    });

    setShipments(() => ({
      ...shipments,
      [state]: {
        total: result[state].total,
        totalWarnings: shipments[state].totalWarnings + result[state].totalWarnings,
        totalErrors: shipments[state].totalErrors + result[state].totalErrors,
        matches: [...shipments[state].matches, ...result[state].matches]
      }
    }));
  };

  const defaultFilterValues = ["types", "loadingDate", "unloadingDate", "creators", "createdAt", "customers", "carriers"];
  const availableFilters = [
    {
      label: t("statistics.shipmentType"),
      value: "types"
    }, {
      label: t("shipments.loadingsDateRange"),
      value: "loadingDate"
    }, {
      label: t("shipments.unloadingsDateRange"),
      value: "unloadingDate"
    }, {
      label: t("shipments.creator"),
      value: "creators"
    }, {
      label: t("shipments.creationDate"),
      value: "createdAt"
    }, {
      label: t("contacts.customer"),
      value: "customers"
    }, {
      label: t("contacts.carrier"),
      value: "carriers"
    }, {
      label: t("shipments.customerPrice"),
      value: "customerPrice"
    }, {
      label: t("shipments.carrierPrice"),
      value: "carrierPrice"
    }, {
      label: t("shipments.commission"),
      value: "commission"
    },
    {
      label: t("shipments.cargo"),
      value: "cargo"
    },
    {
      label: t("shipments.driver"),
      value: "drivers"
    },
    {
      label: t("shipments.draft"),
      value: "isDraft"
    },
    {
      label: t("shipments.vehicles"),
      value: "vehicles"
    },
    {
      label: t("shipments.issuedPaymentState"),
      value: "issuedPaymentState"
    },
    {
      label: t("shipments.receivedPaymentState"),
      value: "receivedPaymentState"
    }
  ];

  useEffect(() => {
    const loadedFilters = loadFilters("board");
    if (loadedFilters.length === 0) {
      setDefaultFilters(expandFilters(defaultFilterValues, availableFilters));
    } else {
      setDefaultFilters(expandFilters(loadedFilters, availableFilters));
    }
  }, []);

  useEffect(() => {
    if (!isBoltClientReady) {
      return;
    }

    const loadedFilters = loadFilters("board");
    if (loadedFilters.length === 0) {
      setDefaultFilters(expandFilters(defaultFilterValues, availableFilters));
    } else {
      setDefaultFilters(expandFilters(loadedFilters, availableFilters));
    }
  }, []);

  useEffect(() => {
    reloadShipments();
  }, [additionalFilter, search, isBoltClientReady]);

  const handleSearch = (_search) => {
    clearTimeout(reloadDelay);
    reloadDelay = setTimeout(() => {
      setSearch(_search);
    }, 250);
  };

  const onShipmentClick = async (event, shipmentId, state) => {
    setLoadingDetails(true);
    try {
      const shipment = await fetchShipmentById(oldApiClient, shipmentId);
      setSelectedShipment({ ...shipment, state });
      setEditDialogOpen(true);
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t("orders.error.loadingOrder"), {
        variant: "error"
      });
    }
    setLoadingDetails(false);
  };

  const handleIsPersonal = (value) => {
    const search = queryString.stringify({
      ...queryString.parse(searchParams),
      onlyPersonal: value
    });

    history.push({ search });
  };

  const getStateOfShipment = (shipmentId) => states.reduce((acc, state) => (shipmentsRef.current[state].matches.find(({ id }) => id === shipmentId) ? state : acc), undefined);

  const calculateShipmentStatus = ({ state, isDraft, journey: { waypoints } }) => {
    const add = (a, b) => a + b;
    const now = new Date();

    const [{ arriveAtFrom: firstArriveAtFrom }] = waypoints;
    const warnings = waypoints
      .map(({ hasWarning }) => hasWarning)
      .reduce(add, 0);

    let errors = waypoints
      .map(({ hasError }) => hasError)
      .reduce(add, 0);

    // inc later
    if (state === "QUEUE" && !isDraft) {
      errors += 0;
    }
    if (state === "READY" && now > firstArriveAtFrom) {
      errors += 0;
    }

    return { errors, warnings };
  };

  const shipmentsRef = useRef({});
  useEffect(() => {
    shipmentsRef.current = shipments;
  }, [shipments]);

  const createdAtRef = useRef({});
  useEffect(() => {
    createdAtRef.current = additionalFilter?.createdAt;
  }, [additionalFilter]);

  const handleRemoteShipmentChange = async (oldShipment, newShipment) => {
    const { id: shipmentId } = oldShipment;

    const shipments = shipmentsRef.current;
    let isCurrentPeriod = !createdAtRef.current?.from && !createdAtRef.current?.to;

    for (let period of oldShipment.periods) {
      period = new Date(period);
      if (period >= new Date(createdAtRef.current?.from) && period <= new Date(createdAtRef.current?.to)) {
        isCurrentPeriod = true;
        break;
      }
    }

    if (!isCurrentPeriod) {
      return;
    }

    // TODO - filter received shipments by filters

    const { errors: originalErrors, warnings: originalWarnings } = calculateShipmentStatus(oldShipment);
    const { errors: newErrors, warnings: newWarnings } = calculateShipmentStatus(newShipment);

    const oldState = getStateOfShipment(shipmentId);

    const originalStateShipments = shipments[oldState];
    const newStateShipments = shipments[newShipment.state];

    const filteredShipments = shipments[oldState].matches.filter(({ id }) => id !== shipmentId);
    const newShipments = newStateShipments.matches.filter(({ id }) => id !== shipmentId);

    if (oldState === undefined) {
      updateShipmentsSameState({
        destination: {
          state: newShipment.state,
          total: newStateShipments.total,
          errors: newStateShipments.totalErrors - originalErrors + newErrors,
          warnings: newStateShipments.totalWarnings - originalWarnings + newWarnings,
          matches: [
            ...newShipments,
            newShipment
          ]
        }
      });
    }

    if (oldState !== newShipment.state) {
      updateShipments({
        origin: {
          state: oldState,
          total: originalStateShipments.total - 1,
          errors: originalStateShipments.totalErrors - originalErrors,
          warnings: originalStateShipments.totalWarnings - originalWarnings,
          matches: filteredShipments
        },
        destination: {
          state: newShipment.state,
          total: newStateShipments.total + 1,
          errors: newStateShipments.totalErrors + newErrors,
          warnings: newStateShipments.totalWarnings + newWarnings,
          matches: [
            ...newShipments,
            newShipment
          ]
        }
      });
    } else {
      updateShipmentsSameState({
        destination: {
          state: newShipment.state,
          total: newStateShipments.total,
          errors: newStateShipments.totalErrors - originalErrors + newErrors,
          warnings: newStateShipments.totalWarnings - originalWarnings + newWarnings,
          matches: newStateShipments.matches.map((shipment) => (shipment.id !== shipmentId ? shipment : newShipment))
        }
      });
    }
  };

  const updateShipments = ({ origin, destination }) => {
    const {
      state: originalState,
      total: originalCount,
      matches: originalShipments,
      errors: originalErrors,
      warnings: originalWarnings
    } = origin;
    const {
      state: newState,
      total: newCount,
      matches: newShipments,
      errors: newErrors,
      warnings: newWarnings
    } = destination;

    setShipments(() => ({
      ...shipmentsRef.current,
      [originalState]: {
        total: originalCount,
        matches: originalShipments,
        totalErrors: originalErrors,
        totalWarnings: originalWarnings
      },
      [newState]: {
        total: newCount,
        matches: newShipments,
        totalErrors: newErrors,
        totalWarnings: newWarnings
      }
    }));
  };

  const updateShipmentsSameState = ({ destination }) => {
    const {
      state: newState,
      total: newCount,
      matches: newShipments,
      errors: newErrors,
      warnings: newWarnings
    } = destination;

    setShipments(() => ({
      ...shipmentsRef.current,
      [newState]: {
        total: newCount,
        matches: newShipments,
        totalErrors: newErrors,
        totalWarnings: newWarnings
      }
    }));
  };

  const handleShipmentChangeState = async (newState, shipmentId, originalState, update = true) => {
    const oldOriginalStateShipments = shipments[originalState];
    const oldNewStateShipments = shipments[newState];
    try {
      const shipment = oldOriginalStateShipments.matches.find(e => e.id === shipmentId);
      const originalStateShipments = oldOriginalStateShipments.matches.filter(({ id }) => id !== shipmentId);

      const shipmentWarnings = totalShipmentWarnings(shipment);

      const waypointsErrors = totalWaypointsErrors(shipment);
      const totalErrors = hasShipmentError(shipment, originalState) + waypointsErrors;
      const totalNewErrors = hasShipmentError(shipment, newState) + waypointsErrors;

      setShipments({
        ...shipments,
        [originalState]: {
          total: shipments[originalState].total - 1,
          totalErrors: shipments[originalState].totalErrors - totalErrors,
          totalWarnings: shipments[originalState].totalWarnings - shipmentWarnings,
          matches: originalStateShipments
        },
        [newState]: {
          total: shipments[newState].total + 1,
          totalErrors: shipments[newState].totalErrors + totalNewErrors,
          totalWarnings: shipments[newState].totalWarnings + shipmentWarnings,
          matches: [
            ...shipments[newState].matches,
            shipment
          ]
        }
      });

      if (update) {
        await updateShipmentState(shipmentId, newState);
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar(t("orders.error.delete"), {
        variant: "error"
      });
      setShipments(() => ({
        ...shipments,
        [originalState]: oldOriginalStateShipments,
        [newState]: oldNewStateShipments
      }));
    }
  };

  const loadAvailableContacts = async (type, search) => {
    try {
      const { matches } = await apiClient.contact.postContactMatchQuery({
        query: {
          match: { search, types: [type] }
        }
      });
      return matches;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  };

  const loadAvailableUsers = async (search, roles = undefined) => {
    try {
      const { matches } = await apiClient.user.postUserMatchQuery({
        query: {
          match: { search, roles, limit: 25 }
        }
      });
      return matches;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  };

  const onDeleteSubmit = async () => {
    setDeleteDialogOpen(false);
    const shipmentId = selectedShipment.id;
    const shipmentState = selectedShipment.state;
    try {
      const shipment = shipments[shipmentState].matches.find(({ id }) => id === shipmentId);
      const deleted = await deleteShipment(shipmentId);
      const stateShipments = shipments[shipmentState].matches.filter(({ id }) => id !== shipmentId);

      const totalWarnings = totalShipmentWarnings(shipment, shipmentState);

      setShipments(() => ({
        ...shipments,
        [shipmentState]: {
          total: shipments[shipmentState].total - 1,
          totalWarnings: shipments[shipmentState].totalWarnings - totalWarnings,
          matches: stateShipments
        }
      }));
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t("orders.error.delete"), {
        variant: "error"
      });
    }
  };

  const handleSave = async (updatedShipment) => {
    const shipment = updatedShipment;

    try {
      await updateShipmentById(apiClient, updatedShipment.id, shipment);
      enqueueSnackbar(t("shipment.update.success"), { variant: "success" });

      history.push("/shipments");
    } catch (error) {
      console.log(error);

      enqueueSnackbar(t("shipment.update.error.general"), { variant: "error" });
    }
  };

  const loadAvailableVehicles = async (search) => {
    try {
      const vehicles = await apiClient.vehicle.postVehicleSuggestQuery({
        query: {
          search
        }
      });
      return vehicles;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  };

  useEffect(() => {
    clearTimeout(storeSearchDelay);
    storeSearchDelay = setTimeout(() => {
      addUrlParam("searchText", search);
    }, 250);
  }, [search]);

  useEffect(() => {
    const listener = ({ data }) => {
      const { type, previous, current } = JSON.parse(data);

      if (type === ShipmentEventType.SHIPMENT_UPDATE) {
        handleRemoteShipmentChange(previous, current);
      }
    };

    boltClient.addEventListener("message", listener);

    return () => {
      boltClient.removeEventListener("message", listener);
    };
  }, []);

  useEffect(() => {
    addUrlParam("filter", additionalFilter);
  }, [additionalFilter]);

  return (
    <>
      <ShipmentBoard
        shipments={shipments}
        loading={loading}
        loadingDetails={loadingDetails}
        search={search}
        loadAdditionalShipments={loadAdditionalShipments}
        hasCompanyReadPermission={hasCompanyReadPermission}
        hasCreateShipmentPermission={hasCreateShipmentPermission}
        hasDeleteShipmentPermission={hasDeleteShipmentPermission}
        hasUpdateShipmentPermission={hasUpdateShipmentPermission}
        updateShipmentsSameState={updateShipmentsSameState}
        totalShipmentWarnings={totalShipmentWarnings}
        hasShipmentError={hasShipmentError}
        onClick={onShipmentClick}
        handleChangeState={handleShipmentChangeState}
        handleSearch={handleSearch}
        filter={additionalFilter}
        openDeleteDialog={({ id, state }) => {
          setSelectedShipment({ id, state });
          setDeleteDialogOpen(true);
        }}
        handleFilterSettingsOpen={handleFilterSettingsOpen}
        defaultFilters={defaultFilters}
        handleDeselect={handleDeselect}
        loadAvailableContacts={loadAvailableContacts}
        loadAvailableUsers={loadAvailableUsers}

        handleSelectShipmentType={handleSelectShipmentType}
        handleSelectDrivers={handleSelectDrivers}
        handleSelectCargoTemplate={handleSelectCargoTemplate}
        handleSelectCreators={handleSelectCreators}
        handleSelectCreatedAt={handleSelectCreatedAt}
        handleSelectLoadingDate={handleSelectLoadingDate}
        handleSelectUnloadingDate={handleSelectUnloadingDate}
        handleSelectCarriers={handleSelectCarriers}
        handleSelectCustomers={handleSelectCustomers}
        handleSelectCommission={handleSelectCommission}
        handleSelectCarrierPrice={handleSelectCarrierPrice}
        handleSelectCustomerPrice={handleSelectCustomerPrice}
        handleSelectIsDraft={handleSelectIsDraft}
        handleSelectVehicles={handleSelectVehicles}
        loadAvailableVehicles={loadAvailableVehicles}
        handleSelectIssuedPaymentState={handleSelectIssuedPaymentState}
        handleSelectReceivedPaymentState={handleSelectReceivedPaymentState}
      />
      <ShipmentsDeleteDialog
        open={deleteDialogOpen}
        selected={1}
        handleClose={() => setDeleteDialogOpen(false)}
        handleSubmit={onDeleteSubmit}
      />
      {hasUpdateShipmentPermission
        ? (
          <SwipeableDialog
            isOpen={editDialogOpen}
            closable
            width="80%"
            onClose={() => {
              setEditDialogOpen(false);
            }}
            form={
              (
                <BoardEditForm
                  shipment={selectedShipment}
                  shipments={shipments}
                  setShipments={setShipments}
                  onSave={() => {
                    setEditDialogOpen(false);
                    reloadShipments();
                  }}
                />
              )}
          />
        )
        : null}
      <FilterSettings
        availableFilters={availableFilters}
        initialFilters={defaultFilters}
        isOpen={isFilterSettingsOpen}
        onClose={handleFilterSettingsClose}
        onSubmit={onFilterSettingsSubmit}
      />
    </>
  );
};

export default ShipmentBoardContainer;
