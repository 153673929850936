import React from "react";
import { useTranslation } from "react-i18next";

import { Card, CardContent, CardHeader, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(({ palette }) => ({
  root: {
    backgroundColor: palette.primary.main,
    color: palette.getContrastText(palette.primary.main)
  },
  content: {
    padding: 10,
    "& > section": {
      display: "flex",
      justifyContent: "space-between"
    }
  }
}));

const ShipmentSummary = ({ commission, distance, duration }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const metrics = [
    ["webapp:shipment.summary.label.distance", distance],
    ["webapp:shipment.summary.label.duration", duration],
    ["webapp:shipment.summary.label.commission", commission]
  ];

  return (
    <Card className={classes.root}>
      <CardHeader title={t("webapp:shipment.summary.title")} />
      <CardContent className={classes.content}>
        {
          metrics.map(([label, value]) => (
            <section key={label}>
              <Typography>
                {t(label)}
              </Typography>
              <Typography>
                <b>
                  {value}
                </b>
              </Typography>
            </section>
          ))
        }
      </CardContent>
    </Card>
  );
};

export default ShipmentSummary;
