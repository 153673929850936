import React, { useState, useEffect, useReducer } from "react";

import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import {
  setDate,
  setMonth,
  getDate,
  getMonth,
  setYear,
  getYear,
  differenceInMilliseconds,
  addMilliseconds
} from "date-fns";
import { pipe } from "@cargotic/common-deprecated";

import {
  ShipmentType,
  Shipment as ShipmentT,
  ShipmentState
} from "@cargotic/model-deprecated";

import {
  createShipment,
  fetchShipmentById,
  fetchNextShipmentIndexNumber,
  updateShipmentById,
  useApiClient
} from "@cargotic/api-client-deprecated";

import { Currency, useExchangeRates } from "@cargotic/currency-deprecated";
import {
  CircularProgress,
  Slide,
  makeStyles
} from "@material-ui/core";
import queryString from "query-string";

import { useApiClient as useNewApiClient } from "../../../../cargotic-webapp-component";
import {
  storeDefaultUniLength,
  loadDefaultUnitLength
} from "../../../storage";

import {
  readUserTerms,
  readCompanyTerms
} from "../../../resource";
import useRouter from "../../../component/hook/useRouter";
import useAuth from "../../../component/hook/useAuth";
import JourneyPlanner from "../../../../../multiload/cargotic-webapp/component/JourneyPlanner";
import CargoticMapTheme from "../../../../../multiload/cargotic-webapp/theme/CargoticMapTheme.json";
import { Waypoint } from "../../../../../multiload/cargotic-core";
import { useGoogleMapsApi } from "../../../../../multiload/cargotic-map";
import ShipmentForm from "../ShipmentForm";
import { hydrateWaypoints, dehydrateWaypoints } from "../../../../../multiload/cargotic-webapp/utility";
import useShipmentConcept from "../../../component/hook/useShipmentConcept";
import ShipmentConceptDialog from "../ShipmentConceptDialog";

import JourneyPlannerReducer from "../../../../../multiload/cargotic-webapp/component/JourneyPlanner/JourneyPlannerReducer";
import JourneyPlannerState from "../../../../../multiload/cargotic-webapp/component/JourneyPlanner/JourneyPlannerState";
import JourneyPlannerAction from "../../../../../multiload/cargotic-webapp/component/JourneyPlanner/JourneyPlannerAction";

interface ShipmentProps {
  shipmentId?: number;
}

const SHIPMENT_INDEX_NUMBER_MIN_LENGTH = 4;

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    position: "relative",
    height: "100%",
    overflow: "hidden"
  },
  form: {
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 200,
    overflow: "auto",
    backgroundColor: palette.background.default
  },
  loader: {
    display: "flex",
    height: "100%",
    justifyContent: "center",
    alignItems: "center"
  },
  planner: {
    position: "absolute",
    width: "100%",
    height: "100%"
  },
  shipmentForm: {
    paddingTop: spacing(2),
    paddingLeft: spacing(4),
    paddingRight: spacing(4),
    paddingBottom: spacing(2)
  }
}));

const getInitialCargoItemSeed = waypoints => {
  const seed = waypoints
    .reduce((accumulator, { cargo = [] }) => [...accumulator, ...cargo], [])
    .map(({ id }) => id)
    .reduce((a, b) => (a > b ? a : b), 0);

  return seed ? seed + 1 : 0;
};

const getInitialCargoItemLinkSeed = waypoints => {
  const seed = waypoints
    .reduce((accumulator, { cargo = [] }) => [...accumulator, ...cargo], [])
    .map(({ itemId }) => itemId)
    .reduce((a, b) => (a > b ? a : b), 0);

  return seed ? seed + 1 : 0;
};

const getInitialWaypointSeed = waypoints => {
  const seed = waypoints
    .map(({ id }) => id)
    .reduce((a, b) => (a > b ? a : b), 0);

  return seed ? seed + 1 : 0;
};

function Shipment(): React.ReactElement {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { match: { params: { id } }, history, location: { pathname, search } } = useRouter();

  const path = pathname.split("/");
  const copy = path.slice(-1)[0] === "copy";
  const edit = !isNaN(parseInt(path.slice(-1)[0]));
  const { carrierId, customerId, vehicleId, trailerId } = queryString.parse(search);
  const language = i18n.language === "cs" ? "CZ" : "EN";

  const { shipment: shipmentConcept, setShipment: setShipmentConcept } = useShipmentConcept();
  const [shipment, setShipment] = useState();
  const [initialJourney, setInitialJourney] = useState({
    cargoItemSeed: 0,
    cargoItemLinkSeed: 0,
    waypointSeed: 2,
    isPlaceSearchFailAlertOpen: false,
    selectedWaypointIndex: 0,
    waypoints: [Waypoint({ id: 0 }), Waypoint({ id: 1 })],
    distance: undefined,
    duration: undefined,
    errors: [{}, {}]
  });

  const [journeyState, dispatchJourney] = useReducer(
    JourneyPlannerReducer,
    JourneyPlannerState(initialJourney)
  );

  const [isPlannerOpen, setIsPlannerOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [drivers, setDrivers] = useState(null);
  const [vehicles, setVehicles] = useState(null);
  const { hasPermission } = useAuth();
  const [defaultUnitLength] = useState(() => loadDefaultUnitLength());
  const [conceptDialogOpen, setConceptDialogOpen] = useState(shipmentConcept && (!id || copy));
  const [isJourneyChanged, setIsJourneyChanged] = useState(true);
  const [availableTerms, setAvailableTerms] = useState([]);

  const apiClient = useApiClient();
  const newApiClient = useNewApiClient();

  const {
    isLoading: isExchangeRatesLoading,
    exchangeRates,
    exchangeRatesEUR,
    refetch
  } = useExchangeRates();

  const {
    isLoading: isGoogleMapsApiLoading,
    api: googleMapsApi
  } = useGoogleMapsApi();

  const checkIndexNumber = (indexNumber) =>
    /^[0-9,a-z,A-Z,-]*$/.test(indexNumber) && indexNumber.length >= SHIPMENT_INDEX_NUMBER_MIN_LENGTH;

  const handleBack = (): void => {
    setIsPlannerOpen(true);
  };

  const handleJourneyComplete = (): void => {
    setIsPlannerOpen(false);
  };

  const storeDefaultLenghUnitUndefinedTemplate = (unit) => storeDefaultUniLength(unit);

  const handleSave = async (
    newShipment: Omit<ShipmentT, "journey">,
    setIsSubmiting
  ): Promise<void> => {
    // create new shipment
    if (!newShipment.isDraft && !checkIndexNumber(newShipment.indexNumber)) {
      enqueueSnackbar(t("webapp:shipment.form.error.indexNumber"), {
        variant: "error"
      });
      return;
    }

    if (id === undefined || copy) {
      const state = newShipment.isDraft ? ShipmentState.QUEUE : newShipment.state;
      const shipment = {
        ...newShipment,
        state,
        journey: copy && journeyState === initialJourney
          ? { waypoints: dehydrateWaypoints(journeyState.waypoints), duration: journeyState.duration, distance: journeyState.distance }
          : journeyState,
        paidAt: undefined,
        bankAccountId: undefined,
        bankAccount: undefined,
        termsId: newShipment.type === ShipmentType.FORWARDED ? (
          newShipment.isDraft ? (
            newShipment.terms ? newShipment.terms.termsId : undefined
          ) : newShipment.terms.termsId
        ) : undefined
      };

      try {
        setShipmentConcept(null);
        await createShipment(apiClient, shipment);

        enqueueSnackbar(t("shipment.create.success"), { variant: "success" });

        history.push("/shipments");
      } catch (error) {
        console.log(error);
        const { response } = error;
        const data = response?.data || {};
        let errorMessage;
        setIsSubmiting(false);
        switch (data.code) {
          case "shipment/invalid-index-number-length":
            errorMessage = t("shipment.create.error.invalidIndexNumberLength");
            break;
          case "shipment/index-number-already-exists":
            errorMessage = t("shipment.create.error.indexNumberAlreadyExists");
            break;
          case "journey/no-point":
            errorMessage = t("shipment.create.error.noPoint");
            break;
          case "journey/unsufficient-point-count":
            errorMessage = t("shipment.create.error.unsufficientPointCount");
            break;
          case "cargo/loaded-more-than-once":
            errorMessage = t("shipment.create.error.loadedMoreThanOnce");
            break;
          case "cargo/unloaded-before-loaded":
            errorMessage = t("shipment.create.error.cargoUnloadedBeforeLoaded");
            break;
          case "cargo/unloaded-more-than-once":
            errorMessage = t("shipment.create.error.cargoUnloadedMoreThanOnce");
            break;
          case "cargo/action-error":
            errorMessage = t("shipment.create.error.cargoActionUnknown");
            break;
          case "cargo/not-unloaded":
            errorMessage = t("shipment.create.error.cargoNotUnloaded");
            break;
          case "journey/no-journey":
            errorMessage = t("shipment.create.error.noJourney");
            break;
            case "contact/no-rights":
            errorMessage = t("shipment.create.error.noRights");
            break;
          case "shipment/invalid-index-number-length":
          case "shipment/invalid-index-number":
            errorMessage = t("webapp:shipment.form.error.indexNumber");
            break;
          default:
            errorMessage = t("shipment.create.error.general");
            break;
        }
        enqueueSnackbar(errorMessage, { variant: "error" });
      }
    } else {
      const shipment = {
        ...newShipment,
        journey: isJourneyChanged
          ? journeyState
          : undefined,
        paidAt: undefined
      };

      try {
        await updateShipmentById(apiClient, id, shipment);
        enqueueSnackbar(t("shipment.update.success"), { variant: "success" });

        history.push("/shipments");
      } catch (error) {
        console.log(error);

        enqueueSnackbar(t("shipment.update.error.general"), { variant: "error" });
      }
    }
  };

  const fetchContact = (id): Promise<any> => newApiClient.contact.getContact({ contactId: id });

  const fetchShipmentData = async (): Promise<void> => {
    // new shipment
    if (id === undefined) {
      const indexNumber = await fetchNextShipmentIndexNumber(apiClient);
      let carrier;
      let customer;
      let vehicle;
      let trailer;
      let driver;

      if (carrierId) {
        carrier = await fetchContact(carrierId);
      }
      if (customerId) {
        customer = await fetchContact(customerId);
      }

      if (vehicleId) {
        vehicle = await newApiClient.vehicle.getVehicle({ vehicleId });
        driver = vehicle?.defaultDriver;
      }

      if (trailerId) {
        trailer = await newApiClient.vehicle.getVehicle({ vehicleId: trailerId });
      }

      const termsArray = [];
      let loadedTerms;
      // const userTerms = await readUserTerms();
      // if (Object.keys(userTerms).length !== 0) {
      //   termsArray.push(userTerms);
      //   loadedTerms = userTerms;
      // }
      const companyTerms = await readCompanyTerms();

      if (Object.keys(companyTerms).length !== 0) {
        termsArray.push(companyTerms);
        if (!loadedTerms) {
          loadedTerms = companyTerms;
        }
      }
      setAvailableTerms(termsArray);
      const type = vehicle ? ShipmentType.CARRIED : ShipmentType.FORWARDED;

      setShipment({
        indexNumber,
        orderSerialNumber: indexNumber,
        carrierContact: carrier,
        driverContact: "",
        customerContact: customer,
        terms: loadedTerms || {},
        customerPosition: "",
        customerPaymentDueDays: "60",
        carrierPaymentDueDays: "60",
        customerPrice: "",
        customerPriceCurrency: Currency.CZK,
        isCustomerPriceWithDph: false,
        carrierPrice: "",
        carrierPriceCurrency: Currency.CZK,
        commissionCurrency: Currency.CZK,
        isCarrierPriceWithDph: false,
        notes: "",
        issuedInvoiceNumber: "",
        issuedInvoiceDueDate: undefined,
        receivedInvoiceNumber: "",
        receivedInvoiceDueDate: undefined,
        documents: [],
        isDraft: true,
        vehicle,
        driver,
        trailer,
        type
      });
    } else {
      if (copy) {
        const indexNumber = await fetchNextShipmentIndexNumber(apiClient);
        const { journey, ...newShipment } = await fetchShipmentById(apiClient, id);
        newShipment.indexNumber = indexNumber;
        newShipment.orderSerialNumber = indexNumber;
        newShipment.documents = [];
        newShipment.isDraft = true;
        newShipment.state = ShipmentState.QUEUE;

        const termsArray = [];
        let loadedTerms;
        // const userTerms = await readUserTerms();
        // if (Object.keys(userTerms).length !== 0) {
        //   termsArray.push(userTerms);
        //   loadedTerms = userTerms;
        // }
        const companyTerms = await readCompanyTerms();

        if (Object.keys(companyTerms).length !== 0) {
          termsArray.push(companyTerms);
          if (!loadedTerms) {
            loadedTerms = companyTerms;
          }
        }
        setAvailableTerms(termsArray);

        // adjust datetime of waypoints relatively
        const referenceDate = journey.waypoints[0].arriveAtFrom;
        const newReferenceDate = pipe(
          (date) => setDate(date, getDate(new Date())),
          (date) => setMonth(date, getMonth(new Date())),
          (date) => setYear(date, getYear(new Date())),
        )(referenceDate);

        journey.waypoints = journey.waypoints.map((waypoint) => {
          const { arriveAtFrom, arriveAtTo } = waypoint;
          const differenceInMsFrom = differenceInMilliseconds(arriveAtFrom, referenceDate);
          const differenceInMsTo = arriveAtTo ? differenceInMilliseconds(arriveAtTo, arriveAtFrom) : -1;
          const todaysFrom = addMilliseconds(newReferenceDate, differenceInMsFrom);
          const todaysTo = differenceInMsTo === -1 ? undefined : addMilliseconds(todaysFrom, differenceInMsTo);
          waypoint.arriveAtFrom = todaysFrom;
          waypoint.arriveAtTo = todaysTo;
          return waypoint;
        });

        const waypoints = hydrateWaypoints(journey.waypoints);

        const fetchedJourney = { waypoints, distance: journey.distance, duration: journey.duration };
        const journeyData = {
          cargoItemSeed: getInitialCargoItemSeed(waypoints),
          cargoItemLinkSeed: getInitialCargoItemLinkSeed(waypoints),
          waypointSeed: getInitialWaypointSeed(waypoints),
          waypoints: fetchedJourney.waypoints,
          errors: Array(fetchedJourney.waypoints.length).fill({}),
          distance: fetchedJourney.distance,
          duration: fetchedJourney.duration,
        };

        setIsJourneyChanged(false);
        setShipment({
          ...newShipment,
          issuedInvoiceNumber: "",
          receivedInvoiceNumber: "",
          issuedInvoicePaidAt: undefined,
          receivedInvoicePaidAt: undefined,
          issuedInvoiceDueDate: undefined,
          receivedInvoiceDueDate: undefined,
          issueDate: undefined,
          taxableSupplyDate: undefined,
          invoiceNote: "",
          terms: newShipment.type === "CARRIED" ? undefined : (loadedTerms || {})
        });
        setInitialJourney(fetchedJourney);
        dispatchJourney({
          type: JourneyPlannerAction.CHANGE_JOURNEY,
          ...journeyData
        });
        setShipmentConcept({
          ...journeyData,
          ...newShipment
        });
      }
      if (edit) {
        const { journey, ...newShipment } = await fetchShipmentById(apiClient, id);
        newShipment.indexNumber = newShipment.indexNumber ? newShipment.indexNumber : await fetchNextShipmentIndexNumber(apiClient);
        newShipment.orderSerialNumber = newShipment.orderSerialNumber && !newShipment.isDraft
          ? newShipment.orderSerialNumber
          : newShipment.indexNumber;

        const termsArray = [];
        // const userTerms = await readUserTerms();
        // if (Object.keys(userTerms).length !== 0) {
        //   termsArray.push(userTerms);
        // }
        const companyTerms = await readCompanyTerms();

        if (Object.keys(companyTerms).length !== 0) {
          termsArray.push(companyTerms);
        }
        setAvailableTerms(termsArray);

        const waypoints = hydrateWaypoints(journey.waypoints);
        const fetchedJourney = { waypoints, distance: journey.distance, duration: journey.duration };

        setIsJourneyChanged(false);
        setShipment({
          ...newShipment
        })
        setInitialJourney(fetchedJourney);
        dispatchJourney({
          cargoItemSeed: getInitialCargoItemSeed(waypoints),
          cargoItemLinkSeed: getInitialCargoItemLinkSeed(waypoints),
          waypointSeed: getInitialWaypointSeed(waypoints),
          type: JourneyPlannerAction.CHANGE_JOURNEY,
          waypoints: fetchedJourney.waypoints,
          errors: Array(fetchedJourney.waypoints.length).fill({}),
          distance: fetchedJourney.distance,
          duration: fetchedJourney.duration
        });
      }
    }
  };

  useEffect(() => {
    (async () => {
      try {
        await fetchShipmentData();
        setIsLoading(false);
      } catch (error) {
        console.log(error);

        enqueueSnackbar(t("shipment.load.error"), { variant: "error" });
      }
    })();
  }, []);

  useEffect(() => {
    if (JSON.stringify(journeyState.waypoints) !== JSON.stringify(initialJourney.waypoints)) {
      setIsJourneyChanged(true);
      onFormChange(journeyState);
    }
  }, [journeyState]);

  useEffect(() => {
    console.log('refetch');
    refetch();
  }, []);

  const onFormChange = (values) => {
    if ((id === undefined || copy) && !conceptDialogOpen) {
      const newShipmentConcept = { ...shipmentConcept, ...values };
      setShipmentConcept(newShipmentConcept);
      setShipment({ ...shipment, ...values });
    }
  }

  const content = (() => {
    if (isLoading || isExchangeRatesLoading || isGoogleMapsApiLoading) {
      return (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      );
    }
    return (
      <>
        <JourneyPlanner
          className={classes.planner}
          googleMapsApi={googleMapsApi!}
          state={journeyState}
          dispatch={dispatchJourney}
          onComplete={handleJourneyComplete}
          mapTheme={CargoticMapTheme}
          defaultLengthUnit={defaultUnitLength}
          storeDefaultLengthUnit={storeDefaultLenghUnitUndefinedTemplate}
        />
        {!conceptDialogOpen ?
          <Slide in={!isPlannerOpen} direction="left" unmountOnExit>
            <div className={classes.form}>
              <ShipmentForm
                className={classes.shipmentForm}
                apiClient={apiClient}
                newApiClient={newApiClient}
                exchangeRates={exchangeRates!}
                exchangeRatesEUR={exchangeRatesEUR}
                // @ts-ignore
                shipment={shipment}
                journey={journeyState}
                availableTerms={availableTerms}
                onBack={handleBack}
                onSave={handleSave}
                drivers={drivers}
                vehicles={vehicles}
                isExtended={hasPermission("resource.shipment.user.extended.create")}
                isUpdating={edit}
                onFormChange={onFormChange}
                isJourneyChanged={isJourneyChanged}
              />
            </div>
          </Slide>
          : null}
        <ShipmentConceptDialog
          t={t}
          isOpen={conceptDialogOpen}
          onClose={async (saveConcept) => {
            if (saveConcept) {
              dispatchJourney({
                cargoItemSeed: shipmentConcept.cargoItemSeed,
                cargoItemLinkSeed: shipmentConcept.cargoItemLinkSeed,
                waypointSeed: shipmentConcept.waypointSeed,
                type: JourneyPlannerAction.CHANGE_JOURNEY,
                waypoints: shipmentConcept.waypoints,
                errors: shipmentConcept.errors,
                distance: shipmentConcept.distance,
                duration: shipmentConcept.duration
              });
              //const userTerms = await readUserTerms();
              let fetchedTerms;

              // if (Object.keys(userTerms).length !== 0) {
              //   fetchedTerms = userTerms;
              // } else {
                const terms = await readCompanyTerms();
                fetchedTerms = terms;
              //}
              setShipment({
                ...shipment,
                ...shipmentConcept,
                terms: fetchedTerms || {}
              });
            } else {
              setShipmentConcept(null);
            }
            setConceptDialogOpen(false);
          }} />
      </>
    );
  })();

  return (
    <>
      <div className={classes.root}>
        {content}
      </div>
    </>
  );
}

export default Shipment;
