import clsx from "clsx";
import { formatDistanceToNow } from "date-fns";
import { cs, enUS } from "date-fns/locale";
import React from "react";
import { useTranslation } from "react-i18next";

import { Typography, makeStyles, Tooltip } from "@material-ui/core";

import UserLink from "../UserLink";
import ActivityTimelineItemDot from "./ActivityTimelineEventItemDot";

import { getDateFnsLocale } from "../../../cargotic-webapp-utility";

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    marginLeft: spacing(4),

    "& > section": {
      "&:first-child": {
        display: "flex",
        alignItems: "center",

        "& > div:last-child": {
          display: "flex",
          marginLeft: spacing(1),

          "& > :not(:first-child)": {
            marginLeft: spacing(1)
          }
        }
      }
    }
  },
  connected: {
    position: "relative",
    marginLeft: 9,

    borderLeft: `2px solid ${palette.primary.light}`,

    "&::before": {
      content: "\" \"",

      position: "absolute",
      top: -20,
      left: -2,

      display: "block",
      width: 2,
      height: 20,

      backgroundColor: palette.primary.light
    },
    "&::after": {
      content: "\" \"",

      position: "absolute",
      bottom: -8,
      left: -2,

      display: "block",
      width: 2,
      height: 8,

      backgroundColor: palette.primary.light
    }
  },
  disconnected: {
    marginLeft: 11
  }
}));

const ActivityTimelineEventItem = ({
  variant,
  user,
  title,
  content,
  createdAt,
  isLast
}) => {
  const { i18n: { language } } = useTranslation();
  const classes = useStyles();
  const dateFnsLocale = getDateFnsLocale(language);

  const localeStringFormat = {
    month: "long",
    year: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric"
  }

  const { code: localCode } = dateFnsLocale;

  return (
    <div className={classes.root}>
      <section>
        <div>
          <ActivityTimelineItemDot variant={variant} />
        </div>
        <div>
          <UserLink user={user} />
          {title}
          <Tooltip title={createdAt.toLocaleString(localCode, localeStringFormat)}>
            <Typography>
              {
                formatDistanceToNow(createdAt, {
                  addSuffix: true, locale: dateFnsLocale
                })
              }
            </Typography>
          </Tooltip>
        </div>
      </section>
      {
        content && content.length !== 0
          ? (
            <section
              className={clsx({
                [classes.connected]: !isLast,
                [classes.disconnected]: isLast
              })}
            >
              <ul>
                {content.map((item, index) => (
                  <li key={index}>
                    {item}
                  </li>
                ))}
              </ul>
            </section>
          ) : (
            null
          )
      }
    </div>
  );
};

export default ActivityTimelineEventItem;
