import React, { useState, useEffect } from "react";

import makeStyles from "@material-ui/core/styles/makeStyles";

import { findOrThrow } from "../../../utility/functional";
import { Roles } from "../../enums/enums";

import DashboardAppBar from "./DashboardAppBar";
import NotFound from "../NotFound";

import {
  getUserElements
} from "./DashboardElements";
import DashboardView from "./DashboardView";

import useAuth from "../../hook/useAuth";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex"
  },
  content: {
    width: "100%",
    minHeight: "100vh",
    paddingTop: 46
  }
}));

const Dashboard = ({ user, view, signOut, children, onNewsIconClick }) => {
  const classes = useStyles();
  let elements = [];

  const { hasPermission } = useAuth();
  elements = getUserElements(hasPermission);

  const items = elements.map(({
    path, title, icon, component, action, showAction, isSubheader, ...element
  }) => ({
    path,
    title,
    icon,
    component,
    isSubheader,
    selected: view === element.view,
    showAction,
    action
  }));

  const notFound = view === DashboardView.NOTFOUND;

  const { component: Component } = notFound || findOrThrow(
    items,
    ({ selected }) => selected,
    () => new Error(`Illegal view '${view}' specified.`)
  );

  useEffect(() => {
    const element = document.querySelector("main#content");
    const listener = () => {
      element.setAttribute("style", `min-height: ${window.innerHeight}px;`);
    };

    window.addEventListener("resize", listener);

    return () => window.removeEventListener("resize", listener);
  });

  return (
    <div className={classes.container}>
      <DashboardAppBar
        items={items.filter(({ path, isSubheader }) => path || isSubheader)}
        user={user}
        signOut={signOut}
        onNewsIconClick={onNewsIconClick}
      />
      <main id="content" className={classes.content}>
        {
          children
            ? children
            : notFound
                ? <NotFound />
                : <Component />
        }
      </main>
    </div>
  );
};

export default Dashboard;
