import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  makeStyles
} from "@material-ui/core";

import { Edit } from "@material-ui/icons";

import {
  CargoItemTemplateSpecification,
  LengthUnit,
  LengthUnitSpecification,
  convertUnit
} from "../../../cargotic-core";

import CargoItemTemplateSelect from "../CargoItemTemplateSelect";
import LengthUnitSelect from "../LengthUnitSelect";
import NumberField from "../NumberField";

const useStyles = makeStyles(() => ({
  select: {
    width: "100%",
    height: "100%",

    "& > div": {
      height: "initial"
    }
  },
  button: {
    maxHeight: 32
  }
}));

const CargoEditorCargoItemListItemForm = ({
  className,
  template,
  quantity,
  description,
  width,
  height,
  length,
  lengthUnit,
  weight,
  volume,
  totalVolume,
  totalWeight,
  isStackable,
  onChange
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const specification = CargoItemTemplateSpecification[template] || {};
  const {
    width: templateWidth,
    height: templateHeight,
    length: templateLength,
    stackability
  } = specification;

  const handleChange = ({ target: { name, type, value, checked }}) =>
    onChange(name, type, type === "checkbox" ? checked : value);

  const handleClick = () => onChange("edit-template", "button", "");

  const normalize = (type, value) => {
    if (type === "string") {
      return value ?? "";
    }

    return value !== undefined
      ? value.toString()
      : "";
  };

  return (
    <div className={className}>
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <CargoItemTemplateSelect
            name="template"
            className={classes.select}
            value={normalize("string", template)}
            onChange={handleChange}
          />
        </Grid>
        <Grid container item spacing={1} xs={9}>
          <Grid item xs={3}>
            <NumberField
              name="quantity"
              type="number"
              value={normalize("number", quantity)}
              label={t("webapp:cargo.editor.quantity")}
              variant="outlined"
              fullWidth
              isRequired
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={9}>
            <TextField
              name="description"
              value={normalize("string", description)}
              label={t("webapp:cargo.editor.description")}
              variant="outlined"
              fullWidth
              onChange={handleChange}
              data-cy="cargo-item-description"
            />
          </Grid>
          <Grid item xs={3}>
            <NumberField
              name="length"
              type="number"
              value={normalize("number", length)}
              label={t("webapp:cargo.editor.length")}
              variant="outlined"
              fullWidth
              onChange={handleChange}
              disabled={templateLength !== undefined}
            />
          </Grid>
          <Grid item xs={3}>
            <NumberField
              name="width"
              type="number"
              value={normalize("number", width)}
              label={t("webapp:cargo.editor.width")}
              variant="outlined"
              fullWidth
              onChange={handleChange}
              disabled={templateWidth !== undefined}
            />
          </Grid>
          <Grid item xs={3}>
            <NumberField
              name="height"
              type="number"
              value={normalize("number", height)}
              label={t("webapp:cargo.editor.height")}
              variant="outlined"
              fullWidth
              onChange={handleChange}
              disabled={templateHeight !== undefined}
            />
          </Grid>
          <Grid item xs={3}>
            <LengthUnitSelect
              name="lengthUnit"
              value={lengthUnit}
              variant="outlined"
              fullWidth
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={3}>
            <NumberField
              name="volume"
              type="number"
              value={normalize("number", volume)}
              label={t("webapp:cargo.editor.volume")}
              variant="outlined"
              fullWidth
              onChange={handleChange}
              disabled={
                templateWidth !== undefined
                  && templateHeight !== undefined
                  && templateLength !== undefined
              }
            />
          </Grid>
          <Grid item xs={3}>
            <FormControl variant="outlined" fullWidth>
              <Select value="M3" disabled>
                <MenuItem value="M3">m³</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <NumberField
              name="weight"
              type="number"
              value={normalize("number", weight)}
              label={t("webapp:cargo.editor.weight")}
              variant="outlined"
              fullWidth
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={3}>
            <FormControl variant="outlined" fullWidth>
              <Select value="KG" disabled>
                <MenuItem value="KG">kg</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container item xs={3} justify="center">
          <Button
            className={classes.button}
            size="small"
            startIcon={<Edit />}
            onClick={handleClick}
            disabled={template === undefined}
            fullWidth
          >
            {t("webapp:cargo.editor.button.edit")}
          </Button>
        </Grid>
        <Grid container item spacing={1} xs={9}>
          <Grid item xs={12}>
            <Typography variant="subtitle2">
              {t("webapp:cargo.editor.additional")}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <NumberField
              name="totalVolume"
              type="number"
              value={normalize("number", totalVolume)}
              label={t("webapp:cargo.editor.totalVolume")}
              variant="outlined"
              fullWidth
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={3}>
            <FormControl variant="outlined" fullWidth>
              <Select value="M3" disabled>
                <MenuItem value="M3">m³</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <NumberField
              name="totalWeight"
              type="number"
              value={normalize("number", totalWeight)}
              label={t("webapp:cargo.editor.totalWeight")}
              variant="outlined"
              fullWidth
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={3}>
            <FormControl variant="outlined" fullWidth>
              <Select value="KG" disabled>
                <MenuItem value="KG">kg</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={9}>
            <FormControlLabel
              control={(
                <Checkbox
                  name="isStackable"
                  checked={Boolean(isStackable)}
                  color="primary"
                  onChange={handleChange}
                  disabled={template !== undefined && !stackability}
                />
              )}
              label={t("webapp:cargo.editor.isStackable")}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default CargoEditorCargoItemListItemForm;
