import { createClient } from "@cargotic/api-client";

import Axios from "axios";

import config from "./config";

const axios = Axios.create({
  baseURL: config.api.url,
  withCredentials: true,
  headers: {
    "X-Cargotic-API-Client": "Cargotic Webapp API Client"
  }
});

const client = createClient(axios);

export default client;
export { axios };
