import React, { useState } from "react";
import {
  Grid, Button, Typography, CardActions, CardHeader, CardContent, Card
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import differenceInDays from "date-fns/differenceInDays";
import { useSnackbar } from "notistack";

import { formatDate } from "../../../utility/common";
import SetTariffDialog from "./SetTariffDialog";
import useAuth from "../../hook/useAuth";

const TariffCard = ({ classes, canUpdateCompanyProfile }) => {
  const { t } = useTranslation();
  const [configureTariff, setConfigureTariff] = useState(false);
  const { user: { subscription } } = useAuth();

  return (
    <>
      <Card className={classes.card}>
        <CardHeader title={t("settings.tariffTitle")} />
        <CardContent>
          <Grid container spacing={1}>
            <Grid container item>
              <Grid item xs>
                <Typography variant="subtitle2">{t("settings.expirationDate")}</Typography>
              </Grid>
              <Grid item xs>
                <Typography variant="subtitle2">
                  {`${formatDate(new Date(subscription.expiresAt))} / ${t("remainsDays", { days: differenceInDays(new Date(subscription.expiresAt), new Date()) })}`}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item>
              <Grid item xs>
                <Typography variant="subtitle2">{t("settings.tariffTitle")}</Typography>
              </Grid>
              <Grid item xs>
                <Typography variant="subtitle2">{t(`settings.tariff.${subscription.tariff}`)}</Typography>
              </Grid>
            </Grid>
            <Grid container item>
              <Grid item xs>
                <Typography variant="subtitle2">{t("settings.subscriptionType")}</Typography>
              </Grid>
              <Grid item xs>
                <Typography variant="subtitle2">{t(`settings.tariff.${subscription.isTrial ? "trial" : "full"}`)}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Button
            variant="outlined"
            disabled={!canUpdateCompanyProfile || !subscription.isTrial}
            size="medium"
            color="secondary"
            onClick={() => setConfigureTariff(true)}
          >
            {t("settings.setup")}
          </Button>
        </CardActions>
      </Card>
      <SetTariffDialog
        disabled={!canUpdateCompanyProfile}
        open={configureTariff}
        handleClose={() => setConfigureTariff(false)}
      />
    </>
  );
};

export default TariffCard;
