import React from "react";

import { makeStyles } from "@material-ui/core";

import ContactProfileDetail from "../ContactProfileDetail";
import ContactProfileBodyNavigation from "./ContactProfileBodyNavigation";
import ContactProfileBodyContent from "./ContactProfileBodyContent";

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: "flex",
    minHeight: "100%"
  },
  body: {
    display: "flex",
    flexDirection: "column",
    marginLeft: spacing(4),
    flex: 1
  },
  content: {
    flex: 1,

    marginTop: spacing(5),
    marginBottom: spacing(2)
  }
}));

const ContactProfileBody = ({
  activity,
  contact,
  employeeQuery,
  employees,
  orderBalanceHighlight,
  selectedEmployee,
  shipmentBalanceHighlight,
  shipmentCommissionHighlight,
  shipmentsHighlight,
  user,
  view,
  isOwnerChangeButtonDisabled,
  isActivityLoading,
  isEmployeeDeleteConfirmationAlertOpen,
  isEmployeeEditorOpen,
  isEmployeesLoading,
  isHighlightsLoading,
  onActivityLoad,
  onAvatarChange,
  onBreadcrumbsChange,
  onCommentaryChange,
  onCommentaryDelete,
  onCommentarySubmit,
  onDelete,
  onEdit,
  onEmployeeAdd,
  onEmployeeDelete,
  onEmployeeDeleteConfirmationAlertClose,
  onEmployeeDeleteConfirmationAlertSubmit,
  onEmployeeEdit,
  onEmployeeEditorClose,
  onEmployeeEditorSubmit,
  onEmployeeQueryChange,
  onOrderBalanceHighlightChange,
  onShipmentBalanceHighlightChange,
  onShipmentCommissionHighlightChange,
  onShipmentRedirect,
  onShipmentsHighlightChange,
  onTagEdit,
  onContactOwnerEditClick,
  onViewChange,
  onShipmentsHeaderIconClick
}) => {
  const classes = useStyles();

  const handleDeleteButtonClick = () => {
    if (onDelete) {
      onDelete();
    }
  };

  const handleEditButtonClick = () => {
    if (onEdit) {
      onEdit();
    }
  };

  const handleShipmentButtonClick = () => {
    if (onShipmentRedirect) {
      onShipmentRedirect();
    }
  };

  return (
    <div className={classes.root}>
      <div>
        <ContactProfileDetail
          contact={contact}
          isOwnerChangeButtonDisabled={isOwnerChangeButtonDisabled}
          onAvatarChange={onAvatarChange}
          onDeleteButtonClick={handleDeleteButtonClick}
          onEditButtonClick={handleEditButtonClick}
          onShipmentButtonClick={handleShipmentButtonClick}
          onTagEditClick={onTagEdit}
          onContactOwnerEditClick={onContactOwnerEditClick}
        />
      </div>
      <div className={classes.body}>
        <ContactProfileBodyNavigation
          view={view}
          onViewChange={onViewChange}
        />
        <ContactProfileBodyContent
          className={classes.content}
          activity={activity}
          contact={contact}
          employeeQuery={employeeQuery}
          employees={employees}
          orderBalanceHighlight={orderBalanceHighlight}
          selectedEmployee={selectedEmployee}
          shipmentBalanceHighlight={shipmentBalanceHighlight}
          shipmentCommissionHighlight={shipmentCommissionHighlight}
          shipmentsHighlight={shipmentsHighlight}
          user={user}
          view={view}
          isActivityLoading={isActivityLoading}
          isEmployeeDeleteConfirmationAlertOpen={
            isEmployeeDeleteConfirmationAlertOpen
          }
          isEmployeeEditorOpen={isEmployeeEditorOpen}
          isEmployeesLoading={isEmployeesLoading}
          isHighlightsLoading={isHighlightsLoading}
          onActivityLoad={onActivityLoad}
          onBreadcrumbsChange={onBreadcrumbsChange}
          onCommentaryChange={onCommentaryChange}
          onCommentaryDelete={onCommentaryDelete}
          onCommentarySubmit={onCommentarySubmit}
          onEmployeeAdd={onEmployeeAdd}
          onEmployeeDelete={onEmployeeDelete}
          onEmployeeDeleteConfirmationAlertClose={
            onEmployeeDeleteConfirmationAlertClose
          }
          onEmployeeDeleteConfirmationAlertSubmit={
            onEmployeeDeleteConfirmationAlertSubmit
          }
          onEmployeeEdit={onEmployeeEdit}
          onEmployeeEditorClose={onEmployeeEditorClose}
          onEmployeeEditorSubmit={onEmployeeEditorSubmit}
          onEmployeeQueryChange={onEmployeeQueryChange}
          onOrderBalanceHighlightChange={onOrderBalanceHighlightChange}
          onShipmentBalanceHighlightChange={onShipmentBalanceHighlightChange}
          onShipmentsHighlightChange={onShipmentsHighlightChange}
          onShipmentCommissionHighlightChange={onShipmentCommissionHighlightChange}
          onShipmentsHeaderIconClick={onShipmentsHeaderIconClick}
        />
      </div>
    </div>
  );
};

export default ContactProfileBody;
