import React from "react";

import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select
} from "@material-ui/core";

const FormikSelect = ({
  className,
  form,
  name,
  label,
  children,
  ...props
}) => {
  const value = form.values[name];
  const error = form.errors[name];
  const isTouched = form.touched[name];

  return (
    <FormControl error={error} {...props}>
      <InputLabel>
        {label}
      </InputLabel>
      <Select
        classes={{
          select: className
        }}
        name={name}
        value={value}
        onChange={form.handleChange}
        onBlur={form.handleBlur}
      >
        {children}
      </Select>
      <FormHelperText>
        {isTouched && error !== undefined ? error : " "}
      </FormHelperText>
    </FormControl>
  );
};

export default FormikSelect;
