import React from "react";
import { Switch } from "react-router-dom";

import ContactProfileView
  from "../../../../cargotic-webapp-contact/ContactProfileView";

import VehicleProfileView
  from "../../../../cargotic-webapp-vehicle/VehicleProfileView";

import { AuthPolicy } from "../../../auth";
import ContactProfileContainerAdapter
  from "../../contact/ContactProfileContainerAdapter";

import { Roles } from "../../enums/enums";

import VehicleProfileContainerAdapter
  from "../../vehicle/VehicleProfileContainerAdapter";
import Auth from "../Auth";
import AuthRoute from "../AuthRoute";
import Dashboard from "../Dashboard";
import NotFound from "../NotFound";
import DashboardView from "../Dashboard/DashboardView";

const AppRouter = () => (
  <Switch>
    <AuthRoute
      exact
      path="/"
      policy={AuthPolicy.ANY}
      component={({ authenticated }) => (
        authenticated
          ? <Dashboard view={DashboardView.OVERVIEW} />
          : <Auth formType="SIGN_IN" />
      )}
    />
    <AuthRoute
      exact
      path="/action"
      policy={AuthPolicy.ANY}
      component={() => (
        <Auth formType="ACTION" />
      )}
    />
      <AuthRoute
          exact
          path="/ico"
          handleExpiredTariff
          policy={AuthPolicy.ANY}
          component={() => (
              <Dashboard view={DashboardView.ICO_LOOKUP} />
          )}
      />
      <AuthRoute
          exact
          path="/bulk-shipments"
          handleExpiredTariff
          policy={AuthPolicy.ANY}
          component={() => (
              <Dashboard view={DashboardView.BULK_SHIPMENTS} />
          )}
      />
    <AuthRoute
      exact
      path="/contacts"
      handleExpiredTariff
      permission={["resource.contact.user.read"]}
      allPermissionsRequired
      policy={AuthPolicy.ENFORCE}
      component={() => (
        <Dashboard view={DashboardView.CONTACTS} />
      )}
    />
    <AuthRoute
      exact
      path="/contacts/:id(\d+)"
      handleExpiredTariff
      policy={AuthPolicy.ENFORCE}
      permission={["resource.contact.user.read"]}
      allPermissionsRequired
      component={() => (
        <Dashboard view={DashboardView.CONTACTS}>
          <ContactProfileContainerAdapter
            view={ContactProfileView.CONTACT_OVERVIEW}
          />
        </Dashboard>
      )}
    />
    <AuthRoute
      exact
      path="/contacts/:id(\d+)/employees"
      handleExpiredTariff
      policy={AuthPolicy.ENFORCE}
      permission={["resource.vehicle.expenses.read"]}
      allPermissionsRequired
      component={() => (
        <Dashboard view={DashboardView.CONTACTS}>
          <ContactProfileContainerAdapter
            view={ContactProfileView.CONTACT_EMPLOYEE_OVERVIEW}
          />
        </Dashboard>
      )}
    />
    <AuthRoute
      exact
      path="/vehicles"
      handleExpiredTariff
      policy={AuthPolicy.ENFORCE}
      permission={["resource.vehicle.read"]}
      allPermissionsRequired
      component={() => (
        <Dashboard view={DashboardView.VEHICLES} />
      )}
    />
    <AuthRoute
      exact
      path="/vehicles/:id(\d+)"
      handleExpiredTariff
      policy={AuthPolicy.ENFORCE}
      permission={["resource.vehicle.read"]}
      allPermissionsRequired
      component={() => (
        <Dashboard view={DashboardView.VEHICLES}>
          <VehicleProfileContainerAdapter
            view={VehicleProfileView.VEHICLE_OVERVIEW}
          />
        </Dashboard>
      )}
    />
    <AuthRoute
      exact
      path="/vehicles/:id(\d+)/expenses"
      handleExpiredTariff
      policy={AuthPolicy.ENFORCE}
      permission={["resource.vehicle.expenses.read"]}
      allPermissionsRequired
      component={() => (
        <Dashboard view={DashboardView.VEHICLES}>
          <VehicleProfileContainerAdapter
            view={VehicleProfileView.VEHICLE_EXPENSE_OVERVIEW}
          />
        </Dashboard>
      )}
    />
    <AuthRoute
      exact
      path="/vehicles/:id(\d+)/expirations"
      handleExpiredTariff
      policy={AuthPolicy.ENFORCE}
      permission={["resource.vehicle.expiration.read"]}
      allPermissionsRequired
      component={() => (
        <Dashboard view={DashboardView.VEHICLES}>
          <VehicleProfileContainerAdapter
            view={VehicleProfileView.VEHICLE_EXPIRATION_OVERVIEW}
          />
        </Dashboard>
      )}
    />
    <AuthRoute
      exact
      path="/vehicles/:id(\d+)/incomes"
      handleExpiredTariff
      policy={AuthPolicy.ENFORCE}
      permission={["resource.vehicle.income.read"]}
      allPermissionsRequired
      component={() => (
        <Dashboard view={DashboardView.VEHICLES}>
          <VehicleProfileContainerAdapter
            view={VehicleProfileView.VEHICLE_INCOME_OVERVIEW}
          />
        </Dashboard>
      )}
    />
    <AuthRoute
      exact
      path="/board"
      handleExpiredTariff
      permission={["resource.shipment.user.read"]}
      allPermissionsRequired
      policy={AuthPolicy.ENFORCE}
      component={() => (
        <Dashboard view={DashboardView.SHIPMENT_BOARD} />
      )}
    />
    <AuthRoute
      exact
      handleExpiredTariff
      path="/shipment/:id(\d+)?"
      policy={AuthPolicy.ENFORCE}
      permission={["resource.shipment.user.update"]}
      allPermissionsRequired
      component={() => (
        <Dashboard view={DashboardView.SHIPMENT} />
      )}
    />
    <AuthRoute
      exact
      handleExpiredTariff
      path="/shipment/:id(\d+)?/copy"
      policy={AuthPolicy.ENFORCE}
      permission={["resource.shipment.user.update"]}
      allPermissionsRequired
      component={() => (
        <Dashboard view={DashboardView.SHIPMENT} />
      )}
    />
    <AuthRoute
      exact
      handleExpiredTariff
      path="/shipment"
      policy={AuthPolicy.ENFORCE}
      permission={["resource.shipment.user.create"]}
      allPermissionsRequired
      component={() => (
        <Dashboard view={DashboardView.SHIPMENT} />
      )}
    />
    <AuthRoute
      exact
      handleExpiredTariff
      path="/shipments"
      permission={["resource.shipment.user.read"]}
      allPermissionsRequired
      policy={AuthPolicy.ENFORCE}
      component={() => (
        <Dashboard view={DashboardView.SHIPMENTS} />
      )}
    />
    <AuthRoute
      exact
      handleExpiredTariff
      path="/users/:id(\d+)?"
      policy={AuthPolicy.ENFORCE}
      permission={["resource.user.read"]}
      allPermissionsRequired
      component={() => (
        <Dashboard view={DashboardView.USERS} />
      )}
    />
    <AuthRoute
      exact
      handleExpiredTariff
      path="/users"
      permission={["resource.user.read"]}
      allPermissionsRequired
      policy={AuthPolicy.ENFORCE}
      component={() => (
        <Dashboard view={DashboardView.USERS} />
      )}
    />
    <AuthRoute
      exact
      handleExpiredTariff
      path="/tracking"
      permission={["resource.track.read"]}
      allPermissionsRequired
      policy={AuthPolicy.ENFORCE}
      component={() => (
        <Dashboard view={DashboardView.TRACKING} />
      )}
    />
    <AuthRoute
      exact
      handleExpiredTariff
      permission={["resource.statistics.user.read", "resource.statistics.user.company"]}
      oneOfPermissionsRequired
      path="/statistics"
      policy={AuthPolicy.ENFORCE}
      component={() => (
        <Dashboard view={DashboardView.STATISTICS} />
      )}
    />
    <AuthRoute
      exact
      handleExpiredTariff
      path="/places"
      policy={AuthPolicy.ENFORCE}
      component={() => (
        <Dashboard view={DashboardView.PLACES} />
      )}
    />
    <AuthRoute
      exact
      handleExpiredTariff
      path="/settings"
      policy={AuthPolicy.ENFORCE}
      component={() => (
        <Dashboard view={DashboardView.SETTINGS} />
      )}
    />
    <AuthRoute
      exact
      handleExpiredTariff
      path="/feedback"
      policy={AuthPolicy.ENFORCE}
      component={() => (
        <Dashboard view={DashboardView.FEEDBACK} />
      )}
    />
    <AuthRoute
      exact
      path="/reset-password"
      policy={AuthPolicy.FORBID}
      component={() => (
        <Auth formType="RESET_PASSWORD" />
      )}
    />
    <AuthRoute
      exact
      path="/sign-in"
      policy={AuthPolicy.FORBID}
      component={() => (
        <Auth formType="SIGN_IN" />
      )}
    />
    <AuthRoute
      exact
      path="/sign-up"
      policy={AuthPolicy.FORBID}
      component={() => (
        <Auth formType="SIGN_UP" />
      )}
    />
    <AuthRoute
      path=""
      policy={AuthPolicy.ANY}
      component={({ authenticated }) => (authenticated
        ? <Dashboard view={DashboardView.NOTFOUND} />
        : <NotFound />)
      }
    />
  </Switch>
);

export default AppRouter;
