import React from "react";

import CargoItemListItem from "./CargoItemListItem";

const CargoItemList = ({ cargo }) => (
  <div>
    {cargo.map((item, index) => {
      const key = index;

      return (
        <CargoItemListItem key={key} item={item} />
      );
    })}
  </div>
);

export default CargoItemList;
