import clsx from "clsx";
import React from "react";

import { Typography, makeStyles } from "@material-ui/core";

import ActivityTimelineCommentaryInput from "./ActivityTimelineCommentaryInput";
import ActivityTimelineItem from "./ActivityTimelineItem";
import ActivityTimelineItemConnector from "./ActivityTimelineItemConnector";

const ActivityTimeline = ({
  className,
  user,
  activity,
  isCommentaryInputDisabled,
  onCommentaryChange,
  onCommentaryDelete,
  onCommentarySubmit
}) => {
  const { type: lastType } = activity[activity.length - 1];

  return (
    <div className={className}>
      <div>
        {activity.map((item, index) => {
          const isFirst = index === 0;
          const { type: currentType } = item;
          const { type: previousType } = activity[index - 1] ?? {};
          const { type: nextType } = activity[index + 1] ?? {};

          return (
            <div key={index}>
              {
                isFirst
                  ? (
                    null
                  ) : (
                    <ActivityTimelineItemConnector
                      hasExtendedTop={previousType === "UPDATE"}
                      hasReducedTop={previousType === "COMMENTARY"}
                      hasExtendedBottom={currentType === "UPDATE"}
                      hasReducedBottom={currentType === "COMMENTARY"}
                      isActive
                    />
                  )
              }
              <ActivityTimelineItem
                userId={user.id}
                activity={item}
                isLast={nextType === undefined}
                onCommentaryChange={onCommentaryChange}
                onCommentaryDelete={onCommentaryDelete}
              />
            </div>
          );
        })}
        <ActivityTimelineItemConnector
          hasExtendedTop={lastType === "UPDATE"}
          hasReducedTop={lastType === "COMMENTARY"}
        />
      </div>
      <div>
        <ActivityTimelineCommentaryInput
          author={user}
          isDisabled={isCommentaryInputDisabled}
          onSubmit={onCommentarySubmit}
        />
      </div>
    </div>
  );
};

export default ActivityTimeline;
