import React from "react";
import { useTranslation } from "react-i18next";

import {
  Collapse,
  Divider,
  Fade,
  Typography,
  makeStyles
} from "@material-ui/core";

import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  LocalShipping as LocalShippingIcon
} from "@material-ui/icons";

import {
  ChipTagGroup,
  OutlinedIconButton,
  UserAvatar,
  TextTable,
  TextTableRow
} from "../../../cargotic-webapp-component";

import ContactProfileDetailContentBody from "./ContactProfileDetailContentBody";
import ContactProfileDetailContentHeader
  from "./ContactProfileDetailContentHeader";

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    flex: 1
  },
  content: {
    padding: spacing(2),

    "& > :not(:first-child)": {
      marginTop: spacing(3)
    }
  },
  creator: {
    display: "flex",
    alignItems: "center",

    "& > :not(:first-child)": {
      marginLeft: spacing(1)
    }
  },
  header: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    textAlign: "center",
    paddingTop: spacing(2),
    paddingBottom: spacing(2),

    "& > :not(:first-child)": {
      marginTop: spacing(2)
    }
  }
}));

const ContactProfileDetailContent = ({
  contact: {
    type,
    avatarUrl,
    name,
    ico,
    dic,
    email,
    phoneNumber,
    website,
    mailingAddress,
    billingAddress,
    paymentDueDays,
    isBilledOnline,
    billingContact,
    mailingPlace,
    createdAt,
    createdBy,
    tags,
    insuranceExpiresAt,
    ownedBy
  },
  isExpanded,
  isOwnerChangeButtonDisabled,
  onAvatarChange,
  onDeleteButtonClick,
  onEditButtonClick,
  onShipmentButtonClick,
  onTagEditClick,
  onContactOwnerEditClick
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Collapse in={!isExpanded}>
        <ContactProfileDetailContentHeader
          avatarUrl={avatarUrl}
          name={name}
          type={type}
          onAvatarChange={onAvatarChange}
          onDeleteButtonClick={onDeleteButtonClick}
          onEditButtonClick={onEditButtonClick}
          onShipmentButtonClick={onShipmentButtonClick}
        />
      </Collapse>
      <ContactProfileDetailContentBody
        ico={ico}
        dic={dic}
        email={email}
        insuranceExpiresAt={insuranceExpiresAt}
        paymentDueDays={paymentDueDays}
        phoneNumber={phoneNumber}
        mailingAddress={mailingAddress}
        billingAddress={billingAddress}
        billingContact={billingContact}
        isBilledOnline={isBilledOnline}
        createdAt={createdAt}
        createdBy={ownedBy}
        isOwnerChangeButtonDisabled={isOwnerChangeButtonDisabled}
        tags={tags}
        website={website}
        isExpanded={isExpanded}
        onTagEditClick={onTagEditClick}
        onContactOwnerEditClick={onContactOwnerEditClick}
      />
    </div>
  );
};

export default ContactProfileDetailContent;
