import React, { useState } from "react";

import { DatePicker } from "@material-ui/pickers";

import {
  Place,
  Close,
  Warning,
  LockOpen,
  Lock,
  AddCircleOutline,
  RemoveCircleOutline,
  EmojiObjectsOutlined
} from "@material-ui/icons";
import { Link } from "react-router-dom";

import {
  Button,
  TextField,
  makeStyles,
  Chip,
  Checkbox,
  Tooltip,
  IconButton,
  Typography,
  ListItemIcon,
  ListItemText,
  FormControlLabel,
  InputAdornment,
  Select,
  FormHelperText,
  MenuItem,
  FormControl,
  Avatar
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import { Autocomplete } from "@material-ui/lab";

import { formatDateTime } from "../../../utility/common";
import { ContactSchema } from "../../../utility/validationSchema";
import PlacesAutocompleteTextField from "../../common/Input/PlacesAutocompleteTextField";
import { replaceEmptyStringsByNull } from "../../../utility/functional";
import AvatarGroup from "../../../avatarGroup";
import useAuth from "../../hook/useAuth";

import SearchAutosuggestTextField from "../../../../../multiload/cargotic-webapp/component/SearchAutosuggestTextField";
import { useGoogleMapsApi } from "../../../../../multiload/cargotic-map";
import { normalizeGooglePlace } from "../../../../../multiload/cargotic-webapp/utility";
import {
  searchPlace,
  searchPlaceByCoordinates,
  suggestPlace
} from "../../../places";

import ContactsSharingDialog from "./ContactsSharingDialog";
import { ContactType } from "@cargotic/model";

const useStyles = makeStyles(({ spacing, palette }) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    paddingRight: spacing(2),
    paddingBottom: spacing(2),
    paddingLeft: spacing(2),
    marginTop: spacing(2),
    alignItems: "flex-start",
    overflowY: "auto"
  },
  abort: {
    background: palette.status.danger,
    marginRight: spacing(1),
    color: "white",
    "&:hover": {
      background: palette.status.danger,
      color: "white"
    }
  },
  error: {
    color: palette.status.danger
  },
  validInsurance: {
    color: palette.primary.main
  },
  incorrectInsurace: {
    color: palette.status.danger
  },
  closeButton: {
    position: "absolute",
    right: 0,
    top: 0
  },
  actionButtonGroup: {
    alignItems: "flex-end",
    display: "flex",
    padding: spacing(2),
    marginTop: "auto",
    borderTop: "1px solid silver"
  },
  warning: {
    color: palette.warning.light
  },
  avatarGroup: {
    marginRight: spacing(2)
  },
  sharingPanel: {
    display: "flex",
    marginRight: spacing(5),
    marginLeft: "auto",
    alignItems: "center"
  },
  header: {
    display: "flex",
    flexDirection: "row",
    margin: 3
  },
  form: {
    display: "flex",
    flexDirection: "column",
    height: "100%"
  },
  icon: {
    marginRight: spacing(1),
    marginLeft: spacing(1),
    verticalAlign: "middle",
    width: 18,
    height: 18
  }
}));

const ContactsUpdateForm = ({
  selectedContact: {
    id,
    companyName,
    ic,
    dic,
    email,
    phoneNumber,
    website,
    notes,
    handleSearchFail,
    billingPlace: {
      formattedAddress: billingPlaceFormattedAddress,
      googleId: billingPlaceGoogleId
    },
    mailingPlace,
    type,
    insuranceExpiresAt,
    paymentDueDays,
    selected,
    tags,
    assignedCount,
    createdAt,
    creator,
    ownedBy,
    isBilledOnline,
    billingContact,
    isPrivate
  },
  handleClose,
  handleSubmit,
  optionTags,
  sharingUsers,
  setSharingDialogOpen,
  sharingDialogOpen,
  handleShareContact,
  canUpdateCompanyContact
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { user } = useAuth();
  const [billingAddressSuggested, setBillingAddressSuggested] = useState(true);
  const [deliveryAddressSuggested, setDeliveryAddressSuggested] = useState(true);

  const {
    formattedAddress: mailingPlaceFormattedAddress,
    googleId: mailingPlaceGoogleId
  } = mailingPlace || {};

  const [billingAddress, setBillingAddress] = useState({
    title: billingPlaceFormattedAddress,
    googleId: billingPlaceGoogleId
  });
  const [deliveryAddress, setDeliveryAddress] = useState({
    title: mailingPlaceFormattedAddress,
    googleId: mailingPlaceGoogleId
  });

  const onBillingPlaceChange = async (setValues, values, place) => {
    const normalizedPlace = await normalizeGooglePlace(placesService, place.place_id);

    setBillingAddress(normalizedPlace);

    setBillingAddressSuggested(true);
    setValues({ ...values, billingAddress: place.description });
  };

  const onDeliveryPlaceChange = async (setValues, values, place) => {
    const normalizedPlace = await normalizeGooglePlace(placesService, place.place_id);

    setDeliveryAddress(normalizedPlace);

    setDeliveryAddressSuggested(true);
    setValues({ ...values, deliveryAddress: place.description });
  };

  const hasBillingAddressError = (errors, touched) => errors.billingAddress && touched.billingAddress;
  const hasDeliveryAddressError = (errors, touched) => errors.deliveryAddress && touched.deliveryAddress;

  const {
    api: googleMapsApi
  } = useGoogleMapsApi();

  const googlePlaceService = new google.maps.places.PlacesService(document.createElement("div"));
  const googleGeocoderService = new googleMapsApi.Geocoder();
  const googleAutocompleteService = new googleMapsApi.places.AutocompleteService();
  const [placesService, setPlacesService] = useState(googlePlaceService);
  const [geocoderService, setGeocoderService] = useState(googleGeocoderService);

  const [tagValue, setTagValue] = useState(tags);

  return (
    <Formik
      validationSchema={ContactSchema}
      initialValues={{
        companyName,
        ic,
        dic,
        email,
        phoneNumber,
        website,
        notes,
        billingAddress: billingPlaceFormattedAddress,
        deliveryAddress: mailingPlaceFormattedAddress,
        type,
        insuranceExpiresAt: insuranceExpiresAt || null,
        paymentDueDays: paymentDueDays || "",
        isBilledOnline,
        billingContact,
        visibility: isPrivate
      }}
      onSubmit={(values, { setSubmitting }) => {

        replaceEmptyStringsByNull(values);
        setSubmitting(false);

        handleSubmit({
          id,
          companyName: values.companyName,
          ic: values.ic,
          dic: values.dic,
          email: values.email,
          phoneNumber: values.phoneNumber,
          website: values.website,
          notes: values.notes,
          billingPlace: {
            formattedAddress: billingAddress?.address?.formatted || values.billingAddress,
            googleId: billingAddress?.googleId
          },
          mailingPlace: values.deliveryAddress ? {
            formattedAddress: deliveryAddress?.address?.formatted || values.deliveryAddress,
            googleId: deliveryAddress?.googleId
          } : undefined,
          type: values.type,
          tags: tagValue,
          insuranceExpiresAt: values.insuranceExpiresAt,
          paymentDueDays: values.paymentDueDays !== "" ? values.paymentDueDays : undefined,
          billingContact: values.billingContact,
          isBilledOnline: values.isBilledOnline,
          isPrivate: values.visibility
        });
      }}
    >
      {({
        isSubmitting,
        values,
        handleBlur,
        handleChange,
        submitForm,
        errors,
        touched,
        setValues
      }) => (
        <div className={classes.form}>
          <div className={classes.header}>
            <Typography variant="h4">{t("contacts.updateTitle")}</Typography>
            {values.visibility
              ? (
                <div className={classes.sharingPanel}>
                  <AvatarGroup max={2} className={classes.avatarGroup}>
                    {sharingUsers.map(({ name, id, avatarUrl, email }) => (
                      <Tooltip
                        key={id}
                        title={
                          <>
                            <Typography>{name}</Typography>
                            <Typography>{email}</Typography>
                          </>
                        }
                      >
                        <Avatar key={id} alt={name} src={avatarUrl}>{name.split(" ").map((x) => x[0]).join("")}</Avatar>
                      </Tooltip>
                    ))}
                  </AvatarGroup>
                  <Button
                    variant="outlined"
                    color="primary"
                    disabled={user.id !== creator.id && isPrivate && !canUpdateCompanyContact}
                    onClick={() => setSharingDialogOpen(true)}
                  >
                    {t("contacts.sharing.title")}
                  </Button>
                </div>
              ) : undefined}
          </div>
          <form className={classes.container}>
            {values.visibility
              ? (
                <ContactsSharingDialog
                  isOpen={sharingDialogOpen}
                  handleClose={() => setSharingDialogOpen(false)}
                  handleSubmit={handleShareContact}
                  sharingUsers={sharingUsers}
                  contact={{
                    id,
                    companyName,
                    ic,
                    dic,
                    email,
                    phoneNumber,
                    website,
                    notes,
                    handleSearchFail,
                    billingPlace: {
                      formattedAddress: billingPlaceFormattedAddress,
                      googleId: billingPlaceGoogleId
                    },
                    mailingPlace,
                    type,
                    insuranceExpiresAt,
                    paymentDueDays,
                    selected,
                    tags,
                    assignedCount,
                    createdAt,
                    creator,
                    isBilledOnline,
                    billingContact,
                    isPrivate
                  }}
                />
              ) : null
            }
            <div>
              {assignedCount ? (<Typography align="right" gutterBottom style={{ float: "right", marginBottom: 8 }}>{t("contacts.assignedShipments", { assignedCount })}</Typography>) : null}
              <Typography align="left" style={{ marginBottom: 8 }}>{t("contacts.createdAt", { createdAt: formatDateTime(new Date(createdAt)), creator: creator.name })}</Typography>
              <TextField
                name="companyName"
                error={errors.companyName && touched.companyName}
                label={t("contacts.companyName")}
                onChange={handleChange}
                value={values.companyName ?? ""}
                required
                helperText={touched.companyName && t(errors.companyName)}
                fullWidth
                onBlur={handleBlur}
              />
              <TextField
                name="ic"
                error={errors.ic && touched.ic}
                label="IČ"
                fullWidth
                helperText={touched.ic && t(errors.ic)}
                value={values.ic ?? ""}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <TextField
                name="dic"
                error={errors.dic && touched.dic}
                label="DIČ"
                fullWidth
                helperText={touched.dic && t(errors.dic)}
                required
                value={values.dic ?? ""}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <TextField
                type="email"
                name="email"
                helperText={touched.email && t(errors.email)}
                fullWidth
                error={errors.email && touched.email}
                label={t("contacts.email")}
                value={values.email ?? ""}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <TextField
                name="phoneNumber"
                label={t("phone")}
                type="tel"
                error={errors.phoneNumber && touched.phoneNumber}
                value={values.phoneNumber ?? ""}
                helperText={touched.phoneNumber && t(errors.phoneNumber)}
                onChange={handleChange}
                fullWidth
                onBlur={handleBlur}
              />
              <TextField
                name="website"
                label="Web"
                value={values.website ?? ""}
                fullWidth
                type="url"
                error={errors.website && touched.website}
                helperText={touched.website && t(errors.website)}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <TextField
                name="notes"
                label={t("contacts.notes")}
                value={values.notes ?? ""}
                fullWidth
                error={errors.notes && touched.notes}
                helperText={touched.notes && t(errors.notes)}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <TextField
                name="paymentDueDays"
                label={t("contacts.dueDays")}
                value={values.paymentDueDays ?? ""}
                fullWidth
                error={errors.paymentDueDays && touched.paymentDueDays}
                helperText={touched.paymentDueDays && t(errors.paymentDueDays)}
                onChange={handleChange}
                onBlur={handleBlur}
                type="number"
              />
              <SearchAutosuggestTextField
                required
                fullWidth
                hideCargoPlaces
                name="billingAddress"
                onBlur={handleBlur}
                text={values.billingAddress ?? ""}
                value={billingAddress}
                helperText={hasBillingAddressError(errors, touched) && t(errors.billingAddress)}
                error={hasBillingAddressError(errors, touched)}
                label={t("contacts.billingAddress")}
                placeholder={t("contacts.address")}
                renderSuggestion={({ description }) => (
                  <>
                    <ListItemIcon>
                      <Place />
                    </ListItemIcon>
                    <ListItemText primary={description} />
                  </>
                )}
                search={(text) => searchPlace(placesService, text)}
                searchByCoordinates={(latitude, longitude) =>
                  searchPlaceByCoordinates(geocoderService, placesService, latitude, longitude)}
                onSearchFail={handleSearchFail}
                suggest={(text) => suggestPlace(googleAutocompleteService, text)}
                onTextChange={(text) => {
                  setValues({ ...values, billingAddress: text });
                  setBillingAddressSuggested(false);
                }}
                onValueChange={(place) => onBillingPlaceChange(setValues, values, place)}
              />
              <SearchAutosuggestTextField
                fullWidth
                hideCargoPlaces
                name="deliveryAddress"
                text={values.deliveryAddress ?? ""}
                value={deliveryAddress}
                helperText={hasDeliveryAddressError(errors, touched) && t(errors.deliveryAddress)}
                error={hasDeliveryAddressError(errors, touched)}
                label={t("contacts.deliveryAddress")}
                placeholder={t("contacts.address")}
                renderSuggestion={({ description }) => (
                  <>
                    <ListItemIcon>
                      <Place />
                    </ListItemIcon>
                    <ListItemText primary={description} />
                  </>
                )}
                search={(text) => searchPlace(placesService, text)}
                searchByCoordinates={(latitude, longitude) =>
                  searchPlaceByCoordinates(geocoderService, placesService, latitude, longitude)}
                onBlur={handleBlur}
                onSearchFail={handleSearchFail}
                suggest={(text) => suggestPlace(googleAutocompleteService, text)}
                onTextChange={(text) => {
                  setValues({ ...values, deliveryAddress: text });
                  setDeliveryAddressSuggested(text === "");
                }}
                onValueChange={(place) => onDeliveryPlaceChange(setValues, values, place)}
              />
              <Autocomplete
                multiple
                id="contact-tags"
                options={optionTags}
                value={tagValue}
                filterSelectedOptions
                noOptionsText={t("contact.noTag")}
                // groupBy={(item) => item.type}
                getOptionSelected={(
                  option,
                  value,
                ) => value.value === option.value}
                onChange={(event, newValue) => {
                  event.preventDefault();
                  return setTagValue(newValue);
                }}
                getOptionLabel={(option) => (option.geopoliticalType !== null ? `${option.value} / ${option.localizedGeopoliticalType}` : `${option.value} / ${option.localizedType}`)}
                renderTags={(value, getTagProps) => (
                  value.map(({
                    value
                  }, index) => (
                    <Chip key={index} label={value} {...getTagProps({ index })} />
                  )))}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label={t("contact.tags")}
                    placeholder="Tag"
                  />
                )}
              />
              <DatePicker
                name="insuranceExpiresAt"
                format="dd.MM.yyyy"
                value={values.insuranceExpiresAt}
                label={t("contacts.insurance")}
                onChange={(value) => setValues({ ...values, insuranceExpiresAt: value })}
                ampm={false}
                renderInput={(props) => <TextField variant="outlined" {...props} helperText={undefined} />}
                fullWidth
                InputProps={{
                  endAdornment:
                    (values.insuranceExpiresAt == null || values.insuranceExpiresAt < new Date()) && values.type !== ContactType.CUSTOMER
                      ? (
                        <InputAdornment position="end">
                          <Tooltip title={values.insuranceExpiresAt ? t("contacts.incorrectInsurance") : t("contacts.undefinedInsurance")}>
                            <IconButton className={values.insuranceExpiresAt ? classes.error : classes.warning} size="small">
                              <Warning />
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ) : null
                }}
              />
              <TextField
                name="billingContact"
                label={t("contacts.billingContact")}
                value={values.billingContact ?? ""}
                fullWidth
                error={errors.billingContact && touched.billingContact}
                helperText={touched.billingContact && t(errors.billingContact)}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <FormControlLabel
                style={{ marginBottom: 0 }}
                control={(
                  <Checkbox
                    name="isBilledOnline"
                    checked={values.isBilledOnline}
                    onChange={handleChange}
                  />
                )}
                label={t("contacts.electronicalBilling")}
              />
            </div>
          </form>
          <div className={classes.actionButtonGroup}>
            <div style={{ flex: 3 }}>
              <FormControl>
                <Select
                  value={values.visibility}
                  name="visibility"
                  variant="outlined"
                  disabled={user.id !== creator.id && isPrivate && !canUpdateCompanyContact}
                  inputProps={{
                    name: "visibility"
                  }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="number"
                >
                  <MenuItem value>
                    <Lock className={classes.icon} />
                    {t("contacts.private")}
                  </MenuItem>
                  <MenuItem value={false}>
                    <LockOpen className={classes.icon} />
                    {t("contacts.public")}
                  </MenuItem>
                </Select>
                <FormHelperText error={errors.visibility && touched.visibility}>{touched.visibility && t(errors.visibility)}</FormHelperText>
              </FormControl>
              <Tooltip title={t("contacts.visibilityTooltip")}>
                <IconButton>
                  <EmojiObjectsOutlined />
                </IconButton>
              </Tooltip>
            </div>
            <div style={{ flex: 4, textAlign: "right" }}>
              <Button onClick={handleClose} variant="contained" className={classes.abort}>
                {t("abort")}
              </Button>
              <Button onClick={submitForm} variant="contained" color="primary" disabled={isSubmitting}>
                {t("update")}
              </Button>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
};

export default ContactsUpdateForm;
