import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { TextField, IconButton, InputAdornment, makeStyles, Tooltip } from "@material-ui/core";
import { Close, Search } from "@material-ui/icons";

const useStyles = makeStyles(() => ({
  field: {
    marginBottom: 0,
    minWidth: '250px',
    marginTop: '15px'
  }
}));

const SearchTextfieldIco = ({
  handleSearch,
  value: inputValue = "",
  fullWidth = false,
  placeholder
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(inputValue);
  const classes = useStyles();

  const withTooltip = (value, props) => (<Tooltip title={value === "" ? (placeholder ?? t("search")) : ""} placement="right" arrow>
    {props}
  </Tooltip>);

  const content = (
    <TextField
      placeholder={placeholder ?? t("search")}
      value={value}
      variant="outlined"

      onChange={e => {
        //handleSearch(e.target.value);
        setValue(e.target.value);
      }}
      classes={{
        root: classes.field
      }}
      InputProps={{
        style: {
          paddingRight: 0,
          marginBottom: 0
        },
        endAdornment: (
          <InputAdornment position="end">
            {value
              ? (
                <IconButton
                  onClick={() => {
                    setValue("");
                  }}
                >
                  <Close />
                </IconButton>
              )
              : null}
            <IconButton
              onClick={e => {
                handleSearch(value);
              }}
            >
              <Search/>
            </IconButton>
          </InputAdornment>
        )
      }}
    />);

  return withTooltip(value, content);
}

export default SearchTextfieldIco;
