import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

import { formatDate } from "@cargotic/common";
import {
  IconButton,
  Link,
  Paper,
  Tooltip,
  Typography,
  makeStyles
} from "@material-ui/core";

import {
  CreditCard as CreditCardIcon,
  LocalShipping as LocalShippingIcon,
  Edit as EditIcon,
  Delete as DeleteIcon
} from "@material-ui/icons";

import {
  OutlinedIconButton,
  TextTable,
  TextTableRow,
  UserAvatar
} from "../../../cargotic-webapp-component";

import {
  formatDimensions,
  formatMonthAndYear
} from "../../../cargotic-webapp-utility";

import { VehicleType } from "@cargotic/model";

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    padding: spacing(2),

    "& > :last-child": {
      marginTop: spacing(2)
    }
  },
  header: {
    display: "flex",
    justifyContent: "space-between",

    "& > div": {
      display: "flex",
      alignItems: "center",

      "&:first-child > span": {
        display: "inline-block",

        "&:not(:first-child)": {
          marginLeft: spacing(2)
        }
      },

      "&:last-child > :not(:first-child)": {
        marginLeft: spacing(1)
      }
    }
  },
  marginLeft: {
    marginLeft: spacing(1)
  },
  trailer: {
    display: "flex",
    alignItems: "center",

    "& > :last-child": {
      marginLeft: spacing(1)
    }
  }
}));

const VehicleProfileDetail = ({
  className,
  vehicle: {
    manufacturer,
    model,
    vin,
    type,
    emissionClass,
    licensePlate,
    defaultTrailer: {
      id: trailerId,
      model: trailerModel,
      manufacturer: trailerManufacturer,
      licensePlate: trailerLicensePlate
    } = {},
    cargoSpaceDimensions,
    cargoSpaceLoad,
    manufacturedAt,
    defaultDriver
  },
  isShipmentCreateButtonDisabled,
  isDeleteButtonDisabled,
  isEditButtonDisabled,
  onDeleteButtonClick,
  onEditButtonClick,
  onShipmentCreateButtonClick,
  onVehicleTrailerEditClick,
  onVehicleDriverEditClick
}) => {
  const { t, i18n: { language } } = useTranslation();
  const classes = useStyles();

  const details = [];
  details.push(
    [
      t("webapp:vehicle.common.vin"),
      vin ?? "-"
    ]
  );

  if (type === VehicleType.CAR) {
    details.push(
      [
        t("webapp:vehicle.title.default-trailer"),
        trailerModel
          ? (
            <div className={classes.trailer}>
              <Typography>
                <Link component={RouterLink} to={`/vehicles/${trailerId}`}>
                  {`${trailerManufacturer} ${trailerModel}, ${trailerLicensePlate}`}
                </Link>
              </Typography>
              <IconButton
                size="small"
                onClick={onVehicleTrailerEditClick}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </div>
          )
          : (
            <Typography>
              <Link
                component="button"
                onClick={onVehicleTrailerEditClick}
              >
                {t("webapp:vehicle.title.assign-trailer")}
              </Link>
            </Typography>
          )
      ], [
      t("webapp:vehicle.title.default-driver"),
      defaultDriver ? (
        <div className={classes.trailer}>
          <Typography>
            <Link component={RouterLink} to={`/users/${defaultDriver.id}`}>
              <span><UserAvatar user={defaultDriver} size="small" /></span>
              <span className={classes.marginLeft}>{`${defaultDriver.name}`}</span>
            </Link>
          </Typography>
          <IconButton
            size="small"
            onClick={onVehicleDriverEditClick}
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </div>
      )
        : (
          <Typography>
            <Link
              component="button"
              onClick={onVehicleDriverEditClick}
            >
              {t("webapp:vehicle.title.assign-driver")}
            </Link>
          </Typography>
        )
    ]
    );
  }

  details.push(
    [
      t("webapp:vehicle.common.cargoSpaceDimensions"),
      cargoSpaceDimensions
        ? `${formatDimensions(cargoSpaceDimensions)} m`
        : "-"
    ],
    [
      t("webapp:vehicle.common.cargoSpaceLoad"),
      cargoSpaceLoad ? `${cargoSpaceLoad} kg` : "-"
    ],
    [
      t("webapp:vehicle.common.manufacturedAt"),
      manufacturedAt ? formatMonthAndYear(manufacturedAt, language) : "-"
    ]
  );

  if (type === VehicleType.CAR) {
    details.push([
      t("webapp:vehicle.common.emissionClass"),
      emissionClass ?? "-"
    ]);
  }

  return (
    <Paper className={clsx(className, classes.root)}>
      <section className={classes.header}>
        <div>
          <span>
            <Typography variant="h5">
              {`${manufacturer} ${model}`}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              {licensePlate}
            </Typography>
          </span>
        </div>
        <div>
          <Tooltip
            title={t("webapp:vehicle.title.createShipment")}
            disableFocusListener={isShipmentCreateButtonDisabled}
            disableHoverListener={isShipmentCreateButtonDisabled}
            disableTouchListener={isShipmentCreateButtonDisabled}
          >
            <span>
              <OutlinedIconButton
                disabled={isShipmentCreateButtonDisabled}
                onClick={onShipmentCreateButtonClick}
              >
                <LocalShippingIcon />
              </OutlinedIconButton>
            </span>
          </Tooltip>
          <Tooltip
            title={t("webapp:vehicle.title.editVehicle")}
            disableFocusListener={isEditButtonDisabled}
            disableHoverListener={isEditButtonDisabled}
            disableTouchListener={isEditButtonDisabled}
          >
            <span>
              <OutlinedIconButton
                disabled={isEditButtonDisabled}
                onClick={onEditButtonClick}
              >
                <EditIcon />
              </OutlinedIconButton>
            </span>
          </Tooltip>
          <Tooltip
            title={t("webapp:vehicle.title.deleteVehicle")}
            disableFocusListener={isDeleteButtonDisabled}
            disableHoverListener={isDeleteButtonDisabled}
            disableTouchListener={isDeleteButtonDisabled}
          >
            <span>
              <OutlinedIconButton
                disabled={isDeleteButtonDisabled}
                onClick={onDeleteButtonClick}
              >
                <DeleteIcon />
              </OutlinedIconButton>
            </span>
          </Tooltip>
        </div>
      </section>
      <section>
        <TextTable>
          {details.map(([label, value]) => (
            <TextTableRow key={label} label={label} value={value} />
          ))}
        </TextTable>
      </section>
    </Paper>
  );
};

export default VehicleProfileDetail;
