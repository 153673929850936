import React from "react";
import { useTranslation } from "react-i18next";

import {
  Avatar,
  Checkbox,
  CircularProgress,
  DialogContent,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  makeStyles
} from "@material-ui/core";

import { getNameInitials } from "../../../cargotic-webapp-utility";

const useStyles = makeStyles(() => ({
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    height: "100%"
  }
}));

const ContactShareEditorSelectList = ({
  users,
  isLoading,
  onDeselect,
  onSelect
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <div className={classes.center}>
        <CircularProgress />
      </div>
    );
  }

  if (users.length === 0) {
    return (
      <div className={classes.center}>
        <Typography color="textSecondary">
          {t("webapp:common.title.noUserFound")}
        </Typography>
      </div>
    );
  }

  return (
    <List>
      {users.map(({
        id,
        email,
        name,
        avatarUrl,
        isSelected,
        ownerShare,
        userShare
      }) => {
        //console.log(name, ownerShare, userShare);
        const handleChange = () => {
          if (isSelected) {
            onDeselect(id);
          } else {
            onSelect(id);
          }
        };

        return (
          <ListItem button key={id} onClick={handleChange}>
            <ListItemAvatar>
              <Avatar
                alt={name}
                src={avatarUrl}
              >
                {getNameInitials(name)}
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={name} secondary={email} />
            {ownerShare && userShare ? `${ownerShare} / ${userShare}%` : undefined}
            <ListItemSecondaryAction>
              <Checkbox
                color="primary"
                checked={isSelected}
                edge="end"
                onChange={handleChange}
              />
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
  );
};

export default ContactShareEditorSelectList;
