import React, { useState } from "react";

import { Formik } from "formik";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  TextField
} from "@material-ui/core";

import { DateTimePicker } from "@material-ui/pickers";

import { useTranslation } from "react-i18next";

const UpdateDriveThroughDialog = ({
  drivenThroughAt,
  isOpen,
  handleSubmit,
  handleClose
}) => {
  const { t } = useTranslation();

  return (
    <Dialog open={isOpen} onClose={handleClose} disableEnforceFocus>
      <DialogTitle>
        {t("shipments.driveThrough")}
      </DialogTitle>
      <Formik
        initialValues={{
          drivenThroughAt: drivenThroughAt ?? new Date()
        }}
        validateOnChange
        validate={({ drivenThroughAt }) => {
          if (drivenThroughAt instanceof Date) {
            if (drivenThroughAt > new Date()) {
              return { drivenThroughAt: t("shipments.validation.drivenThroughAtFuture") };
            }
            else if (drivenThroughAt.getTime() !== drivenThroughAt.getTime()) {
              return { drivenThroughAt: t("shipments.validation.drivenThroughAtValid") };
            }
          } else {
            return { drivenThroughAt: t("shipments.validation.drivenThroughAtValid") };
          }

          return {};
        }}
        onSubmit={({ drivenThroughAt }) => {
          handleSubmit(drivenThroughAt);
        }}
      >
        {({
          values,
          setValues,
          setErrors,
          setFieldError,
          handleBlur,
          handleChange,
          submitForm,
          resetForm,
          touched,
          errors,
          isSubmitting
        }) => (
            <>
              <DialogContent>
                <form>
                  <DateTimePicker
                    value={values.drivenThroughAt}
                    allowKeyboardControl
                    ampm={false}
                    fullWidth
                    disableMaskedInput
                    inputFormat="dd.MM.yyyy HH:mm"
                    renderInput={(props) => <TextField
                      {...props}
                      variant="outlined"
                      label={t("shipments.driveThroughAt")}
                      error={errors.drivenThroughAt && touched.drivenThroughAt}
                      helperText={touched.drivenThroughAt && errors.drivenThroughAt}
                      name="drivenThroughAt"
                      onBlur={handleBlur}
                    />}
                    onChange={(date) => {
                      setValues({ drivenThroughAt: date });
                    }}
                  />
                </form>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  {t("shipments.cancel")}
                </Button>
                <Button onClick={submitForm} color="primary" disable={isSubmitting}>
                  {t("shipments.driveThrough")}
                </Button>
              </DialogActions>
            </>
          )}
      </Formik>
    </Dialog>
  )
}

export default UpdateDriveThroughDialog;
