import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { cs, enUS } from "date-fns/locale";

import {
  makeStyles,
  Grid,
  IconButton,
  Collapse,
  Tooltip
} from "@material-ui/core";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Tune, ExpandMore, Clear, ExpandLess, Settings } from "@material-ui/icons";
import { StaticDateRangePicker, DateRangeDelimiter, DateRange, LocalizationProvider } from "@material-ui/pickers";
import DateFnsAdapter from "@material-ui/pickers/adapter/date-fns";

const useStyles = makeStyles(({ spacing }) => ({
  container: {
    paddingBottom: spacing(1)
  },
  clearButton: {
    textTransform: "none"
  },
  wrapper: {
    display: "block"
  },
  item: {
    paddingRight: spacing(1)
  },
  flexEnd: {
    display: "flex",
    justifyContent: "flex-end"
  }
}));


const FilterContainer = ({
  onClear,
  filters,
  loading,
  searchField,
  showClearButton,
  handleFilterSettingsOpen,
  defaultFilters,
  onOpenSelectColumnsDialog
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    const hiddenFilters = getFilters(false);
    const selectedFiltersInHiddenContainer = hiddenFilters.some(({ props: { value } }) => Array.isArray(value) ? value.length > 0 : value !== undefined);
    setIsExpanded(Boolean(selectedFiltersInHiddenContainer));
  }, [defaultFilters]);

  const sortedFilters = defaultFilters.map(item => filters.find(i => i.key === item.value));
  const getFilters = (firstPart) => firstPart ? sortedFilters : filters.filter(item => !defaultFilters.find(i => i.value === item.key));


  const SelectColumnsButton = ({ onClick }) => (
    <Tooltip title={t("webapp:common.tooltip.select-columns-settings")} variant="dense">
      <IconButton aria-label="filter list" size="medium" onClick={onClick}>
        <Settings />
      </IconButton>
    </Tooltip>
  );

  return (
    <Grid container spacing={2} className={classes.container} alignItems="center">
      <Grid item xs={3}>
        {searchField}
      </Grid>
      <Grid item container xs={7} spacing={1}>
        {getFilters(true).map((comp) => {
          const component = { ...comp, props: { ...comp.props, disabled: loading } };

          return (
            <Grid item key={component.key}>
              {component}
            </Grid>
          )
        })}
        <Collapse in={isExpanded} timeout="auto">
          <Grid container direction="row" item spacing={1}>
            {getFilters(false).map((comp) => {
              const component = { ...comp, props: { ...comp.props, disabled: loading } };

              return (
                <Grid item key={component.key} className={classes.item}>
                  {component}
                </Grid>
              )
            })}
          </Grid>
        </Collapse>
      </Grid>
      <Grid item xs={2} className={classes.flexEnd}>
        {filters.length > defaultFilters.length ? (
          <IconButton
            disableRipple
            className={classes.clearButton}
            onClick={() => {
              setIsExpanded(!isExpanded);
            }}
          >
            {isExpanded ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        ) : null}
        {showClearButton() ? (
          <IconButton disableRipple onClick={onClear} className={classes.clearButton}>
            <Clear />
          </IconButton>
        ) : null}
        <Tooltip title={t("webapp:common.tooltip.filter-settings")}>
          <IconButton onClick={handleFilterSettingsOpen}>
            <Tune />
          </IconButton>
        </Tooltip>
        {onOpenSelectColumnsDialog ? <SelectColumnsButton onClick={onOpenSelectColumnsDialog} /> : undefined}
      </Grid>
    </Grid >
  );
};

export default FilterContainer;
