import React from "react";

import { makeStyles } from "@material-ui/core";

import VehicleEditor from "../VehicleEditor";
import VehicleDeleteConfirmationAlert from "../VehicleDeleteConfirmationAlert";
import VehicleProfileHeader from "./VehicleProfileHeader";
import VehicleProfileBody from "./VehicleProfileBody";
import VehicleTrailerEditor from "../VehicleTrailerEditor";
import VehicleDriverEditor from "../VehicleDriverEditor";

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100%",

    "& > div:last-child": {
      flex: 1,
      marginTop: spacing(1),
      marginBottom: spacing(2)
    }
  }
}));

const VehicleProfile = ({
  activity,
  breadcrumbs,
  expirationPage,
  expirationQuery,
  expirationRowsPerPage,
  expirations,
  latestExpirations,
  selectedExpiration,
  user,
  vehicle,
  view,
  isActivityLoading,
  isVehicleDeletionDisabled,
  isVehicleDeleteConfirmationAlertOpen,
  isVehicleEditationDisabled,
  isExpenseTabDisabled,
  isIncomeTabDisabled,
  isExpirationCreationDisabled,
  isExpirationDeleteConfirmationAlertOpen,
  isExpirationDeletionDisabled,
  isExpirationEditationDisabled,
  isExpirationEditorOpen,
  isExpirationsLoading,
  isExpirationTabDisabled,
  isIncomeCreationDisabled,
  isIncomeDeletionDisabled,
  isIncomeEditationDisabled,
  isVehicleEditorOpen,
  isVehicleTrailerEditorOpen,
  isVehicleDriverEditorOpen,
  isShipmentCreationDisabled,
  onActivityLoad,
  onAvatarChange,
  onBreadcrumbsChange,
  onCommentaryChange,
  onCommentaryDelete,
  onCommentarySubmit,
  onVehicleDeleteConfirmationAlertClose,
  onVehicleDeleteConfirmationAlertOpen,
  onVehicleDeleteConfirmationAlertSubmit,
  onExpirationAdd,
  onExpirationDelete,
  onExpirationDeleteConfirmationAlertClose,
  onExpirationDeleteConfirmationAlertSubmit,
  onExpirationEdit,
  onExpirationEditorClose,
  onExpirationEditorSubmit,
  onExpirationPageChange,
  onExpirationQueryChange,
  onExpirationRowsPerPageChange,
  onVehicleEditorClose,
  onVehicleEditorOpen,
  onVehicleEditorSubmit,
  onVehicleTrailerEditorClose,
  onVehicleDriverEditorClose,
  onVehicleTrailerEditorOpen,
  onVehicleDriverEditorOpen,
  onVehicleTrailerEditorSubmit,
  onVehicleDriverEditorSubmit,
  onShipmentCreate,
  onViewChange
}) => {
  const classes = useStyles();

  const { id, avatarUrl } = vehicle;

  const handleVehicleDeleteButtonClick = () => {
    if (onVehicleDeleteConfirmationAlertOpen) {
      onVehicleDeleteConfirmationAlertOpen();
    }
  };

  const handleVehicleEditButtonClick = () => {
    if (onVehicleEditorOpen) {
      onVehicleEditorOpen();
    }
  };

  return (
    <>
      <div className={classes.root}>
        <VehicleProfileHeader
          breadcrumbs={breadcrumbs}
          latestExpirations={latestExpirations}
          vehicle={vehicle}
          isDeleteButtonDisabled={isVehicleDeletionDisabled}
          isEditButtonDisabled={isVehicleEditationDisabled}
          isExpirationCreateButtonDisabled={isExpirationCreationDisabled}
          isShipmentCreateButtonDisabled={isShipmentCreationDisabled}
          onAvatarChange={onAvatarChange}
          onDeleteButtonClick={handleVehicleDeleteButtonClick}
          onEditButtonClick={handleVehicleEditButtonClick}
          onExpirationAdd={onExpirationAdd}
          onShipmentCreateButtonClick={onShipmentCreate}
          onVehicleTrailerEditClick={onVehicleTrailerEditorOpen}
          onVehicleDriverEditClick={onVehicleDriverEditorOpen}
        />
        <VehicleProfileBody
          className={classes.body}
          expirations={expirations}
          expirationPage={expirationPage}
          expirationQuery={expirationQuery}
          expirationRowsPerPage={expirationRowsPerPage}
          selectedExpiration={selectedExpiration}
          view={view}
          user={user}
          activity={activity}
          vehicle={vehicle}
          isActivityLoading={isActivityLoading}
          isExpenseTabDisabled={isExpenseTabDisabled}
          isIncomeTabDisabled={isIncomeTabDisabled}
          isExpirationCreationDisabled={isExpirationCreationDisabled}
          isExpirationDeleteConfirmationAlertOpen={
            isExpirationDeleteConfirmationAlertOpen
          }
          isExpirationDeletionDisabled={isExpirationDeletionDisabled}
          isExpirationEditationDisabled={isExpirationEditationDisabled}
          isExpirationEditorOpen={isExpirationEditorOpen}
          isExpirationsLoading={isExpirationsLoading}
          isExpirationTabDisabled={isExpirationTabDisabled}
          isIncomeCreationDisabled={isIncomeCreationDisabled}
          isIncomeDeletionDisabled={isIncomeDeletionDisabled}
          isIncomeEditationDisabled={isIncomeEditationDisabled}
          onActivityLoad={onActivityLoad}
          onBreadcrumbsChange={onBreadcrumbsChange}
          onCommentaryChange={onCommentaryChange}
          onCommentaryDelete={onCommentaryDelete}
          onCommentarySubmit={onCommentarySubmit}
          onExpirationAdd={onExpirationAdd}
          onExpirationDelete={onExpirationDelete}
          onExpirationDeleteConfirmationAlertClose={
            onExpirationDeleteConfirmationAlertClose
          }
          onExpirationDeleteConfirmationAlertSubmit={
            onExpirationDeleteConfirmationAlertSubmit
          }
          onExpirationEdit={onExpirationEdit}
          onExpirationEditorClose={onExpirationEditorClose}
          onExpirationEditorSubmit={onExpirationEditorSubmit}
          onExpirationPageChange={onExpirationPageChange}
          onExpirationQueryChange={onExpirationQueryChange}
          onExpirationRowsPerPageChange={onExpirationRowsPerPageChange}
          onViewChange={onViewChange}
        />
      </div>
      <VehicleDeleteConfirmationAlert
        isOpen={isVehicleDeleteConfirmationAlertOpen}
        onClose={onVehicleDeleteConfirmationAlertClose}
        onSubmit={onVehicleDeleteConfirmationAlertSubmit}
      />
      <VehicleEditor
        initialValue={vehicle}
        isOpen={isVehicleEditorOpen}
        onClose={onVehicleEditorClose}
        onSubmit={onVehicleEditorSubmit}
      />
      <VehicleTrailerEditor
        trailer={vehicle.defaultTrailer}
        isOpen={isVehicleTrailerEditorOpen}
        onClose={onVehicleTrailerEditorClose}
        onSubmit={onVehicleTrailerEditorSubmit}
      />
      <VehicleDriverEditor
        driver={vehicle.defaultDriver}
        isOpen={isVehicleDriverEditorOpen}
        onClose={onVehicleDriverEditorClose}
        onSubmit={onVehicleDriverEditorSubmit}
      />
    </>
  );
};

export default VehicleProfile;
