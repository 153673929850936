const debounce = (period: number) => (
    <T extends (...args: any[]) => any>(execute: T) => {
      let timeout: number;
      return (...args: Parameters<T>) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => execute(...args), period);
      };
    }
);

export default debounce;
