import {
  Button,
  FormControlLabel,
  Radio,
  Stepper,
  Step,
  StepButton,
  StepContent,
  TextField,
  Typography,
  makeStyles,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Tooltip,
  Fab,
  List,
  IconButton,
  ListItem, ListItemAvatar, Avatar, ListItemText, Divider
} from "@material-ui/core";

import {
  Lock as LockIcon,
  LockOpen as LockOpenIcon,
  FlashOn as FlashOnIcon, Add
} from "@material-ui/icons";
import React, {useEffect, useState} from "react";
import {createRating, deleteRating, getRatings} from "../cargotic-webapp/resource";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import {Stack} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {ImageAvatar} from "../cargotic-webapp/component/common";
import {getNameInitials} from "../cargotic-webapp-utility";
import {useTranslation} from "react-i18next";
import clsx from "clsx";


const useStyles = makeStyles(({ spacing }) => ({
  actions: {
    position: "fixed",
    bottom: spacing(3),
    right: spacing(3),
    "& > :not(:last-child)": {
      marginRight: spacing(1)
    }
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  },
  list: {
    width: '100%',
    maxWidth: 500
  },
  progress: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
}));

const RatingPanel = ({
 contactId,
 ratingTypeView
}) => {

  const { t } = useTranslation();
  const classes = useStyles();

  const [isAddRatingModalOpen, setIsAddRatingModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ratings, setRatings] = useState({
    ratings: [],
    canAddRating: false
  });

  const fetchRatings = () => {
    setLoading(true);
    getRatings(contactId).then(({ data }) => {
      console.log(data)
      setRatings(data);
      setLoading(false);
    })
  };

  useEffect(() => {
    fetchRatings();
  }, []);

  const RatingForm = ({
    isAddRatingModalOpen,
    setIsAddRatingModalOpen
  }) => {

    const [ratingType, setRatingType] = useState(1);
    const [ratingText, setRatingText] = useState("");
    const MAX_LENGTH = 500;

    const onSubmit = () => {
      setIsAddRatingModalOpen(false);
      setLoading(true);
      createRating(contactId, ratingType, ratingText).then(() => fetchRatings());
    };

    return (
      <Dialog
        open={isAddRatingModalOpen}
        onClose={() => setIsAddRatingModalOpen(false)}
      >
        <DialogTitle>{t("newRating")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FormControl component="fieldset" sx={{ width: 350 }}>
              <FormLabel component="legend">{t('experience')}</FormLabel>
              <FormGroup aria-label="position" column>
                <FormControlLabel
                  value="end"
                  control={<Switch
                    aria-label="rating-type"
                    checked={ratingType === 1}
                    onChange={() => setRatingType(ratingType === 0 ? 1 : 0)}
                    sx={{
                      "&.MuiSwitch-root .MuiSwitch-switchBase": {
                        color: "red"
                      },

                      "&.MuiSwitch-root .Mui-checked": {
                        color: "green"
                      }
                    }}
                  />}
                  label={ratingType === 1 ? t('positive') : t('negative')}
                  labelPlacement="end"
                />
                <FormControlLabel sx={{width: '100%'}} control={
                  <TextField
                    fullWidth
                    id="standard-multiline-static"
                    label={`${t('ratings')} (${t('left')} ${MAX_LENGTH - ratingText.length} ${t('chars')})`}
                    multiline
                    variant="standard"
                    onChange={(e) => {
                      if (e.target.value.length <= MAX_LENGTH) {
                        setRatingText(e.target.value)
                      } else {
                        e.target.value = ratingText;
                      }
                    }}
                  />
                }/>
              </FormGroup>
            </FormControl>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsAddRatingModalOpen(false)} color="secondary">
            {t("abort")}
          </Button>
          <Button onClick={onSubmit} color="primary" data-cy="add-rating-confirm">
            {t("add")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const formatDate = (date) => {
    if (!date) {
      return '-';
    }
    date = new Date(date);
    return `${date.getDate().toString().padStart(0, 2)}.${(date.getMonth() + 1).toString().padStart(0, 2)}.${date.getFullYear()}`;
  };

  const filteredRatings = ratings.ratings.filter((rating) => {
    if (ratingTypeView === 3) {
      return true;
    } else if (ratingTypeView === 1) {
      return rating.type == 1;
    }
    return rating.type == 0;
  });

  return (
    loading ?
      <div className={classes.progress}>
        <CircularProgress />
      </div>
      :
    <>
      <div className={classes.actions}>
        <Tooltip title={t("newRating")}>
            <span>
              <Fab
                color="primary"
                disabled={!ratings.canAddRating}
                onClick={() => setIsAddRatingModalOpen(true)}
              >
                <Add />
              </Fab>
            </span>
        </Tooltip>
      </div>
      <div>
        {
          filteredRatings.length > 0 ?

            <List className={classes.list}
            >
              {filteredRatings.map((rating) => {
                const { id, isMine, type, text, createdAt, userAvatarUrl, userName } = rating;
                return (
                  <div>
                    <ListItem style={{
                      position: 'relative'
                    }}>
                      {isMine ?
                        <div>
                          <IconButton onClick={
                            () => {
                              setLoading(true);
                              deleteRating(id).then(() => fetchRatings());
                            }
                          } style={{
                            position: 'absolute',
                            right: 0,
                            top: 0
                          }} aria-label="delete" size="small">
                            <DeleteIcon color="error" fontSize="small" />
                          </IconButton>
                        </div>: null}
                      <ListItemAvatar>
                        <Avatar>
                          <ImageAvatar
                            source={userAvatarUrl}
                            text={getNameInitials(userName).toUpperCase()}
                          />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={<Typography variant="body2" style={{ color: type == 1 ? 'green' : 'red' }}>{`${formatDate(createdAt)}: ${type == 1 ? t('positive') : t('negative')} ${t('exp')}`}</Typography>} secondary={text} />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                  </div>);
              })}

            </List>
            : t("noRatings")
        }
      </div>
      <RatingForm
        isAddRatingModalOpen={isAddRatingModalOpen}
        setIsAddRatingModalOpen={setIsAddRatingModalOpen}
      />
    </>
  );
};

export default RatingPanel;





