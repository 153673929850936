import React from "react";

import {
  Grid,
  Button,
  Typography,
  CardActions,
  CardHeader,
  CardContent,
  Card
} from "@material-ui/core";
import { parseISO } from "date-fns";
import { useTranslation } from "react-i18next";

import { formatDate } from "../../../utility/common";
import { BillingPeriod } from "../../enums/enums";

import config from "../../../config";

const BillingCard = ({ classes, invoices }) => {
  const { t } = useTranslation();

  return (
    <Card className={classes.card}>
      <CardHeader title={t("settings.billing.caption")} />
      <CardContent>
        <Grid container>
          <Grid container item spacing={2}>
            <Grid item xs>
              <Typography variant="subtitle2">{t("settings.billing.dateCreated")}</Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="subtitle2">{t("settings.billing.period")}</Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="subtitle2">{t("settings.billing.status")}</Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="subtitle2">{t("settings.billing.price")}</Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="subtitle2" />
            </Grid>
          </Grid>
          {invoices.length === 0 ? (<Typography variant="subtitle1">{t("settings.none")}</Typography>) : invoices.map(({
            id, createdDate, isPaid, price, currency
          }, index) => (
              <Grid container item spacing={2} key={id}>
                <Grid item xs>
                  <Typography variant="subtitle1">{formatDate(parseISO(createdDate))}</Typography>
                </Grid>
                <Grid item xs>
                  <Typography variant="subtitle1">{t("settings.tariff.yearly")}</Typography>
                </Grid>
                <Grid item xs>
                  <Typography variant="subtitle1">{isPaid ? t("settings.billing.paid") : t("settings.billing.unpaid")}</Typography>
                </Grid>
                <Grid item xs>
                  <Typography variant="subtitle1">
                    {price}
                    {" "}
                    {currency}
                  </Typography>
                </Grid>
                <Grid item xs>
                  <Button
                    variant="outlined"
                    size="medium"
                    color="secondary"
                    href={`${config.api.url}/pdf/invoice/${id}?language=cs`}
                    target="_blank"
                    download
                  >
                    PDF
                </Button>
                </Grid>
              </Grid>
            ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default BillingCard;
