import React, { useState } from "react";
import { useSnackbar } from "notistack";
import {
  Button, Grid, CardActions, CardHeader, CardContent, Card, Typography
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

import useAuth from "../../hook/useAuth";
import { getNameInitials } from "../../../utility/common";
import ImageAvatar from "../../common/ImageAvatar/ImageAvatar";
import SettingsReauthenticate from "./ReauthenticateDialog";
import { reauthenticate } from "../../../auth";

const UserProfileCard = ({
  classes,
  customerProfile,
  handleUpdateClick,
  handleChangePassword,
  handleChangeAvatarImage,
  canDeletePersonalProfile,
  canUpdatePersonalProfile
}) => {
  const { t } = useTranslation();
  const {
    user: { email }
  } = useAuth();
  const {
    firstName,
    lastName,
    phoneNumber,
    avatarUrl
  } = customerProfile;
  const [reauthenticateChangePasswordDialogOpen, setReauthenticateChangePasswordDialogOpen] = useState(false);
  const [reauthenticateDeleteAccountDialogOpen, setReauthenticateDeleteAccountDialogOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleChangePasswordClick = (email, password) => {
    reauthenticate(email, password)
      .then(() => {
        setReauthenticateChangePasswordDialogOpen(false);
        handleChangePassword();
      })
      .catch((err) => {
        console.error(err);
        enqueueSnackbar(t("settings.error.reauthenticating"), {
          variant: "error"
        });
      });
  };

  return (
    <>
      <Card className={classes.card}>
        <CardHeader title={t("settings.customerProfile")} />
        <CardContent>
          <div className={classes.flexbox}>
            <div className={classes.leftFlex}>
              <input
                id="user-profile-avatar-file-input"
                style={{ display: "none" }}
                type="file"
                accept="image/png,image/jpeg"
                onChange={async ({ target: { files } }) => {
                  if (files.length !== 1) {
                    return;
                  }

                  const file = files[0];

                  if (file.type !== "image/png" && file.type !== "image/jpeg") {
                    enqueueSnackbar(t("settings.changeAvatarFileTypeError"), {
                      variant: "error"
                    });
                    return;
                  }

                  handleChangeAvatarImage(file);
                }}
              />
              <Button
                size="medium"
                color="secondary"
                onClick={(event) => {
                  event.preventDefault();
                  document.querySelector("#user-profile-avatar-file-input").click();
                }}
              >
                <ImageAvatar
                  classes={{
                    root: classes.purpleAvatar
                  }}
                  source={avatarUrl}
                  text={getNameInitials(`${firstName} ${lastName}`).toUpperCase()}
                />
              </Button>
            </div>
            <div className={classes.rightFlex}>
              <Grid container spacing={2}>
                <Grid item xs>
                  <Typography
                    variant="subtitle1"
                    style={{ fontWeight: "bold" }}
                  >
                    {`${firstName} ${lastName}`}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={3}>
                  <Typography variant="subtitle2">
                    {phoneNumber ? t("phone") : null}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <Typography variant="subtitle2">{phoneNumber}</Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={3}>
                  <Typography variant="subtitle2">{t("email")}</Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <Typography variant="subtitle2">{email}</Typography>
                </Grid>
              </Grid>
            </div>
          </div>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Button
            disabled={!canUpdatePersonalProfile}
            variant="outlined"
            size="medium"
            color="secondary"
            onClick={() => setReauthenticateChangePasswordDialogOpen(true)}
          >
            {t("settings.changePassword")}
          </Button>
          <Button
            disabled={!canUpdatePersonalProfile}
            variant="outlined"
            size="medium"
            color="secondary"
            onClick={() => handleUpdateClick()}
          >
            {t("update")}
          </Button>
        </CardActions>
      </Card>
      <SettingsReauthenticate
        open={reauthenticateChangePasswordDialogOpen}
        handleClose={() => setReauthenticateChangePasswordDialogOpen(false)}
        handleSubmit={handleChangePasswordClick}
        email={email}
      />
    </>
  );
};

export default UserProfileCard;
