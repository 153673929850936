import clsx from "clsx";
import React, { useState, useRef, useMemo } from "react";

import { Typography, makeStyles } from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import { Description } from "@material-ui/icons";

import { hexToRgba } from "../../../cargotic-common";

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    padding: spacing(4),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",

    border: "2px dashed",
    borderColor: palette.primary.light,

    transition: "background-color .5s ease",

    "&:hover": {
      cursor: "pointer"
    },

    "& svg": {
      color: palette.primary.dark,
      fontSize: 64,

      transition: "color .5s ease"
    },

    "& p": {
      lineHeight: 1.5
    }
  },
  input: {
    display: "none",
  },
  dragged: {
    backgroundColor: hexToRgba(palette.primary.light, 0.2),
    borderColor: palette.primary.main
  },
  error: {
    color: palette.co,
    borderColor: red[500],
    backgroundColor: hexToRgba(red[200], 0.2),

    "& svg": {
      color: red[500]
    }
  }
}));

const FileDropzone = ({
  accept,
  title,
  description,
  hasError,
  onError,
  onSelect,
  multipleFiles = true
}) => {
  const classes = useStyles();
  const inputRef = useRef();
  const [isDraggedOver, setIsDraggedOver] = useState(false);

  const handleChange = ({ target: { files } }) => {
    const elements = Array.from(files);

    if (accept) {
      const invalid = elements.filter(({ type }) => !accept.includes(type));

      if (invalid.length !== 0) {
        if (onError) {
          onError();
        }

        return;
      }
    }

    if (onSelect) {
      onSelect(elements);
    }

    inputRef.current.value = null;
  };

  const handleClick = event => {
    event.preventDefault();

    inputRef.current.click();
  };

  const handleDragEnter = () => setIsDraggedOver(true);
  const handleDragLeave = () => setIsDraggedOver(false);

  const handleDragOver = event => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = event => {
    event.preventDefault();
    event.stopPropagation();

    setIsDraggedOver(false);

    const files = Array.from(event.dataTransfer.items)
      .map(item => item.getAsFile())
      .filter(item => item);

    if (files.length === 0) {
      return;
    }

    if (accept) {
      const invalid = files.filter(({ type }) => !accept.includes(type));

      if (invalid.length !== 0) {
        if (onError) {
          onError();
        }

        return;
      }
    }

    if (onSelect) {
      onSelect(files);
    }
  };

  return (
    <>
      <input
        ref={inputRef}
        className={classes.input}
        type="file"
        onChange={handleChange}
        multiple={multipleFiles}
        accept={useMemo(() => accept ? accept.join(",") : undefined, [accept])}
      />
      <div
        className={clsx(classes.root, {
          [classes.dragged]: isDraggedOver,
          [classes.error]: hasError
        })}
        onClick={handleClick}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <section>
          <Description />
        </section>
        <section>
          <Typography>{title}</Typography>
          {
            description
              .map(content => (
                <Typography key={content} color="textSecondary">{content}</Typography>
              ))
          }
        </section>
      </div>
    </>
  );
};

export default FileDropzone;
