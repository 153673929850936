import React from "react";
import { useTranslation } from "react-i18next";

import {
  Avatar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  InputAdornment,
  Tooltip,
  IconButton,
  makeStyles
} from "@material-ui/core";

import { DriveEta as DriveEtaIcon, Error } from "@material-ui/icons";

import { getNameInitials } from "../../../cargotic-webapp-utility";
import { useApiClient } from "../../hook";
import AutosuggestTextField from "../AutosuggestTextField";

import { VehicleType } from "@cargotic/model";

const useStyles = makeStyles(({ palette }) => ({
  danger: {
    color: palette.status.danger
  }
}));

const VehicleAutosuggestTextField = ({ type, ...rest }) => {
  const client = useApiClient();
  const { t } = useTranslation();
  const classes = useStyles();

  const deletedAdornment = () => ({
    endAdornment: (
      <InputAdornment position="start">
        <Tooltip title={type === VehicleType.CAR ? t("webapp:shipment.form.error.deletedVehicle") : t("webapp:shipment.form.error.deletedTrailer")}>
          <IconButton className={classes.danger} size="small">
            <Error />
          </IconButton>
        </Tooltip>
      </InputAdornment>
    )
  })

  const resolveInputProps = () => {
    const { value } = rest;

    if (value?.isDeleted) {
      return deletedAdornment();
    }

    return {};
  }


  return (
    <AutosuggestTextField
      {...rest}
      emptySuggestionsLabel={t("webapp:common.title.noVehicleFound")}
      getSuggestionText={(suggest) => {
        if (!suggest) {
          return '';
        }

        const { manufacturer, model, licensePlate } = suggest;

        return `${manufacturer} ${model}, ${licensePlate}`
      }}
      suggest={search => (
        client.vehicle.postVehicleSuggestQuery({ query: { search, type } })
      )}
      InputProps={resolveInputProps()}
      suggestionsComponent={({ suggestions, onSuggestionClick }) => (
        <List>
          {suggestions.map(suggestion => {
            const {
              id,
              model,
              manufacturer,
              licensePlate
            } = suggestion;

            const handleClick = event => onSuggestionClick(event, suggestion);

            return (
              <ListItem key={id} button onClick={handleClick}>
                <ListItemIcon>
                  <DriveEtaIcon />
                </ListItemIcon>
                <ListItemText
                  primary={`${manufacturer} ${model}`}
                  secondary={licensePlate}
                />
              </ListItem>
            );
          })}
        </List>
      )}
    />
  );
};

export default VehicleAutosuggestTextField;
