import React from "react";

import {
    Dashboard,
    Add as AddIcon,
    Ballot as BallotIcon,
    Group as GroupIcon,
    DirectionsCar,
    ListAlt as ListAltIcon,
    Settings as SettingsIcon,
    ShowChart as ShowChartIcon,
    BubbleChart as BubbleChartIcon,
    NotListedLocation as NotListedLocationIcon,
    Help as HelpIcon,
    EmojiPeople as EmojiPeopleIcon,
    Place,
    Contacts as ContactsIcon,
    Feedback as FeedbackIcon,
    EditLocation,
    Message,
    LocationSearching,
    ViewWeek,
    ViewDay,
    Edit, Pageview
} from "@material-ui/icons";
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';

import Users from "../Users";
import Contacts from "../Contacts";
import Vehicles from "../Vehicles";
import Shipments from "../Shipments";
import Overview from "../Overview";
import Statistics from "../Statistics";
import Settings from "../Settings";
import Places from "../Places";
import Tracking from "../Tracking";
import DashboardView from "./DashboardView";
import Feedback from "../Feedback";
import TariffExpiration from "../TariffExpiration/TariffExpiration";
import TariffExpirationDispatcher from "../TariffExpiration/TariffExpirationDispatcher";
import { Shipment as NewShipment } from "../../../shipment";
import ShipmentBoard from "../ShipmentBoard";
import { VehicleProfileContainer } from "../../../../cargotic-webapp-vehicle";
import useShipmentConcept from "../../hook/useShipmentConcept";
import Ico from "../Ico";
import BulkShipmentsContainer from "../BulkShipments";

const getUserElements = (hasPermission) => {
  const canCreateShipment = hasPermission("resource.shipment.user.create");
  const canReadShipments = hasPermission("resource.shipment.user.read") || hasPermission("resource.shipment.company.read");
  const canReadTracks = hasPermission("resource.track.read");
  const canReadStatistics = hasPermission("resource.statistics.user.read") || hasPermission("resource.statistics.company.read");
  const canReadUsers = hasPermission("resource.user.read");
  const canReadVehicles = hasPermission("resource.vehicle.read");
  const canReadPlaces = hasPermission("resource.place.read");
  const canReadContacts = hasPermission("resource.contact.user.read");

  const { shipment: shipmentConcept } = useShipmentConcept();

  return [
    {
      view: DashboardView.OVERVIEW,
      path: "/",
      title: "menu.overview",
      icon: <Dashboard />,
      component: Overview
    },
    {
      isSubheader: true,
      title: "menu.subheader.shipments",
      icon: <></>
    },
    (canReadShipments
      ? {
        view: DashboardView.SHIPMENT_BOARD,
        path: "/board",
        title: "menu.board",
        icon: <ViewWeek />,
        component: ShipmentBoard
      }
      : undefined),
    (canCreateShipment
      ? {
        view: DashboardView.SHIPMENT,
        path: "/shipment",
        title: "menu.shipment",
        icon: <AddIcon />,
        showAction: shipmentConcept,
        action: <Edit style={{ width: 18, height: 18 }} color="action" />,
        component: NewShipment
      }
      : undefined),
      // (canCreateShipment
      //     ? {
      //         view: DashboardView.BULK_SHIPMENTS,
      //         path: "/bulk-shipments",
      //         title: "menu.bulkShipment",
      //         icon: <PlaylistAddIcon />,
      //         component: BulkShipmentsContainer
      //     }
      //     : undefined),
    (canReadShipments
      ? {
        view: DashboardView.SHIPMENTS,
        path: "/shipments",
        title: "menu.shipments",
        icon: <ViewDay />,
        component: Shipments
      }
      : undefined),
    (canReadTracks
      ? {
        view: DashboardView.TRACKING,
        path: "/tracking",
        title: "menu.onlineTracking",
        icon: <LocationSearching />,
        component: Tracking
      }
      : undefined),
    (canReadPlaces
      ? {
        view: DashboardView.PLACES,
        path: "/places",
        title: "menu.places",
        icon: <EditLocation />,
        component: Places
      }
      : undefined),
    {
      isSubheader: true,
      title: "menu.subheader.analysis",
      icon: <></>
    },
    (canReadStatistics
      ? {
        view: DashboardView.STATISTICS,
        path: "/statistics",
        title: "menu.statistics",
        icon: <BubbleChartIcon />,
        component: Statistics
      }
      : undefined),
    {
      isSubheader: true,
      title: "menu.subheader.overview",
      icon: <></>
    },
    (canReadUsers
      ? {
        view: DashboardView.USERS,
        path: "/users",
        title: "menu.users",
        icon: <GroupIcon />,
        component: Users
      }
      : undefined),
    (canReadVehicles
      ? {
        view: DashboardView.VEHICLES,
        path: "/vehicles",
        title: "menu.vehicles",
        icon: <DirectionsCar />,
        component: Vehicles
      }
      : undefined),
    (canReadVehicles
      ? {
        view: DashboardView.VEHICLE_OVERVIEW,
        component: VehicleProfileContainer
      }
      : undefined),
    (canReadContacts
      ? {
        view: DashboardView.CONTACTS,
        path: "/contacts",
        title: "menu.contacts",
        icon: <ContactsIcon />,
        component: Contacts
      }
      : undefined),
      {
          view: DashboardView.ICO_LOOKUP,
          path: "/ico",
          title: "menu.ico_lookup",
          icon: <Pageview />,
          component: Ico
      },
    {
      isSubheader: true,
      title: "menu.subheader.communication",
      icon: <></>
    },
    {
      view: DashboardView.SETTINGS,
      path: "/settings",
      title: "menu.settings",
      icon: <SettingsIcon />,
      component: Settings
    }, {
      view: DashboardView.FEEDBACK,
      path: "/feedback",
      title: "menu.feedback",
      icon: <FeedbackIcon />,
      component: Feedback
    }
  ]
    .filter(element => element);
}

export {
  getUserElements
};
